<template>
<!-- 计费界面：左边菜单栏和右边内容组成 -->
  <div class="balance_div">
    <div class="leftmenu_div">
      <leftnav :activeMenu="activeMenu" :items="menus"  @menu="menu"></leftnav>
    </div>
    <div class="content_div">
      <router-view name="content"></router-view>
    </div>
  </div>
</template>

<script>
import leftnav from "../../components/leftnav.vue";
export default {
  components: {
    leftnav,
  },
  data() {
    return {
      activeMenu: 88,
      menus: [],
    };
  },
  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.$store.commit('saveActiveIndex',88);
    var arr = JSON.parse(sessionStorage.getItem("menus"));
    for (let i = 0; i < arr.length; i++) {
      if(arr[i].id == 88){
        this.menus = arr[i].list;
        break;
      }
    }
    var flag = this.$route.params.subMenu;
    if(flag == 3){ /**首页-账号余额  跳转到'余额'菜单界面 */
      this.$store.commit('saveLeftMenu', 101);
      this.$router.push({ name: "balanceDetail"});
    }else if(flag == 1) { /**首页-流量池 跳转到'账单'菜单界面 */
      this.$store.commit('saveLeftMenu', 99);
      this.$router.push({ name: "bill", 
        params: {
          date: this.$route.params.date,
          name: this.$route.params.name,
        }
      });
    }else{ /** 默认点击计费进入账单界面 */
      this.$store.commit('saveLeftMenu', 99);
      this.menu(this.menus[0].id);
    }
  },

  methods: {
    menu(selectMenu) {
     if (selectMenu == 99){
        this.$router.push({ name: "bill" });
      }else  if (selectMenu == 100) {
        this.$router.push({ name: "billDetail" });
      } else  if (selectMenu == 101) {
        this.$router.push({ name: "balanceDetail" });
      }
    },
  },
};
</script>

<style scoped>
.balance_div {
  width: 100%;
  display: flex;
}
.leftmenu_div {
  width: 10%;
}
.content_div {
  width: 90%;
}
</style>