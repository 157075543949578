<template>
  <div>
    <div class="operate_nav">
      <span class="operate_nav_title">{{ title }}</span>
      <span :class="['operate_nav_btn_frame', hasFilter ? 'search_Filter' : '']" @click.prevent="showSearch">
        <span>
          <a-icon type="search" /><span style="margin-left: 3px">{{this.$t('deviceDayActive.button.search')}} </span>
        </span>
      </span>
      <span class="operate_nav_btn_frame" @click.prevent="exportData">
        <a-icon type="download" />
        {{$t('deviceDayActive.button.report')}}
      </span>
      <span class="operate_nav_btn_frame" @click.prevent="refresh">
        <a-icon type="reload" />
        {{$t('deviceDayActive.button.reload')}}
      </span>
      <export-tips
        v-if="tips"
        :total="pagination.total"
        @export-tips-visible="changeShowExportTips"  
      ></export-tips>
    </div>
    <div :class="[search.visible ? 'search_show' : 'search_hide']">
      <div class="search_div"> 
        <div style="width: 25%;">
          <span class="search_item_label">{{this.$t('deviceDayActive.searchList.useCountry')}}</span>
          <a-select
            class="iot_search_item"
            show-search
            allowClear
            option-filter-prop="children"
            :filter-option="filterOption"
            @change="handleChange"
            :placeholder="this.$t('deviceDayActive.searchList.useCountryMsg')"
          >
            <a-select-option
              allowClear
              v-for="item in countrys"
              :value="item.code"
              :key="item.code"
            >
              {{ item.country }}
            </a-select-option>
          </a-select>
        </div>
        <div style="width: 25%;">
          <span class="search_item_label">{{this.$t('deviceDayActive.searchList.status')}}</span>
          <a-select
            class="iot_search_item"
            v-model="search.status"
            allowClear
            :placeholder="this.$t('deviceDayActive.searchList.selectMsg')"
          >
            <a-select-option value="1"> {{this.$t('deviceDayActive.online')}} </a-select-option>
            <a-select-option value="0"> {{this.$t('deviceDayActive.offline')}} </a-select-option>
          </a-select>
        </div>
         <!-- <div style="width: 25%;">
          <span class="search_item_label">流量池：</span>
          <a-select
            class="iot_search_item"
            v-model="search.status"
            allowClear
            placeholder="请选择"
          >
            <a-select-option value="1"> 在线 </a-select-option>
          </a-select>
        </div> -->
        <div  style="width: 25%;">
          <span class="search_item_label">{{this.$t('deviceDayActive.searchList.date')}}</span>
          <a-config-provider >
            <a-date-picker
              class="iot_search_item"
              allowClear
              v-model="search.date"
              :showToday="false"
              :placeholder="this.$t('deviceDayActive.searchList.dateMsg')"
            />
          </a-config-provider>
        </div>
      </div>
      <div class="search_div">
        <span class="search_item_label">{{this.$t('deviceDayActive.searchList.tsid')}}</span>
        <a-textarea
          style="margin-left: 6px"
          v-model.trim="search.tsid"
          :placeholder="this.$t('deviceDayActive.searchList.tsidMsg')"
          autocomplete="off"
        />
      </div>
      <div class="search_div">
        <span class="search_item_label">{{this.$t('deviceDayActive.searchList.imei')}}</span>
        <a-textarea
          style="margin-left: 6px"
          v-model.trim="search.imei"
          :placeholder="this.$t('deviceDayActive.searchList.imeiMsg')"
          autocomplete="off"
        />
      </div>
      <span class="search_item_btn" @click.prevent="searchConfirm">{{this.$t('deviceDayActive.button.inquire')}}</span>
    </div>
    <div style="margin-top: 10px">
      <a-config-provider >
         <a-table
          :loading="loading"
          :columns="columns"
          :data-source="datas"
          :pagination="pagination"
          :rowClassName="rowClassName"
          :customRow="selectRow"
          :rowKey="(record,i)=>{return i}"
          size="middle"
        >
          <template slot="tsid" slot-scope="text, record">
            <a @click="showUsedDetail(record)">{{ record.tsid }} </a>
          </template>
          <div slot="status" slot-scope="text, record">
            <div style="color:#00e100;" v-if="record.status == 1">{{$t('deviceDayActive.online')}}</div> 
            <div v-if="record.status != 1">{{$t('deviceDayActive.offline')}}</div>
          </div>
        </a-table>
      </a-config-provider>
    </div>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import exportTips from "../../dialog/exportTipsModal.vue"
import { errMsg, isNull, successMsg, warnMsg } from "../../../utils/CommonUtils";
export default {
  components:{
    exportTips
  },
  data() {
    return {
      title: this.$t('deviceDayActive.title'),
      locale: zhCN,
      loading: true,
      tips: false,
      hasFilter: false /** 是否有搜索条件 */,
      rowId: "",
      pagination: {
        total: 0,
        pageSize: 10,
        current: 1,  
        pageSizeOptions: ["10", "20", "100", "300", "500"],
        showSizeChanger: true,
        onChange: (current, pageSize) =>
          this.changeCurrentPage(current, pageSize),
        onShowSizeChange: (current, pageSize) =>
          this.changePageSize(current, pageSize),
          showTotal: () => {
              const count = this.pagination.total;
              return this.$i18n.t('deviceDayActive.totalRecords', { count });
          },
      },
      countrys: [],
      search: {
        imei: "",
        tsid: "",
        country: "",
        status: undefined,
        date: "",
        visible: false,
      },
      datas: [],
      columns: [
        {
          title: this.$t('deviceDayActive.columns.tsid'),
          dataIndex: "tsid",
          scopedSlots: { customRender: "tsid" },
        },
        {
          title: this.$t('deviceDayActive.columns.imei'),
          dataIndex: "imei",
        },
        {
          title: this.$t('deviceDayActive.columns.account'),
          dataIndex: "account",
        },
        {
          title: this.$t('deviceDayActive.columns.date'),
          dataIndex: "date",
        },
        {
          title: this.$t('deviceDayActive.columns.remainTime'),
          dataIndex: "remainTime",
        },
        {
          title: this.$t('deviceDayActive.columns.flow'),
          dataIndex: "flow",
        },
        {
          title: this.$t('deviceDayActive.columns.country'),
          dataIndex: "country",
        },
        {
          title: this.$t('deviceDayActive.columns.status'),
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
      ],
    };
  },
  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    if (
      !isNull(this.search.status) ||
      !isNull(this.search.imei) ||
      !isNull(this.search.tsid) ||
      !isNull(this.search.country) ||
      !isNull(this.search.date)
    ) {
      this.hasFilter = true;
    }
    this.init();
  },
  methods: {
    //改变页码调用此函数
    changeCurrentPage(currentPage, pageSize) {
      this.loading = true;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pageSize;
      this.rowId = "";
      this.init();
    },
    //改变页记录数会调用此函数
    changePageSize(currentPage, pageSize) {
      this.loading = true;
      this.pagination.pageSize = pageSize;
      this.pagination.current = 1;
       this.rowId = "";
      this.init();
    },
     changeShowExportTips(show, flag, val){
      this.tips = show;
      if(flag == 1){
        this.exportReq(val)
      }
    },
    exportData() {
      if(isNull(this.datas)){
        warnMsg(this.$t('deviceDayActive.hint.warn.reportWarn'));
        return;
      }
      if(this.pagination.total > 10000){
         this.tips = true; 
      }else{
        this.exportReq(null);
      }
    },
    exportReq(val){
      let param = this.initParam()
        if(!isNull(val)){
           param.pageNo = val;
        }else{
          param.pageNo = 1;
        }
      this.axios.post(this.baseUrl + "exportTodayDevice", param, {
          responseType: "blob",
          headers: { token: sessionStorage.getItem("token"),
          'Accept-Language': localStorage.getItem('locale') },
        }).then((res) => {
          if (res.status == 200) {
            let link = document.createElement("a");
            let url = window.URL.createObjectURL(
              new Blob([res.data], {
                type: "application/vnd.ms-excel;charset=UTF-8;",
              })
            );
            link.style.display = "none";
            link.href = url;
            let fileName = window.decodeURI(res.headers['content-disposition'].split('=')[1], "UTF-8");
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); //下载完成移除元素
            window.URL.revokeObjectURL(url); //释放掉blob对象
          }
        }).catch(() => {
          errMsg(this.$t('deviceDayActive.hint.error.systemErr'));
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange(value) {
      this.search.country = value;
    },
    init() {
      this.doInit(false);
    },
    refresh(){
      this.doInit(true);
    },
    doInit(isflush){
      this.axios.post(this.baseUrl + "getTodayDevice", this.initParam(), {
          headers: { token: sessionStorage.getItem("token"),
          'Accept-Language': localStorage.getItem('locale') },
        }).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            if (res.data.code == 0) {
              if(isflush){
                successMsg(this.$t('deviceDayActive.hint.success.reload'))
              }
              this.datas = res.data.data;
              if (!isNull(res.data.count) && res.data.count > 0) {
                this.pagination.total = res.data.count;
              }
            } else if (res.data.code == 1) {
              this.datas = [];
              this.pagination.total = 0;
            } else if (res.data.code == -1) {
              warnMsg(this.$t('deviceDayActive.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if(res.data.code == -3){
              errMsg(this.$t('deviceDayActive.hint.error.systemErr'))
            } 
          } else {
            warnMsg(this.$t('deviceDayActive.hint.warn.getDateWarn'));
          }
        }).catch(() => {
          this.loading = false;
          errMsg(this.$t('deviceDayActive.hint.error.systemErr'));
        });
    },
    initParam() {
      var date = null;
      if (!isNull(this.search.date)) {
        date = this.search.date;
        date = date.format("YYYY-MM-DD");
      }
      let param = {
        tsid: this.search.tsid,
        imei: this.search.imei,
        country: this.search.country,
        date: date,
        status: isNull(this.search.status) ? null : this.search.status,
        pageNo: this.pagination.current,
        limit: this.pagination.pageSize,
      };
      return param;
    },
    showSearch() {
      if (this.search.visible) {
        this.search.visible = false;
      } else {
        this.search.visible = true;
        if (this.countrys.length == 0) {
          this.axios.post(this.baseUrl + "getCountryList", "", {
              headers: { token: sessionStorage.getItem("token"),
              'Accept-Language': localStorage.getItem('locale') },
            }).then((res) => {
              if (res.status == 200) {
                let dataArr = res.data.data;
                for (let i = 0; i < dataArr.length; i++) {
                  var json = dataArr[i];
                  var key = "key" + i;
                  var country = {
                    code: json[key].split("-")[0],
                    country: json[key].split("-")[1],
                  };
                  this.countrys.push(country);
                }
                }
            });
        }
      }
    },
    searchConfirm() {
      this.search.tsid = this.search.tsid.replace(/\s+|[\r\n]+/g,"");
      this.search.imei = this.search.imei.replace(/\s+|[\r\n]+/g, "");
      this.loading = true;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.rowId = "";
      if (
      !isNull(this.search.status) ||
      !isNull(this.search.imei) ||
      !isNull(this.search.tsid) ||
      !isNull(this.search.country) ||
      !isNull(this.search.date)
    ) {
      this.hasFilter = true;
    }else{
      this.hasFilter = false;
    }
      this.init();
    },
    showUsedDetail(record) {
      let newUrl = this.$router.resolve({
        path: "/device/Detail",
        query:{
          tsid: record.tsid,
          date: record.date,
        }
      });
      window.open(newUrl.href, "_blank");
    },
    rowClassName(record, index) {
      if(index === this.rowId){
        return "select_row_bg_color"; /** 返回的是类名，在table.css文件中有 */
      }else{
        return "";
      }
    },
    selectRow(record, index){
       return {
            on: {
               click: () => {
                  this.rowId = index;
               }
            }
        }
    },  
  },
};
</script>
<style scoped>
@import '../../../assets/css/table.css';
/* 操作栏 */
.operate_nav {
  margin-top: 10px;
  text-align: right;
}

.operate_nav_title {
  /* 整行标题 */
  float: left;
  font-size: 24px;
  font-weight: bold;
  color: rgb(14, 14, 14);
}

.operate_nav_btn_frame {
  margin: 7px 0px 0px;
  display: inline-block;
  text-align: center;
  min-width: 70px;
  border: 1px solid rgb(160, 160, 160);
  height: 30px;
  line-height: 30px;
  margin-left: 15px;
  border-radius: 5px;
}

.operate_nav_btn_frame:hover {
  cursor: pointer;
  color: rgb(78, 78, 255);
  border: 1px solid rgb(78, 78, 255);
}

/*搜索框 */
.search_div {
  display: flex;
  margin-bottom: 10px;
  align-items: flex-end;
}

.iot_search_item {
  width: calc(100% - 80px);
}

.search_item_btn {
  float: right;
  display: block;
  width: 50px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  border: 1px solid gray;
  text-align: center;
}

.search_item_btn:hover {
  cursor: pointer;
  color: rgb(78, 78, 255);
  border: 1px solid rgb(78, 78, 255);
}

.search_item_label {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  width: 80px;
  text-align: right;
}

.search_show {
  display: block;
  text-align: left;
  margin: 20px 0px 35px;
  transition: all 3s ease-in;
}

.search_hide {
  display: none;
  transition: all 3s ease-out;
}
.search_Filter {
  background-color: #1bb1f6;
  color: #fff;
}
.search_Filter:hover {
  border: 1px solid #fff;
  color: #fff !important;
}
::v-deep .ant-table-small {
  border: none;
}
</style>
 