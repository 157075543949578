<template>
  <div style="margin: 30px">
    <span class="operate_nav_title">{{ title }}</span>
    <div style="text-align: right; margin-bottom: 10px">
      {{$t('deviceUseDetail.changeOperator')}}
      <a-select
        style="width:230px;margin-right:10px;"
        @change="changeOperator"
        v-model="operator"
        :placeholder="$t('deviceUseDetail.selectMsg')"
      >
      <a-select-option v-for="item in operatorList" :key="item.id" :value="item.carrierAccess">
          {{ item.carrierAccess }}
        </a-select-option>
      </a-select>
          
      {{$t('deviceUseDetail.date')}}<a-date-picker 
      allowClear
      :showToday="false"
      style="width:230px;margin-right:10px;"
      @change="selectDate" :placeholder="$t('deviceUseDetail.selectDateMsg')" />

      <span class="search_item_btn" @click.prevent="refresh">{{$t('deviceUseDetail.reload')}}</span>
    </div>
    <a-config-provider >
       <a-table
        :loading="loading"
        :columns="columns"
        :data-source="datas"
        :rowKey="(record,i)=>{return i}"
        :pagination="pagination"
        :rowClassName="rowClassName"
        :customRow="selectRow"
        size="middle"
      >

        <template slot="type" slot-scope="text, record">
          <span v-if="record.type == 0">{{$t('deviceUseDetail.initialHearbeat')}}</span>
          <span v-else-if="record.type == 1">{{$t('deviceUseDetail.normalHearbeat')}}</span>
          <span v-else-if="record.type == 2">{{$t('deviceUseDetail.reissueHearbeat')}}</span>
        </template>
      </a-table>
    </a-config-provider>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { errMsg,isNull,successMsg,warnMsg,formatNumber } from "../../../utils/CommonUtils.js";
export default {
  data() {
    return {
      title: this.$t('deviceUseDetail.title'),
      locale: zhCN,
      loading: true,
      operator: undefined,
      operatorList: [],
      tsid: "",
      date: "",
      rowId: "",
      pagination: {
        total: 0,
        pageSize: 500,
        current: 1,
        pageSizeOptions: ["15", "20", "100", "300", "500"],
        showSizeChanger: true,
        onChange: (current, pageSize) =>
          this.changeCurrentPage(current, pageSize),
        onShowSizeChange: (current, pageSize) =>
          this.changePageSize(current, pageSize),
          showTotal: () => {
              const count = this.pagination.total;
              return this.$i18n.t('deviceUseDetail.totalRecords', { count });
          },
      },
      datas: [],
      columns: [
        {
          title: this.$t('deviceUseDetail.columns.tsid'),
          dataIndex: "tsid",
        },
        {
          title: this.$t('deviceUseDetail.columns.country'),
          dataIndex: "country",
        },
        {
          title: this.$t('deviceUseDetail.columns.type'),
          dataIndex: "type",
          scopedSlots: { customRender: "type" },
        },
        {
          title: this.$t('deviceUseDetail.columns.date'),
          dataIndex: "date",
        },
        {
          title: this.$t('deviceUseDetail.columns.flow'),
          dataIndex: "flow",
          customRender: (text)=>{
            return formatNumber(text)
          }
        },
        {
          title: this.$t('deviceUseDetail.columns.newCount'),
          dataIndex: "newCount",
        },
        {
          title: this.$t('deviceUseDetail.columns.operator'),
          dataIndex: "operator",
        },
        {
          title: this.$t('deviceUseDetail.columns.signalType'),
          dataIndex: "signalType",
        },
        {
          title: this.$t('deviceUseDetail.columns.signal'),
          dataIndex: "signal",
        },
        {
          title: this.$t('deviceUseDetail.columns.status'),
          dataIndex: "status",
        },
      ],
    };
  },
  created(){
  const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.date = this.$route.query.date;
    this.tsid = this.$route.query.tsid;
    if(isNull(this.tsid) || isNull(this.date)){
      warnMsg(this.$t('deviceUseDetail.hint.warn.getDataWarn'));
      return;
    }
    this.init();
  },
  methods: {
    /** 切换页码 */
    changeCurrentPage(currentPage, pageSize) {
      this.pagination.current = currentPage;
      this.pagination.pageSize = pageSize;
      this.rowId = "";
    },
    /** 改变每页显示记录数 */
    changePageSize(currentPage, pageSize) {
      this.pagination.pageSize = pageSize;
      this.pagination.current = 1;
       this.rowId = "";
    },
    changeOperator(val, options){
      var currentObj = this; //解决this指向问题
      if(val == this.datas[0].operator){
        warnMsg(this.$t('deviceUseDetail.hint.warn.changeOperatorWarn'))
        return;
      }
      var param = {
        tsid: this.tsid,
        mapId: options.key,
      }
      this.$modal.confirm({
        content: this.$t('deviceUseDetail.content',{val}),
        okText: this.$t('deviceUseDetail.okText'),
        cancelText: this.$t('deviceUseDetail.cancelText'),
        onOk() {
        currentObj.loading = true;
        currentObj.axios.post(currentObj.baseUrl + "changeCarrierAccess", param , {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          currentObj.loading = false;
          if (res.status == 200) {
            if (res.data.code == 0) {
              successMsg(this.$t('deviceUseDetail.hint.success.changeOperator'))
              currentObj.init();
            }else if (res.data.code == -1){
              warnMsg(this.$t('deviceUseDetail.hint.warn.loginWarn'));
              setTimeout(() => {
                currentObj.$router.push({ name: "login" });
              }, "2000");
            }else if(res.data.code == -3){
              errMsg(this.$t('deviceUseDetail.hint.error.systemErr'));
            }else{
              warnMsg(res.data.message);
            }
          } else {
            errMsg(this.$t('deviceUseDetail.hint.error.changeOperatorErr'));
          }
        }).catch(() => {
          currentObj.loading = false;
          errMsg(this.$t('deviceUseDetail.hint.error.systemErr'));
        });
        },
        onCancel() {
          warnMsg(currentObj.$t('deviceUseDetail.hint.warn.cancelWarn'));
        },
      });
    },
    init() {
      this.axios.post(this.baseUrl + "getDailyByTsid?tsid="+this.tsid+"&date="+this.date, "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.datas = res.data.data
              this.handlerFunit(res.data.funit)
              this.pagination.total = this.datas.length
              this.initOperator();
            }else if(res.data.code == 1){
              this.datas = [];
              this.pagination.total = 0;
            }else if(res.data.code == -1){
              warnMsg(this.$t('deviceUseDetail.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }else if(res.data.code == -3){
              errMsg(this.$t('deviceUseDetail.hint.error.systemErr'));
            }else if(res.data.code == -2){
              warnMsg(this.$t('deviceUseDetail.hint.warn.retryWarn'));
            }else if(res.data.code == 2){
              warnMsg(this.$t('deviceUseDetail.hint.warn.getDataWarn2'));
            }
          } else {
            errMsg(this.$t('deviceUseDetail.hint.error.getDataWarn'));
          }
        }).catch(() => {
          this.loading = false;
          errMsg(this.$t('deviceUseDetail.hint.error.systemErr'));
        });
    },
    initOperator() {
      this.axios.post(this.baseUrl + "getSupportCarrierAccess/"+this.datas[0].countryCode, "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            if (res.data.code == 0) {
             this.operatorList = res.data.data;
            }else if (res.data.code == -1){
              warnMsg(this.$t('deviceUseDetail.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }else if(res.data.code == -2){
              warnMsg(this.$t('deviceUseDetail.hint.warn.changeOperatorWarn2'))
           }else if(res.data.code == -3){
              errMsg(this.$t('deviceUseDetail.hint.error.systemErr'));
            }
          } else {
            errMsg(this.$t('deviceUseDetail.hint.error.getDataWarn'));
          }
        }).catch(() => {
          this.loading = false;
          errMsg(this.$t('deviceUseDetail.hint.error.systemErr'));
        });
    },
    refresh(){
      this.axios.post(this.baseUrl + "getDailyByTsid?tsid="+this.tsid+"&date="+this.date, "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            if (res.data.code == 0) {
              successMsg(this.$t('deviceUseDetail.hint.success.reload'))
              this.datas = res.data.data
              this.pagination.total = this.datas.length
              this.initOperator();
            }else if(res.data.code == 1){
              successMsg(this.$t('deviceUseDetail.hint.success.reload'))
              this.datas = [];
              this.pagination.total = 0;
            }else if(res.data.code == -1){
              warnMsg(this.$t('deviceUseDetail.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }else if(res.data.code == -3){
              errMsg(this.$t('deviceUseDetail.hint.error.systemErr'));
            }else if(res.data.code == -2){
              warnMsg(this.$t('deviceUseDetail.hint.warn.retryWarn'));
            }else if(res.data.code == 2){
              warnMsg(this.$t('deviceUseDetail.hint.warn.getDataWarn2'));
            }
          } else {
            errMsg(this.$t('deviceUseDetail.hint.error.reloadErr'));
          }
        }).catch(() => {
          this.loading = false;
          errMsg(this.$t('deviceUseDetail.hint.error.systemErr'));
        });
    },
    /** 日期条件 */
    selectDate(moment, date) {
      if(isNull(date)){
        return;
      }
      var d = new Date(date);
      d.setHours(0);
      if(d.getTime() > new Date().getTime()){
        warnMsg(this.$t('deviceUseDetail.hint.warn.dateWarn'));
        return;
      }
      this.operator = undefined;
      this.operatorList = [];
      this.loading = true;
      this.date = date;
      this.pagination.current = 1;
      this.pagination.pageSize = 500;
      this.rowId = "";
      this.init();
    },
    rowClassName(record, index) {
      if(index === this.rowId){
        return "select_row_bg_color";
      }else{
        return "";
      }
    },
    selectRow(record, index){
       return {
            on: {
               click: () => {
                  this.rowId = index;
               }
            }
        }
    }, 
    handlerFunit(funit){
      for(var k = 0; k<this.columns.length; k++){
        if(this.columns[k].dataIndex==="flow"){
          this.columns[k].title = this.$t('deviceUseDetail.columns.flow')+"("+funit+")";
          continue;
        }
      }
    }
  },
};
</script>
<style scoped>
@import '../../../assets/css/table.css';
.operate_nav_title {
  /* 整行标题 */
  float: left;
  font-size: 24px;
  font-weight: bold;
  color: rgb(14, 14, 14);
}


.search_item_btn {
  margin-left: 15px;
  float: right;
  display: block;
  width: 50px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  border: 1px solid gray;
  text-align: center;
}

.search_item_btn:hover {
  cursor: pointer;
  color: rgb(137, 137, 255);
  border: 1px solid rgb(137, 137, 255);
}
.search_item_btn:active{
  cursor: pointer;
  color: rgb(0, 0, 255);
  border: 1px solid rgb(0, 0, 255);
}
::v-deep .ant-table-small {
  border: none;
}
</style>
 