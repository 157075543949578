<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="66%"
    :footer="null"
    :maskClosable="false"
    :dialogStyle="{ top: '10%' }"
    @cancel="handleCancel"
  >
    <div style="padding: 20px 24px 10px">
      <div>
        <div v-if="visibleGroup" class="auto_sub_div">
          <span>{{$t('buyPacket.selectGroup')}}</span
          ><a-select
            :placeholder="$t('buyPacket.selectGroupMsg')"
            allowClear
            v-model="groupName"
            class="select_list"
          >
            <a-select-option
              v-for="(group, i) in groups"
              :value="group"
              :key="i"
              >{{ group }}</a-select-option
            >
          </a-select>
        </div>
        <div class="auto_sub_div">
          <span>{{$t('buyPacket.search')}}</span>
          <a-select
            :placeholder="$t('buyPacket.selectCountryMsg')"
            allowClear
            v-model="selectCountry"
            class="select_list"
          >
            <a-select-option
              v-for="countrys in data"
              :value="countrys.ccode"
              :key="countrys.ccode"
            >
              {{ $i18n.locale=='zh' ? countrys.showName : countrys.showNameEn}}
            </a-select-option>
          </a-select>
        </div>
        <div v-if="isDayFlow" class="auto_sub_div">
          <span>{{$t('buyPacket.beginTime')}}</span>
          <a-date-picker
            class="select_list"
            :showToday="false"
            @change="changeDate"
            :placeholder="$t('buyPacket.selectDateMsg')"
          />
        </div>
        <div style="height: 300px; overflow: auto">
          <a-collapse accordion v-if="data">
            <a-collapse-panel
              v-for="item in showData"
              :key="item.ccode"
              :header="$i18n.locale=='zh' ? item.showName : item.showNameEn"
            >
              <div>
                <span
                  v-for="packet in item.priceflow"
                  :class="[
                    'item_packet',
                    'cursor_attr',
                    selectPacket.attachid == packet.attachid
                      ? 'select'
                      : 'unselect',
                  ]"
                  :key="packet.id"
                  @click.prevent="choocePacket(packet)"
                  >{{
                    packet.showName == "" ? packet.packetName : packet.showName
                  }}</span
                >
              </div>
              <div slot="extra">
                <a-popover :title="$t('buyPacket.supportCountry')" placement="bottom">
                  <div
                    :class="[item.useCn.length > 30 ? 'support_country' : '']"
                    slot="content"
                  >
                    {{ $i18n.locale=='zh' ? item.useCn : item.useEn }}
                  </div>
                  <a-icon type="question-circle" :title="$t('buyPacket.supportCountry')" />
                </a-popover>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
        <div class="auto_sub_btn">
          <span v-if="selectPacket.attachid"
            >{{$t('buyPacket.selectPacketMsg')}}&nbsp;
            <span style="color: red">{{ selectPacket.country }}</span>
            <span
              >&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{{
                selectPacket.name
              }}</span
            >
            <span
              >&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{{
                selectPacket.days
              }}</span
            >
            <span v-if="selectPacket.price != -1">
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<span style="color: red"
                >¥&nbsp;</span
              >
              <span>{{ selectPacket.price }}</span>
            </span>
            <span v-if="selectPacket.time"
              >&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<span style="color: red">{{
                selectPacket.time
              }}</span></span
            >
          </span>
          <span class="auto_sub_confirm cursor_attr" @click.prevent="confirm"
            >{{$t('buyPacket.define')}}</span
          >
          <span class="auto_sub_cancel cursor_attr" @click.prevent="cancel"
            >{{$t('buyPacket.cancel')}}</span
          >
        </div>
      </div>
    </div>
    <a-modal
      v-model="subVisible"
      width="250px"
      :footer="null"
      :closable="false"
      :maskClosable="false"
      :dialogStyle="{ top: '36%' }"
    >
      <div class="take_effect_time">
        <div style="margin-top: 15px; color: black">
          {{$t('buyPacket.packageTime')}}{{ selectPacket.count }}{{$t('buyPacket.month')}}
        </div>
        <div style="margin-top: 25px">
          <span class="current_month_start cursor_attr" @click="curMonthStart"
            >{{$t('buyPacket.effectiveMonth')}}</span
          >
          <span class="next_month_start cursor_attr" @click="nextMonthStart"
            >{{$t('buyPacket.nextEffectiveMonth')}}</span
          >
        </div>
      </div>
    </a-modal>
  </a-modal>
</template>
<script>
import { errMsg, warnMsg,isNull,successMsg,} from "../../../utils/CommonUtils";
import moment from "moment";
export default {
  props: ["visible", "visibleGroup", "selectedRow"],
  data() {
    return {
      title: this.$t('buyPacket.title'),
      showModal: false,
      subVisible: false /** 本月生效,次月生效显示控制 */,
      selectCountry: undefined,
      groupName: undefined,
      startTime: "",/** 天包开始时间 */
      endTime: "",/** 天包结束时间 */
      isDayFlow: false,/** 是否是天包 */
      groups: [],
      selectPacket: {
        attachid: "",
        country: "",
        name: "",
        price: "",
        days: "",
        time: "",
        count: "",
        countrycode: "",
        timeUnit: "",
        immediately: false,
      },
      data: [],
      showData: [],
    };
  },
  created(){
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.showModal = true;
    this.init();
  },
  watch: {
  //   visible(val) {
  //     this.showModal = val;
  //     if (this.showModal) {
  //       this.init();
  //     } else {
  //       this.reset();
  //     }
  //   },
    /** 通过国家过滤套餐 */
    selectCountry(newVal) {
      if (isNull(newVal)) {
        this.showData = this.data;
      } else {
        this.showData = this.data.filter((item) => {
          return item.ccode == newVal;
        });
      }
    },
  },
  methods: {
    /** 选择天包开始时间 */
    changeDate(dayjs, dateStr) {
      this.startTime = dateStr;
      if(isNull(this.selectPacket.days)){
        // this.endTime = moment(dateStr).add(1,"days").format("YYYY-MM-DD");
        warnMsg(this.$t('buyPacket.hint.warn.packetWarn'))
      }else{
        let dayCount = this.selectPacket.days;
        dayCount = dayCount.replace(this.$t('buyPacket.day'),"");
        this.endTime = moment(dateStr).add(dayCount,"days").format("YYYY-MM-DD");
      }
      this.selectPacket.immediately = true;
      this.selectPacket.time = this.startTime + " / " + this.endTime;
    },

    //选中流量包套餐(这里主要是拼接套餐信息,显示在了弹窗最下面)
    choocePacket(packet) {
      this.selectPacket.time = "";
      this.selectPacket.attachid = packet.attachid;
      this.selectPacket.name = isNull(packet.showName) ? packet.packetName : packet.showName;
      this.selectPacket.country = this.$i18n.locale == 'zh' ?  packet.countryName : packet.countryNameEn;
      this.selectPacket.price = packet.price;
      this.selectPacket.countrycode = packet.countrycode;
      this.selectPacket.timeUnit = packet.time_unit;
      if (packet.time_unit == 0) {
        this.selectPacket.days = packet.days + this.$t('buyPacket.day');
        this.isDayFlow = true;
      } else if (packet.time_unit == 1) {
        this.selectPacket.days = packet.days + this.$t('buyPacket.month');
        this.subVisible = true;
        this.isDayFlow = false;
        this.selectPacket.count = packet.days;
      }
    },
    cancel() {
      this.showModal = false;
      this.$emit("buy-packet-visible", false, 0);
    },
    /** 当月生效 */
    curMonthStart() {
      this.getDate(0);
      this.selectPacket.immediately = true;
      this.subVisible = false;
    },
    getImei() {
      let imeis = "";
      if (this.selectedRow.length > 1) {
        this.selectedRow.forEach((item) => {
          imeis = imeis + item.imei + ",";
        });
      } else if (this.selectedRow.length == 1) {
        imeis = this.selectedRow[0].imei;
      }
      return imeis;
    },
    getDate(flag) {
      var date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth() + 1;
      let lastDay, month;
      if (flag == 1) {
        //次月生效
        m = m + 1;
      }
      lastDay = new Date(y, m, 0);
      month = m > 9 ? m : "0" + m;
      this.selectPacket.time =
        y + "-" + month + "-01 / " + y + "-" + month + "-" + lastDay.getDate();
    },
    confirm() {
      if (isNull(this.groupName) &&
        this.groupName !== 0 &&
        isNull(this.getImei())
      ) {
        warnMsg(this.$t('buyPacket.hint.warn.selectImeiWarn'));
        return;
      }
      if (isNull(this.selectPacket.attachid)) {
        warnMsg(this.$t('buyPacket.hint.warn.selectPacketWarn'));
        return;
      }
      if(this.selectPacket.timeUnit == 0 && isNull(this.startTime)){
        warnMsg(this.$t('buyPacket.hint.warn.selectDateWarn'));
        return;
      }
      this.axios.post(this.baseUrl + "submitByType", this.params(), {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              successMsg(this.$t('buyPacket.hint.success.subscribe'));
              this.showModal = false;
              this.$emit("buy-packet-visible", false, 1);
            } else if (res.data.code == -1) {
              warnMsg(this.$t('buyPacket.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -2) {
              warnMsg(this.$t('buyPacket.hint.warn.subscribeWarn2'));
            } else if (res.data.code == -3) {
              warnMsg(this.$t('buyPacket.hint.warn.subscribeWarn3'));
            } else if (res.data.code == -4) {
              warnMsg(this.$t('buyPacket.hint.warn.subscribeWarn4'));
            } else if (res.data.code == -5) {
              warnMsg(this.$t('buyPacket.hint.warn.subscribeWarn5'));
            } else if (res.data.code == -6) {
              errMsg(this.$t('buyPacket.hint.error.subscribeErr1'));
            } else if (res.data.code == -7) {
              errMsg(this.$t('buyPacket.hint.error.systemErr'));
            }
          }
        }).catch(() => {
          errMsg(this.$t('buyPacket.hint.error.systemErr'));
        });
    },

    handleCancel() {
      this.showModal = false;
      this.$emit("buy-packet-visible", false, 0);
    },
    init() {
      this.axios.post(this.baseUrl + "getPackageInfo", "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              if (res.data.data.length > 0) {
                var data = res.data.data;
                for (let i = 0; i < data.length; i++) {
                  let item = data[i];
                  let packets = item.priceflow;
                  for (let j = 0; j < packets.length; j++) {
                    let packet = packets[j];
                    if (isNull(packet.showName)) {
                      if (packet.time_unit == 0) {
                        //天
                        packet.packetName = packet.count + packet.count_unit + "/" +
                          (packet.days == 365 ? this.$t('buyPacket.year') : packet.days == 730 ? "2"+this.$t('buyPacket.year')
                            : packet.days == 30 || packet.days == 31 ? this.$t('buyPacket.month') : packet.days + this.$t('buyPacket.day'));
                      } else if (packet.time_unit == 1) {
                        //月
                        packet.packetName =  packet.count + packet.count_unit + "/" + (packet.days > 1 ? packet.days + this.$t('buyPacket.month') : this.$t('buyPacket.month'));
                      }
                    }
                  }
                }
                this.data = data;
                this.showData = data;
              }
            } else if (res.data.code == -1) {
              warnMsg(this.$t('buyPacket.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -3) {
              errMsg(this.$t('buyPacket.hint.error.systemErr'));
            }
          }
        }).catch(() => {
          errMsg(this.$t('buyPacket.hint.error.systemErr'));
        });

      if (this.visibleGroup) {
        this.axios.post(this.baseUrl + "getDeviceGroup", "", {
            headers: { token: sessionStorage.getItem("token") },
          }).then((res) => {
            if (res.status == 200) {
              if (res.data.code == 0) {
                this.groups = res.data.data;
              } else if (res.data.code == -1) {
                warnMsg(this.$t('buyPacket.hint.warn.loginWarn'));
                setTimeout(() => {
                  this.$router.push({ name: "login" });
                }, "2000");
              } else if (res.data.code == -2) {
                warnMsg(this.$t('buyPacket.hint.warn.retryWarn'));
              }
            }
          }).catch(() => {
            errMsg(this.$t('buyPacket.hint.error.systemErr'));
          });
      }
    },
    /** 次月生效 */
    nextMonthStart() {
      this.getDate(1);
      this.selectPacket.immediately = false;
      this.subVisible = false;
    },

    // reset() {
    //   this.selectPacket.attachid = "";
    //   this.selectPacket.country = "";
    //   this.selectPacket.name = "";
    //   this.selectPacket.price = "";
    //   this.selectPacket.days = "";
    //   this.selectPacket.count = "";
    //   this.selectPacket.time = "";
    //   this.selectCountry = undefined;
    // },
    params() {
      // subType：  0-分组组名称，1-终端imei
      //condition： subType不同情况下的具体值
      var condition, subType;
      if (this.visibleGroup) {
        subType = 0;
        condition = this.groupName;
      } else {
        subType = 1;
        condition = this.getImei();
      }

      let param = {
        subType: subType,
        condition: condition,
        attach: this.selectPacket.attachid,
        countrycode: this.selectPacket.countrycode,
        immediately: this.selectPacket.timeUnit == 0 ? true : this.selectPacket.timeUnit == 1 ? this.selectPacket.immediately : false, 
        // immediately: this.selectPacket.timeUnit == 0 ? false : this.selectPacket.timeUnit == 1 ? this.selectPacket.immediately : false, 
        startT: this.selectPacket.immediately ? this.selectPacket.time : "",
      };
      return param;
    },
  },
};
</script>
<style scoped>
* {
  font-size: 15px;
}
.iot_search_item {
  width: calc(100% - 80px);
}
.auto_sub_div {
  display: inline-block;
  width: 50%;
  margin-bottom: 10px;
}
.select_list {
  width: 70%;
  margin-right: 50px;
}

/* .auto_sub_div {
  height: 36px;
  line-height: 36px;
  margin-bottom: 10px;
  font-size: 15px;
} */
.auto_sub_btn {
  height: 33px;
  line-height: 33px;
  margin: 20px 0 0 0;
}

.auto_sub_cancel,
.auto_sub_confirm {
  float: right;
  border-radius: 5px;
  text-align: center;
  display: block;
  height: 33px;
  width: 60px;
  line-height: 33px;
}
.auto_sub_cancel {
  margin-right: 15px;
  border: 1px solid rgb(184, 184, 184);
}
.auto_sub_cancel:hover {
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}
.auto_sub_confirm {
  border: 1px solid rgb(78, 78, 255);
  background-color: rgb(78, 78, 255);
  color: white;
}
.auto_sub_confirm:hover {
  box-shadow: 0px 0px 2px 0px rgb(23, 23, 255);
}

.item_packet {
  display: inline-block;
  min-width: 80px;
  padding: 0px 3px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #acacac;
  margin: 3px 10px 3px 0px;
  border-radius: 3px;
}

.select {
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}
.support_country {
  width: 360px;
  height: 150px;
  overflow-y: scroll;
}
.unselect:hover {
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}
.take_effect_time {
  height: 120px;
  text-align: center;
  padding-top: 15px;
}

.next_month_start,
.current_month_start {
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  height: 28px;
  width: 80px;
  line-height: 28px;
}
.next_month_start {
  border: 1px solid rgb(219, 140, 255);
  background-color: rgb(219, 140, 255);
  color: white;
}
.next_month_start:active,
.current_month_start:active {
  box-shadow: 0px 0px 3px 0px rgba(2, 2, 255, 0.5);
}

.current_month_start {
  border: 1px solid rgb(53, 181, 255);
  background-color: rgb(53, 181, 255);
  color: white;
  margin-right: 10px;
}
.cursor_attr:hover {
  cursor: pointer;
}
::v-deep .ant-modal-body {
  padding: 0px;
}

::v-deep .ant-collapse {
  border: none;
}
</style>