<template>
  <div>
  </div>
</template>
<script>
 /** 银行卡充值界面 */
export default {
  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
      let form = this.$route.query.form;
      const div = document.createElement("div");
      div.innerHTML = form;
      document.body.appendChild(div);
      document.forms[0].submit();
  },
};
</script>
<style scoped>
</style>