<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="68%"
    :footer="null"
    :maskClosable="false"
    :dialogStyle="{ top: '16%' }"
    @cancel="handleCancel"
  >
    <div style="padding: 12px 24px; background-color: #f7f7f7">
      <div style="text-align: right; margin-bottom: 10px">
        <span class="batch_group_clear cursor_attr" @click.prevent="verifyDeviceBelong(0)"
          >{{$t('batchGroup.clearGroup')}}</span>
      </div>
      <div class="batch_group_content" >
        <div>
          <span class="batch_group_label">{{$t('batchGroup.groupName')}}</span>
          <a-input v-model="groupName" :placeholder="$t('batchGroup.inputGroupNameMsg')" style="width: 70%" />
        </div>
        <div>
          <span class="batch_group_label">{{$t('batchGroup.existingGroup')}}</span>
          <span>{{ oldGroup }}</span>
        </div>
      </div>
      <div style="text-align: right; padding-top: 24px">
        <span class="batch_group_cancel cursor_attr" @click.prevent="cancel">{{$t('batchGroup.cancel')}}</span>
        <span class="batch_group_confirm cursor_attr" @click.prevent="verifyDeviceBelong(1)">{{$t('batchGroup.define')}}</span>
      </div>
    </div>
     <tip-modal
      v-if="tipVisible"
      :content="content"
      :tipTitle="tipTitle"
      @tip-visible="tipCallback"
    >
    </tip-modal>
  </a-modal>
</template>
<script>
import tipModal from "./tipModal.vue";
import {warnMsg, errMsg,isNull, successMsg} from "../../../utils/CommonUtils.js"
export default {
   components:{
    tipModal
  },
  props: ["visible","selectedRow"],
  data() {
    return {
      title: this.$t('batchGroup.title'),
      showModal: false,
      oldGroup: "",
      groupName: "",
      tipVisible: false,
      content: "",
      allImei: "",
      passImei: "",
      tipTitle: "",
      flag: "",
    };
  },
  watch: {
    visible(val) {
      this.showModal = val;
      if(this.showModal){
        this.init();
      }
    },
  },
  methods: {
    cancel() {
      this.groupName = "";
      this.$emit("batch-group-visible", false, 0);
    },
    confirm(imei) {
      if(isNull(imei)){
        warnMsg(this.$t('batchGroup.hint.warn.addGroupWarn'));
        return;
      }
      this.axios.post(this.baseUrl + "grouping?imei="+imei+"&groupName="+this.groupName,"",{ headers: { token: sessionStorage.getItem("token")}}).then((res)=>{
            if(res.status == 200){
              if(res.data.code == 0){
                  successMsg(this.$t('batchGroup.hint.success.updateGroup'))
                  this.groupName = "";
                  this.$emit("batch-group-visible", false, 1);
              }else if(res.data.code == 1){
                warnMsg(this.$t('batchGroup.hint.warn.updateGroupWarn'))
              }else if(res.data.code == -2){
               warnMsg(this.$t('batchGroup.hint.warn.updateGroupWarn2'))
            }else if(res.data.code == -3){
               errMsg(this.$t('batchGroup.hint.error.systemErr'))
              } 
            }else{
              errMsg(this.$t('batchGroup.hint.warn.retryWarn'))
            }
          }).catch(()=>{
            errMsg(this.$t('batchGroup.hint.error.systemErr'))
          })
    },
    clearGroup(imei) {
      if(isNull(imei)){
        warnMsg(this.$t('batchGroup.hint.warn.clearGroupWarn'));
        return;
      }
       this.axios.post(this.baseUrl + "clear_bissgroup?imei="+imei,"",{headers:{token:sessionStorage.getItem("token")}}).then((res)=>{
          if(res.status == 200){
            if(res.data.code == 0){
              successMsg(this.$t('batchGroup.hint.success.clearGroup'))
              this.groupName = "";
              this.$emit("batch-group-visible", false, 1);
            }else if(res.data.code == 1){
              warnMsg(this.$t('batchGroup.hint.warn.clearGroupWarn1'))
            }else if(res.data.code == -1){
              warnMsg(this.$t('batchGroup.hint.warn.loginWarn'))
              setTimeout (()=>{
                this.$router.push({ name: "login"});
              },"2000");
            }else if(res.data.code == -2){
               warnMsg(this.$t('batchGroup.hint.warn.clearGroupWarn1'))
            }else if(res.data.code == -3){
               errMsg(this.$t('batchGroup.hint.error.systemErr'))
            } 
          }else{
            errMsg(this.$t('batchGroup.hint.warn.retryWarn'))
          }
        }).catch(()=>{
          errMsg(this.$t('batchGroup.hint.error.systemErr'))
        })
    },
    getImei(){
      var imeis = "";
        if (this.selectedRow.length > 1) {
          this.selectedRow.forEach((item) => {
            imeis = imeis + item.imei + ",";
          });
        } else  if (this.selectedRow.length == 1) {
          imeis = this.selectedRow[0].imei;
        }
      return imeis;
    },
    getImeiStr(imei){
      let imeis = "";
      if (imei.length > 1) {
          for (let i = 0; i < imei.length; i++) {
            imeis = imeis + imei[i] + ",";          
          }
      } else if (imei.length == 1){
        return imei[0];
      }
      return imeis;
    },
    handleCancel() {
      this.groupName = "";
      this.$emit("batch-group-visible", false, 0);
    },
    init(){
       this.axios.post(this.baseUrl + "getDeviceGroup","",{headers: {token: sessionStorage.getItem("token")}}).then((res)=>{
          if(res.status == 200){
             if(res.data.code == 0){
               if(!isNull(res.data.data)){
                 this.oldGroup = (res.data.data + "")
                 this.oldGroup = this.oldGroup.replaceAll(",","，")
               }
             }else if(res.data.code == -1){
                warnMsg(this.$t('batchGroup.hint.warn.loginWarn'))
                setTimeout (()=>{
                  this.$router.push({ name: "login"});
                },"2000");
             }else if(res.data.code == -2){
               warnMsg(this.$t('batchGroup.hint.warn.retryWarn'))
             }
          }
        }).catch(()=>{
            errMsg(this.$t('batchGroup.hint.error.systemErr'))
        })
    },
    
    /** 提示回调 */ 
    tipCallback(val, flag0){ 
      this.tipVisible = val;
      if (flag0 == 1) { //取消
        if(this.flag == 0){
          this.clearGroup(this.passImei);
        }else if(this.flag == 1){
          this.confirm(this.passImei)
        }
      }else if(flag0 == 2){ //确认
        if(this.flag == 0){ //清除备注
          this.clearGroup(this.allImei);
        }else if(this.flag == 1){ //添加备注
          this.confirm(this.allImei)
        }
      }
    },
    /** 验证终端归属，如果终端归属账号不是当前登录账号，则提示用户是否继续操作 */
    verifyDeviceBelong(flag){
      let imei = this.getImei()
      if (isNull(imei)) {
            warnMsg(this.$t('batchGroup.hint.warn.selectImeiWarn'));
            return;
      }
      if(flag == 1){
        if(isNull(this.groupName)){
          warnMsg(this.$t('batchGroup.hint.warn.inputGroupWarn'));
          return;
        }
      }
      this.axios.post(this.baseUrl + "verifyImeisBelong",{imeis:imei},{ headers: { token: sessionStorage.getItem("token")}}).then((res)=>{
          if(res.status == 200){
              if(res.data.code == 0){
                let datas = res.data.data;
                if(datas.isMainAccount){
                    if((datas.passCount == 0) && (datas.childCount == 0) && (datas.noPassCount == 0)){  //没有可修改的设备
                      warnMsg(this.$t('batchGroup.hint.warn.accountWarn0')) 
                    }else if((datas.childCount == 0) && (datas.noPassCount == 0) && (datas.passCount > 0)){ //全部是主账号的设备
                      let devices = this.getImeiStr(datas.passImei);
                      if(flag == 0){
                        this.clearGroup(devices);
                      }else if(flag == 1){
                        this.confirm(devices)
                      }
                  }else {  
                      let devices = datas.passCount > 0 ? this.getImeiStr(datas.passImei):"";
                      let imeis = "";
                      let imeiList = [];
                      let childrenImeis = [];
                      let childImei = "";
                      if(datas.noPassCount > 0){
                          imeiList = datas.noPassImei;
                          imeiList.forEach(imei=>{
                            childImei = childImei + imei + ", ";
                            imeis = imeis + imei + ",";
                          })
                          childrenImeis.push({
                            "account": "其他",
                            "imei": childImei,
                          })
                          childImei = "";
                      }
                      
                      if(datas.childCount > 0){
                        for (const key in datas.childImeiMap) {
                          imeiList = datas.childImeiMap[key]
                          imeiList.forEach(imei=>{
                            childImei = childImei + imei + ", ";
                            imeis = imeis + imei + ",";
                          })
                          childImei = childImei.substring(0, childImei.lastIndexOf(","));
                          childrenImeis.push({
                            "account": key,
                            "imei": childImei,
                          })
                          childImei = "";
                        }
                      }
                      this.passImei = devices; //主账号设备
                      this.allImei = imeis + devices;
                      this.tipTitle = this.$t('batchGroup.tipTitle');
                      this.content = childrenImeis;
                      this.tipVisible = true;
                      this.flag = flag;
                    }
                }else{
                    if(this.flag == 0){ //清除备注
                      this.clearGroup(imei);
                    }else if(this.flag == 1){ //添加备注
                      this.confirm(imei)
                    }
                }
              }else if(res.data.code == -1 && res.data.message == "未登录"){
                warnMsg(this.$t('batchGroup.hint.warn.loginWarn'))
                setTimeout (()=>{
                  this.$router.push({ name: "login"});
                },"2000");
              }else if(res.data.code == -2){
                warnMsg(this.$t('batchGroup.hint.warn.accountWarn2'))
              }else if(res.data.code == -3){
                errMsg(this.$t('batchGroup.hint.error.systemErr'))
              }
          }else{
            warnMsg(this.$t('batchGroup.hint.warn.operationWarn'))
          }
        }).catch(()=>{
          errMsg(this.$t('batchGroup.hint.error.systemErr'))
        })
    }

  },
};
</script>
<style scoped>
.batch_group_content {
  height: 130px;
  margin: 20px 0px 0px 0px;
  box-shadow: 0px 0px 3px 0px rgb(187, 187, 187);
  padding: 20px 0px 0px 0px;
  background-color: rgb(255, 255, 255);

}
.batch_group_label {
  display: inline-block;
  height: 36px;
  line-height: 36px;
  width: 120px;
  text-align: right;
  padding-right: 10px;
}
.batch_group_clear {
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  height: 36px;
  min-width: 80px;
  line-height: 36px;
  border: 1px solid rgb(184, 184, 184);
  background-color: rgb(255, 255, 255);
}
.batch_group_clear:hover {
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}

.batch_group_cancel,
.batch_group_confirm {
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  height: 33px;
  width: 60px;
  line-height: 33px;
}
.batch_group_cancel {
  margin-right: 15px;
  border: 1px solid rgb(184, 184, 184);
  background-color: white;
}
.batch_group_cancel:hover {
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}
.batch_group_confirm {
  border: 1px solid rgb(78, 78, 255);
  background-color: rgb(78, 78, 255);
  color: white;
}
.batch_group_confirm:hover {
  box-shadow: 0px 0px 2px 0px rgb(23, 23, 255);
}


.cursor_attr:hover{
  cursor: pointer;
}
::v-deep .ant-modal-body {
  padding: 0px;
}
</style>