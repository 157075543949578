import VueRouter from 'vue-router'
/** 其他 */
import Login from "../views/login.vue"
import Index from "../views/index.vue"
import alarmInfo from "../views/common/alarmInfo.vue"

/** 头部菜单导航栏 */
import Chart from "../views/home/chart.vue"
import Device from "../views/device/device.vue"
import Balance from "../views/balance/balance.vue"
import Account from "../views/account/account.vue"
import Report from "../views/report/report.vue"

/** report */
import packetList from "../views/report/tables/packetList.vue"
import deviceDistribution from "../views/report/tables/deviceDistribution.vue"

/** account */
import describe from "../views/account/tables/accountDescribe.vue"
import subAccount from "../views/account/tables/subAccount.vue"
import serviceAccount from "../views/account/tables/serviceAccount.vue"

/** balance */
import balanceDetail from "../views/balance/tables/balanceDetail.vue"
import billDetail from "../views/balance/tables/billDetail.vue"
import alipay from "../views/balance/tables/alipay.vue"
import bankCardPay from "../views/balance/tables/bankCardPay.vue"
import wxPay from "../views/balance/tables/wxPay.vue"
import bill from "../views/balance/tables/bill.vue"
import flowPoolUseDetail from "../views/balance/tables/flowPoolUseDetail.vue"
import deviceDetail from "../views/balance/tables/deviceDetail.vue"
import flowPoolDetail from "../views/balance/tables/flowPoolDetail.vue"

/** device */
import deviceManage from "../views/device/tables/deviceManage.vue"
import dayActive from "../views/device/tables/deviceDayActive.vue"
import order from "../views/device/tables/order.vue"
import monthActive from "../views/device/tables/deviceMonthActive.vue"
import deviceUseDetail from "../views/device/tables/deviceUseDetail.vue"
import inactive from "../views/device/tables/deviceInactive.vue"
import deviceDayCost from "../views/device/tables/deviceDayCost.vue"

export default new VueRouter({
    // mode: 'history', //去掉访问页面前面的 # 
    routes: [
        { path: '/', redirect: '/login' },
        /** 登录界面 */
        {
            path: "/login/:lang",
            name: 'login',
            component: Login,
            meta: {
                title: 'RoamLink'
            }
        },
        /** 整个登录成功后的界面，包括(头部导航，左侧菜单栏，右边内容) */
        {
            path: "/index",
            name: 'index',
            component: Index,
            meta: {
                title: 'RoamLink',
            },
            children: [
                 /** 首页菜单 */
                {
                    path: '/chart',
                    name: 'chart',
                    components: {
                        main: Chart,
                    },
                    meta: {
                        requireAuth: true
                    }
                },
                /** 终端菜单 */
                {
                    path: '/device',
                    name: 'device',
                    components: {
                        main: Device,
                    },
                    meta: {
                        requireAuth: true
                    },
                    /** 终端菜单下的子菜单，注意：右边内容界面需要放在对应菜单下的children下面 */
                    children: [
                        {
                            path: '/device/iot-manage',
                            name: 'deviceManage',
                            components: {
                                content: deviceManage,
                            },
                            meta: {
                                requireAuth: true
                            },
                        },
                        {
                            path: '/device/dayActive',
                            name: 'dayActive',
                            components: {
                                content: dayActive,
                            },
                            meta: {
                                requireAuth: true
                            },
                        },
                        {
                            path: '/device/monthActive',
                            name: 'monthActive',
                            components: {
                                content: monthActive,
                            },
                            meta: {
                                requireAuth: true
                            },
                        },
                        {
                            path: '/device/order',
                            name: 'order',
                            components: {
                                content: order,
                            },
                            meta: {
                                requireAuth: true
                            },
                        },
                        {
                            path: "/device/inactive",
                            name: 'inactive',
                            components: {
                                content: inactive,
                            },
                            meta: {
                                requireAuth: true
                            },
                        },
                    ]
                },
                 /** 计费菜单 */
                {
                    path: '/balance',
                    name: 'balance',
                    components: {
                        main: Balance,
                    },
                    meta: {
                        requireAuth: true
                    },
                    /** 注意：右边内容界面需要放在对应菜单下的children下面 */
                    children: [
                        {
                            path: '/balance/detail',
                            name: 'balanceDetail',
                            components: {
                                content: balanceDetail,
                            },
                            meta: {
                                requireAuth: true
                            },
                        },
                        {
                            path: '/balance/bill',
                            name: 'bill',
                            components: {
                                content: bill,
                            },
                            meta: {
                                requireAuth: true
                            },
                        },
                        {
                            path: '/balance/billDetail',
                            name: 'billDetail',
                            components: {
                                content: billDetail,
                            },
                            meta: {
                                requireAuth: true
                            },
                        }
                    ]
                },
                 /** 账号菜单 */
                {
                    path: '/account',
                    name: 'account',
                    components: {
                        main: Account,
                    },
                    meta: {
                        requireAuth: true
                    },
                    /** 注意：右边内容界面需要放在对应菜单下的children下面 */
                    children: [
                        {
                            path: '/account/describe',
                            name: 'describe',
                            components: {
                                content: describe,
                            },
                            meta: {
                                requireAuth: true
                            },
                        },
                        {
                            path: '/account/subAccount',
                            name: 'subAccount',
                            components: {
                                content: subAccount,
                            },
                            meta: {
                                requireAuth: true
                            },
                        },
                        {
                            path: '/account/serviceAccount',
                            name: 'serviceAccount',
                            components: {
                                content: serviceAccount,
                            },
                            meta: {
                                requireAuth: true
                            },
                        }
                    ]
                },
                /** 报表菜单 */
                {
                    path: '/report',
                    name: 'report',
                    components: {
                        main: Report,
                    },
                    meta: {
                        requireAuth: true
                    },
                    children: [
                        {
                            path: '/report/packetList',
                            name: 'packetList',
                            components: {
                                content: packetList,
                            },
                            meta: {
                                requireAuth: true
                            },
                        },
                        {
                            path: '/report/distribution',
                            name: 'deviceDistribution',
                            components: {
                                content: deviceDistribution,
                            },
                            meta: {
                                requireAuth: true
                            },
                        },
                    ]
                },
            ]
        },
        /** 下面是在点击超链接之后，新窗口打开的界面 */
        {
            path: "/device/Detail",
            name: 'deviceDetail',
            component: deviceUseDetail,
            meta: {
                title: 'RoamLink'
            }
        },
        {
            path: "/device/dayCost",
            name: 'deviceDayCost',
            component: deviceDayCost,
            meta: {
                title: 'RoamLink'
            }
        },
        {
            path: "/balance/flowPoolUseDetail",
            name: 'flowPoolUseDetail',
            component: flowPoolUseDetail,
            meta: {
                title: 'RoamLink'
            }
        },
        {
            path: "/balance/deviceDetail",
            name: 'deviceDetail',
            component: deviceDetail,
            meta: {
                title: 'RoamLink'
            }
        },
        {
            path: "/balance/flowPoolDetail",
            name: 'flowPoolDetail',
            component: flowPoolDetail,
            meta: {
                title: 'RoamLink'
            }
        },
        {
            path: "/balance/alipay",
            name: 'alipay',
            component: alipay,
            meta: {
                title: 'RoamLink'
            }
        },
        {
            path: "/balance/bankCardPay",
            name: 'bankCardPay',
            component: bankCardPay,
            meta: {
                title: 'RoamLink'
            }
        },
        {
            path: "/balance/wxPay",
            name: 'wxPay',
            component: wxPay,
            meta: {
                title: 'RoamLink'
            }
        },
        {
            path: "/alarmInfo",
            name: 'alarmInfo',
            component: alarmInfo,
            meta: {
                title: 'RoamLink'
            }
        },
    ]
})