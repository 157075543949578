import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './config/en'
import zh from './config/zh'

Vue.use(VueI18n)

const browserLanguage = (navigator.language || 'en').toLocaleLowerCase().split('-')[0]; // 获取浏览器语言
const i18n = new VueI18n({
    legacy: false,
    locale: localStorage.getItem('locale') || browserLanguage,
    fallbackLocale: 'en',
    messages: { //语言
        zh, //中文       
        en, //英文
    }
})

export default i18n;