<template>
  <div style="margin: 30px">
    <span class="operate_nav_title">{{ $t('deviceDayCosts.title') }}</span>
    <div style="text-align: right;">
      <span class="search_item_label">{{$t('deviceDayCosts.date')}}</span>
      <a-config-provider >
        <a-month-picker
          allowClear
          @change="selectDate"
          :placeholder="$t('deviceDayCosts.selectDateMsg')"
        />
      </a-config-provider>
    </div>
    <a-config-provider >
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="datas"
        :rowClassName="rowClassName"
        :pagination="false"
        :customRow="selectRow"
        :rowKey="(record, i) => {return i;}"
        size="middle"
      >
        <div slot="tsid" slot-scope="text, record">
          <a class="row_record_tsid" @click.prevent="showUsedDetail(record)">{{
            record.tsid
          }}</a>
        </div>
      </a-table>
    </a-config-provider>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { errMsg, isNull, warnMsg } from "../../../utils/CommonUtils.js";
export default {
  data() {
    return {
      title: this.$t('deviceDayCosts.title'),
      locale: zhCN,
      loading: true,
      rowId: "",
      tsid: "",
      date: "",
      datas: [],
    
    };
  },
  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.tsid = this.$route.query.tsid;
    this.date = this.$route.query.date;
    this.init();
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t('deviceDayCosts.columns.tsid'),
          dataIndex: "tsid",
          scopedSlots: { customRender: "tsid" },
        },
        {
          title: this.$t('deviceDayCosts.columns.date'),
          dataIndex: "date",
        },
        {
          title: this.$t('deviceDayCosts.columns.used'),
          dataIndex: "used",
        },
        {
          title: this.$t('deviceDayCosts.columns.flow'),
          dataIndex: "flow",
        },
        {
          title: this.$t('deviceDayCosts.columns.country'),
          dataIndex: "country",
        },
      ];
    },
  },
  methods: {
    init() {
      if (isNull(this.tsid) || isNull(this.date)) {
        warnMsg(this.$t('deviceDayCost.hint.warn.getDataWarn'));
        return;
      }
      this.axios.post( this.baseUrl + "getDeviceDaily?tsid=" + this.tsid + "&date=" + this.date, "", {
            headers: { token: sessionStorage.getItem("token") }, } ).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.datas = res.data.data;
            } else if (res.data.code == 1) {
              this.datas = [];
            } else if (res.data.code == -1) {
              warnMsg(this.$t('deviceDayCost.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -3) {
              errMsg(this.$t('deviceDayCost.hint.error.systemErr'));
            } else if (res.data.code == -2) {
              warnMsg(this.$t('deviceDayCost.hint.warn.retryWarn'));
            }
          } else {
            warnMsg(this.$t('deviceDayCost.hint.warn.getDataWarn'));
          }
        }).catch(() => {
          this.loading = false;
          errMsg(this.$t('deviceDayCost.hint.error.systemErr'));
        });
    },
    showUsedDetail(record) {
      let newUrl = this.$router.resolve({
        path: "/device/Detail",
        query: {
          tsid: record.tsid,
          date: record.date,
        },
      });
      window.open(newUrl.href, "_blank");
    },
    rowClassName(record, index) {
      if (index === this.rowId) {
        return "select_row_bg_color";
      } else {
        return "";
      }
    },
    selectRow(record, index) {
      return {
        on: {
          click: () => {
            this.rowId = index;
          },
        },
      };
    },
    selectDate(moment, date){
      if(isNull(date)){
        return;
      }
      this.date = date;
      this.init();
    },
  },
};
</script>
<style scoped>
@import "../../../assets/css/table.css";
.row_record_tsid:hover {
  text-decoration: underline;
}
.operate_nav_title {
  /* 整行标题 */
  float: left;
  font-size: 24px;
  font-weight: bold;
  color: rgb(14, 14, 14);
}
::v-deep .ant-table-small {
  border: none;
}
</style>
 