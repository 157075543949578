<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="72%"
    :footer="null"
    :maskClosable="false"
    :dialogStyle="{ top: '5%' }"
    @cancel="handleCancel"
  >
     <a-table
      :loading="loading"
      :columns="showColumns"
      :data-source="datas"
      :pagination="false"
      :scroll="total>12?{y: 300 }:{}"
      :rowKey="(record, i) => {return i}"
      size="small"
    >
    
     <div slot="zone">
        <span>{{zone}}</span>
      </div>
      <div slot="namecn">
        <span>{{ namecn }}</span>
      </div>
    </a-table>
  </a-modal>
</template>
<script>
import {errMsg, warnMsg} from "../../../utils/CommonUtils.js"
export default {
  props: ["countryCode", "month", "zone", "namecn"],
  data() {
    return {
      title: this.$t('monthActiveDevice.title'),
      total: 0,
      showModal: false,
      loading: true,
      datas: [],
      showColumns: [
        {
          title: this.$t('monthActiveDevice.columns.zone'),
          dataIndex: "zone",
          scopedSlots: { customRender: "zone" },
        },
        {
          title: this.$t('monthActiveDevice.columns.namecn'),
          dataIndex: "namecn",
          scopedSlots: { customRender: "namecn" },
        },
        {
          title: this.$t('monthActiveDevice.columns.date'),
          dataIndex: "dateStr",
        },
        {
          title: this.$t('monthActiveDevice.columns.count'),
          dataIndex: "count",
        },
      ],
    };
  },
  created(){
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.showModal = true
    this.init();
  },
  methods: {
    init() {
      this.axios.post(this.baseUrl + "getMonthCount?month=" + this.month +"&code="+this.countryCode+"&flag=1", "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.total = res.data.count;
              this.datas = res.data.data;
            } else if (res.data.code == 1) {
              this.datas = [];
              this.total = 0;
            } else if (res.data.code == -1) {
              warnMsg(this.$t('monthActiveDevice.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -2) {
              errMsg(this.$t('monthActiveDevice.hint.error.getDataErr'));
            }
          } else {
            errMsg(this.$t('monthActiveDevice.hint.error.getDataErr'));
          }
        }).catch(() => {
          this.loading = false;
          errMsg(this.$t('monthActiveDevice.hint.error.systemErr'));
        });
    },
    handleCancel() {
      this.$emit("month-active-device", false);
    },
  },
};
</script>
<style scoped>
</style>