<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="320px"
    :footer="null"
    :maskClosable="false"
    @cancel="handleCancel"
    :dialogStyle="{ top: '32%' }"
  >
    <div>
      <div class="update_name_div">
        <span>{{$t('updatename.name')}}</span>
        <a-input
          class="new_name_input"
          :placeholder="$t('updatename.nameMsg')"
          v-model.trim="name"
          :maxLength="15"
        />
      </div>
      <div class="update_name_div update_name_btn">
        <span class="update_name_cancel" @click.prevent="cancel">{{$t('updatename.cancel')}}</span>
        <span class="update_name_confirm" @click.prevent="confirm">{{$t('updatename.confirm')}}</span>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { successMsg, warnMsg, errMsg, isNull } from '../../../utils/CommonUtils';
export default {
  props: ["visible", "id"],
  data() {
    return {
      title: this.$t('updatename.title'),
      showModal: false,
      name: "",
    };
  },
  watch: {
    visible(val) {
      this.showModal = val;
      if(!this.showModal){
        this.name = "";
      }
    },
  },
  methods: {
    cancel() {
      this.$emit("update-name-visible", false, 0);
    },
    confirm() {
      if(isNull(this.name)){
        warnMsg(this.$t('updatename.hint.warn.nameWarn'))
        return;
      }
       this.axios.post(this.baseUrl + "updateRelaName?id="+this.id + "&afterName="+this.name, "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              successMsg(this.$t('updatename.hint.success.update'))
              this.$emit("update-name-visible", false, 1);
            }else if(res.data.code == -1){
              warnMsg(this.$t('updatename.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }else if(res.data.code == -2){
              warnMsg(this.$t('updatename.hint.warn.operationWarn'))
            }else if(res.data.code == -3){
              warnMsg(this.$t('updatename.hint.warn.permissionWarn'))
            }
          } else {
            errMsg(this.$t('updatename.hint.error.updateErr'));
          }
        }).catch(() => {
          errMsg(this.$t('updatename.hint.error.systemErr'));
        });
    },
    handleCancel() {
      this.$emit("update-name-visible", false, 0);
    },
  },
};
</script>
<style scoped>
.update_name_div {
  margin-bottom: 10px;
  font-size: 15px;
}
.new_name_input {
  width: 200px;
}
.update_name_btn {
  text-align: center;
  margin: 20px 0 0 0;
}
.update_name_cancel,
.update_name_confirm {
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  height: 33px;
  width: 60px;
  line-height: 33px;
}
.update_name_cancel {
  margin-right: 15px;
  border: 1px solid rgb(184, 184, 184);
}
.update_name_cancel:hover {
  cursor: pointer;
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}
.update_name_confirm {
  border: 1px solid rgb(78, 78, 255);
  background-color: rgb(78, 78, 255);
  color: white;
}
.update_name_confirm:hover {
  cursor: pointer;
  box-shadow: 0px 0px 2px 0px rgb(23, 23, 255);
}
::v-deep .ant-modal-body {
  padding: 16px 24px 12px;
}
</style>