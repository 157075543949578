<template>
  <div>
    <div class="operate_nav">
      <span class="operate_nav_title">{{ title }}</span>
      <span class="operate_nav_btn_frame" @click.prevent="exportData">
        <a-icon type="download" />
        {{$t('deviceDistribution.report')}}
      </span>
    </div>
    <div class="data_filter_nav">
        <a-date-picker 
          :placeholder="$t('deviceDayCost.selectDateMsg')"
          :allowClear="false"
          v-model="showDate"
          @change="changeDate"
          :showToday="false"
        > <a-icon slot="suffixIcon" type="schedule" theme="filled" style="margin-right:5px;"/>
        </a-date-picker>
        <div class="data_filter_sep"></div>
    </div>
    <div>
      <a-config-provider >
         <a-table
          :columns="columns"
          :data-source="datas"
          :loading="loading"
          :pagination="pagination"
          :rowClassName="rowClassName"
          :customRow="selectRow"
          :rowKey="(record, i) => {return i}"
          size="small"
        >
          <div slot="zone" slot-scope="text, record">
            <a @click="toOrder(record)">{{ record.zone }}</a>
          </div>
          <div slot="names" slot-scope="text, record">
            <a @click="toMonthActive(record)">{{ record.names }}</a>
          </div>
          <div slot="days" slot-scope="text, record">
            <a @click="showDayActiveDevice(record)">{{ record.days }}</a>
          </div>
          <div slot="month" slot-scope="text, record">
            <a @click="showMonthActiveDevice(record)">{{ record.month }}</a>
          </div>
        </a-table>
      </a-config-provider>
      <day-active-device
        v-if="visible.dayActive"
        :countryCode="record.codes"
        :month="month"
        :zone="record.zone"
        :namecn="record.names"
        @day-active-device="changeShowDayActiveDevice"
      ></day-active-device>
      <month-active-device
        v-if="visible.monthActive"
        :countryCode="record.codes"
        :month="month"
        :zone="record.zone"
        :namecn="record.names"
        @month-active-device="changeShowMonthActiveDevice"
      ></month-active-device>
    </div>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import dayActiveDevice from "../../dialog/report/dayActiveDeviceModal.vue";
import monthActiveDevice from "../../dialog/report/monthActiveDeviceModal.vue";
import moment from 'moment';
import { errMsg, isNull, warnMsg } from "../../../utils/CommonUtils.js";
export default {
  components: {
    dayActiveDevice,
    monthActiveDevice,
  },
  data() {
    return {
      title: this.$t('deviceDistribution.title'),
      locale: zhCN,
      loading: true,
      showDate: "",
      date: "",
      month: "",
      record: "",
      rowId: "",
      visible: {
        dayActive: false,
        monthActive: false,
      },
      search: {
        country: "",
        visible: false,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "100", "300", "500"],
        showSizeChanger: true,
        onChange: (current, pageSize) =>
          this.changeCurrentPage(current, pageSize),
        onShowSizeChange: (current, pageSize) =>
          this.changePageSize(current, pageSize),
          showTotal: () => {
              const count = this.pagination.total;
              return this.$i18n.t('deviceDistribution.totalRecords', { count });
          },
      },
      datas: [],
      columns: [
        {
          title: this.$t('deviceDistribution.columns.zone'),
          dataIndex: "zone",
          scopedSlots: { customRender: "zone" },
        },
        {
          title: this.$t('deviceDistribution.columns.names'),
          dataIndex: "names",
          scopedSlots: { customRender: "names" },
        },
        {
          title: this.$t('deviceDistribution.columns.dayes'),
          dataIndex: "days",
          scopedSlots: { customRender: "days" },
        },
        {
          title: this.$t('deviceDistribution.columns.month'),
          dataIndex: "month",
          scopedSlots: { customRender: "month" },
        },
      ],
    };
  },

  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    let date = this.$route.params.date;
    if(!isNull(date)){
      this.month = date;
      this.date = date + "-01";
      this.showDate = moment(new Date(this.date), "yyyy-mm-dd");  
    }else{
      var d = new Date();
      this.month = d.getFullYear() + "-" + ((d.getMonth() + 1) > 9 ? (d.getMonth() + 1) : ('0'+(d.getMonth() + 1)))
      this.date = this.month +"-"+ (d.getDate() > 9 ? d.getDate() : '0'+ d.getDate())
      this.showDate = moment(d, "yyyy-mm-dd");
    }
    this.init();
  },
  methods: {
    changeCurrentPage(currentPage, pageSize) {
      this.pagination.current = currentPage;
      this.pagination.pageSize = pageSize;
      this.rowId = "";
    },
    changePageSize(currentPage, pageSize) {
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.rowId = "";
    },
    /** 日期条件 */
    changeDate(dayjs, dateStr){
      this.pagination.current = 1;
      this.pagination.current = 15;
      this.date = dateStr;
      this.month = dateStr.substring(0, dateStr.lastIndexOf("-"));
      this.rowId = "";
      this.loading = true;
      this.init();
 
    },
    exportData() {
      if(isNull(this.datas)){
        warnMsg(this.$t('deviceDistribution.hint.warn.reportWarn'));
        return;
      }
     this.axios.post(this.baseUrl + "exporDistribution?month="+this.date,"", {
          responseType: "blob",
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            let link = document.createElement("a");
            let url = window.URL.createObjectURL(
              new Blob([res.data], {
                type: "application/vnd.ms-excel;charset=UTF-8;",
              })
            );
            let fileName = window.decodeURI(res.headers['content-disposition'].split('=')[1], "UTF-8");
            link.style.display = "none";
            link.href = url;
             link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); //下载完成移除元素
            window.URL.revokeObjectURL(url); //释放掉blob对象
          }
        }).catch(() => {
          errMsg(this.$t('deviceDistribution.hint.error.systemErr'));
        });
    },
   
    init() {
      this.axios.post(this.baseUrl + "getBottomByCountry?month=" + this.date, "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.datas = res.data.data
              this.pagination.total = res.data.count;
            } else if (res.data.code == 1) {
              this.datas = [];
              this.pagination.total = 0;
            } else if (res.data.code == -1) {
              warnMsg(this.$t('deviceDistribution.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -2) {
              errMsg(this.$t('deviceDistribution.hint.error.getDataErr'));
            }
          } else {
            errMsg(this.$t('deviceDistribution.hint.error.getDataErr'));
          }
        }).catch(() => {
          this.loading = false;
          errMsg(this.$t('deviceDistribution.hint.error.systemErr'));
        });
    },
 
    showDayActiveDevice(record) {
      this.record = record
      this.visible.dayActive = true;
    },
    changeShowDayActiveDevice(val){
      this.visible.dayActive = val;
    },

    showMonthActiveDevice(record) {
      this.record = record
      this.visible.monthActive = true;
    },
    changeShowMonthActiveDevice(val){
      this.visible.monthActive = val;
    },
    rowClassName(record, index) {
      if(index === this.rowId){
        return "select_row_bg_color"; /** 返回的是类名，在table.css文件中有 */
      }else{
        return "";
      }
    },
    selectRow(record, index){
       return {
            on: {
               click: () => {
                  this.rowId = index;
               }
            }
        }
    },
    /** 已购套餐 */
    toOrder(record){
       this.$router.push({
        name: "device",
        params: {
          subMenu: 3, //标识已购套餐
          menu: 106, //标识终端地域分布
          zoneCode: record.zoneCode,
          date: this.month,
        },
      });
    },
    /** 跳转到月活跃 */
    toMonthActive(record){
       this.$router.push({
        name: "device",
        params: {
          subMenu: 4,
          countryCode: record.codes,
          date: this.month,
        },
      });
    }    
  },
};
</script>
<style scoped>
@import '../../../assets/css/table.css';
/* 操作栏 */
.operate_nav {
  margin-top: 10px;
  text-align: right;
}

.operate_nav_title {
  /* 整行标题 */
  float: left;
  font-size: 24px;
  font-weight: bold;
  color: rgb(14, 14, 14);
}

.operate_nav_btn_frame {
  margin: 7px 0px 0px;
  display: inline-block;
  text-align: center;
  min-width: 70px;
  border: 1px solid rgb(160, 160, 160);
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
}

.operate_nav_btn_frame:hover {
  cursor: pointer;
  color: rgb(78, 78, 255);
  border: 1px solid rgb(78, 78, 255);
}

.row_record_country:hover {
  text-decoration: underline;
}

/* 日期过滤 */
.data_filter_nav {
  display: inline-flex;
  margin-top: 10px;
  width: 100%;
  text-align: left;
  padding: 0;
  height: 30px;
  line-height: 30px;
  align-items: center;
}

/* .date_filter{
} */

.data_filter_sep {
  height: 30px;
  width: 100%;
  border-bottom: 1px solid rgb(172, 172, 172);
}

/* 日期样式 */
::v-deep .ant-calendar-picker-icon{
  left: 0px;  
  color: rgba(0, 0, 0);
  cursor: pointer;
}

::v-deep .ant-calendar-picker-input{
  border: none;
  width: 110px;
  padding-left: 20px;
  cursor: pointer;
}

::v-deep .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled){
  border: none;
  box-shadow: none;
}
::v-deep .ant-table-small {
  border: none;
}
</style>
 