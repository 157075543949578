<template>
  <div>
    <div class="operate_nav">
      <span class="operate_nav_title">{{ title }}</span>
      <span class="operate_nav_btn_frame" @click.prevent="showSearch">
        <span>
          <a-icon type="search" /><span style="margin-left: 3px">{{$t('deviceMonthActive.button.search')}} </span>
        </span>
      </span>
      <span class="operate_nav_btn_frame" @click.prevent="exportData">
        <a-icon type="download" />
        {{this.$t('deviceMonthActive.button.report')}}
      </span>
      <export-tips
        v-if="tips"
        :total="pagination.total"
        @export-tips-visible="changeShowExportTips"  
      ></export-tips>
    </div>
    <div :class="[search.visible ? 'search_show' : 'search_hide']">
      <div class="search_div">
        <div style="width:25%">
            <span class="search_item_label">{{$t('deviceMonthActive.searchList.useCountry')}}</span>
            <a-select
              class="iot_search_item"
              show-search
              allowClear
              v-model="search.country"
              option-filter-prop="children"
              :filter-option="filterOption"
              @change="handleChange"
              :placeholder="$t('deviceMonthActive.searchList.useCountryMsg')"
            >
              <a-select-option
                allowClear
                v-for="item in countrys"
                :value="item.code"
                :key="item.code"
              >
                {{ item.country }}
              </a-select-option>
            </a-select>
          </div>
          <!-- <div style="width:25%">
            <span class="search_item_label">流量池：</span>
            <a-select
              class="iot_search_item"
              show-search
              allowClear
              option-filter-prop="children"
              :filter-option="filterOption"
              @change="handleChange"
              placeholder="请选择国家"
            >
              <a-select-option
                allowClear
                v-for="item in countrys"
                :value="item.code"
                :key="item.code"
              >
                {{ item.country }}
              </a-select-option>
            </a-select>
          </div> -->
          <div style="width:25%">
            <span class="search_item_label">{{$t('deviceMonthActive.searchList.date')}}</span>
            <a-config-provider >
            <a-month-picker
              allowClear
              class="iot_search_item"
              v-model="search.date"
              :placeholder="$t('deviceMonthActive.searchList.dateMsg')"
            />
            </a-config-provider>
          </div>
      </div>
      <div class="search_div">
        <span class="search_item_label">{{this.$t('deviceMonthActive.searchList.tsid')}}</span>
        <a-textarea
          style="margin-left: 6px"
          v-model.trim="search.tsid"
          :placeholder="this.$t('deviceMonthActive.searchList.tsidMsg')"
          autocomplete="off"
        />
      </div>
      <div class="search_div">
        <span class="search_item_label">{{this.$t('deviceMonthActive.searchList.imei')}}</span>
        <a-textarea
          style="margin-left: 6px"
          v-model.trim="search.imei"
          :placeholder="this.$t('deviceMonthActive.searchList.imeiMsg')"
          autocomplete="off"
        />
      </div>
      <span class="search_item_btn" @click.prevent="searchConfirm">{{this.$t('deviceMonthActive.button.inquire')}}</span>
    </div>
    <div style="margin-top: 10px">
      <a-config-provider >
         <a-table
          :loading="loading"
          :columns="columns"
          :data-source="datas"
          :rowClassName="rowClassName"
          :customRow="selectRow"
          :pagination="pagination"
          :rowKey="(record,i)=>{return i}"
          size="small"
        >
          <template slot="tsid" slot-scope="text, record">
            <a class="row_record_tsid" @click.prevent="showDayCost(record)">{{
              record.tsid
            }}</a>
          </template>
        </a-table>
      </a-config-provider>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import exportTips from "../../dialog/exportTipsModal.vue";
import { errMsg,isNull, warnMsg } from "../../../utils/CommonUtils";
export default {
  components:{
    exportTips
  },
  data() {
    return {
      title: this.$t('deviceMonthActive.title'),
      locale: zhCN,
      loading: true,
      rowId: "",
      tips: false,
      countrys: [],
      pagination: {
        total: 0,
        pageSize: 10,
        current: 1,  
        pageSizeOptions: ["10", "20", "100", "300", "500"],
        showSizeChanger: true,
        onChange: (current, pageSize) =>
          this.changeCurrentPage(current, pageSize),
        onShowSizeChange: (current, pageSize) =>
          this.changePageSize(current, pageSize),
          showTotal: () => {
              const count = this.pagination.total;
              return this.$i18n.t('deviceMonthActive.totalRecords', { count });
          },
      },
      search: {
        imei: "",
        tsid: "",
        date: undefined,
        country: undefined,
        visible: false,
      },
      datas: [],
      columns: [
        {
          title: this.$t('deviceMonthActive.columns.tsid'),
          dataIndex: "tsid",
          scopedSlots: { customRender: "tsid" },
        },
        {
          title: this.$t('deviceMonthActive.columns.tsid'),
          dataIndex: "imei",
        },
        {
          title: this.$t('deviceMonthActive.columns.account'),
          dataIndex: "account",
        },
        {
          title: this.$t('deviceMonthActive.columns.date'),
          dataIndex: "date",
        },
        {
          title: this.$t('deviceMonthActive.columns.remainTime'),
          dataIndex: "remainTime",
        },
        {
          title: this.$t('deviceMonthActive.columns.flow'),
          dataIndex: "flow",
        },
        {
          title: this.$t('deviceMonthActive.columns.country'),
          dataIndex: "country",
        },
      ],
    };
  },
  created(){
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    var countryCode = this.$route.params.countryCode;
    var date = this.$route.params.date;
    if(!isNull(date)){
      this.search.date = moment(new Date(date));
    }
    if(!isNull(countryCode)){
      this.search.country = countryCode;
    }
    this.init()
  },
  methods: {
     /** 切换页码 */
    changeCurrentPage(currentPage, pageSize) {
      this.loading = true;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pageSize;
      this.rowId = "";
      this.init()
    },
    /** 改变页记录数会调用此函数 */
    changePageSize(currentPage, pageSize) {
      this.loading = true;
      this.pagination.pageSize = pageSize;
      this.pagination.current = 1;
      this.rowId = "";
      this.init()
    },
    changeShowExportTips(show, flag, val){
      this.tips = show;
      if(flag == 1){
        this.exportReq(val)
      }
    },
    exportData() {
      if(isNull(this.datas)){
        warnMsg(this.$t('deviceMonthActive.hint.warn.reportWarn'));
        return;
      }
       if(this.pagination.total > 10000){
         this.tips = true; 
      }else{
        this.exportReq(null);
      }
       
    },
    exportReq(val){
      let param = this.initParam()
        if(!isNull(val)){
           param.pageNo = val;
        }else{
          param.pageNo = 1;
      }
      this.axios.post(this.baseUrl + "exportMonthDevice",param,{responseType: 'blob',headers: { token: sessionStorage.getItem("token")}}).then((res)=>{
         if(res.status == 200){
            let link = document.createElement("a");
            let url = window.URL.createObjectURL(new Blob([res.data],{type:"application/vnd.ms-excel;charset=UTF-8"}));
            let fileName = window.decodeURI(res.headers['content-disposition'].split('=')[1], "UTF-8");
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName);
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link); //下载完成移除元素
            window.URL.revokeObjectURL(url); //释放掉blob对象
          } 
      }).catch(() => {
          errMsg(this.$t('deviceMonthActive.hint.error.systemErr'));
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange(value) {
      this.search.country = value;
    },
    init() {
       this.axios.post(this.baseUrl + "getMonthDevice",this.initParam(),{headers: { token: sessionStorage.getItem("token")}}).then((res)=>{
        this.loading = false;
        if(res.status == 200){
          if(res.data.code == 0 ){
            this.datas = res.data.data;
            if(!isNull(res.data.count) && res.data.count > 0){
              this.pagination.total = res.data.count
            }
          }else if(res.data.code == 1){
              this.datas = []
              this.pagination.total = 0  
            }else if(res.data.code == -1){
              warnMsg(this.$t('deviceMonthActive.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }else if(res.data.code == -3){
              errMsg(this.$t('deviceMonthActive.hint.error.systemErr'))
            }else if(res.data.code == -4){
              errMsg(this.$t('deviceMonthActive.hint.error.retryErr'))
            }   
        }else{
          warnMsg(this.$t('deviceMonthActive.hint.warn.getDateWarn'))
        }
      }).catch(() => {
        this.loading = false;
          errMsg(this.$t('deviceMonthActive.hint.error.systemErr'));
      });
    },
    initParam(){
      var date = null;
      if(!isNull(this.search.date)){
        date = this.search.date
        date = date.format("YYYY-MM")
      }
      let param = {
        tsid: isNull(this.search.tsid) ? null : this.search.tsid,
        imei: isNull(this.search.imei) ? null : this.search.imei,
        country: this.search.country,
        date: date,
        pageNo: this.pagination.current,
        limit: this.pagination.pageSize,
      };
      return param;
    },
    showSearch() {
      if (this.search.visible) {
        this.search.visible = false;
      } else {
        this.search.visible = true;
        if (this.countrys.length == 0) {
          this.axios.post(this.baseUrl + "getCountryList", "", {
              headers: { token: sessionStorage.getItem("token"),
              'Accept-Language': localStorage.getItem('locale') },
            }).then((res) => {
              if (res.status == 200) {
                let dataArr = res.data.data;
                for (let i = 0; i < dataArr.length; i++) {
                  var json = dataArr[i];
                  var key = "key" + i;
                  var country = {
                    code: json[key].split("-")[0],
                    country: json[key].split("-")[1],
                  };
                  this.countrys.push(country);
                }
              }
            });
        }
      }
    },

    searchConfirm() {
      this.search.tsid = this.search.tsid.replace(/\s+|[\r\n]+/g,"");
      this.search.imei = this.search.imei.replace(/\s+|[\r\n]+/g, "");
      this.loading = true;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.rowId = "";
      this.init();
    },

    showDayCost(record) {
      let newUrl = this.$router.resolve({
        path: "/device/dayCost",
        query:{
          tsid: record.tsid,
          date: record.date,
        }
      });
      window.open(newUrl.href, "_blank");
    },
    rowClassName(record, index) {
      if(index === this.rowId){
        return "select_row_bg_color";
      }else{
        return "";
      }
    },
    selectRow(record, index){
       return {
            on: {
               click: () => {
                  this.rowId = index;
               }
            }
        }
    },  
  },
};
</script>
<style scoped>
@import '../../../assets/css/table.css';
/* 操作栏 */
.operate_nav {
  margin-top: 10px;
  text-align: right;
}

.operate_nav_title {
  /* 整行标题 */
  float: left;
  font-size: 24px;
  font-weight: bold;
  color: rgb(14, 14, 14);
}

.operate_nav_btn_frame {
  margin: 7px 0px 0px;
  display: inline-block;
  text-align: center;
  min-width: 70px;
  border: 1px solid rgb(160, 160, 160);
  height: 30px;
  line-height: 30px;
  margin-left: 15px;
  border-radius: 5px;
}

.operate_nav_btn_frame:hover {
  cursor: pointer;
  color: rgb(78, 78, 255);
  border: 1px solid rgb(78, 78, 255);
}

/*搜索框 */
.search_div {
  display: flex;
  margin-bottom: 10px;
  align-items: flex-end;
}
.iot_search_item {
  width: calc(100% - 80px);
}


.search_item_btn {
  float: right;
  display: block;
  width: 50px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  border: 1px solid gray;
  text-align: center;
}

.search_item_btn:hover {
  cursor: pointer;
  color: rgb(78, 78, 255);
  border: 1px solid rgb(78, 78, 255);
}

.search_item_label {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  width: 80px;
  text-align: right;
}

.search_show {
  display: block;
  text-align: left;
  margin: 20px 0px 45px;
  transition: all 3s ease-in;
}

.search_hide {
  display: none;
  transition: all 3s ease-out;
}

.row_record_tsid:hover {
  text-decoration: underline;
}
::v-deep .ant-table-small {
  border: none;
}
</style>
 