<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="68%"
    :footer="null"
    :maskClosable="false"
    :dialogStyle="{ top: '16%' }"
    @cancel="handleCancel"
  >
    <div style="padding: 12px 24px; background-color: #f7f7f7">
      <div class="distribute_device_content">
        <div style="padding-left: 34px; display: inline-block">
          {{$t('deviceAuthModal.mainAccount')}}
          <a-tree-select
            allowClear
            class="distribute_device"
            v-model.trim="account"
            :tree-data="treeData"
            :maxTagCount="1"
            :treeCheckStrictly="true"
            :multiple="false"
            :placeholder="$t('deviceAuthModal.selectAccountMsg')"
          >
          </a-tree-select>
        </div>
      </div>
      <div style="text-align: right; margin-top: 30px">
        <span
          class="update_note_clear"
          style="margin-right: 10px"
          @click.prevent="verifyDeviceBelong"
          >{{$t('deviceAuthModal.distributeDevice')}}</span
        >
      </div>
    </div>
    <tip-modal
      v-if="tipVisible"
      :content="content"
      :tipTitle="tipTitle"
      @tip-visible="tipCallback"
    >
    </tip-modal>
  </a-modal>
</template>
<script>
import tipModal from "./tipModal.vue"
import {errMsg,isNull,successMsg,warnMsg} from "../../../utils/CommonUtils.js"
export default {
  components:{
    tipModal
  },
  props: ["visible","selectedRow"],
  data() {
    return {
      title: this.$t('deviceAuthModal.title'),
      showModal: false,
      account: [],
      treeData: [],
      tipVisible: false,
      content: "",
      allImei: "",
      tipTitle: "",
      passImei: "",
    };
  },
  created(){
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.showModal = true;
    this.init()
  },
  methods: {
    distributeDeivce(imeis){
      if(isNull(imeis)){
        warnMsg(this.$t('deviceAuthModal.hint.warn.imeisWarn'));
        return;
      }
      this.axios.post(this.baseUrl + "distributionModule?childid="+this.account + "&imei="+ imeis,"",{headers: {token: sessionStorage.getItem("token")}}).then((res)=>{
          if(res.status == 200){
              if(res.data.code == 0){
                successMsg(this.$t('deviceAuthModal.hint.success.distribute'))
                this.$emit("device-auth-visible", false, 1);
              }else if(res.data.code == 1){
                warnMsg(this.$t('deviceAuthModal.hint.warn.distributeWarn1'))
              }else if(res.data.code == 2){
                warnMsg(this.$t('deviceAuthModal.hint.warn.distributeWarn2'))
              }else if(res.data.code == 3){
                warnMsg(this.$t('deviceAuthModal.hint.warn.distributeWarn3'))
              }else if(res.data.code == -1){
                warnMsg(this.$t('deviceAuthModal.hint.warn.loginWarn'))
                setTimeout (()=>{
                  this.$router.push({ name: "login"});
                },"2000");
              }else if(res.data.code == -2){
                errMsg(this.$t('deviceAuthModal.hint.error.distributeErr'))  
              }else if(res.data.code == -3){
                errMsg(this.$t('deviceAuthModal.hint.error.systemErr'))
              }
          }
        }).catch(()=>{
            errMsg(this.$t('deviceAuthModal.hint.error.systemErr'))
        })
    },
    getImei(){
      let imeis = "";
      if (this.selectedRow.length > 1) {
        this.selectedRow.forEach((item) => {
          imeis = imeis + item.imei + ",";
        });
      } else if (this.selectedRow.length == 1){
        imeis = this.selectedRow[0].imei;
      }
      return imeis;
    },
    getImeiStr(imei){
      let imeis = "";
      if (imei.length > 1) {
          for (let i = 0; i < imei.length; i++) {
            imeis = imeis + imei[i] + ",";          
          }
      } else if (imei.length == 1){
        return imei[0];
      }
      return imeis;
    },
    handleCancel() {
      this.$emit("device-auth-visible", false, 0);
    },
    init(){
      this.axios.post(this.baseUrl + "getAllAccount", "",{headers: {token: sessionStorage.getItem("token")}}).then((res)=>{
          if(res.status == 200){
            if(res.data.code == 1 || res.data.code == 0){
              this.treeData = res.data.data;
            }else  if(res.data.code == -1){
                warnMsg(this.$t('deviceAuthModal.hint.warn.loginWarn'))
                setTimeout (()=>{
                  this.$router.push({ name: "login"});
                },"2000");
            }else if(res.data.code == -2){
              errMsg(this.$t('deviceAuthModal.hint.error.systemErr'))
            }
          }
        }).catch(()=>{
            errMsg(this.$t('deviceAuthModal.hint.error.accountErr'))
        })
    },
    selectAccount(val) {
      this.account = val;
    },
    tipCallback(val, flag){ // 提示回调
      this.tipVisible = val;
      if (flag == 1) { //取消
          this.distributeDeivce(this.passImei);
      }else if(flag == 2){ //确认
          this.distributeDeivce(this.allImei)
        }
    },
     /** 验证终端归属，如果终端归属账号不是当前登录账号，则提示用户是否继续操作 */
    verifyDeviceBelong(){
      let imei = this.getImei()
       if(isNull(this.account)){
        warnMsg(this.$t('deviceAuthModal.hint.warn.searchAccountWarn'));
        return;
      }
      this.axios.post(this.baseUrl + "verifyImeisBelong",{imeis:imei},{ headers: { token: sessionStorage.getItem("token")}}).then((res)=>{
          if(res.status == 200){
              if(res.data.code == 0){
                let datas = res.data.data;
                if(datas.isMainAccount){
                    if((datas.passCount == 0) && (datas.childCount == 0) && (datas.noPassCount == 0)){  //没有可修改的设备
                      warnMsg(this.$t('deviceAuthModal.hint.warn.accountWarn0')) 
                    }else if((datas.childCount == 0) && (datas.noPassCount == 0) && (datas.passCount > 0)){ //全部是主账号的设备
                      let devices = this.getImeiStr(datas.passImei);
                      this.distributeDeivce(devices);
                   }else {  
                      let devices = datas.passCount > 0 ? this.getImeiStr(datas.passImei):"";
                      let imeis = "";
                      let imeiList = [];
                      let childrenImeis = [];
                      let childImei = "";
                      if(datas.noPassCount > 0){
                          imeiList = datas.noPassImei;
                          imeiList.forEach(imei=>{
                            childImei = childImei + imei + ", ";
                            imeis = imeis + imei + ",";
                          })
                          childrenImeis.push({
                            "account": "其他",
                            "imei": childImei,
                          })
                          childImei = "";
                      }
                      
                      if(datas.childCount > 0){
                        for (const key in datas.childImeiMap) {
                          imeiList = datas.childImeiMap[key]
                          imeiList.forEach(imei=>{
                            childImei = childImei + imei + ", ";
                            imeis = imeis + imei + ",";
                          })
                          childImei = childImei.substring(0, childImei.lastIndexOf(","));
                          childrenImeis.push({
                            "account": key,
                            "imei": childImei,
                          })
                          childImei = "";
                        }
                      }
                      this.passImei = devices; //主账号设备
                      this.allImei = imeis + devices;
                      this.tipTitle = this.$t('deviceAuthModal.tipTitle');
                      this.content = childrenImeis;
                      this.tipVisible = true;
                    }
                }else{
                   this.distributeDeivce(imei)
                }
              }else if(res.data.code == -1 && res.data.message == "未登录"){
                warnMsg(this.$t('deviceAuthModal.hint.warn.loginWarn'))
                setTimeout (()=>{
                  this.$router.push({ name: "login"});
                },"2000");
              }else if(res.data.code == -2){
                warnMsg(this.$t('deviceAuthModal.hint.warn.accountWarn2'))
              }else if(res.data.code == -3){
                errMsg(this.$t('deviceAuthModal.hint.error.systemErr'))
              }
          }else{
            warnMsg(this.$t('deviceAuthModal.hint.warn.operationWarn'))
          }
        }).catch(()=>{
          errMsg(this.$t('deviceAuthModal.hint.error.systemErr'))
        })
    },
  }
};
</script>
<style scoped>
.distribute_device_content {
  height: 100px;
  margin: 20px 0px 0px 0px;
  box-shadow: 0px 0px 3px 0px rgb(187, 187, 187);
  padding: 20px 0px 0px 0px;
  background-color: rgb(255, 255, 255);
}
.distribute_device {
  display: inline-block;
  width: 200px;
}
.distribute_device_cancel,
.distribute_device_confirm {
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  height: 33px;
  width: 60px;
  line-height: 33px;
}
.distribute_device_cancel {
  margin-right: 15px;
  border: 1px solid rgb(184, 184, 184);
  background-color: white;
}
.distribute_device_cancel:hover {
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}
.distribute_device_confirm {
  border: 1px solid rgb(78, 78, 255);
  background-color: rgb(78, 78, 255);
  color: white;
}
.distribute_device_confirm:hover {
  box-shadow: 0px 0px 2px 0px rgb(23, 23, 255);
}

.update_note_clear {
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  height: 36px;
  min-width: 80px;
  line-height: 36px;
  border: 1px solid rgb(184, 184, 184);
  background-color: rgb(255, 255, 255);
}
.update_note_clear:hover {
  cursor: pointer;
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}
::v-deep .ant-modal-body {
  padding: 0px;
}
</style>