<template>
  <div>
    <div class="wechat_head">
      <div class="w800 tr">
        您好，欢迎使用微信付款！&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如有疑问,请联系微信客服
      </div>
    </div>
    <div class="wechat_title">
      <div class="w800">
        <s class="ico_paylogo fl"></s>
        <div class="desk fl">收银台</div>
        <s class="ico_little fl"></s><s class="ico_slogan fr"></s>
      </div>
    </div>
    <div class="context">
      <div class="w800">
        <p>正在使用即时到账交易</p>
        <h2>{{ title }}</h2>
        <div>
          <ul class="dtl">
            <li><label>收款方：</label>天津泰岳小漫科技有限公司</li>
            <li><label>订单号：</label>{{ orderId }}</li>
            <li><label>商品名称：</label>{{ describe }}</li>
            <li>
              <label>交易金额：</label><span class="orange">{{ money }}</span
              >元
            </li>
          </ul>
        </div>
        <div class="scan">
          <div class="flex">
            <div class="box">
              <p>扫一扫付款</p>
              <span class="orange">{{ money }}</span
              ><em>&nbsp;&nbsp;元</em>
              <s class="ico_left"></s>
            </div>
          </div>
          <div class="flex">
            <s class="ico_scanborder"></s>
            <div class="scanbox"><img id="scanpayurl" :src="src" /></div>
          </div>
          <div class="flex"><s class="ico_right"></s></div>
        </div>
        <div class="copyright">
          <div><s class="ico_roamwifi"></s>天津泰岳小漫科技有限公司</div>
          <div>
            <p>
              Copyright © 2014, roamwifi.com<span class="ml30"
                >津ICP备14006601号</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isNull, errMsg } from "../../../utils/CommonUtils.js";
export default {
  data() {
    return {
      title: "",
      money: 0,
      orderId: "",
      describe: "",
      form: "",
      src: "/recharge/anon_getwxpayurl?url=",
    };
  },
  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.getData();
    this.init();
    setTimeout(this.wxPayCallBack, 3000);
  },
  methods: {
    getData() {
      var wxpay = JSON.parse(localStorage.getItem("wxpay"));
      this.title = isNull(wxpay.title)?"":wxpay.title;
      this.money = isNull(wxpay.amount)?"":wxpay.amount;
      this.orderId = isNull(wxpay.ordernumflag)?"":wxpay.ordernumflag;
      this.describe = isNull(wxpay.describe)?"":wxpay.describe;
      this.form = localStorage.getItem("form");
      localStorage.removeItem("wxpay"); //防止微信付款相关信息泄露。所以删除保留在浏览器上的信息
      localStorage.removeItem("form");
    },
    init() {
      if (!isNull(this.form)) {
        this.axios.post(this.baseUrl + "getwxpayurl?url=" + this.form, "", {responseType: "blob",
            headers: { token: sessionStorage.getItem("token") },
          }).then((res) => {
            if (res.status == 200) {
              this.src = window.URL.createObjectURL(
                new Blob([res.data], { type: "image/jpeg" })
              );
            }
          });
      }
    },
    /** 微信支付回调 */
    wxPayCallBack() {
      this.axios.post(this.wxPayCallBackUrl + "recharge/anon_scanpay_query?orderCode="+this.orderId, "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.flag == "success") {
              var tempForm = document.createElement("form");
              tempForm.id = "tempForm2";
              tempForm.method = "post";
              tempForm.action = "/anon_return_callbankwechat";
              var hideInput = document.createElement("input");
              hideInput.type = "hidden";
              hideInput.name = "orderId";
              hideInput.value = res.data.data.orderId;
              tempForm.appendChild(hideInput);
              document.body.appendChild(tempForm);
              tempForm.submit();
            } else {
              setTimeout(this.wxPayCallBack, 10000);  
            }
          } else {
            setTimeout(this.wxPayCallBack, 10000);  
          }
        }).catch(() => {
          errMsg("系统异常，请联系客服。");
        });
    },
  },
};
</script>
<style scoped>
body {
  margin: 0;
  padding: 0;
  font-family: "microsoft yahei", Arial, Helvetica, sans-serif;
  color: #333;
}

.wechat_head {
  background: #81868b;
  height: 28px;
  color: #fff;
  line-height: 28px;
  font-size: 12px;
}
.w800 {
  width: 800px;
  margin: auto;
}
.tr {
  text-align: right;
}

.wechat_title {
  height: 65px;
  border-bottom: 1px solid #d9d9d9;
  padding-top: 7px;
  font-size: 12px;
}
.ico_paylogo {
  background: url(../../../assets/img/wechat.png) no-repeat center;
  width: 150px;
  height: 50px;
  background-size: 80%;
}
.fl {
  float: left;
  display: inline;
}
.fr {
  float: right;
  display: inline;
}

.wechat_title div.desk {
  border-left: 1px solid #d9d9d9;
  margin-top: 15px;
  padding-left: 10px;
}

.ico_slogan {
  background: url(../../../assets/img/ico_slogan.png) no-repeat center;
  width: 150px;
  height: 50px;
  display: inline-block;
}

.ico_little {
  background: url(../../../assets/img/ico_little.png) no-repeat center;
  width: 50px;
  height: 50px;
  background-size: 65%;
}

.context {
  background: #eff0f1;
  width: 100%;
  padding: 40px 0 20px 0;
  font-size: 12px;
}
.context h2 {
  font-size: 24px;
  font-family: "SimHei";
  font-weight: bold;
  margin: 10px 0;
}
.context ul.dtl {
  margin: 10px 0;
  padding: 0;
  font-size: 14px;
}
.context ul.dtl li {
  display: inline-block;
  padding: 0;
  width: 49%;
  font-size: 14px;
  line-height: 28px;
}

.scan {
  border-bottom: 3px solid #b3b3b3;
  border-top: 3px solid #b3b3b3;
  background: #fff;
  display: flex;
}
.scan div.flex {
  flex: 1;
  padding: 40px 0;
  position: relative;
}
.scan div.flex .box {
  padding: 30px 40px 20px;
  position: relative;
}
.context p {
  margin: 0;
  color: #919191;
}
.scan div.flex span.orange {
  font-size: 60px;
  display: block;
  height: 100px;
  line-height: 100px;
  display: inline-block;
}

.orange {
  color: #ff6600;
}
.scan div.flex em {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  display: block;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  margin-left: 5px;
}

img,
a,
em,
input[button] {
  border: 0;
  font-style: normal;
}
.scan div.flex .box s.ico_left {
  width: 100%;
  height: 70px;
  background-size: 98%;
  display: block;
}

.ico_left {
  background: url(../../../assets/img/ico_left.png) no-repeat left;
}

.scan div.flex s.ico_scanborder {
  width: 100%;
  height: 250px;
  background-size: 70%;
  display: block;
}

.ico_scanborder {
  background: url(../../../assets/img/scanborder.png) no-repeat center;
}
.scan div.flex div.scanbox {
  background: #929292;
  width: 150px;
  height: 150px;
  left: 59px;
  top: 90px;
  position: absolute;
}
.scan div.flex s.ico_right {
  width: 100%;
  height: 250px;
  background-size: 60%;
  display: block;
}

.ico_right {
  background: url(../../../assets/img/ico_right.png) no-repeat center;
}
.context .copyright {
  background: none;
}

.copyright {
  text-align: center;
  margin: 20px 0;
}
.copyright {
  background: #4a4a4a;
  color: #888888;
  padding: 10px 0;
}
.copyright s.ico_roamwifi {
  display: inline-block;
  vertical-align: bottom;
  margin-right: 5px;
}

.ico_roamwifi {
  background: url(../../../assets/img/ico_roamwifi.png) no-repeat center;
  width: 25px;
  height: 25px;
}

.copyright {
  text-align: center;
  margin: 20px 0;
}

.copyright {
  background: #4a4a4a;
  color: #888888;
  padding: 10px 0;
}
</style>