<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="46%"
    :footer="null"
    :maskClosable="false"
    :dialogStyle="{ top: '16%' }"
    @cancel="handleCancel"
  >
    <div style="padding: 12px 24px;">
      <div style="margin-bottom: 10px;white-space:pre-line;">
            <div> {{tipTitle}}</div>
            <div style="margin-top: 10px;">
              <table>
                  <tr>
                    <th>{{$t('tipModal.account')}}</th>
                    <th>{{$t('tipModal.imei')}}</th>
                  </tr>
                  <tr v-for="(item, i) in content" :key="i">
                    <td style="padding-right:20px;text-align:left;">{{item.account}}</td>
                    <td>{{item.imei}}</td>
                  </tr>
              </table>
            </div>
      </div>
      <div style="text-align: right; padding-top: 24px">
        <span class="update_note_cancel" @click.prevent="cancel">{{$t('tipModal.no')}}</span>
        <span class="update_note_confirm" @click.prevent="confirm">{{$t('tipModal.yes')}}</span>
      </div>
    </div>
  </a-modal>
</template>
<script>
/** 终端归属提示框 */
export default {
  props: ["visible","content", "tipTitle"],
  data() {
    return {
      title: this.$t('tipModal.title'),
      showModal: false,
    };
  },
  created(){
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.showModal = true;
  },
  methods: {
    handleCancel() {
      this.showModal = false;
      this.$emit("tip-visible", false, 0);
    },
    cancel() {
      this.showModal = false;
      this.$emit("tip-visible", false, 1);
    },
    confirm() {
     this.showModal = false;
     this.$emit("tip-visible", false, 2);
    },
  },
};
</script>
<style scoped>
.update_note_cancel,
.update_note_confirm {
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  height: 33px;
  width: 60px;
  line-height: 33px;
}
.update_note_cancel {
  margin-right: 15px;
  border: 1px solid rgb(184, 184, 184);
  background-color: white;
}
.update_note_cancel:hover {
  cursor: pointer;
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}
.update_note_confirm {
  border: 1px solid rgb(78, 78, 255);
  background-color: rgb(78, 78, 255);
  color: white;
}
.update_note_confirm:hover {
  cursor: pointer;
  box-shadow: 0px 0px 2px 0px rgb(23, 23, 255);
}

::v-deep .ant-modal-body {
  padding: 0px;
}
</style>