<template>
  <a-modal
    v-model="showModal"
    :title="title"
     width="360px"
    :footer="null"
    :maskClosable="false"
    :dialogStyle="{ top: '20%' }"
    @cancel="handleCancel"
  >
    <div>
      <div class="account_recharge_div" >
        <span class="account_recharge_label"> {{$t('accountRecharge.balance')}} </span>
        <a-input class="account_recharge_input" v-model="data.balance" disabled/>
      </div>
      <div  class="account_recharge_div">
        <span class="account_recharge_label"> {{$t('accountRecharge.subBalance')}} </span>
        <a-input class="account_recharge_input" v-model="record.balance" disabled/>
      </div>
      <div  class="account_recharge_div">
        <span class="account_recharge_label"> {{$t('accountRecharge.recharge')}} </span>
        <a-input class="account_recharge_input" :maxLength="13" :placeholder="$t('accountRecharge.rechargeMsg')" v-model="balance"/>
       
      </div>
      <div class="account_recharge_div account_recharge_btn">
        <span class="account_recharge_cancel" @click.prevent="cancel">{{$t('accountRecharge.cancel')}}</span>
        <span class="account_recharge_confirm" @click.prevent="confirm">{{$t('accountRecharge.confirm')}}</span>
      </div>
    </div>
  </a-modal>
</template>
<script>
import {warnMsg, errMsg, successMsg, isNull} from "../../../utils/CommonUtils.js"
export default {
  props: ["visible", "record"],
  data() {
    return {
      title: this.$t('accountRecharge.title'),
      showModal: false,
      data: "",
      balance: "",
    };
  },
  watch:{
    /** 监听visible值的变化,val是visible的最新值 */
    visible(val){
      this.showModal = val;
      if(this.showModal){
        this.init();
        this.balance = ""
      }
    }
  },
  methods: {
    cancel() {
      /**回调父组件的方法, 方法名account-recharge-visible, 参数false, 0 */
      this.$emit("account-recharge-visible", false, 0);
    },
    confirm() {
      var reg = /^[0-9]{1,}[\\.]?[0-9]{0,2}$/
      var reg2 = /\.[0,2]$/
      if(isNull(this.balance)){
        warnMsg(this.$t('accountRecharge.hint.warn.rechargeWarn2'))
        return;
      }
      if(!reg.test(this.balance)){
        if(!reg2.test(this.balance)){
          warnMsg(this.$t('accountRecharge.hint.warn.rechargeWarn3'))  
          return;
        }else{
          warnMsg(this.$t('accountRecharge.hint.warn.rechargeWarn4'))
          return;
        }
      }
      if(this.balance <= 0){
        warnMsg(this.$t('accountRecharge.hint.warn.rechargeWarn5'))
        return;
      }
      if(this.balance.indexOf(".") == this.balance.length - 1){
         this.balance = this.balance.replace(".", "");
      }
      this.axios.post(this.baseUrl + "rechargeSubAccount?customer="+this.record.id+"&price="+this.balance, "", 
            {headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              successMsg(this.$t('accountRecharge.hint.success.recharge'))
              this.$emit("account-recharge-visible", false, 1);
            }else if(res.data.code == 1){
              warnMsg(this.$t('accountRecharge.hint.warn.rechargeWarn1'));
            }else if(res.data.code == -1){
              warnMsg(this.$t('accountRecharge.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }
          } else {
            errMsg(this.$t('accountRecharge.hint.error.getDateErr'));
          }
        }).catch(() => {
          errMsg(this.$t('accountRecharge.hint.error.systemErr'));
        });
    },
    /** 右上角 'X' 关闭按钮调用 */
    handleCancel(){
      this.$emit("account-recharge-visible", false, 0);
    },
    init(){
      this.axios.post(this.baseUrl + "queryMainBalance", "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.data = res.data.data
            }else if(res.data.code == -1){
              warnMsg(this.$t('accountRecharge.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }
          } else {
            errMsg(this.$t('accountRecharge.hint.error.getDateErr'));
          }
        }).catch(() => {
          errMsg(this.$t('accountRecharge.hint.error.systemErr'));
        });
    }
  },
};
</script>
<style scoped>
.account_recharge_div {
  margin-bottom: 10px;
  font-size: 15px;
}
.account_recharge_label{
  display: inline-block;
  width: 100px;
  text-align: justify;
  text-align-last: justify;
}
.account_recharge_input {
  width: 55%;
}
.account_recharge_btn {
  text-align: center;
  margin: 20px 0 0 0;
}
.account_recharge_cancel,
.account_recharge_confirm {
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  height: 33px;
  width: 60px;
  line-height: 33px;
}
.account_recharge_cancel {
  margin-right: 15px;
  border: 1px solid rgb(184, 184, 184);
}
.account_recharge_cancel:hover {
  cursor: pointer;
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}
.account_recharge_confirm {
  border: 1px solid rgb(78, 78, 255);
  background-color: rgb(78, 78, 255);
  color: white;
}
.account_recharge_confirm:hover {
  cursor: pointer;
  box-shadow: 0px 0px 2px 0px rgb(23, 23, 255);
}
::v-deep .ant-modal-body {
  padding: 16px 24px 12px;
}
</style>