<template>
  <div class="account_div">
    <div class="leftmenu_div">
      <leftnav :activeMenu="activeMenu" :items="menus"  @menu="menu"></leftnav>
    </div>
    <div class="content_div">
      <router-view name="content"></router-view>
    </div>
  </div>
</template>

<script>
import leftnav from "../../components/leftnav.vue";
export default {
  data() {
    return {
      activeMenu: 89,  //数据库表菜单id, selectItem,也是菜单id，leftMenu也是菜单id
      menus:[],
    };
  },
  components: {
    leftnav,
  },

  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.$store.commit('saveActiveIndex',89);
     var arr = JSON.parse(sessionStorage.getItem("menus"));
    for (let i = 0; i < arr.length; i++) {
      if(arr[i].id == 89){
        this.menus = arr[i].list;
        break;
      }
    }
    this.menu(this.menus[0].id);
    this.$store.commit('saveLeftMenu', this.menus[0].id);
  },

  methods: {
    menu(selectMenu) {
      if(selectMenu == 102){
          this.$router.push({ name: "describe" });
      }else if(selectMenu == 103){
          this.$router.push({ name: "subAccount" });
      }else if(selectMenu == 104){
          this.$router.push({ name: "serviceAccount" });
      }
    },
  },
};
</script>

<style scoped>
.account_div {
  width: 100%;
  display: flex;
}
.leftmenu_div {
  width: 10%;
}
.content_div {
  width: 90%;
}
</style>