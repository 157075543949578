<template>
<a-modal v-model="showModal" :title="title"  width="88%" :footer="null"
 :maskClosable="false" :dialogStyle="{top: '6%'}" 
      @cancel="handleCancel">
        <a-table
            :columns="showColumns" 
            :data-source="datas" 
            :pagination="false"
            :rowKey="(record,i)=>{return i}"
            size="small" 
            style="height:360px;overflow-y:auto;"
          > 
          <div slot="status" slot-scope="text, record">
              <span v-if="record.status1 == 0">{{$t('purchasedPacket.status.normal')}}</span>
              <span v-else-if="record.status1 == 1">{{$t('purchasedPacket.status.stopped')}}</span>  
              <span v-else-if="record.status1 == 2">{{$t('purchasedPacket.status.unsubscribed')}}</span>  
              <span v-else></span>   
          </div>
          <div slot="operation" slot-scope="text, record">
               <div v-if="$store.state.role != 1">
                   <span  v-if="showBtn(record)" class="row_record_cancel_btn" @click="cancel(record.order_num)">{{$t('purchasedPacket.unsubscribed')}}</span> 
               </div> 
          </div>
        </a-table>
   </a-modal>
</template>
<script>
import {errMsg, warnMsg,isNull, successMsg}  from "../../../utils/CommonUtils.js";
export default {
  props: ["tsid"],
  data() {
    return {
      title: this.$t('purchasedPacket.title'),
      showModal: false,
      currentPage: 1, //当前页码, 默认1
      datas:[],
      showColumns: [
        {
          title: this.$t('purchasedPacket.columns.orderNum'),
          dataIndex: "order_num",
          width: 155,
        },
        {
          title: this.$t('purchasedPacket.columns.startTime'),
          dataIndex: "start_time",
          width: 160,
        },
        {
          title: this.$t('purchasedPacket.columns.endTime'),
          dataIndex: "end_time",
          width: 160,
        },
        {
          title: this.$t('purchasedPacket.columns.orderTime'),
          dataIndex: "order_time",
          width: 160,
        },
        {
          title: this.$t('purchasedPacket.columns.createUser'),
          dataIndex: "createUser",
          ellipsis: true,
          width: 100,
        },
        {
          title: this.$t('purchasedPacket.columns.flowCount'),
          dataIndex: "flow_count",
          width: 90,
        },
        {
          title: this.$t('purchasedPacket.columns.usedFlow'),
          dataIndex: "used_flow",
           width: 90,
        },
        {
          title: this.$t('purchasedPacket.columns.countryCn'),
          dataIndex: this.$i18n.locale == 'zh' ? "countryCn" : "countryEn",
          ellipsis: true,
          width: 80,
        },
         {
          title: this.$t('purchasedPacket.columns.status'),
          dataIndex: "status1",
          scopedSlots: { customRender: "status" },
          width: 60,
        },
        {
          title: this.$t('purchasedPacket.columns.operation'),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          width: 100,
        },
      ],
    };
  },
  created(){
  const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.init()
  },
  methods: {
    init(){
      if(isNull(this.tsid)){
          warnMsg(this.$t('purchasedPacket.hint.warn.getDataWarn'))
          return;
      }
      var reg = /^[0-9]{6,10}$/;
      if(!reg.test(this.tsid)){
         warnMsg(this.$t('purchasedPacket.hint.warn.getDataWarn1'))
         return; 
      }
      this.axios.post(this.baseUrl + "getHistoryData?tsid="+this.tsid, "",{headers:{token: sessionStorage.getItem("token")}}).then((res)=>{
        if(res.status == 200){
          if(res.data.code == 0){
              this.datas = res.data.data;
              this.showModal = true
          }else{
              this.$emit("history-visible", false); //不显示弹框
              if(res.data.code == -1){
                warnMsg(this.$t('purchasedPacket.hint.warn.loginWarn'));
                setTimeout(() => {
                  this.$router.push({ name: "login" });
                }, "2000");
              }else if(res.data.code == -2){
                warnMsg(this.$t('purchasedPacket.hint.warn.retryWarn'))
              }else if(res.data.code == -3){
                errMsg(this.$t('purchasedPacket.hint.error.systemErr'))
              }else if(res.data.code == -4){
                warnMsg(this.$t('purchasedPacket.hint.warn.retryWarn'))
              }
          }
        }else{
          warnMsg(this.$t('purchasedPacket.hint.warn.getDataWarn2'))
          this.$emit("history-visible", false);
        }
      }).catch(()=>{
          errMsg(this.$t('purchasedPacket.hint.error.systemErr'))
          this.$emit("history-visible", false);
        })
    },
  
     handleCancel(){
        this.$emit("history-visible", false);
    },
    cancel(orderNum){
      var currentObj = this; //解决this指向问题
      this.$modal.confirm({
        content: this.$t('purchasedPacket.unsubscribePacket'),
        okText: this.$t('purchasedPacket.ok'),
        cancelText: this.$t('purchasedPacket.cancle'),
        onOk() {
          currentObj.axios.post(currentObj.baseUrl + "detail_cancel_order?orderNum=" + orderNum, "",{headers:{token: sessionStorage.getItem("token")}}).then((res)=>{
              if(res.status == 200){
                if(res.data.code == 0){
                  successMsg(this.$t('purchasedPacket.hint.success.unsubscribe'))
                  currentObj.init()
                }else if(res.data.code == -1){
                  warnMsg(this.$t('purchasedPacket.hint.warn.loginWarn'));
                  setTimeout(() => {
                    currentObj.$router.push({ name: "login" });
                  }, "2000");
                }else if(res.data.code == -3){
                  warnMsg(this.$t('purchasedPacket.hint.warn.packetWarn'))
                }else if(res.data.code == -4){
                  warnMsg(this.$t('purchasedPacket.hint.warn.unsubscribeWarn')) 
                } else if(res.data.code == -7){
                  errMsg(this.$t('purchasedPacket.hint.error.systemErr'))
                }  
              }else{
                warnMsg(this.$t('purchasedPacket.hint.warn.getDataWarn2'))
                currentObj.$emit("history-visible", false);
              }
            }).catch(()=>{
                errMsg(this.$t('purchasedPacket.hint.error.systemErr'))
                currentObj.$emit("history-visible", false);
            })
        },
        onCancel() {
          warnMsg(currentObj.$t('purchasedPacket.hint.warn.cancelWarn'));
        },
      });
    },
    /** 是否显示退订按钮 */
    showBtn(record){
        /** 登录用户不等于订单创建用户就不显示退订 */
        if(record.createUser != sessionStorage.getItem("username")){
          return false;
        }

        let today = new Date();
        today.setHours(23);
        today.setMinutes(59);
        today.setSeconds(59);
        
        let date = record.start_time;
        date = date.substring(0,19);
        date = date.replace(/-/g,'/');
        let st = new Date(date).getTime();

        let date1 = record.end_time;
        date1 = date1.substring(0,19);
        date1 = date1.replace(/-/g,'/');
        let et = new Date(date1).getTime();

        /**不是客服账号(record.ifc != 1),可以参考order.vue文件里面的退订判断 */
        if (record.ifc != 1 && (record.status1 == 0 || record.status1 == 1) && ((record.start_time == "" && record.end_time == "") 
        || (st > today.getTime() && et > st)) && record.orderUser != 100) {
            return true;
        } else {
            return false;
        }
    }
  },
};
</script>
<style scoped>
.row_record_cancel_btn{
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  border: 1px solid rgb(182, 182, 182);
  color: rgb(56, 56, 56);
}
.row_record_cancel_btn:hover {
  cursor: pointer;
  color: rgb(39, 132, 255);
  border: 1px solid rgb(39, 132, 255);
}

.row_record_cancel_btn:active {
  color: rgb(0, 60, 255);
}

::v-deep .ant-modal-mask{
  background-color: rgb(0 0 0 / 15%) !important;
}
</style>