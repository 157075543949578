<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="68%"
    :footer="null"
    :maskClosable="false"
    :dialogStyle="{ top: '15%' }"
    @cancel="handleCancel"
  >
    <div style="height: 180px">
      <div class="refund_info">
        <div>
          <span class="refund_info_label">{{$t('refundModal.orderNum')}}</span>
          <span>{{ record.order }}</span>
        </div>
        <div style="margin-left: 20px">
          <span class="refund_info_label">{{$t('refundModal.tsid')}}</span>
          <span>{{ record.tsid }}</span>
        </div>
        <div style="margin-left: 20px" v-if="record.begin">
          <span class="refund_info_label">{{$t('refundModal.beginTime')}}</span>
          <span>{{ record.begin }}</span>
        </div>
        <div style="margin-left: 20px" v-if="record.end">
          <span class="refund_info_label">{{$t('refundModal.endTime')}}</span>
          <span>{{ record.end }}</span>
        </div>
      </div>
      <div style="margin-top: 30px; display: flex; align-items: center">
        <span class="refund_info_label" style="width: 120px"
          >{{$t('refundModal.reason')}}</span
        >
        <a-textarea
         v-model.trim="reason"
          style="margin-left:6px; height:80px;resize:none;"
          :maxLength="150"
          :placeholder="$t('refundModal.resonMsg')"
          autocomplete="off"
        />
      </div>
      <div style="margin-top: 30px">
        <span class="refund_confirm" @click.prevent="confirm">{{$t('refundModal.confirm')}}</span>
        <span class="refund_cancel" @click.prevent="cancel">{{$t('refundModal.cancel')}}</span>
      </div>
    </div>
  </a-modal>
</template>
<script>
import {errMsg, warnMsg, successMsg, isNull} from "../../../utils/CommonUtils.js"
export default {
  props: ["record"],
  data() {
    return {
      title: this.$t('refundModal.title'),
      showModal: false,
      reason: "",
    };
  },
  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.showModal = true;
  },
  methods: {
    handleCancel() {
      this.showModal = false;
      this.$emit("refund-visible", false, 0);
    },
    confirm() {
      if(isNull(this.reason)){
        warnMsg(this.$t('refundModal.hint.warn.refundWarn1'))
        return;
      }
      this.axios.post(this.baseUrl +"afterrefund_order?tsid=" + this.record.tsid + "&orderid=" + 
          this.record.id + "&orderNumber=" + this.record.order+"&reason="+this.reason,"", 
          { headers: { token: sessionStorage.getItem("token") } }
        ).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              successMsg(this.$t('refundModal.hint.success.refund'));
              this.$emit("refund-visible", false, 1);
              this.showModal = false;
            } else if (res.data.code == -1) {
              warnMsg(this.$t('refundModal.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -2) {
              warnMsg(this.$t('refundModal.hint.warn.refundWarn2'));
            } else if (res.data.code == -3) {
              warnMsg(this.$t('refundModal.hint.warn.refundWarn3'));
            } else if (res.data.code == -4) {
              warnMsg(this.$t('refundModal.hint.warn.refundWarn4'));
            }else if (res.data.code == -7) {
              errMsg(this.$t('refundModal.hint.error.systemErr'));
            }
          } else {
            errMsg(this.$t('refundModal.hint.error.refundErr'));
          }
        }).catch(() => {
          errMsg(this.$t('refundModal.hint.error.systemErr'));
        });
    },
    cancel() {
      this.showModal = false;
      this.$emit("refund-visible", false, 0);
    },
  },
};
</script>
<style scoped>

.refund_info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.refund_info_label {
  color: rgb(48, 48, 48);
  font-weight: 600;
}

.refund_cancel,
.refund_confirm {
  float: right;
  border-radius: 5px;
  text-align: center;
  display: block;
  height: 33px;
  width: 60px;
  line-height: 33px;
}
.refund_cancel {
  margin-right: 15px;
  border: 1px solid rgb(184, 184, 184);
}
.refund_cancel:hover {
  cursor: pointer;
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}
.refund_confirm {
  border: 1px solid rgb(78, 78, 255);
  background-color: rgb(78, 78, 255);
  color: white;
}
.refund_confirm:hover {
  cursor: pointer;
  box-shadow: 0px 0px 2px 0px rgb(23, 23, 255);
}
</style>