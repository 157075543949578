<template>
  <div style="margin:30px;">
    <div class="operate_nav">
      <span class="operate_nav_title">{{ title }}</span>
      <span class="operate_nav_btn_frame" @click.prevent="exportData">
        <a-icon type="download" />
        {{this.$t('toDeviceDetail.report')}}
      </span>
      <export-tips
        v-if="tips"
        :total="pagination.total"
        @export-tips-visible="changeShowExportTips"  
      ></export-tips>
    </div>
    <div style="margin-top: 10px">
       <a-config-provider >
       <a-table
        :loading="loading"
        :columns="columns"
        :data-source="datas"
        :pagination="pagination"
        :rowClassName="rowClassName"
        :customRow="selectRow"
        :rowKey="(record, i)=>{return i}"
        size="small"
      >
      </a-table>
       </a-config-provider>
    </div>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import exportTips from "../../dialog/exportTipsModal.vue";
import {errMsg,warnMsg,isNull } from '../../../utils/CommonUtils';
export default {
   components:{
    exportTips
  },
  data() {
    return {
      locale: zhCN,
      loading: true,
      tips: false,
      date: "",
      rowId: "",
      pagination: {
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeOptions: ["10","20", "100", "300", "500"],
        showSizeChanger: true,
        onChange: (current,pageSize)=>this.changeCurrentPage(current,pageSize),
        onShowSizeChange: (current, pageSize)=>this.changePageSize(current, pageSize), 
        showTotal: () => {
              const count = this.pagination.total;
              return this.$i18n.t('toDeviceDetail.totalRecords', { count });
          },
      },
   
      datas: [],
    };
  },
  computed: {
    title() {
      return  this.$t('toDeviceDetail.title');
    },
    columns() {
      return [
        {
          title: this.$t('subAccount.columns.phone'),
          dataIndex: "phone",
        },
        {
          title: this.$t('subAccount.columns.mainA'),
          dataIndex: "mainA",
        },
        {
          title: this.$t('subAccount.columns.relaname'),
          dataIndex: "relaname",
        },
        {
          title: this.$t('subAccount.columns.balance'),
          dataIndex: "balance",
          width: 100,
        },
        {
          title: this.$t('subAccount.columns.type'),
          dataIndex: "type",
          width: 100,
          scopedSlots: { customRender: "type" },
        },
        {
          title: this.$t('subAccount.columns.show'),
          dataIndex: "show",
          scopedSlots: { customRender: "show" },
        },
        {
          title: this.$t('subAccount.columns.status'),
          dataIndex: "status",
          width: 100,
          scopedSlots: { customRender: "status" },
        },
        {
          title: this.$t('subAccount.columns.date'),
          dataIndex: "date",
          scopedSlots: { customRender: "date" },
        },
      ];
    },
  },
  created(){
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.date = this.$route.query.date;
    this.init();
  },
  methods: {
    //改变页码调用此函数
    changeCurrentPage(currentPage,pageSize){
      this.pagination.current = currentPage;
      this.pagination.pageSize = pageSize;
      this.rowId = "";
    },
    //改变页记录数会调用此函数
    changePageSize(currentPage, pageSize){
      this.pagination.pageSize = pageSize
      this.pagination.current = 1
      this.rowId = "";
    },
    changeShowExportTips(show, flag, val){
      this.tips = show;
      if(flag == 1){
        this.exportReq(val)
      }
    },
    exportData() {
      if(isNull(this.datas)){
        warnMsg(this.$t('toDeviceDetail.hint.warn.reportWarn'));
        return;
      }
      if(this.pagination.total > 10000){
         this.tips = true; 
      }else{
        this.exportReq(null);
      }
    },
    exportReq(val){
      let pageNo = 1
      if(!isNull(val)){
          pageNo = val;
      } 
      this.axios.post(this.baseUrl + "exportDeviceDetails?date="+this.date+"&pageNo="+pageNo,"",{responseType: 'blob',headers: { token: sessionStorage.getItem("token"),'Accept-Language': localStorage.getItem('locale')}}).then((res)=>{
       if(res.status == 200){
            let link = document.createElement("a");
            let url = window.URL.createObjectURL(new Blob([res.data],{type:"application/vnd.ms-excel;charset=UTF-8;"}));
            link.style.display = 'none'
            link.href = url
            let fileName = window.decodeURI(res.headers['content-disposition'].split('=')[1], "UTF-8");
            link.setAttribute('download', fileName);
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link); /**下载完成移除元素*/
            window.URL.revokeObjectURL(url); /**释放掉blob对象*/
          } 
      }).catch(() => {
          errMsg(this.$t('toDeviceDetail.hint.error.systemErr'));
      });
    },
    init(){
      if(isNull(this.date)){
        warnMsg(this.$t('toDeviceDetail.hint.warn.getDataWarn'))
        return;
      }
      this.axios.post(this.baseUrl + "getDeviceDetails?date="+this.date,"",{headers: {token: sessionStorage.getItem("token")}}).then((res)=>{
          this.loading = false;
          if(res.status == 200){
            if(res.data.code == 0 && res.data.data.count > 0){
               this.datas = res.data.data.list;
               this.pagination.total = res.data.data.count;
            }else if(res.data.code == 1 || res.data.data.count == 0){
              this.pagination.total = 0;
              this.datas = [];
            }else if(res.data.code == -1){
              warnMsg(this.$t('toDeviceDetail.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }else if(res.data.code == -2 || res.data.code == -4){
              warnMsg(this.$t('toDeviceDetail.hint.warn.retryWarn'))
            }else if(res.data.code == -3){
              errMsg(this.$t('toDeviceDetail.hint.error.systemErr'))
            } 
          }else{
            errMsg(this.$t('toDeviceDetail.hint.warn.getDataWarn'))
          }
        }
      ).catch(()=>{
        this.loading = false;
        errMsg(this.$t('toDeviceDetail.hint.error.systemErr'))
      })
    },
    rowClassName(record, index) {
      if(index === this.rowId){
        return "select_row_bg_color";
      }else{
        return "";
      }
    },
    selectRow(record, index){
       return {
            on: {
               click: () => {
                  this.rowId = index;
               }
            }
        }
    },  
  },
};
</script>
<style scoped>
@import '../../../assets/css/table.css'; 
  /* 操作栏 */
.operate_nav {
    margin-top: 10px;
    text-align: right;
  }
  
.operate_nav_title {
    /* 整行标题 */
    float: left;
    font-size: 24px;
    font-weight: bold;
    color: rgb(14, 14, 14);
  }
  
.operate_nav_btn_frame {
    margin: 7px 0px 0px;
    display: inline-block;
    text-align: center;
    min-width: 70px;
    border: 1px solid rgb(160, 160, 160);
    height: 30px;
    line-height: 30px;
    margin-left: 15px;
    border-radius: 5px;
  }
  
.operate_nav_btn_frame:hover {
    cursor: pointer;
    color:  rgb(78, 78, 255);
    border: 1px solid rgb(78, 78, 255);
  }
::v-deep .ant-table-small {
  border: none;
}
</style>
 