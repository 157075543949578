<template>
  <div>
    <div class="operate_nav">
      <span class="operate_nav_title">{{ title }}</span>
      <!-- $store.state.role等于1,表示登录的是客服账号， isParentAcount等于0，表示登录的是主账号 -->
       <span v-if="$store.state.role != 1 && isParentAcount == 0" class="operate_nav_account" @click.prevent="createAccount(true)">
        {{$t('serviceAccount.createAccount')}}
      </span>
      <create-account
        :visible="visible.account"
        @create-account-visible="createAccount"
      ></create-account>
    </div>
    <div style="margin-top: 10px">
      <a-config-provider >
         <a-table
          :loading="loading"
          :columns="columns"
          :data-source="datas"
          :rowClassName="rowClassName"
          :customRow="selectRow"
          :pagination="pagination"
          :rowKey="(record,i)=>{return i}"
          size="small"
        >
          <div slot="date" slot-scope="text, record">
            <div>
              <span>{{ record.createTime }}</span>
              <span v-if="$store.state.role != 1" class="row_record_btn_operate" :title="$t('serviceAccount.operation')">
                <a-dropdown :trigger="['click']">
                  <a-icon type="ellipsis" />
                  <a-menu slot="overlay" style="text-align: center">
                    <a-menu-item>
                      <span @click.prevent="showUpdatePhone(record)">{{$t('serviceAccount.updatePhone')}}</span>
                    </a-menu-item>
                    <a-menu-item>
                      <span @click.prevent="showUpdateName(record)">{{$t('serviceAccount.updateName')}}</span>
                    </a-menu-item>
                     <a-menu-item>
                      <span @click.prevent="showUpdatePassword(record)">{{$t('serviceAccount.updatePassword')}}</span>
                    </a-menu-item>
                    <a-menu-item
                      @click="updateOperateStatus(record, 0)"
                      v-if="record.status == -2">
                      {{$t('serviceAccount.enable')}}
                    </a-menu-item>
                    <a-menu-item
                      @click="updateOperateStatus(record, -2)"
                      v-if="record.status == 0">
                      {{$t('serviceAccount.deactivate')}}
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </span>
            </div>
          </div>
        </a-table>
      </a-config-provider>
      <update-phone
        :visible="visible.phone"
        :id="record.id"
        @update-phone-visible="updatePhone"
      ></update-phone>
      <update-password
        :visible="visible.password"
         :id="record.id"
        @update-password-visible="updatePassword"
      ></update-password>
      <update-name
        :visible="visible.name"
        :id="record.id"
         @update-name-visible="updateName"
      ></update-name>
    </div>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import updatePhone from "../../dialog/account/updatePhoneModal.vue"
import updatePassword from "../../dialog/account/updatePasswordModal.vue"
import createAccount from "../../dialog/account/createServiceAccountModal.vue";
import updateName from "../../dialog/account/updateNameModal.vue"
import {errMsg,warnMsg,successMsg} from "../../../utils/CommonUtils.js"
export default {
  components: {
    updatePhone,
    updatePassword,
    createAccount,
    updateName,
  },
  data() {
    return {
      locale: zhCN,
      loading: true,
      isParentAcount: "",
      record: "",
      rowId: "",
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "100", "300", "500"],
        showSizeChanger: true,
        onChange: (current,pageSize)=>this.changeCurrentPage(current,pageSize),
        onShowSizeChange: (current, pageSize)=>this.changePageSize(current, pageSize), 
        showTotal: () => {
              const count = this.pagination.total;
              return this.$i18n.t('serviceAccount.totalRecords', { count });
          },
      },
      visible: {
        phone: false,
        name: false,
        password: false,
        account: false,
      },
      datas: [],

    };
  },
  computed: {
    title() {
      return this.$t('serviceAccount.title');
    },
    columns() {
      return [
        {
          title: this.$t('serviceAccount.phone'),
          dataIndex: "phone",
        },
        {
          title: this.$t('serviceAccount.mainA'),
          dataIndex: "mainA",
        },
        {
          title: this.$t('serviceAccount.relaname'),
          dataIndex: "relaname",
        },
        {
          title: this.$t('serviceAccount.type'),
          dataIndex: "type",
        },
        {
          title: this.$t('serviceAccount.date'),
          dataIndex: "date",
          scopedSlots: { customRender: "date" },
        },
      ];
    },
  },
  created(){
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.isParentAcount = sessionStorage.getItem("main"); /** 是否是主账号 */
    this.init();
  },
  methods: {
    //改变页码调用此函数
    changeCurrentPage(currentPage,pageSize){
      this.pagination.current = currentPage;
      this.pagination.pageSize = pageSize;
      this.rowId = "";
    },
    //改变页记录数会调用此函数
    changePageSize(currentPage, pageSize){
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.rowId = "";
    },
    createAccount(val, flag) {
      this.visible.account = val;
      if(flag == 1){
        this.init();
      }
    },
    init(){
      this.axios.post(this.baseUrl + "getSonAccount", "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.datas  = res.data.data
              this.pagination.total = res.data.count
            }else if(res.data.code == 1){
              this.datas = []
              this.pagination.total = 0
            }else if(res.data.code == -1){
              warnMsg(this.$t('serviceAccount.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }
          } else {
            errMsg(this.$t('serviceAccount.hint.error.getDateErr'));
          }
        }).catch(() => {
          this.loading = false;
          errMsg(this.$t('serviceAccount.hint.error.systemErr'));
        });
    },
    showUpdatePhone(record){
      this.record = record;
      this.visible.phone = true;
    },
    showUpdateName(record){
      this.record = record;
      this.visible.name = true;
    },
    showUpdatePassword(record){
      this.record = record;
      this.visible.password = true;
    },
    updatePhone(val, flag){
      this.visible.phone = val;
      if(flag == 1){
        this.init()
      }
    },
    updatePassword(val,flag){
      this.visible.password = val;
       if(flag == 1){
        this.init()
      }
    },
    updateName(val, flag){
      this.visible.name = val;
      if(flag == 1){
        this.init()
      }
    },
    updateOperateStatus(record, status) {
      this.axios.post(this.baseUrl + "updateStatus?id="+record.id+"&type="+status, "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              successMsg(this.$t('serviceAccount.hint.success.update'))
              this.init();
            }else if(res.data.code == -1){
              warnMsg(this.$t('serviceAccount.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }else if(res.data.code == -2){
              warnMsg(this.$t('serviceAccount.hint.warn.getDateWarn'));
            }else if(res.data.code == -3){
              warnMsg(this.$t('serviceAccount.hint.warn.permissionWarn'));
            }
          } else {
            errMsg(this.$t('serviceAccount.hint.error.getDateErr'));
          }
        }).catch(() => {
          errMsg(this.$t('serviceAccount.hint.error.systemErr'));
        });
    },
    rowClassName(record, index) {
      if(index === this.rowId){
        return "select_row_bg_color";
      }else{
        return "";
      }
    },
    selectRow(record, index){
       return {
            on: {
               click: () => {
                  this.rowId = index;
               }
            }
        }
    },  
  },
};
</script>
<style scoped>
@import '../../../assets/css/table.css';
  /* 操作栏 */
.operate_nav {
    margin-top: 10px;
    text-align: right;
  }
  
.operate_nav_title {
    /* 整行标题 */
    float: left;
    font-size: 24px;
    font-weight: bold;
    color: rgb(14, 14, 14);
  }
  
/* 创建子账号 */
.operate_nav_account {
  display: inline-block;
  text-align: center;
  min-width: 100px;
  border: 1px solid rgb(160, 160, 160);
  height: 30px;
  line-height: 30px;
  margin-left: 15px;
  border-radius: 5px;
}

.operate_nav_account:hover {
  cursor: pointer;
  color: rgb(78, 78, 255);
  border: 1px solid rgb(78, 78, 255);
}

/* 操作 */
.row_record_btn_operate {
  float: right;
  margin-right: 10px;
}
.row_record_btn_operate:hover {
  cursor: pointer;
}
::v-deep .ant-table-small {
  border: none;
}
</style>