<template>
  <div>
    <div class="operate_nav">
      <span class="operate_nav_title">{{ title }}</span>
    </div>
    <div style="margin-top: 10px">
      <a-config-provider >
        <a-table
          :loading="loading"
          :columns="columns"
          :data-source="datas"
          :pagination="pagination"
          :rowClassName="rowClassName"
          :customRow="selectRow"
          bordered
          :rowKey="(record, i) => {return i;}"
          size="small"
        >
        </a-table>
      </a-config-provider>
    </div>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { errMsg, isNull, warnMsg } from "../../../utils/CommonUtils";
export default {
  data() {
    return {
      title: this.$t('packetList.title'),
      locale: zhCN,
      pagination: false,
      rowId: "",
      loading: true,
      datas: [],
      columns: [
        {
          title: this.$t('packetList.columns.areaName'),
          dataIndex: "areaName",
          width: 100,
        },
        {
          title: this.$t('packetList.columns.supportNames'),
          dataIndex: "supportNames",
        },
      ],
    };
  },

  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.init();
  },
  methods: {
    init() {
      this.axios.post(this.baseUrl + "getPacketProfile", "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            if (res.data.code == 0) {
              if(!isNull(res.data.data)){
                /**动态列名构造(套餐名称) */
                if (!isNull(res.data.data.shownameList)) {
                  var childrens = [];
                  let cols = res.data.data.shownameList;
                  for (const key in cols) {
                    if (!isNull(cols[key])) {
                      var item = {
                        title: cols[key],
                        dataIndex: cols[key],
                        width: 70,
                      };
                      childrens.push(item);
                    }
                  }
                  childrens.push({
                    title: this.$t('packetList.columns.overPrice'),
                    dataIndex: "overPrice",
                    width: 70,
                  });
                  var price = {
                    title: this.$t('packetList.columns.price'),
                    dataIndex: "prices",
                    children: childrens,
                  };
                  this.columns.push(price);
                }

                /** 渲染的数据结构构造 */
                var datas = res.data.data.List;
                for (let i = 0; i < datas.length; i++) {
                  if (datas[i].overPrice == 0) {
                    datas[i].overPrice = "-";
                  }
                  var prices = datas[i].prices;
                  for (const key in prices) {
                    if (prices[key] == 0) {
                      datas[i][key] = "-";
                    } else {
                      datas[i][key] = prices[key];
                    }
                  }
                  datas[i].prices = "";
                }
                this.datas = datas;
              }
            } else if (res.data.code == -1) {
              warnMsg(this.$t('packetList.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }
          } else {
            errMsg(this.$t('packetList.hint.error.getDataErr'));
          }
        }).catch(() => {
          this.loading = false;
          errMsg(this.$t('packetList.hint.error.systemErr'));
        });
    },
    rowClassName(record, index) {
      if(index === this.rowId){
        return "select_row_bg_color";
      }else{
        return "";
      }
    },
    selectRow(record, index){
       return {
            on: {
               click: () => {
                  this.rowId = index;
               }
            }
        }
    },  
  },
};
</script>
<style scoped>
/* 操作栏 */
.operate_nav {
  margin-top: 10px;
  text-align: right;
}

.operate_nav_title {
  /* 整行标题 */
  float: left;
  font-size: 24px;
  font-weight: bold;
  color: rgb(14, 14, 14);
}

/* 表头样式 */
::v-deep .ant-table-thead > tr > th {
  background-color: white !important;
  padding: 8px 4px 8px !important;
  font-size: 12px !important;
  color: #6f6f6fa6 !important;
}
/**表记录样式 */
::v-deep .ant-table-tbody > tr > td {
  padding: 7px !important;
  font-size: 14px;
  color: rgb(51, 51, 51);
}
::v-deep .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0px;
}

</style>