const zh = {
    /** 请求 */
    request: {
        hint: {

        }
    },

    /** 响应 */
    response: {
        hint: {
            exception: "",
            warning: "",
            success: "",
            login: "",
        }
    },

    /** 公共 */
    common: {
        button: {
            cancel: "取消",
            confirm: "确认",
        }
    },

    /** 页面头部导航栏 */
    navcon: {
        menu: {
            首页: "首页",
            终端: "终端",
            计费: "计费",
            账号: "账号",
            报表: "报表",
        },
        /** 修改密码模态框 */
        title: "修改密码",
        alarm: '告警通知',
        api: 'api文档',
        cancel: '取消',
        confirm: '确认',
        button: {
            updatePassword: "修改密码",
            logOut: "退出",
            language: 'EN'
        },
        label: {
            oldPassword: "旧密码",
            newPassword: "新密码",
            confirmPassword: "确认密码"
        },
        hint: {
            oldPassword: "请输入旧密码",
            newPassword: "请输入新密码",
            confirmPassword: "请输入确认密码",
            login: '请登录',
            warn: {
                loginWarn: '请先登录',
                passwordWarn1: '新密码长度不能小于6位',
                passwordWarn2: '旧密码错误',
                passwordWarn3: '新密码和旧密码相同，修改失败',
                updateWarn: '修改失败',
            },
            error: {
                systemErr: '系统异常，请联系客服',
                updateErr: '修改失败',
                passwordErr1: '密码未修改，请重新填写新密码',
                passwordErr2: '新密码与确认密码不一致',
            }
        },
        error: {

        }
    },

    leftnav: {
        终端管理: '终端管理',
        已购套餐: '已购套餐',
        当日活跃终端: '当日活跃终端',
        当月活跃终端: '当月活跃终端',
        不活跃终端: '不活跃终端',
        账单: '账单',
        账户流水: '账户流水',
        余额: '余额',
        账号一览: '账号一览',
        分销账号管理: '分销账号管理',
        客服账号管理: '客服账号管理',
        套餐列表: '套餐列表',
        终端地域分布: '终端地域分布',
    },

    /** 登录界面 */
    login: {
        title: "小漫物联客户平台",
        formTitle: "登录",
        forget: "忘记密码",
        signIn: "登录",
        refreshVerifyCode: '看不清楚，换一张',
        hint: {
            phone: "手机号",
            password: "密码",
            verifyCode: "验证码",
        },
        loginHint: {
            phone: "手机号不能为空",
            phoneError: "手机号格式不正确",
            password: "密码不能为空",
            verifyCode: "验证码不能为空",
            verifyCodeError: "验证码错误",
            usernameWarn: '请输入用户名或密码',
            loginWarn1: '登陆失败，没有接口访问权限',
            loginWarn2: '登陆失败，没有菜单访问权限',
            codeWarn: '请重新获取验证码',
            warn: '未知异常，请联系客服',
            systemErr: '系统异常，请联系客服',
            err: '请求失败，请联系客服'
        },
    },

    /** 首页界面 */
    chart: {
        date: "日期",
        name: "姓名",
        address: "地址",
        dayActive: '日活跃终端',
        changeDateMsg: '请选择日期',
        balance: '账户余额',
        cost: '本月消费',
        total: '终端总数',
        using: '使用中',
        stop: '停用终端',
        tower: ' 台',
        trafficPoolOverview: '流量池概览',
        trafficPool: '流量池',
        device_all: '终端数量：',
        device_use: '活跃终端：',
        device_stop: '未使用终端：',
        used: '已使用',
        poolTraffic: '池流量',
        flowExceeded: '超出流量',
        distributionTrend: '终端分布趋势',
        hint: {
            warn: {
                notData: '没有数据',
                loginWarn: '请先登录',
                getDataWarn: '获取数据失败',
                retryWarn: '请稍后重试，如多次重试无效，请联系客服。',
                trafficPoolWarn: '没有流量池信息',
            },
            error: {
                systemErr: '系统异常，请联系客服',

            }
        }
    },

    /**============================================== */
    /** 终端界面 */
    device: {
        menu: {
            deviceManage: "终端管理",
            dayActive: "当日活跃终端",
            order: "已订套餐",
            monthActive: "本月活跃终端",
            flowPool: "已订阅流量池",
            inactiveDevice: "不活跃终端",
        },
        deviceManage: {
            title: "终端管理",
            selectMsg: '请选择',
            button: {
                operation: '操作',
                search: '搜索',
                report: '导出',
                filter: '展示列',
            },
            searchList:{
                search: '查询',
                reset: '重置',
                group: '业务分组：',
                status: '状态：',
                account: '归属账号：',
                model: '产品型号：',
                batch: '生产批次：',
                version: '软件版本：',
                tsid: '终端编号：',
                imei: 'IMEI：',
                note: '备注：',
                searchAccountMsg: '请选择归属账号',
                inputModelMsg: '请输入产品型号',
                inputBatchMsg: '请输入生产批次',
                inputVersionMsg: '请输入软件版本',
                inputTsidMsg: '请输入编号，以英文逗号隔开',
                inputImeiMsg: '请输入IMEI，以英文逗号隔开',
                inputNoteMsg: '请输入备注',
            },

            deviceAuth: '终端授权',
            batchGroup: '终端分组',
            buyPacket: '套餐订购',
            autoSubscribe: '自动续订',
            cancelSubscribe: '取消续订',
            updateStatus: '状态变更',
            updateNote: '修改备注',
            

            totalDevice: '总终端数：',
            myDevice: '自有终端数：',
            ownDevice: '自有终端',
            table: '台',
            normal: '正常',
            deactivate: '停用',
            yes: '是',
            no: '否',
            operation: '操作',
            details: '详情',
            purchasedPackage: '已购套餐',

            totalRecords: '共{count}条记录',

            hint:{
                success: {

                },
                warn: {
                    exportWarn: '没有数据可以导出',
                    loginWarn: '请先登录',
                    retryWarn: '请稍后重试',
                    selectorColumnWarn: '请至少选中一列',
                    deviceAuthWarn: '请选择要授权的终端',
                    batchGroupWarn: '请选择要分组的终端',
                    updateNoteWarn: '请选择要修改备注的终端',
                    updateStatusWarn: '请选择要修改状态的终端',
                },
                error: {
                    systemErr: '系统异常，请联系客服',
                    getDataErr: '查询数据失败'
                }  
            },

            columns:{
                tsid: '终端编号',
                imei: 'IMEI',
                belong: '归属账号',
                bissgroup: '业务分组',
                status: '状态',
                autoRenewal: '自动续订',
                usePackage: '使用套餐',
                expireTime: '套餐到期时间',
                model: '产品型号',
                version: '软件版本',
                remark: '备注',
                operation: '操作',
            }
        },
        dayActive: {
            title: "",
        },
        order: {
            title: "",
        },
        monthActive: {
            title: "",
        },
        flowPool: {
            title: "",
        },
        inactiveDevice: {
            title: "",
        },
    },


    //终端授权弹窗
    deviceAuthModal:{
        title: '终端授权',
        mainAccount: '主账号',
        selectAccountMsg: '请选择账号',
        distributeDevice: '分发终端',
        tipTitle: '不归属当前账号的设备是否一起授权？',
        hint: {
            success: {
                distribute: '分发终端成功',
            },
            warn: {
                loginWarn: '请先登录',
                imeisWarn: '没有可授权的终端',
                distributeWarn1: '分发失败',
                distributeWarn2: '终端为关联代理商',
                distributeWarn3: '下级账号错误',
                searchAccountWarn: '请选择账号',
                accountWarn0: '终端不属于当前账号或当前账号的子账号,操作失败！',
                accountWarn2: '没有查询到设备',
                operationWarn: '操作失败，请稍后重试！',
                retryWarn: '请稍后重试',
            },
            error: {
                longinErr: '未登录',
                distributeErr: '请退出重试，如多次重试无效，请联系客服',
                systemErr: '系统异常，请联系客服',
                accountErr: '归属账号获取失败',
            }
        }
    },

    //终端分组(批量分组)弹窗
    batchGroup: {
        title: '批量分组',
        clearGroup: '清除分组',
        groupName: '分组名称：',
        existingGroup: '现有分组：',
        inputGroupNameMsg: '请输入组别名称',
        cancel: '取消',
        define: '确定',
        tipTitle: '不归属当前账号的设备是否一起修改？',
        hint:{
            success:{
                updateGroup: '修改分组成功',
                clearGroup: '清空分组成功',
            },
            warn:{
                addGroupWarn: '没有要添加分组的终端',
                updateGroupWarn: '修改分组失败',
                updateGroupWarn2: '请退出重试，如多次重试失败，请联系客服',
                clearGroupWarn: '没有要清除分组的终端',
                clearGroupWarn1: '清空分组失败',
                loginWarn: '请先登录',
                selectImeiWarn: '请选中终端',
                inputGroupWarn: '请输入新的分组名称',
                accountWarn0: '终端不属于当前账号或当前账号的子账号,操作失败！',
                accountWarn2: '没有查询到设备',
                operationWarn: '操作失败，请稍后重试！',
                retryWarn: '请稍后重试',
            },
            error:{
                longinErr: '未登录',
                systemErr: '系统异常，请联系客服',
            }
        }
    },

    //套餐订购弹窗
    buyPacket:{
        title: '套餐订购',
        search: '搜索：',
        selectGroup: '请选择组',
        selectGroupMsg: '请选择分组',
        selectCountryMsg: '请选择国家',
        beginTime: '开始时间：',
        selectDateMsg: '请选择日期',
        supportCountry: '支持国家',
        selectPacketMsg: '已选择套餐：',
        cancel: '取消',
        define: '确定',
        packageTime: '套餐时长',
        day: '天',
        month: '月',
        year: '年',
        effectiveMonth: '本月生效',
        nextEffectiveMonth: '次月生效',
        hint:{
            success:{
                subscribe: '订购成功',
            },
            warn:{
                packetWarn: '天包信息错误，请联系客服。',
                selectImeiWarn: '请选择终端或分组',
                selectPacketWarn: '请选择套餐',
                selectDateWarn: '请选择天包开始时间',
                loginWarn: '请先登录',
                subscribeWarn2: '订购失败，请稍后再试',
                subscribeWarn3: '无操作权限',
                subscribeWarn4: '终端状态错误，无法订购',
                subscribeWarn5: '余额不足',
                retryWarn: '请稍后重试',
            },
            error:{
                longinErr: '未登录',
                systemErr: '系统异常，请联系客服',
                subscribeErr1: '批量订购失败，请联系客服',
            }
        }
    },

    //自动续订弹窗
    autoSubscribe:{
        title: '自动续订',
        search: '搜索',
        selectGroup: '请选择组：',
        selectGroupMsg: '请选择分组',
        selectCountryMsg: '请选择国家',
        isOrderEndRenew: '订单结束是否续订：',
        isTrafficEndRenew: '流量用完是否续订：',
        renewDate: '续订有效期：',
        foreverValidity: '永久有效',
        searchTime: '选择时间',
        activePeriod: '有效时段：',
        placeholder: ['开始日期', '结束日期'],
        supportCountry: '支持国家',
        selectPacketMsg: '已选择套餐：',
        cancel: '取消',
        define: '确定',
        day: '天',
        month: '月',
        year: '年',
        yes: '是',
        no: '否',
        hint:{
            success:{
                renew: '自动续订成功',
            },
            warn:{
                selectImeiWarn: '请选择终端或分组',
                selectPacketWarn: '请选择套餐',
                loginWarn: '请先登录',
                renewWarn3: '入库失败',
                renewWarn4: '存在未使用或使用中的订单',
                renewWarn5: '终端归属有误，请联系客服',
                renewWarn6: '无操作权限',
                renewWarn7: '请勿重复续订',
                renewWarn8: '余额不足',
                retryWarn: '请稍后重试',
                getDataWarn: '查询数据失败，请稍后重试',
            },
            error:{
                longinErr: '未登录',
                systemErr: '系统异常，请联系客服',
                subscribeErr1: '批量订购失败，请联系客服',
            }
        }
    },

    //取消续订弹窗
    cancelSubscribe:{
        title: '取消续订',
        selectGroup: '请选择组',
        selectDevice: '已选择终端',
        cancel: '取消',
        define: '确定',
        hint:{
            success:{
                cancelRenew: '取消续订成功',
            },
            warn:{
                selectImeiWarn: '请先选择终端或分组',
                loginWarn: '请先登录',
                cancelRenewWarn1: '终端查询失败',
                cancelRenewWarn2: '终端未绑定',
                cancelRenewWarn3: '操作失败',
                cancelRenewWarn4: '无自动续订记录',
                cancelRenewWarn5: '无权限',
                cancelRenewWarn6: '请选中分组或设备',
                retryWarn: '请稍后重试',
            },
            error:{
                longinErr: '未登录',
                systemErr: '系统异常，请联系客服',
            }
        }
    },

    //状态变更(修改状态)弹窗
    updateStatus:{
        title: '修改状态',
        status: '状态：',
        selectMsg: '请选择',
        normal: '正常',
        deactivate: '停用',
        cancel: '取消',
        define: '确定',
        tipTitle: '不归属当前账号的设备是否一起修改？',
        hint:{
            success:{
                update: '修改成功',
                update1: '部分终端修改成功'
            },
            warn:{
                updateWarn: '没有要修改状态的终端',
                loginWarn: '请先登录',
                updateWarn2: '终端未绑定',
                retryWarn: '请稍后重试',
                selectDevice: '请选中终端',
                selectStatus: '请选择状态',
                accountWarn0: '终端不属于当前账号或当前账号的子账号,操作失败！',
                getDeviceWarn: '没有查询到设备',
                operationWarn: '操作失败，请稍后重试！',
            },
            error:{
                longinErr: '未登录',
                updateErr1: '修改失败',
                updateErr6: '请选中要修改的终端',
                systemErr: '系统异常',
                systemErr2: '系统异常，请联系客服',
            }
        }
    },

    //修改备注弹窗
    updateNote:{
        title: '修改备注',
        note: '备注',
        emptyNote: '清空备注',
        inputNoteMsg: '请输入备注',
        cancel: '取消',
        define: '确定',
        tipTitle: '不归属当前账号的设备是否一起修改？',
        hint:{
            success:{
                update: '修改成功',
                update1: '部分终端修改成功',
                emptyNote: '清空备注成功'
            },
            warn:{
                addNoteDeviceWarn: '没有要添加备注的终端',
                loginWarn: '请先登录',
                updateWarn2: '终端未绑定',
                updateWarn3: '请选中要修改的终端或分组',
                retryWarn: '请稍后重试',
                emptyNoteWarn: '没有要清除备注的终端',
                selectDeviceWarn: '请选中终端',
                inputNoteWarn: '请输入备注信息',
                accountWarn0: '终端不属于当前账号或当前账号的子账号,操作失败！',
                getDeviceWarn: '没有查询到设备',
                operationWarn: '操作失败，请稍后重试！',
            },
            error:{
                longinErr: '未登录',
                updateErr1: '修改失败',
                updateErr6: '请选中要修改的终端',
                systemErr: '系统异常',
                systemErr2: '系统异常，请联系客服',
                emptyNoteErr: '清空备注失败',
                emptyNoteErr2: '清空备注失败，请稍后重试',
                
            }
        }
    },

    //终端管理 详情弹窗
    deviceDetail: {
        title: '详情',
        basicInfo: '基本信息',
        tsid: 'TSID：',
        model: '产品型号：',
        batch: '生产批次：',
        status: '状态：',
        normal: '正常',
        stopped: '停用',
        imei: 'IMEI：',
        version: '软件版本：',
        bissgroup: '业务分组：',
        mac: 'MAC：',
        remark: '备注：',
        packageOrders: '套餐订单',
        orderNum: '订单编号：',
        countryCode: '预约国家：',
        packetFlow: '套餐流量：',
        orderTime: '下单时间：',
        packetTime: '套餐时长：',
        amount: '金额：',
        effectiveTime: '生效时间：',
        usedFlowMaxFlow: '已用流量/最大流量：',
        autoRenewal: '自动续订',
        packageName: '套餐名称：',
        packagePrice: '套餐价格：',
        packageCountry: '套餐国家：',
        operatorAccount: '操作账号：',
        orderStatus: '订单状态：',
        operationTime: '操作时间：',
        startTime: '开始时间：',
        endTime: '截止时间：',
        renewAfterUsedUp: '流量用完是否续订：',
        yes: '是',
        no: '否',
        year: '年',
        month: '月',
        day: '日',
        hint: {
            error: {
                systemErr: '系统异常，请联系客服'
            }
        }
    },


    //终端管理 已购套餐弹窗
    purchasedPacket: {
        title: '已购套餐',
        unsubscribePacket: '退订套餐?',
        unsubscribed: '退订',
        ok: '确定',
        cancle: '取消',
        status: {
            normal: '正常',
            stopped: '已停用',
            unsubscribed: '已退订',
        },
        columns: {
            orderNum: '订单编号',
            startTime: '开始时间',
            endTime: '结束时间',
            orderTime: '下单时间',
            createUser: '下单账号',
            flowCount: '套餐流量(KB)',
            usedFlow: '已用流量(KB)',
            countryCn: '套餐国家',
            status: '状态',
            operation: '操作',
        },
        hint: {
            success: {
                unsubscribe: '退订成功',
            },
            warn: {
                getDataWarn: '查询数据失败',
                getDataWarn1: '查询数据失败，tsid格式不正确',
                getDataWarn2: '查询失败，请稍后重试',
                loginWarn: '请先登录',
                retryWarn: '请稍后重试',
                packetWarn: '套餐信息异常',
                unsubscribeWarn: '退订失败，请联系客服人员',
                cancelWarn: '已取消',
            },
            error: {
                systemErr: '系统异常，请联系客服',
            }
        }
    },


    



    //已购套餐页
    deviceOrder: {
        title: '已购套餐',
        system: '系统',
        totalRecords: '共{count}条记录',
        unsubscribePacket: '退订套餐?',
        ok: '确定',
        cancle: '取消',
        searchList: {
            order: '订单编号：',
            country: '国家：',
            orderTime: '下单时间：',
            startTime: '开始时间：',
            tsid: '终端编号：',
            orderMsg: '请输入订单编号',
            countryMsg: '请选择国家',
            placeholder: ['开始日期', '结束日期'],
            tsidMsg: '请输入编号，以英文逗号隔开',
        },
        button: {
            check: '启用',
            close: '停用',
            search: '搜索',
            report: '导出',
            filter: '展示列',
            inquire: '查询',
            unsubscribe: '退订',
            refund: '退款',
        },
        showcolumns: {
            tsid: '终端编号',
            order: '订单编号',
            flow: '套餐流量(KB)',
            used: '已使用(KB)',
            price: '价格(元)',
            countryName: '国家',
            orderStatus: '状态',
            begin: '开始时间',
            payment: '支付账号',
            operation: '操作',
        },
        columns: {
            tsid: '终端编号',
            order: '订单编号',
            flow: '总流量',
            used: '已使用',
            price: '价格',
            countryName: '国家',
            orderStatus: '状态',
            subtime: '下单时间',
            begin: '开始时间',
            end: '结束时间',
            payment: '支付账号',
            homeAccount: '下单账号',
            belongSystem: '下单系统',
            note: '备注',
            operation: '操作',
        },
        menus: {
            allOrder: '全部订单',
            effectiveOrder: '生效订单',
            deactivateOrder: '停用订单',
            unsubscribeOrder: '退订订单',
        },
        hint: {
            success: {
                unsubscribe: '退订成功',
                updateStatus: '修改状态成功',
            },
            warn: {
                selectedRowWarn1: '请选中记录',
                selectedRowWarn2: '每次操作不能超过100台',
                checkWarn: '已结束的订单不可以启用',
                closeWarn: '已结束的订单不可以停用',
                reportWarn: '没有数据可以导出',
                loginWarn: '请先登录',
                retryWarn: '请退出重试，若重试多次无效，请联系客服',
                selectorColumnWarn: '请至少选中一列',
                packetWarn: '套餐信息异常',
                unsubscribeWarn: '退订失败，请联系客服人员',
                cancelWarn: '已取消',
                updateStatusWarn1: '此订单不能修改状态',
                updateStatusWarn2: '不支持该状态的修改',
                getDataWarn: '查询失败,请稍后重试'
            },
            error: {
                systemErr: '系统异常，请联系客服',
                getDataErr: '查询数据失败',
                updateStatusErr: '修改状态失败',
            }
        }

    },


    //当日活跃终端
    deviceDayActive: {
        title: '当日活跃终端',
        totalRecords: '共{count}条记录',
        online: '在线',
        offline: '离线',
        button: {
            search: '搜索',
            report: '导出',
            reload: '刷新',
            inquire: '查询',
        },
        searchList: {
            useCountry: '使用国家：',
            status: '在线状态：',
            date: '日期：',
            tsid: '终端编号：',
            imei: 'IMEI：',
            useCountryMsg: '请选择国家',
            selectMsg: '请选择',
            dateMsg: '请选择日期',
            tsidMsg: '请输入编号，以英文逗号隔开',
            imeiMsg: '请输入IMEI，以英文逗号隔开',
        },
        columns: {
            tsid: '终端编号',
            imei: 'IMEI',
            account: '账号',
            date: '日期',
            remainTime: '使用时长(Min)',
            flow: '使用流量(KB)',
            country: '使用国家',
            status: '在线状态',
        },
        hint: {
            success: {
                reload: '刷新成功',
            },
            warn: {
                reportWarn: '没有数据可以导出',
                loginWarn: '请先登录',
                getDateWarn: '查询数据失败，请稍后重试',

            },
            error: {
                systemErr: '系统异常，请联系客服',
            }
        }
    },


    //当月活跃终端
    deviceMonthActive: {
        title: '当月活跃终端',
        totalRecords: '共{count}条记录',
        online: '在线',
        offline: '离线',
        button: {
            search: '搜索',
            report: '导出',
            inquire: '查询',
        },
        searchList: {
            useCountry: '使用国家：',
            date: '日期：',
            tsid: '终端编号：',
            imei: 'IMEI：',
            useCountryMsg: '请选择国家',
            dateMsg: '请选择日期',
            tsidMsg: '请输入编号，以英文逗号隔开',
            imeiMsg: '请输入IMEI，以英文逗号隔开',
        },
        columns: {
            tsid: '终端编号',
            imei: 'IMEI',
            account: '账号',
            date: '日期',
            remainTime: '使用时长(Min)',
            flow: '使用流量(KB)',
            country: '使用国家',
        },
        hint: {
            success: {
                reload: '刷新成功',
            },
            warn: {
                reportWarn: '没有数据可以导出',
                loginWarn: '请先登录',
                getDateWarn: '查询数据失败，请稍后重试',

            },
            error: {
                systemErr: '系统异常，请联系客服',
                retryErr: '请退出重试，若重试多次无效，请联系客服',
            }
        }
    },


    //不活跃终端
    deviceInactive: {
        title: '不活跃终端',
        totalRecords: '共{count}条记录',
        normal: '正常',
        deactivate: '停用',
        uninitialized: '未初始化',
        recycle: '回收',
        oneMonth: '一个月',
        threeMonth: '三个月',
        sixMonth: '六个月',
        oneYear: '一年',
        yes: '是',
        no: '否',
        operation: '操作',
        button: {
            check: '启用',
            close: '停用',
            search: '搜索',
            inquire: '查询',
            detail: '详情',
        },
        searchList:{
            group: '业务分组：',
            status: '状态：',
            days: '关机时长：',
            tsid: '终端编号：',
            imei: 'IMEI：',
            selectMsg: '请选择',
            tsidMsg: '请输入编号，以英文逗号隔开',
            imeiMsg: '请输入IMEI，以英文逗号隔开',
        },
        columns: {
            tsid: '终端编号',
            imei: 'IMEI',
            date: '最后使用日期',
            days: '关机时长(天)',
            status: '状态',
            auto: '自动续订',
            bissgroup: '业务分组',
            remark: '备注',
            operation: '操作'
        },
        hint: {
            success: {
                updateStatus: '修改状态成功',

            },
            warn: {
                selectedRowWarn1: '请选中记录',
                selectedRowWarn2: '每次操作不能超过100台',
                loginWarn: '请先登录',
                retryWarn: '请退出重试，若重试多次无效，请联系客服',
                updateWarn: '修改失败',
                updateStatusWarn: '修改状态失败'
            },
            error: {
                systemErr: '系统异常，请联系客服',
                getDataErr: '查询数据失败',
            }
        }

    },


    //终端日消费
    deviceDayCosts: {
        title: '终端日消费',
        date: '日期：',
        selectDateMsg: '请选择日期',
        columns: {
            tsid: '终端编号',
            date: '日期',
            used: '使用时长(Min)',
            flow: '使用流量(KB)',
            country: '使用国家',
        },
        hint: {
            success: {
            },
            warn: {
                getDataWarn: '查询数据失败',
                loginWarn: '请先登录',
                retryWarn: '请退出重试，若重试多次无效，请联系客服',
            },
            error: {
                systemErr: '系统异常，请联系客服',
            }
        }
    },


    //终端使用详情
    deviceUseDetail: {
        title: '终端使用详情',
        changeOperator: '切换运行商：',
        selectMsg: '请选择',
        date: '日期：',
        selectDateMsg: '请选择日期',
        reload: '刷新',
        initialHearbeat: '初始心跳',
        normalHearbeat: '正常心跳',
        reissueHearbeat: '补发心跳',
        totalRecords: '共{count}条记录',
        content: "系统将在下个心跳之后开始切接入运营商。新的接入运营商为{val}。",
        okText: "确定",
        cancelText: "取消",
        columns: {
            tsid: '终端编号',
            country: '国家',
            type: '类型',
            date: '时间',
            flow: '流量增量',
            newCount: '心跳次数',
            operator: '接入运行商',
            signalType: '接入网络类型',
            signal: '信号强度',
            status: '状态',
        },
        hint: {
            success: {
                changeOperator: '切换运营商成功',
                reload: '刷新成功'
            },
            warn: {
                getDataWarn: '查询数据失败',
                getDataWarn2: '未查询到数据',
                loginWarn: '请先登录',
                retryWarn: '请退出重试，若重试多次无效，请联系客服',
                changeOperatorWarn: '切换运营商与要修改的运营商一致',
                changeOperatorWarn2: '切换运营商获取失败',
                cancelWarn: '已取消',
                dateWarn: '时间错误',
            },
            error: {
                systemErr: '系统异常，请联系客服',
                changeOperatorErr: '切换运营商失败',
                getDateErr: '查询数据失败',
                reloadErr: '刷新失败'
            }
        }
    },

    /**============================================== */


















    /**============================================== */
    //计费

    //账单
    bill: {
        title: '账单',
        totalRecords: '共{count}条记录',
        button: {
            queryDeviceDetail: '查看设备详单',
            queryDetail: '查看使用明细',
            report: '导出',
        },
        columns: {
            flowPoolName: '流量池名称',
            month: '月份',
            tsNum: '激活终端数',
            flowPoolTotalFlow: '总流量(MB)',
            flowPoolUseFlow: '使用流量(MB)',
            flowPoolUnitPrice: '单价(元)',
            flowPoolPrice: '套餐合计(元)',
            excessFlow: '超额流量(MB)',
            excessPrice: '超额单价(元/MB)',
            excessCost: '超额费用合计(元)',
            totalCost: '总费用(元)',
        },
        hint: {
            success: {
            },
            warn: {
                reportWarn: '没有数据可以导出',
                getDataWarn: '查询数据失败',
                loginWarn: '请先登录',
            },
            error: {
                systemErr: '系统异常，请联系客服',
                getDateErr: '查询数据失败',
            }
        }

    },

    //查看设备详单（跳转 设备详情）
    toDeviceDetail: {
        title: '设备详情',
        report: '导出',
        totalRecords: '共{count}条记录',
        columns: {
            tsid: '设备号',
            imei: 'IMEI',
            month: '月份',
            poolname: '套餐地区',
            showname: '套餐类型',
            country: '使用国家',
            duration: '使用时长(分钟)',
            flow: '使用流量(MB)',
            unitPrice: '套餐费用',
            price: '超出流量费用',
        },
        hint: {
            success: {
            },
            warn: {
                getDataWarn: '查询数据失败',
                loginWarn: '请先登录',
                reportWarn: '没有数据可以导出',
                retryWarn: '请退出重试，若重试多次无效，请联系客服',
            },
            error: {
                systemErr: '系统异常，请联系客服',
                getDateErr: '查询数据失败',
            }
        }

    },


    //查看使用明细
    flowPoolUseDetail: {
        title: '流量池使用详情',
        report: '导出',
        totalRecords: '共{count}条记录',
        columns: {
            tsid: '终端编号',
            imei: 'IMEI',
            data: '时间',
            used: '使用时长(Min)',
            flow: '使用流量(KB)',
            country: '国家',
        },
        hint: {
            success: {
            },
            warn: {
                getDataWarn: '查询数据失败',
                loginWarn: '请先登录',
                reportWarn: '没有数据可以导出',
                retryWarn: '请退出重试，若重试多次无效，请联系客服',
            },
            error: {
                systemErr: '系统异常，请联系客服',
                getDateErr: '查询数据失败',
            }
        }

    },

    //查看使用明细
    flowPoolDetail: {
        title: '流量池使用详情',
        report: '导出',
        columns: {
            flowPoolName: '流量池名称',
            tsid: '终端编号',
            date: '日期',
            tsNum: '终端数',
            flowPoolTotalFlow: '总流量(MB)',
            flowPoolUseFlow: '使用流量(MB)',
            flowPoolUnitPrice: '单价(元)',
            flowPoolPrice: '套餐合计(元)',
            excessFlow: '超额流量(MB)',
            excessPrice: '超额单价(元/MB)',
            excessCost: '超额费用合计(元)',
            totalCost: '总费用(元)',
        },
        hint: {
            success: {
            },
            warn: {
                getDataWarn: '查询数据失败',
                loginWarn: '请先登录',
                reportWarn: '没有数据可以导出',
                retryWarn: '请退出重试，若重试多次无效，请联系客服',
            },
            error: {
                systemErr: '系统异常，请联系客服',
                getDateErr: '查询数据失败',
            }
        }

    },


    //账户流水
    billDetail: {
        title: '账户流水',
        search: '搜索',
        report: '导出',
        totalRecords: '共{count}条记录',
        searchList: {
            bankOrder: '订单号：',
            type: '交易类型：',
            orderTime: '下单时间',
            placeholder: ['开始时间', '结束时间'],
            bankOrderMsg: '请输入订单号',
            selectMsg: '请选择',
            inquire: '查询',

        },
        columns: {
            bankOrder: '订单号',
            payMethod: '交易类型',
            income: '收支(元)',
            balance: '余额(元)',
            operateTime: '操作时间',
            orderPersion: '交易账号',
            remark: '备注',
        },
        hint: {
            success: {
                
            },
            warn: {
                getDataWarn: '查询数据失败',
                loginWarn: '请先登录',
                reportWarn: '没有数据可以导出',
                retryWarn: '请退出重试，若重试多次无效，请联系客服',
            },
            error: {
                systemErr: '系统异常，请联系客服',
                getDateErr: '查询数据失败',
                getTypeErr: '获取交易类型失败',
            }
        }
    },


    //余额
    balanceDetail: {
        title: '余额',
        consume: '当月消费',
        balance: '余额',
        recharge: '充值',
        rechargeOnline: '在线充值',
        rechargeAmount: '充值金额',
        rechargeAmountMsg: '请输入金额',
        note: '注：如有小数位，仅支持保留两位小数。',
        alipay: '支付宝支付',
        wecaht: '微信支付',
        cmb: '银行卡支付',
        cancel: '取消',
        confirm: '立即支付',
        hint: {
            success: {
                
            },
            warn: {
                getDataWarn: '查询数据失败',
                loginWarn: '请先登录',
                reportWarn: '没有数据可以导出',
                retryWarn: '请退出重试，若重试多次无效，请联系客服',
                moneyWarn1: '请输入充值金额，必须大于0',
                moneyWarn2: '充值金额格式不正确',
                moneyWarn3: '充值金额必须大于0',
                payWarn: '支付失败',
                operationWarn: '没有操作权限',
            },
            error: {
                systemErr: '系统异常，请联系客服',
                getDateErr: '查询数据失败',
                getTypeErr: '获取交易类型失败',
            }
        }
    },

    /**============================================== */

















    /**============================================== */


    //账号
    //账号一览
    accountDescribe: {
        title: '账号一览',
        mainAccount: '主账号',
        customerAccount: '客服账号',
        distributeAccount: '分销账号',
        box0: {
            title1: '1、主账号可以创建、停用客服账号和分销账号；',
            title2: '2、主账号可以将自有终端授权给分销账号使用；',
            title3: '3、主账号可以给分销账号配置不同的价格，差价会实时返还给主账号；',
            title4: '4、主账号可以隐藏分销账号套餐价格；'
        },
        box1: {
            title1: '1、客服账号主要用于协助主账号工作；',
            title2: '2、客服账号与主账号相比，权限范围一致，能够查看和导出；',
            title3: '3、客服账号没有操作权限，不允许对终端数据进行修改操作；'
        },
        box2: {
            title1: '1、分销账号主要用于发展下级客户和分销商；',
            title2: '2、分销账号的功能权限和主账号一致，但权限范围只限于主账号给予授权的终端；',
            title3: '3、分销账号有自付和代付两种，自付账号需要自行充值，终端使用产生的费用从分销账号扣除；代付账号不能充值，终端使用产生的费用从主账号扣除；'
        },
        hint: {
            success: {
                
            },
            warn: {
                getDataWarn: '获取数据失败',
                loginWarn: '请先登录',
                retryWarn: '请稍后重试，如多次重试无效，请联系客服。'
            },
            error: {
                systemErr: '系统异常，请联系客服',
            }
        }
    },


    //分销账号管理
    subAccount: {
        title: '分销账号管理',
        operateAccount: '创建子账号',
        behalfpay: '代付',
        selfPay: '自付',
        deactivate: '停用',
        enable: '启用',
        hide: '隐藏',
        show: '显示',
        configurePackage: '配置套餐价格',
        updateName: '修改姓名',
        updatePassword: '修改密码',
        recharge: '充值',
        totalRecords: '共{count}条记录',
        columns: {
            phone: '账号',
            mainA: '主账号',
            relaname: '姓名',
            balance: '余额(元)',
            type: '类型',
            show: '是否显示套餐价格',
            status: '状态',
            date: '创建时间',

        },
        hint: {
            success: {
                update: '修改成功'
            },
            warn: {
                getDataWarn: '获取数据失败',
                loginWarn: '请先登录',
                retryWarn: '请稍后重试，如多次重试无效，请联系客服。',
                getAccountWarn: '未查询到账号',
                permissionWarn: '无权限',
            },
            error: {
                systemErr: '系统异常，请联系客服',
                getDataErr: '获取数据失败',
            }
        }
    },



    //创建分销账号弹窗
    createAccount: {
        title: '创建分销账号',
        phone: '手机号码：',
        verifyCode: '验证码：',
        username: '账号姓名：',
        password: '密码：',
        confirmPassword: '确认密码：',
        bpmUserType: '结算方式',
        price: '流量包价格：',
        behalfpay: '代付',
        selfPay: '自付',
        deactivate: '停用',
        enable: '启用',
        hide: '隐藏',
        show: '显示',
        cancel: '取消',
        add: '新增',
        phoneMsg: '请输入手机号',
        verifyCodeMsg: '请输入验证码',
        usernameMsg: '请输入账号姓名',
        passwordMsg: '请输入密码',
        confirmPasswordMsg: '请输入确认密码',
        codeMsg1: '发送验证码',
        codeMsg2: '60s后重新获取',
        codeMsg3: 's后重新获取',
        hint: {
            success: {
                create: '创建成功',
                update: '修改成功',
                sendMsg: '短信发送成功，请注意接收',
            },
            warn: {
                getDataWarn: '获取数据失败',
                loginWarn: '请先登录',
                retryWarn: '请稍后重试，如多次重试无效，请联系客服。',
                getAccountWarn: '未查询到账号',
                permissionWarn: '无权限',
                phoneWarn1: '手机号不能为空',
                phoneWarn2: '手机号格式不正确',
                codeWarn1: '验证码不能为空',
                codeWarn2: '验证码错误',
                codeWarn3: '验证码已过期',
                usernameWarn: '账号姓名不能为空',
                passwordWarn1: '密码不能为空',
                passwordWarn2: '密码长度不能小于6位',
                confirmPasswordWarn1: '确认密码不能为空',
                confirmPasswordWarn2: '密码与确认密码不一致',
                accountWarn1: '您不是主账号，没有操作权限',
                accountWarn2: '账号已存在',
            },
            error: {
                systemErr: '系统异常，请联系客服',
                getDataErr: '获取数据失败',
                sendMsgErr: '发送短信失败'
            }
        }
    },


    //配置套餐价格（流量包管理）弹窗
    flowPacket: {
        title: '流量包管理',
        masterPacket: '主账号流量包',
        updatePrice: '修改价格',
        delete: '删除',
        day: '天',
        month: '月',
        totalRecords: '共{count}条记录',
        columns: {
            country: '国家',
            name: '名称',
            count: '套餐流量',
            maxflow: '最大流量(MB)',
            priceType: '套餐时长',
            price_0_0: '本级价格(元)',
            price_1_0: '下级价格(元)',
            operation: '操作',   
        },
        hint: {
            success: {
                delete: '删除成功'
            },
            warn: {
                loginWarn: '请先登录',
                getAccountWarn: '未查询到主账号流量包',
                deleteWarn: '删除失败',
            },
            error: {
                systemErr: '系统错误，请联系管理员',
                getDataErr: '查询数据失败',
                deleteErr: '删除失败',
            }
        }
    },


    //主账号流量包管理 弹窗
    masterFlowPacket: {
        title: '主账号流量包管理',
        addSubaccount: '添加到账子账号',
        deactivate: '停用',
        enable: '启用',
        notAdded: '未添加',
        totalRecords: '共{count}条记录',
        columns: {
            country: '国家',
            showname: '套餐名称',
            price: '价格(元)',
            status: '状态',
            explain: '说明',
            operation: '操作'
        },
        hint: {
            success: {
                add: '添加成功'
            },
            warn: {
                getDataWarn: '获取数据失败',
                loginWarn: '请先登录',
                retryWarn: '请稍后重试，如多次重试无效，请联系客服。',
                getAccountWarn: '未查询到主账号流量包',
                permissionWarn: '无权限',
            },
            error: {
                systemErr: '系统错误，请联系管理员。',
                getDataErr: '查询数据失败',
                addErr: '添加失败'
            }
        }
    },


    //修改价格 弹窗
    updatePrice: {
        title: '修改价格',
        country: '国家名称：',
        price_0_0: '本级价格：',
        packageType: '流量包类型：',
        subPrice: '子账号价格：',
        cancel: '取消',
        confirm: '确定',
        dayPacket: '天/包',
        monthPacket: '月/包',
        hint: {
            success: {
                operation: '操作成功'
            },
            warn: {
                subPriceWarn1: '子账号价格不正确',
                subPriceWarn2: '没有修改子账号价格',
                subPriceWarn3: '子账号价格必须大于本级价格',
            },
            error: {
                systemErr: '系统异常，请联系客服。',
                getDataErr: '查询数据失败',
                operationErr: '操作失败'
            }
        }
    },

    //修改姓名 弹窗
    updatename: {
        title: '修改姓名',
        name: '姓名：',
        nameMsg: '请输入姓名',
        cancel: '取消',
        confirm: '确定',
        hint: {
            success: {
                update: '修改成功'
            },
            warn: {
                nameWarn: '请输入新姓名',
                loginWarn: '请先登录',
                operationWarn: '操作失败',
                permissionWarn: '没有操作权限',

            },
            error: {
                systemErr: '系统异常，请联系客服。',
                updateErr: '修改失败，请稍后重试'
            }
        }
    },


    //修改密码(分销账号管理中的) 弹窗
    updatePasswordAccount: {
        title: '修改密码',
        password: '新密码：',
        confirmPassword: '确认密码：',
        cancel: '取消',
        confirm: '确定',
        passwordMsg: '请输入新密码',
        confirmPasswordMsg: '请输入确认密码',
        hint: {
            success: {
                update: '修改成功'
            },
            warn: {
                loginWarn: '请先登录',
                operationWarn: '操作失败',
                permissionWarn: '没有操作权限',
                passwordWarn1: '新密码不能为空',
                passwordWarn2: '新密码长度不能小于6位',
                confirmPasswordWarn1: '确认密码不能为空',
                confirmPasswordWarn2: '新密码与确认密码不一致',

            },
            error: {
                systemErr: '系统异常，请联系客服。',
                updateErr: '修改失败，请稍后重试'
            }
        }
    },

    //修改手机号 弹窗
    updatePhone: {
        title: '修改手机号',
        phone: '手机号：',
        phoneMsg: '请输入手机号',
        verifyCode: '验证码：',
        verifyCodeMsg: '验证码',
        cancel: '取消',
        confirm: '确定',
        codeMsg1: '发送验证码',
        codeMsg2: '60s重新获取',
        codeMsg3: 's重新获取',
        hint: {
            success: {
                update: '修改成功',
                sendMsg: '短信发送成功，请注意接收',
            },
            warn: {
                loginWarn: '请先登录',
                operationWarn: '操作失败',
                permissionWarn: '无权限',
                phoneWarn1: '手机号不能为空',
                phoneWarn2: '手机号格式不正确',
                verifyCodeWarn1: '验证码不能为空',
                verifyCodeWarn2: '验证码错误',
                verifyCodeWarn3: '验证码过期，请重新发送',

            },
            error: {
                systemErr: '系统异常，请联系客服。',
                updateErr: '修改失败',
                verifyCodeErr: '验证码错误',
                sendMsgErr: '发送短信失败'
            }
        }
    },


    //充值 弹窗
    accountRecharge: {
        title: '充值',
        balance: '本账号余额：',
        subBalance: '子账号余额：',
        recharge: '充值金额：',
        rechargeMsg: '请输入充值金额',
        cancel: '取消',
        confirm: '确定',
        hint: {
            success: {
                recharge: '充值成功'
            },
            warn: {
                loginWarn: '请先登录',
                rechargeWarn1: '充值失败',
                rechargeWarn2: '请输入充值金额，必须大于0',
                rechargeWarn3: '格式不正确或小数位超过两位',
                rechargeWarn4: '请输入正确的充值金额',
                rechargeWarn5: '充值金额必须大于0',
            },
            error: {
                systemErr: '系统异常，请联系客服。',
                updateErr: '修改失败，请稍后重试',
                getDateErr: '查询数据失败',
            }
        }
    },


    //客服账号管理
    serviceAccount: {
        title: '客服账号管理',
        createAccount: '创建客服账号',
        operation: '操作',
        updatePhone: '修改手机号',
        updateName: '修改姓名',
        updatePassword: '修改密码',
        deactivate: '停用',
        enable: '启用',
        totalRecords: '共{count}条记录',
        phone: '账号',
        mainA: '主账号',
        relaname: '姓名',
        type: '类型',
        date: '创建时间',
        hint: {
            success: {
                update: '修改成功'
            },
            warn: {
                loginWarn: '请先登录',
                getDateWarn: '未查询到账号',
                permissionWarn: '无权限',
            },
            error: {
                systemErr: '系统异常，请联系客服。',
                getDateErr: '查询数据失败',
            }
        }
    },


    //创建客服账号 弹窗
    createServiceAccount: {
        title: '创建客服账号',
        phone: '手机号码：',
        verifyCode: '验证码：',
        username: '账号姓名：',
        password: '密码：',
        confirmPassword: '确认密码：',
        bpmUserType: '结算方式',
        price: '流量包价格：',
        behalfpay: '代付',
        selfPay: '自付',
        deactivate: '停用',
        enable: '启用',
        hide: '隐藏',
        show: '显示',
        cancel: '取消',
        add: '新增',
        phoneMsg: '请输入手机号',
        verifyCodeMsg: '请输入验证码',
        usernameMsg: '请输入账号姓名',
        passwordMsg: '请输入密码',
        confirmPasswordMsg: '请输入确认密码',
        codeMsg1: '发送验证码',
        codeMsg2: '60s后重新获取',
        codeMsg3: 's后重新获取',
        hint: {
            success: {
                create: '创建成功',
                update: '修改成功',
                sendMsg: '短信发送成功，请注意接收',
            },
            warn: {
                getDataWarn: '获取数据失败',
                loginWarn: '请先登录',
                retryWarn: '请稍后重试，如多次重试无效，请联系客服。',
                getAccountWarn: '未查询到账号',
                permissionWarn: '无权限',
                phoneWarn1: '手机号不能为空',
                phoneWarn2: '手机号格式不正确',
                codeWarn1: '验证码不能为空',
                codeWarn2: '验证码错误',
                codeWarn3: '验证码已过期',
                usernameWarn: '账号姓名不能为空',
                passwordWarn1: '密码不能为空',
                passwordWarn2: '密码长度不能小于6位',
                confirmPasswordWarn1: '确认密码不能为空',
                confirmPasswordWarn2: '密码与确认密码不一致',
                accountWarn1: '您不是主账号，没有操作权限',
                accountWarn2: '账号已存在',
            },
            error: {
                systemErr: '系统异常，请联系客服',
                getDataErr: '获取数据失败',
                sendMsgErr: '发送短信失败'
            }
        }

    },

    /**============================================== */























//报表
//套餐列表
packetList: {
    title: '套餐列表',
    columns: {
        areaName: '区域',
        supportNames: '支持国家',
        overPrice: '超出单价(元/MB)',
        price: '价格(元/月)',
    },
        hint: {
            warn: {
                loginWarn: '请先登录',

            },
            error: {
                getDataErr: '查询数据失败',
                systemErr: '系统异常，请联系客服',
            }
        }
},

//终端地域分布
deviceDistribution: {
    title: '终端地域分布',
    report: '导出',
    totalRecords: '共{count}条记录',
    columns: {
        zone: 'Zone',
        names: '国家',
        dayes: '当日活跃数(台)',
        month: '当月活跃数(台)'
    },
    hint: {
        warn: {
            reportWarn: '没有数据可以导出',
            loginWarn: '请先登录',

        },
        error: {
            systemErr: '系统异常，请联系客服',
            getDataErr: '查询数据失败',
        }
    }
},


/**============================================== */





//通知信息
alarmInfo: {
    title: '通知信息',
    totalRecords: '共{count}条记录',
    time: '时间',
    content: '通知内容',
    hint: {
        warn: {
            loginWarn: '请先登录',
            getDataErr: '查询失败',
        },
        error: {
            systemErr: '系统异常，请联系客服',
            getDataErr: '查询数据失败',
        }
    }
},



//device中的详情
inactiveDetail:{
        title: '详情',
        packetOrder: '套餐订单',
        status: '状态',
        orderNum: '订单编号：',
        countryCode: '预约国家：',
        packetFlow: '套餐流量：',
        orderTime: '下单时间：',
        packetTime: '套餐时长：',
        amount: '金额：',
        effectiveTime: '生效时间：',
        usedFlowMaxFlow: '已用流量/最大流量：',
        autoRenewal: '自动续订',
        packageName: '套餐名称：',
        packagePrice: '套餐价格：',
        packageCountry: '套餐国家：',
        operatorAccount: '操作账号：',
        orderStatus: '订单状态：',
        operationTime: '操作时间：',
        startTime: '开始时间：',
        endTime: '截止时间：',
        renewAfterUsedUp: '流量用完是否续订：',
        yes: '是',
        no: '否',
        year: '年',
        month: '月',
        day: '日',
        normal: '正常',
        deactivate: '停用',
        noData: '没有数据',
        hint: {
            warn: {
                loginWarn: '请先登录',
                getDataErr: '查询失败',
            },
            error: {
                systemErr: '系统异常，请联系客服',
                getDataErr: '查询数据失败',
            }
        }
},



//device中的退款
refundModal: {
    title: '退款',
    orderNum: '订单编号：',
    tsid: 'TSID：',
    beginTime: '起始使用时间：',
    endTime: '结束使用时间：',
    reason: '申请退款原因：',
    reasonMsg: '请输入退款原因',
    cancel: '取消',
    confirm: '确定',
    hint: {
        success: {
            refund: '退款申请中',
        },
        warn: {
            loginWarn: '请先登录',
            refundWarn1: '请输入退款原因',
            refundWarn2: '退订失败',
            refundWarn3: '没有操作权限',
            refundWarn4: '该订单无法退款',
        },
        error: {
            systemErr: '系统异常，请联系客服',
            refundErr: '操作失败，请稍后重试',
        }
    }
},



/** 终端归属提示框 */
tipModal: {
    title: '提示',
    account: '归属账号',
    imei: 'IMEI',
    no: '否',
    yes: '是'
},


//每日终端活跃数
dayActiveDevice: {
    title: '每日终端活跃数',
    columns: {
        zone: 'Zone',
        namecn: '国家',
        date: '日期',
        count: '活跃终端数(台)'
    },
    hint: {
        warn: {
            loginWarn: '请先登录',
            getDataErr: '查询失败',
        },
        error: {
            systemErr: '系统异常，请联系客服',
            getDataErr: '查询数据失败',
        }
    }
},


//每月终端活跃数
monthActiveDevice: {
    title: '每月终端活跃数',
    columns: {
        zone: 'Zone',
        namecn: '国家',
        date: '月份',
        count: '活跃终端数(台)'
    },
    hint: {
        warn: {
            loginWarn: '请先登录',
            getDataErr: '查询失败',
        },
        error: {
            systemErr: '系统异常，请联系客服',
            getDataErr: '查询数据失败',
        }
    }
},



//导出提示
exportTips: {
    title: '导出提示',
    cancel: '取消',
    confirm: '确认',
    tip: '请选择导出记录数区间：',
},



//navcon中的忘记密码弹窗
updatePassword: {
    phone: '手机号',
    title: '修改密码',
    password: '新密码',
    confirmPassword: '确认新密码',
    cancel: '取消',
    confirm: '确定',
    passwordMsg: '确认新密码',
    verify: '验证码',
    refreshVerifyCode: '看不清楚，换一张',
    verifyCode: '短信验证码',
    sendVerify: '发送验证码',
    codeMsg2: '60s后重新获取',
    codeMsg3: 's后重新获取',
    hint: {
        success: {
            update: '修改成功',
            send: '短信发送成功，请注意接收'
        },
        warn: {
            loginWarn: '请先登录',
            operationWarn: '操作失败',
            permissionWarn: '没有操作权限',
            phoneWarn1: '手机号不能为空',
            phoneWarn2: '手机号格式不正确',
            passwordWarn1: '新密码不能为空',
            passwordWarn2: '新密码长度不能小于6位',
            confirmPasswordWarn1: '确认密码不能为空',
            confirmPasswordWarn2: '新密码与确认密码不一致',
            verifyWarn1: '验证码不能为空',
            verifyWarn2: '验证码错误',
            verifyCodeWarn: '短信验证码不能为空',
            updateWarn1: '修改失败',
            updateWarn2: '归属系统错误',
            updateWarn3: '账号错误',
            updateWarn4: '验证码失效',
            updateWarn5: '短信验证码过期',
            updateWarn6: '短信验证码错误或验证码已失效或验证码与电话号码不匹配',
            accountWarn1: '无效账号',


        },
        error: {
            systemErr: '系统异常，请联系客服。',
            updateErr: '修改失败，请稍后重试',
            sendErr: '发送短信失败',
            accountErr1: '异常，请联系客服人员',

        }
    }
},





}

export default zh;