<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="550px"
    :footer="null"
    :maskClosable="false"
    :dialogStyle="{ top: '10%' }"
    @cancel="handleCancel"
  >
    <form>
      <div>
        <div class="create_account_item">
          <span class="create_account_item_label">{{$t('createAccount.phone')}}</span>
          <a-input
            class="create_account_item_input"
            v-model.trim="account.phone"
            :maxLength="25"
            :placeholder="$t('createAccount.phoneMsg')"
          />
          <span
            :class="[
              'verify_code',
              code.hasSend ? 'verify_code_count_down' : 'send_verify_code',
            ]"
            @click="sendCode"
            >{{ code.codeMsg }}</span
          >

        </div>
        <div class="create_account_item">
          <span class="create_account_item_label">{{$t('createAccount.verifyCode')}}</span>
          <a-input
            class="create_account_item_input"
            v-model.trim="account.verifyCode"
            :maxLength="6"
            :placeholder="$t('createAccount.verifyCodeMsg')"
          />
        </div>
        <div class="create_account_item">
          <span class="create_account_item_label">{{$t('createAccount.username')}}</span>
          <a-input
            class="create_account_item_input"
            :maxLength="15"
            v-model.trim="account.username"
            :placeholder="$t('createAccount.usernameMsg')"
          />
        </div>
          <div class="create_account_item">
          <span class="create_account_item_label">{{$t('createAccount.password')}}</span>
          <a-input-password
            class="create_account_item_input"
            :maxLength="25"
            v-model.trim="account.password"
            autocomplete="off"
            :placeholder="$t('createAccount.passwordMsg')"
          />
        </div>
          <div class="create_account_item">
          <span class="create_account_item_label">{{$t('createAccount.confirmPassword')}}</span>
          <a-input-password
            class="create_account_item_input"
            :maxLength="25"
            v-model.trim="account.confirmPassword"
            autocomplete="off"
            :placeholder="$t('createAccount.confirmPasswordMsg')"
          />
        </div>
        <div class="create_account_item">
          <span class="create_account_item_label">{{$t('createAccount.bpmUserType')}}</span>
          <a-select class="create_account_item_input" v-model="account.bpmUserType">
            <a-select-option value="0"> {{$t('createAccount.selfPay')}} </a-select-option>
            <a-select-option value="1"> {{$t('createAccount.behalfpay')}} </a-select-option>
          </a-select>
        </div>
        <div class="create_account_item">
          <span class="create_account_item_label">{{$t('createAccount.price')}}</span>
          <a-select class="create_account_item_input" v-model="account.ifShowPrice">
            <a-select-option value="0"> {{$t('createAccount.show')}} </a-select-option>
            <a-select-option value="1"> {{$t('createAccount.hide')}} </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="create_account_div create_account_btn">
        <span class="create_account_cancel" @click.prevent="cancel">{{$t('createAccount.cancel')}}</span>
        <span class="create_account_confirm" @click.prevent="confirm">{{$t('createAccount.add')}}</span>
      </div>
    </form>
  </a-modal>
</template>
<script>
import {isNull,successMsg, errMsg, warnMsg} from "../../../utils/CommonUtils.js"
export default {
  props: ["visible"],
  data() {
    return {
      title: this.$t('createAccount.title'),
      showModal: false,
      code: {
        timeInterval: "", //倒计时对象
        hasSend: false,
        codeMsg: this.$t('createAccount.codeMsg1'),
      },
      account: {
        verifyCode: "",
        phone: "",
        username: "",
        password: "",
        confirmPassword: "",
        ifShowPrice: "0",
        bpmUserType: "0",
      },
    };
  },
  watch: {
    visible(val) {
      this.showModal = val;
      if (!this.showModal) {
        this.reset();
        this.changeSendVerifyCodeContent();
      }
    },
  },
  methods: {
    /** 关闭弹框后，清除倒计时 */
    changeSendVerifyCodeContent() {
      this.code.codeMsg = this.$t('createAccount.codeMsg1'),
      this.code.hasSend = false;
      clearInterval(this.code.timeInterval);
    },

    cancel() {
      this.$emit("create-account-visible", false, 0);
    },

    confirm() {
      var phoneReg = /^[0-9]{3,20}$/g
      var codeReg = /^[0-9]{6}$/g
      if (isNull(this.account.phone)) {
        warnMsg(this.$t('createAccount.hint.warn.phoneWarn1'),);
        return;
      }
      if(!phoneReg.test(this.account.phone)){
        warnMsg(this.$t('createAccount.hint.warn.phoneWarn2'),);
        return;
      }
      if (isNull(this.account.verifyCode)) {
        warnMsg(this.$t('createAccount.hint.warn.codeWarn1'),);
        return;
      }
      if(!codeReg.test(this.account.verifyCode)){
        warnMsg(this.$t('createAccount.hint.warn.codeWarn2'),);
        return;
      }
      if (isNull(this.account.username)) {
        warnMsg(this.$t('createAccount.hint.warn.usernameWarn'),);
        return;
      }
      if(isNull(this.account.password)){
         warnMsg(this.$t('createAccount.hint.warn.passwordWarn1'),);
        return;
      }
      if(isNull(this.account.confirmPassword)){
         warnMsg(this.$t('createAccount.hint.warn.confirmPasswordWarn1'),);
        return;
      }
      if(this.account.confirmPassword != this.account.password){
         warnMsg(this.$t('createAccount.hint.warn.confirmPasswordWarn2'),);
        return;
      }
      if(this.account.password.length < 6){
        warnMsg(this.$t('createAccount.hint.warn.passwordWarn2'),)
        return;
      }
      let param = {
        phoneNumber: this.account.phone,
        realName: this.account.username,
        code: this.account.verifyCode,
        password: this.account.password,
        confirmPassword: this.account.confirmPassword,
        bpmUserType: this.account.bpmUserType,
        ifShowPrice: this.account.ifShowPrice,
      }
      this.axios.post(this.baseUrl + "addAccount",param,
        {headers: { token: sessionStorage.getItem("token") }}).then((res)=>{
        if(res.status == 200){
          if(res.data.code == 0){
             successMsg(this.$t('createAccount.hint.success.create'),)
             this.$emit("create-account-visible", false, 1);
          }else if(res.data.code == -1){
            warnMsg(this.$t('createAccount.hint.warn.loginWarn'),);
            setTimeout(() => {
              this.$router.push({ name: "login" });
            }, "2000");
          }else if(res.data.code == 2){
            warnMsg(this.$t('createAccount.hint.warn.codeWarn3'),)
            this.changeSendVerifyCodeContent()
          }else if(res.data.code == -4){
            warnMsg(this.$t('createAccount.hint.warn.accountWarn1'),);  
          }    
        }else{
          errMsg(this.$t('createAccount.hint.error.sendMsgErr'),)
        }
      }).catch(()=>{
        errMsg(this.$t('createAccount.hint.error.systemErr'),)
      })  
    },
     /** 右上角 'X' 关闭按钮调用 */
    handleCancel() {
      this.$emit("create-account-visible", false, 0);
    },
    reset(){
      this.account.phone = "";
      this.account.username = ""; 
      this.account.verifyCode = "";
      this.account.password = "";
      this.account.confirmPassword = "";
    },
    /** 发送短信 */
    sendCode() {
      var phoneReg = /^[0-9]{3,20}$/g
      if (isNull(this.account.phone)) {
         warnMsg(this.$t('createAccount.hint.warn.phoneWarn1'),);
        return;
      }
      if(!phoneReg.test(this.account.phone)){
        warnMsg(this.$t('createAccount.hint.warn.phoneWarn2'),);
        return;
      }
      this.axios.post(this.baseUrl + "getCode?phonenumber="+this.account.phone, "",
        {headers: { token: sessionStorage.getItem("token") }}).then((res)=>{
        if(res.status == 200){
          if(res.data.code == 0){
            successMsg(this.$t('createAccount.hint.success.sendMsg'),)
            this.countDown();
          }else if(res.data.code == -1){
            warnMsg(this.$t('createAccount.hint.warn.loginWarn'),);
            setTimeout(() => {
              this.$router.push({ name: "login" });
            }, "2000");
          }else if(res.data.code == -4){
            warnMsg(this.$t('createAccount.hint.warn.accountWarn2'),);
          }   
        }else{
          errMsg(this.$t('createAccount.hint.error.sendMsgErr'),)
        }
      }).catch(()=>{
        errMsg(this.$t('createAccount.hint.error.systemErr'),)
      })  
    },

    /** 发送短信后，倒计时 */
    countDown(){
      var i = 59;
      this.code.hasSend = true;
      this.code.codeMsg = this.$t('createAccount.codeMsg2');
      this.code.timeInterval = setInterval(() => {
        if (i > 0) {
          this.code.codeMsg = i-- + this.$t('createAccount.codeMsg3');
        } else {
          this.changeSendVerifyCodeContent();
        }
      }, 1000);
    }
  },
};
</script>
<style scoped>
.verify_code {
  display: inline-block;
  text-align: center;
  height: 30px;
  line-height: 30px;
  padding: 0px 5px;
  border-radius: 3px;
  margin-left: 5px;
}
.verify_code_count_down {
  pointer-events: none;
  cursor: default;
  border: 1px solid #d9d9d9;
  color: #d9d9d9;
}

.send_verify_code {
  border: 1px solid rgb(211, 211, 211);
  box-shadow: 0px 0px 2px 0px rgb(241, 241, 241);
}
.send_verify_code:hover {
  cursor: pointer;
  color: rgb(137, 208, 255);
  border: 1px solid rgb(137, 208, 255);
}
.send_verify_code:active {
  color: rgb(66, 178, 252);
}
.create_account_item {
  width: 100%;
  margin-bottom: 12px;
}

.create_account_item_label {
  display: inline-block;
  width: 85px;
  text-align: justify;
  text-align-last: justify;
}
.create_account_item_input {
  width: 50%;
}

.create_account_div {
  margin-bottom: 10px;
  font-size: 15px;
}
.create_account_btn {
  text-align: center;
  margin: 20px 0 0 0;
}
.create_account_cancel,
.create_account_confirm {
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  height: 33px;
  width: 60px;
  line-height: 33px;
}
.create_account_cancel {
  margin-right: 15px;
  border: 1px solid rgb(184, 184, 184);
}
.create_account_cancel:hover {
  cursor: pointer;
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}
.create_account_confirm {
  border: 1px solid rgb(78, 78, 255);
  background-color: rgb(78, 78, 255);
  color: white;
}
.create_account_confirm:hover {
  cursor: pointer;
  box-shadow: 0px 0px 2px 0px rgb(23, 23, 255);
}
</style>
