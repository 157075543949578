<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="40%"
    :footer="null"
    :maskClosable="false"
    :dialogStyle="{ top: '20%' }"
    @cancel="handleCancel"
  >
    <div>
      <div>
        <span>{{$t('exportTips.tip')}}</span>
        <div style="margin:5px 0px 0px 20px;">
          <span
            v-for="(item, i) in arr"
            :key="i"
            @click.prevent="selectItem(i)"
            :class="['item', selected == i ? 'select_item' : 'unselect_item']"
            >{{ item }}</span
          >
        </div>
      </div>
      <div style="text-align: center; margin-top: 20px">
        <a-button style="margin-right: 25px" @click.prevent="cancel"
          >{{$t('exportTips.cancel')}}
        </a-button>
        <a-button type="primary" @click.prevent="confirm"> {{$t('exportTips.confirm')}} </a-button>
      </div>
    </div>
  </a-modal>
</template>
<script>
/** 导出记录时，超过10000条记录时，会弹出此提示框 */
export default {
  props: ["total"],
  data() {
    return {
      title: this.$t('exportTips.title'),
      showModal: false,
      arr: [],
      selected: 0,
    };
  },
  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.showModal = true;
    //向下取整
    let count = Math.floor(this.total / 10000);
    for (let i = 0; i < count; i++) {
      this.arr[i] = i * 10000 + 1 + "-" + (i + 1) * 10000;
    }
    if (this.total != count * 10000) {
      this.arr[count] =
        count * 10000 + 1 + "-" + (this.total - (count * 10000));
    }
  },
  methods: {
    handleCancel() {
      this.showModal = false;
      this.$emit("export-tips-visible", false, 0, null);
    },
    cancel() {
      this.showModal = false;
      this.$emit("export-tips-visible", false, 0, null);
    },
    confirm() {
      this.showModal = false;
      this.$emit("export-tips-visible", false, 1, this.selected + 1);
    },
    selectItem(val){
        this.selected = val;
    }
  },
};
</script>
<style scoped>
.item {
  display: inline-block;
  width: 110px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  margin: 5px 10px 0px 0px;
  border-radius: 3px;
}
.item:hover{
  cursor: pointer;
}
.select_item {
  border: 1px solid rgb(111, 95, 255);
}
.unselect_item {
  border: 1px solid rgb(201, 201, 201);
}

::v-deep .ant-modal-body{
    padding: 10px !important;
}
</style>