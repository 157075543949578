<template>
  <div>
    <div class="title">{{ dynamicTitle }}</div>
    <div class="menus">
      <div :class="['item',$store.state.leftMenu == item.id ? 'select' : 'unselect',]"
        v-for="item in items"
        :key="item.id"
        @click.prevent="changeItem(item.id)">
          <span style="margin-left: 10px">{{ $t(`leftnav.${item.name}`) }}</span>
      </div>
    </div>
  </div>
</template>
<script>
/** 左侧菜单栏 */
import { getTitle } from "../utils/CommonUtils";
export default {
  name: "leftnav",
  props: ["activeMenu", "items"],
  data() {
    return {
      title: "",
    };
  },
  
  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.title = this.$t(getTitle(this.activeMenu));
  },

  computed: {
  dynamicTitle() {
    return this.$t(getTitle(this.activeMenu));
  }
},


  methods: {
    changeItem(itemId) {
      this.$store.state.leftMenu = itemId;
      if (this.activeMenu != 86) {
        this.$emit("menu", this.$store.state.leftMenu);
      }
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
.title {
  font-size: 18px;
  padding-top: 20px;
  margin-bottom: 5px;
  color: rgb(14, 14, 14);
  text-align: left;
  font-weight: bold;
}

.item {
  height: 20px;
  line-height: 20px;
  margin-bottom: 5px;
  width: 100%;
  text-align: left;
}
.item:hover {
  cursor: pointer;
}
.select {
  color: #1890ff;
  box-shadow: inset 2px 0 #1890ff;
}

.unselect:hover {
  color: #68b6ff;
}

.menus {
  display: flex;
  flex-direction: column; 
}

.menus .item {
  margin-top: 5px; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer; /* 鼠标指针样式 */
}

.menus .item:hover {
  overflow: visible;
  white-space: nowrap;
  z-index: 1000;
  position: relative;
  background-color: #f5f5f5; /* 鼠标悬停时的背景颜色 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 阴影效果 */
}




</style>