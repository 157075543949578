<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="66%"
    :footer="null"
    :maskClosable="false"
    :dialogStyle="{ top: '3%' }"
    @cancel="handleCancel"
  >
    <div style="padding: 20px 24px 10px">
      <div>
        <div>
          <div v-if="visibleGroup" class="auto_sub_div">
            <span>{{$t('autoSubscribe.selectGroup')}}</span
            ><a-select
              :placeholder="$t('autoSubscribe.selectGroupMsg')"
              allowClear
              v-model="groupName"
              class="select_list"
            >
              <a-select-option
                v-for="(group, i) in groups"
                :value="group"
                :key="i"
                >{{ group }}</a-select-option
              >
            </a-select>
          </div>
          <div class="auto_sub_div">
            <span>{{$t('autoSubscribe.search')}}</span>
            <a-select
              :placeholder="$t('autoSubscribe.selectCountryMsg')"
              allowClear
              v-model="selectCountry"
              class="select_list"
            >
              <a-select-option
                v-for="countrys in data"
                :value="countrys.ccode"
                :key="countrys.ccode"
              >
                {{ $i18n.locale=='zh' ? countrys.showName : countrys.showNameEn }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="auto_sub_div">
          <span class="label_span">{{$t('autoSubscribe.isOrderEndRenew')}}</span>
          <a-radio-group :default-value="1" :disabled="true">
            <span class="radio_label_span">{{$t('autoSubscribe.yes')}}</span><a-radio :value="1" />
            <span class="radio_label_span" style="margin-left: 5px">{{$t('autoSubscribe.no')}}</span
            ><a-radio :value="2" />
          </a-radio-group>
        </div>
        <div class="auto_sub_div">
          <span class="label_span">{{$t('autoSubscribe.isTrafficEndRenew')}}</span>
          <a-radio-group v-model="autoSub">
            <span class="radio_label_span">{{$t('autoSubscribe.yes')}}</span><a-radio :value="0" />
            <span class="radio_label_span" style="margin-left: 5px">{{$t('autoSubscribe.no')}}</span
            ><a-radio :value="1" />
          </a-radio-group>
        </div>
      </div>
      <div style="margin-bottom: 10px;  line-height: 36px">
        <span class="label_span">{{$t('autoSubscribe.renewDate')}}</span>
        <a-radio-group v-model="date.select" @change="changeDate">
          <span class="radio_label_span">{{$t('autoSubscribe.foreverValidity')}}</span><a-radio :value="0" />
          <span class="radio_label_span" style="margin-left: 5px">{{$t('autoSubscribe.searchTime')}}</span
          ><a-radio :value="1" />
        </a-radio-group>
        <div style="display: inline-block" v-if="date.show">
          <span style="margin-left: 50px">{{$t('autoSubscribe.activePeriod')}}</span>
          <a-range-picker
            :placeholder="[$t('autoSubscribe.placeholder')[0], $t('autoSubscribe.placeholder')[1]]"
            @change="changeRangeDate"
          />
        </div>
      </div>
      <div style="height: 260px; overflow: auto">
        <a-collapse accordion v-if="data">
          <a-collapse-panel
            v-for="item in showData"
            :key="item.ccode"
            :header="$i18n.locale=='zh' ? item.showName : item.showNameEn"
          >
            <div>
              <span
                v-for="packet in item.priceflow"
                :class="[
                  'item_packet','cursor_attr',
                  selectPacket.attachid == packet.attachid ? 'select' : 'unselect',
                ]"
                :key="packet.id"
                @click.prevent="choocePacket(packet)"
                >{{ packet.showName == ""?packet.packetName:packet.showName }}</span
              >
            </div>
            <div slot="extra">
              <a-popover :title="$t('autoSubscribe.supportCountry')" placement="bottom">
                <div
                  :class="[item.useCn.length > 30 ? 'support_country' : '']"
                  slot="content"
                >
                  {{ $i18n.locale=='zh' ? item.useCn : item.useEn }}
                </div>
                <a-icon type="question-circle" :title="$t('autoSubscribe.supportCountry')" />
              </a-popover>
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>
      <div class="auto_sub_btn">
        <span v-if="selectPacket.attachid"
          >{{$t('autoSubscribe.selectPacketMsg')}}&nbsp;
          <span style="color: red">{{ selectPacket.country }}</span>
          <span
            >&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{{ selectPacket.name }}</span
          >
          <span
            >&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{{
              selectPacket.days
            }}</span
          >
          <span v-if="selectPacket.price != -1">
               &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<span style="color: red">¥&nbsp;</span>
               <span>{{ selectPacket.price }}</span> 
            </span>
        </span>
        <span class="auto_sub_confirm cursor_attr" @click.prevent="confirm">{{$t('autoSubscribe.define')}}</span>
        <span class="auto_sub_cancel cursor_attr" @click.prevent="cancel">{{$t('autoSubscribe.cancel')}}</span>
      </div>
    </div>
  </a-modal>
</template>
<script>
import {
  errMsg,
  isNull,
  successMsg,
  warnMsg,
} from "../../../utils/CommonUtils";
export default {
  props: ["visible", "visibleGroup", "selectedRow"],
  data() {
    return {
      title: this.$t('autoSubscribe.title'),
      showModal: false,
      selectCountry: undefined,
      groupName: undefined,
      groups: [],
      selectPacket: {
        attachid: "",
        country: "",
        name: "",
        price: "",
        days: "",
        countrycode: "",
      },
      autoSub: 1, //流量用完是否续订 0、是   1、否
      date: {
        select: 0, //0、永久有效 1、自定义选择时间
        show: false,
        range: [],
      },
      data: [],
      showData: [],
    };
  },
  created(){
  const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.showModal = true;
    this.init();
  },
  watch: {
    /** 通过国家过滤套餐 */
    selectCountry(newVal) {
      if (isNull(newVal)) {
        this.showData = this.data;
      } else {
        this.showData = this.data.filter((item) => {
          return item.ccode == newVal;
        });
      }
    },
  },
  methods: {
    cancel() {
      this.showModal = false;
      this.$emit("auto-subscribe-visible", false, 0);
    },
    confirm() {
      if (isNull(this.groupName) && this.groupName !== 0 && isNull(this.getImei())) {
        warnMsg(this.$t('autoSubscribe.hint.warn.selectImeiWarn'));
        return;
      }
      if (isNull(this.selectPacket.attachid)) {
        warnMsg(this.$t('autoSubscribe.hint.warn.selectPacketWarn'));
        return;
      }
      this.axios.post(this.baseUrl + "autoRenewFlowPacages", this.param(), {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              successMsg(this.$t('autoSubscribe.hint.success.renew'));
              this.showModal = false;
              this.$emit("auto-subscribe-visible", false, 1);
            } else if (res.data.code == -1) {
              warnMsg(this.$t('autoSubscribe.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -2) {
              warnMsg(this.$t('autoSubscribe.hint.warn.retryWarn'));
            } else if (res.data.code == -3) {
              warnMsg(this.$t('autoSubscribe.hint.warn.renewWarn3'));
            } else if (res.data.code == -4) {
              warnMsg(this.$t('autoSubscribe.hint.warn.renewWarn4'));
            } else if (res.data.code == -5) {
              warnMsg(this.$t('autoSubscribe.hint.warn.renewWarn5'));
            } else if (res.data.code == -6) {
              warnMsg(this.$t('autoSubscribe.hint.warn.renewWarn6'));
            } else if (res.data.code == -7) {
              warnMsg(this.$t('autoSubscribe.hint.warn.renewWarn7'));
            } else if (res.data.code == -8) {
              warnMsg(this.$t('autoSubscribe.hint.warn.renewWarn8'));
            }else if (res.data.code == -9) {
              errMsg(this.$t('autoSubscribe.hint.error.systemErr'));
            }
          }
        }).catch(() => {
          errMsg(this.$t('autoSubscribe.hint.error.systemErr'));
        });
    },
    /**有效期单选框改变*/
    changeDate() {
      if (this.date.select == 1) {
        this.date.show = true;
      } else {
        this.date.show = false;
      }
    },
    /**选中流量包套餐*/
    choocePacket(packet) {
      this.selectPacket.attachid = packet.attachid;
      this.selectPacket.name = isNull(packet.showName)?packet.packetName:packet.showName;
      this.selectPacket.country = this.$i18n.locale == 'zh' ?  packet.countryName : packet.countryNameEn;
      this.selectPacket.price = packet.price;
      this.selectPacket.countrycode = packet.countrycode;
      if (packet.time_unit == 0) {
        this.selectPacket.days = packet.days + this.$t('autoSubscribe.day');
      } else if (packet.time_unit == 1) {
        this.selectPacket.days = packet.days + this.$t('autoSubscribe.month');
      }
    },
    /**有效时间段选择 (参数dates不可以去掉)*/
    changeRangeDate(dates, dateArr) {
      this.date.range = dateArr;
    },
    getImei() {
      let imeis = "";
      if (this.selectedRow.length > 1) {
        this.selectedRow.forEach((item) => {
          imeis = imeis + item.imei + ",";
        });
      } else if (this.selectedRow.length == 1) {
        imeis = this.selectedRow[0].imei;
      }
      return imeis;
    },
    handleCancel() {
      this.showModal = false;
      this.$emit("auto-subscribe-visible", false, 0);
    },
    init() {
      this.axios.post(this.baseUrl + "getPackageInfo", "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              if (res.data.data.length > 0) {
                var data = res.data.data;
                for (let i = 0; i < data.length; i++) {
                  let item = data[i];
                  let packets = item.priceflow;
                  for (let j = 0; j < packets.length; j++) {
                    let packet = packets[j];
                    if(isNull(packet.showName)){
                      if (packet.time_unit == 0) {  //天包
                        packet.packetName = packet.count + packet.count_unit +
                          "/" +(packet.days == 365 ? this.$t('autoSubscribe.year') : packet.days == 730 ? "2"+this.$t('autoSubscribe.year') : (packet.days == 30 || packet.days == 31)?this.$t('autoSubscribe.month'): packet.days + this.$t('autoSubscribe.day'));
                      } else if (packet.time_unit == 1) { //月包
                        packet.packetName = packet.count +packet.count_unit +
                          "/" + (packet.days > 1 ? packet.days + this.$t('autoSubscribe.month') : this.$t('autoSubscribe.month'));
                      }
                    }
                  }
                }
                this.data = data;
                this.showData = data;
              }
            } else if (res.data.code == -1) {
              warnMsg(this.$t('autoSubscribe.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -3) {
              errMsg(this.$t('autoSubscribe.hint.error.systemErr'));
            }
          } else {
            warnMsg(this.$t('autoSubscribe.hint.warn.getDataWarn'));
          }
        }).catch(() => {
          errMsg(this.$t('autoSubscribe.hint.error.systemErr'));
        });

      if(this.visibleGroup){
        this.axios.post(this.baseUrl + "getDeviceGroup", "", {
            headers: { token: sessionStorage.getItem("token") },
          }).then((res) => {
            if (res.status == 200) {
              if (res.data.code == 0) {
                this.groups = res.data.data;
              }else if (res.data.code == -1) {
                warnMsg(this.$t('autoSubscribe.hint.warn.loginWarn'));
                setTimeout(() => {
                  this.$router.push({ name: "login" });
                }, "2000");
              } else if (res.data.code == -2) {
                warnMsg(this.$t('autoSubscribe.hint.warn.retryWarn'));
              }
            }
          }).catch(() => {
            errMsg(this.$t('autoSubscribe.hint.error.systemErr'));
          });
      }
    },
    param() {
      var condition, subType;
      if (this.visibleGroup) {
        subType = 0;
        condition = this.groupName;
      } else {
        subType = 1;
        condition = this.getImei();
      }

      let param = {
        subType: subType,
        condition: condition,
        attach: this.selectPacket.attachid,
        countrycode: this.selectPacket.countrycode,
        useUp: this.autoSub,
        sdate: this.date.select == 1 ? this.date.range[0] : "",
        edate: this.date.select == 1 ? this.date.range[1] : "",
      };
      return param;
    },
  },
};
</script>
<style scoped>
* {
  font-size: 15px;
}

.auto_sub_div {
  display: inline-block;
  width: 50%;
  margin-bottom: 10px;
}
.select_list {
  width: 70%;
  margin-right: 50px;
}

.label_span {
  margin-right: 10px;
}
.radio_label_span {
  margin-right: 8px;
}

.auto_sub_div {
  height: 36px;
  line-height: 36px;
  margin-bottom: 10px;
  font-size: 15px;
}
.auto_sub_btn {
  height: 33px;
  line-height: 33px;
  margin: 20px 0 0 0;
}

.auto_sub_cancel,
.auto_sub_confirm {
  float: right;
  border-radius: 5px;
  text-align: center;
  display: block;
  height: 33px;
  width: 60px;
  line-height: 33px;
}
.auto_sub_cancel {
  margin-right: 15px;
  border: 1px solid rgb(184, 184, 184);
}
.auto_sub_cancel:hover {
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}
.auto_sub_confirm {
  border: 1px solid rgb(78, 78, 255);
  background-color: rgb(78, 78, 255);
  color: white;
}
.auto_sub_confirm:hover {
  box-shadow: 0px 0px 2px 0px rgb(23, 23, 255);
}

.item_packet {
  display: inline-block;
  min-width: 80px;
  padding: 0px 3px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #acacac;
  margin: 3px 10px 3px 0px;
  border-radius: 3px;
}

.select {
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}

.unselect:hover {
  border: 1px solid rgb(144, 144, 255);
  color: rgb(144, 144, 255);
}

.support_country {
  width: 360px;
  height: 150px;
  overflow-y: scroll;
}
.cursor_attr:hover{
  cursor: pointer;
}
::v-deep .ant-modal-body {
  padding: 0px;
}

::v-deep .ant-collapse {
  border: none;
}
</style>