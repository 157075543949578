<template>
  <div>
    <div class="operate_nav">
      <span class="operate_nav_title">{{ title }}</span>
       <!-- $store.state.role等于1,表示登录的是客服账号， isParentAcount等于0，表示登录的是主账号 -->
      <span v-if="$store.state.role != 1 && isParentAcount == 0" class="operate_nav_account" @click.prevent="createAccount(true)">
        {{this.$t('subAccount.operateAccount')}}
      </span>
      <create-account
        :visible="visible.account"
        @create-account-visible="createAccount"
      ></create-account>
    </div>
    <div style="margin-top: 10px">
      <a-config-provider >
         <a-table
          :loading="loading"
          :columns="columns"
          :rowClassName="rowClassName"
          :customRow="selectRow"
          :data-source="datas"
          :pagination="pagination"
          :rowKey="(record,i)=>{return i}"
          size="small"
        >
        <div slot="type" slot-scope="text, record">
          <span v-if="record.type == 1">{{$t('subAccount.behalfpay')}}</span>
          <span v-if="record.type == 0">{{$t('subAccount.selfPay')}}</span>
        </div> 
        <div slot="status" slot-scope="text, record">
          <span v-if="record.status == -2">{{$t('subAccount.deactivate')}}</span>
          <span v-if="record.status == 0">{{$t('subAccount.enable')}}</span>
        </div>
         <div slot="show" slot-scope="text, record">
          <span v-if="record.show == 1">{{$t('subAccount.hide')}}</span>
          <span v-if="record.show == 0">{{$t('subAccount.show')}}</span>
        </div>  
        <div slot="date" slot-scope="text, record">
            <div>
              <span>{{ record.date }}</span>
              <span v-if="$store.state.role != 1" class="row_record_btn_operate" title="操作">
                <a-dropdown :trigger="['click']">
                  <a-icon type="ellipsis" />
                  <a-menu slot="overlay" style="text-align: center">
                    <a-menu-item>
                      <span @click.prevent="showFlow(record)">{{$t('subAccount.configurePackage')}}</span>
                    </a-menu-item>
                    <a-menu-item>
                      <span @click.prevent="showUpdateName(record)">{{$t('subAccount.updateName')}}</span>
                    </a-menu-item>
                    <a-menu-item>
                      <span @click.prevent="showUpdatePassword(record)"
                        >{{$t('subAccount.updatePassword')}}</span
                      >
                    </a-menu-item>
                    <a-menu-item v-if="record.type != 1">
                      <span @click.prevent="showAccountRecharge(record)">{{$t('subAccount.recharge')}}</span>
                    </a-menu-item>
                    <a-menu-item
                      @click="updateOperateStatus(record, 0)"
                      v-if="record.status == -2">
                      {{$t('subAccount.enable')}}
                    </a-menu-item>
                    <a-menu-item
                      @click="updateOperateStatus(record, -2)"
                      v-if="record.status == 0">
                      {{$t('subAccount.deactivate')}}
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </span>
            </div>
          </div>
        </a-table>
      </a-config-provider>
    </div>
    <update-name
      :visible="visible.name"
      :id="record.id"
      @update-name-visible="updateName"
    >
    </update-name>
    <update-password
      :visible="visible.password"
      :id="record.id"
      @update-password-visible="updatePassword"
    ></update-password>
    <account-recharge
      :visible="visible.recharge"
      :record="record"
      @account-recharge-visible="accountRecharge"
    ></account-recharge>
    <flow-packet :visible="visible.flow"  :id="record.id" @flow-visible="flow"> </flow-packet>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import updateName from "../../dialog/account/updateNameModal.vue";
import accountRecharge from "../../dialog/account/accountRechargeModal.vue";
import flowPacket from "../../dialog/account/flowPacketModal.vue";
import createAccount from "../../dialog/account/createAccountModal.vue";
import updatePassword from "../../dialog/account/updatePasswordModal.vue";
import { errMsg, successMsg, warnMsg } from "../../../utils/CommonUtils.js";
export default {
  components: {
    updateName,
    accountRecharge,
    flowPacket,
    createAccount,
    updatePassword,
  },
  data() {
    return {

      locale: zhCN,
      loading: true,
      record: "",
      rowId: "",  //被选中的行记录下标
      isParentAcount: "",
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "100", "300", "500"],
        showSizeChanger: true,
        onChange: (current, pageSize) =>
          this.changeCurrentPage(current, pageSize),
        onShowSizeChange: (current, pageSize) =>
          this.changePageSize(current, pageSize),
          showTotal: () => {
              const count = this.pagination.total;
              return this.$i18n.t('subAccount.totalRecords', { count });
          },
      },
      visible: {
        name: false,
        password: false,
        recharge: false,
        flow: false,
        account: false,
      },
      datas: [],

    };
  },
  created(){
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.isParentAcount = sessionStorage.getItem("main");
    this.init()
  },
  computed: {
    title() {
      return  this.$t('subAccount.title');
    },
    columns() {
      return [
        {
          title: this.$t('subAccount.columns.phone'),
          dataIndex: "phone",
        },
        {
          title: this.$t('subAccount.columns.mainA'),
          dataIndex: "mainA",
        },
        {
          title: this.$t('subAccount.columns.relaname'),
          dataIndex: "relaname",
        },
        {
          title: this.$t('subAccount.columns.balance'),
          dataIndex: "balance",
          width: 100,
        },
        {
          title: this.$t('subAccount.columns.type'),
          dataIndex: "type",
          width: 100,
          scopedSlots: { customRender: "type" },
        },
        {
          title: this.$t('subAccount.columns.show'),
          dataIndex: "show",
          scopedSlots: { customRender: "show" },
        },
        {
          title: this.$t('subAccount.columns.status'),
          dataIndex: "status",
          width: 100,
          scopedSlots: { customRender: "status" },
        },
        {
          title: this.$t('subAccount.columns.date'),
          dataIndex: "date",
          scopedSlots: { customRender: "date" },
        },
      ];
    },
  },
  methods: {
    accountRecharge(val,flag) {
      this.visible.recharge = val;
       if(flag == 1){
        this.init();
      }
    },
    createAccount(val, flag) {
      this.visible.account = val;
      if(flag == 1){
        this.init();
      }
    },
    changeCurrentPage(currentPage, pageSize) {
      this.pagination.current = currentPage;
      this.pagination.pageSize = pageSize;
      this.rowId = "";
    },
    changePageSize(currentPage, pageSize) {
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.rowId = "";
    },
    flow(val) {
      this.visible.flow = val;
    },
    init() {
      this.axios.post(this.baseUrl + "getDistributionAccount", "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.datas  = res.data.data
              this.pagination.total = res.data.count
            }else if(res.data.code == 1){
              this.datas = []
              this.pagination.total = 0
            }else if(res.data.code == -1){
              warnMsg(this.$t('subAccount.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }
          } else {
            errMsg(this.$t('subAccount.hint.error.getDataErr'));
          }
        }).catch(() => {
          this.loading = false;
          errMsg(this.$t('subAccount.hint.error.systemErr'));
        });
    },
    showFlow(record){
      this.record = record
      this.visible.flow = true;
    },
    showUpdateName(record){
      this.record = record
      this.visible.name = true;
    },
    showUpdatePassword(record){
      this.record = record
      this.visible.password = true;
    },
    showAccountRecharge(record){
      this.record = record
      this.visible.recharge = true;
    },
    updateOperateStatus(record, status) {
      this.axios.post(this.baseUrl + "updateStatus?id="+record.id+"&type="+status, "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              successMsg(this.$t('subAccount.hint.success.update'))
              this.init();
            }else if(res.data.code == -1){
              warnMsg(this.$t('subAccount.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }else if(res.data.code == -2){
              warnMsg(this.$t('subAccount.hint.warn.getAccountWarn'));
            }else if(res.data.code == -3){
              warnMsg(this.$t('subAccount.hint.warn.permissionWarn'));
            }
          } else {
            errMsg(this.$t('subAccount.hint.error.getDataErr'));
          }
        }).catch(() => {
          errMsg(this.$t('subAccount.hint.error.systemErr'));
        });
    },
    updateName(val, flag) {
      this.visible.name = val;
      if(flag == 1){
        this.init();
      }
    },
    updatePassword(val, flag) {
      this.visible.password = val;
      if(flag == 1){
        this.init();
      }
    },
    rowClassName(record, index) {
      if(index === this.rowId){
        return "select_row_bg_color";
      }else{
        return "";
      }
    },
    selectRow(record, index){
       return {
            on: {
               click: () => {
                  this.rowId = index;
               }
            }
        }
    },  
  },
};
</script>
<style scoped>
@import '../../../assets/css/table.css';
/* 操作栏 */
.operate_nav {
  margin-top: 10px;
  text-align: right;
}

.operate_nav_title {
  /* 整行标题 */
  float: left;
  font-size: 24px;
  font-weight: bold;
  color: rgb(14, 14, 14);
}

/* 创建子账号 */
.operate_nav_account {
  display: inline-block;
  text-align: center;
  min-width: 100px;
  border: 1px solid rgb(160, 160, 160);
  height: 30px;
  line-height: 30px;
  margin-left: 15px;
  border-radius: 5px;
}

.operate_nav_account:hover {
  cursor: pointer;
  color: rgb(78, 78, 255);
  border: 1px solid rgb(78, 78, 255);
}

/* 操作 */
.row_record_btn_operate {
  float: right;
  margin-right: 10px;
}
.row_record_btn_operate:hover {
  cursor: pointer;
}
::v-deep .ant-table-small {
  border: none;
}
</style>