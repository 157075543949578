<template>
  <div>
    <div class="operate_nav">
      <span class="operate_nav_title">{{ title }}</span>
      <span v-if="$store.state.role != 1" class="operate_nav_btn_frame" @click.prevent="batchOpen">
        <a-icon type="check" />
        {{$t('deviceInactive.button.check')}}
      </span>
      <span v-if="$store.state.role != 1" class="operate_nav_btn_frame" @click.prevent="batchClose">
        <a-icon type="close" />
        {{$t('deviceInactive.button.close')}}
      </span>
      <span class="operate_nav_btn_frame" @click.prevent="showSearch">
        <span>
          <a-icon type="search" /><span style="margin-left: 3px">{{$t('deviceInactive.button.search')}} </span>
        </span>
      </span>
    </div>
    <div :class="[search.visible ? 'search_show' : 'search_hide']">
      <div class="search_line_one_group search_div">
        <div style="width: 33.3%;">
          <span class="search_item_label">{{$t('deviceInactive.searchList.group')}}</span>
          <a-select
            class="search_item"
            :placeholder="this.$t('deviceInactive.searchList.selectMsg')"
            allowClear
            v-model="search.group"
          >
            <a-select-option v-for="(groupName,i) in groups" :value="i" :key="i">{{groupName}}</a-select-option>
          </a-select>
        </div>
        <div  style="width: 33.3%;">
          <span class="search_item_label">{{$t('deviceInactive.searchList.status')}}</span>
          <a-select
            allowClear
            class="search_item"
            v-model.trim="search.status"
            :placeholder="this.$t('deviceInactive.searchList.selectMsg')"
          >
            <a-select-option value="0"> {{$t('deviceInactive.normal')}} </a-select-option>
            <a-select-option value="2"> {{$t('deviceInactive.deactivate')}} </a-select-option>
            <a-select-option value="1"> {{$t('deviceInactive.uninitialized')}} </a-select-option>
            <a-select-option value="3"> {{$t('deviceInactive.recycle')}} </a-select-option>
          </a-select>
        </div>
        <div  style="width: 33.3%;">
          <span class="search_item_label">{{$t('deviceInactive.searchList.days')}}</span>
          <a-select
            allowClear
            class="search_item"
            v-model.trim="search.time"
            :placeholder="this.$t('deviceInactive.searchList.selectMsg')"
          >
            <a-select-option :value="30"> {{$t('deviceInactive.oneMonth')}} </a-select-option>
            <a-select-option :value="90"> {{$t('deviceInactive.threeMonth')}} </a-select-option>
            <a-select-option :value="180"> {{$t('deviceInactive.sixMonth')}} </a-select-option>
            <a-select-option :value="365"> {{$t('deviceInactive.oneYear')}} </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="search_div">
        <span class="search_item_label">{{$t('deviceInactive.searchList.tsid')}}</span>
        <a-textarea
          style="margin-left: 6px"
          v-model.trim="search.tsid"
          :placeholder="this.$t('deviceInactive.searchList.tsidMsg')"
          autocomplete="off"
        />
      </div>
      <div class="search_div">
        <span class="search_item_label">{{$t('deviceInactive.searchList.imei')}}</span>
        <a-textarea
          style="margin-left: 6px"
          v-model.trim="search.imei"
          :placeholder="this.$t('deviceInactive.searchList.imeiMsg')"
          autocomplete="off"
        />
      </div>
      <span class="search_item_btn" @click.prevent="searchConfirm">{{$t('deviceInactive.button.inquire')}}</span>
    </div>
    <div style="margin-top: 10px">
      <a-config-provider >
         <a-table
          :loading="loading"
          :row-selection="rowSelection"
          :columns="columns"
          :data-source="datas"
          :pagination="pagination"
          :rowClassName="rowClassName"
          :customRow="selectRow"
          :rowKey="(record, i)=>{return i}"
          size="small"
        >
          <template slot="tsid" slot-scope="text, record">
            <a @click="showUsedDetail(record)">{{ record.tsid }} </a>
          </template>
           <template slot="status" slot-scope="text, record">
            <span v-if="record.status == 0">{{$t('deviceInactive.normal')}}</span>
            <span v-else-if="record.status == 1">{{$t('deviceInactive.uninitialized')}}</span>
            <span v-else-if="record.status == 2">{{$t('deviceInactive.deactivate')}}</span>
            <span v-else-if="record.status == 3">{{$t('deviceInactive.recycle')}}</span>
          </template>
           <div slot="auto" slot-scope="text, record">
            <span v-if="record.auto == 1">{{$t('deviceInactive.no')}}</span>
            <span v-else-if="record.auto == 0">{{$t('deviceInactive.yes')}}</span>
          </div>
          <div slot="operation" slot-scope="text, record">
            <div>
              <span class="row_record_btn_operate" :title="$t('deviceInactive.operation')">
                <a-dropdown :trigger="['click']">
                  <a-icon type="ellipsis" />
                  <a-menu
                    style="width: 70px; text-align: center"
                    slot="overlay"
                  >
                    <a-menu-item v-if="$store.state.role != 1">
                    <div>
                      <div  @click="updateStatus(record.imei, 0)" v-if="record.status == 2">
                          {{$t('deviceInactive.button.check')}}
                      </div>
                      <div @click="updateStatus(record.imei, 2)"  v-if="record.status == 0">
                        {{$t('deviceInactive.button.close')}}
                      </div>
                    </div>
                    </a-menu-item>
                      <a-menu-item @click="showDetail(record)">
                        {{$t('deviceInactive.button.detail')}}
                      </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </span>
            </div>
          </div>
        </a-table>
      </a-config-provider>
    </div>
    <inactive-detail-modal
        v-if="visible.detail"
        :tsid="params.tsid"
        @inactive-detail-visible="changeShowDetail"
      >
      </inactive-detail-modal>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import inactiveDetailModal from "../../dialog/device/inactiveDetailModal.vue";
import { warnMsg, errMsg, successMsg, isNull } from "../../../utils/CommonUtils";
export default {
  components: {
    inactiveDetailModal,
  },
  data() {
    return {
      title: this.$t('deviceInactive.title'),
      locale: zhCN,
      loading: true,
      rowId: "",
      selectedRow: [],
      selectedRowKeys: [],
      visible: {
        detail: false,
      },
      params: {
        tsid: "",
      },
      groups: [],
      search: {
        group: undefined,
        tsid: "",
        imei: "",
        status: undefined,
        time: undefined,
        visible: false,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "100", "300", "500"],
        showSizeChanger: true,
        onChange: (current, pageSize) =>
          this.changeCurrentPage(current, pageSize),
        onShowSizeChange: (current, pageSize) =>
          this.changePageSize(current, pageSize),
          showTotal: () => {
              const count = this.pagination.total;
              return this.$i18n.t('deviceInactive.totalRecords', { count });
          },
      },
      datas: [],
      columns: [
        {
          title: this.$t('deviceInactive.columns.tsid'),
          dataIndex: "tsid",
          width: 120,
          scopedSlots: { customRender: "tsid" },
        },
        {
          title: this.$t('deviceInactive.columns.imei'),
          dataIndex: "imei",
        },
        {
          title: this.$t('deviceInactive.columns.date'),
          dataIndex: "data",
        },
        {
          title: this.$t('deviceInactive.columns.days'),
          dataIndex: "days",
          width: 120,
        },

        {
          title: this.$t('deviceInactive.columns.status'),
          dataIndex: "status",
          width: 100,
          scopedSlots: { customRender: "status" },
        },
        {
          title: this.$t('deviceInactive.columns.auto'),
          dataIndex: "auto",
          width: 100,
          scopedSlots: { customRender: "auto" },
        },
         {
          title: this.$t('deviceInactive.columns.bissgroup'),
          dataIndex: "bissgroup",
          width: 120,
        },
        {
          title: this.$t('deviceInactive.columns.remark'),
          dataIndex: "remark",
          ellipsis: true,
          width: 150,
        },
        {
          title: this.$t('deviceInactive.columns.operation'),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          width: 60,
        },
      ],
    };
  },
  created(){
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.init()
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys, // 一定要加上这一行代码，清除才会有作用
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          this.selectedRow = selectedRows;
        },
      };
    },
  },
  methods: {
   batchOpen() {
      if (this.selectedRow.length == 0) {
        warnMsg(this.$t('deviceInactive.hint.warn.selectedRowWarn1'));
        return;
      }
      if (this.selectedRow.length >= 100) {
        warnMsg(this.$t('deviceInactive.hint.warn.selectedRowWarn2'));
        return;
      }
      var imeis = "";
      for (let i = 0; i < this.selectedRow.length; i++) {
        imeis = imeis + this.selectedRow[i].imei + ",";
      }
      imeis = imeis.substring(0, imeis.lastIndexOf(","));
      this.updateStatus(imeis, 0);
    },
    batchClose() {
      if (this.selectedRow.length == 0) {
        warnMsg(this.$t('deviceInactive.hint.warn.selectedRowWarn1'));
        return;
      }
      if (this.selectedRow.length >= 100) {
        warnMsg(this.$t('deviceInactive.hint.warn.selectedRowWarn2'));
        return;
      }
      var imeis = "";
      for (let i = 0; i < this.selectedRow.length; i++) {
        imeis = imeis + this.selectedRow[i].imei + ",";
      }
      imeis = imeis.substring(0, imeis.lastIndexOf(","));
      this.updateStatus(imeis, 2);
    },
    changeShowDetail(val) {
      this.visible.detail = val;
    },
    //改变页码调用此函数
    changeCurrentPage(currentPage, pageSize) {
      this.loading = true
      this.pagination.current = currentPage;
      this.pagination.pageSize = pageSize;
       this.rowId = "";
      this.init();
    },
    //改变页记录数会调用此函数
    changePageSize(currentPage, pageSize) {
      this.loading = true
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
       this.rowId = "";
      this.init();
    },
    getGroup(){
      this.axios.post(this.baseUrl + "getDeviceGroup","",{headers: {token: sessionStorage.getItem("token")}}).then((res)=>{
          if(res.status == 200){
             if(res.data.code == 0){
               this.groups = res.data.data
             }else if(res.data.code == -1){
                warnMsg(this.$t('deviceInactive.hint.warn.loginWarn'))
                setTimeout (()=>{
                  this.$router.push({ name: "login"});
                },"2000");
             }else if(res.data.code == -2){
               errMsg(this.$t('deviceInactive.hint.error.systemErr'))
             }
          }
        }).catch(()=>{
            errMsg(this.$t('deviceInactive.hint.error.systemErr'))
        })
    },
    init() {
      this.selectedRowKeys = [];
      this.selectedRow = [];
      this.axios.post(this.baseUrl + "getInactiveDevice", this.initParam(), {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.pagination.total = res.data.count;
              this.datas = res.data.data
            }else if (res.data.code == -1) {
              warnMsg(this.$t('deviceInactive.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }else if (res.data.code == -3) {
              errMsg(this.$t('deviceInactive.hint.error.systemErr'));
            }
          } else {
            errMsg(this.$t('deviceInactive.hint.error.getDataErr'));
          }
        }).catch(() => {
          this.loading = false;
          errMsg(this.$t('deviceInactive.hint.error.systemErr'));
        });
    },
    initParam(){
      let param = {
          tsid: this.search.tsid,
          imei: this.search.imei,
          bissgroup: this.search.group,
          status: isNull(this.search.status) ? "" : this.search.status,
          time: isNull(this.search.time) ? "" : this.search.time,
          pageNo: this.pagination.current,
          limit: this.pagination.pageSize,
      }
      return param;
    },
    onSelectChange(keys, selectedRow) {
      this.selectedRow = selectedRow;
    },
    showDetail(record) {
      this.params.tsid = record.tsid;
      this.visible.detail = true;
    },

    showSearch() {
      if (this.search.visible) {
        this.search.visible = false;
      } else {
        this.getGroup();
        this.search.visible = true;
      }
    },
    searchConfirm() {
      this.loading = true
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.search.tsid = this.search.tsid.replace(/\s+|[\r\n]+/g,"");
      this.search.imei = this.search.imei.replace(/\s+|[\r\n]+/g, "");
       this.rowId = "";
      this.init();
    },
    /** 超链接跳转 */
    showUsedDetail(record) {
      let newUrl = this.$router.resolve({
        path: "/device/dayCost",
        query:{
          tsid: record.tsid,
          date: record.data,
        }
      });
      window.open(newUrl.href, "_blank");
    },
    updateStatus(imeis, status) {
      if(isNull(imeis) || (status !=0 && status != 2)){
        return;
      }
      this.axios.post(this.baseUrl +"statusChange?imei=" +imeis + "&status=" +status,
          "",{ headers: { token: sessionStorage.getItem("token") } }
        ).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0 || res.data.code == 1) {
              successMsg(this.$t('deviceInactive.hint.success.updateStatus'));
               this.rowId = "";
              this.init();
            }else if (res.data.code == -5) {
              warnMsg(this.$t('deviceInactive.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }else if (res.data.code == -7 || res.data.code == -1) {
              errMsg(this.$t('deviceInactive.hint.error.systemErr'))
            }else if (res.data.code == -6){
              warnMsg(this.$t('deviceInactive.hint.warn.retryWarn'));
            }else if (res.data.code == -2) {
              warnMsg(this.$t('deviceInactive.hint.warn.updateWarn'))
            }
          } else {
            warnMsg(this.$t('deviceInactive.hint.warn.updateStatusWarn'));
          }
        }).catch(() => {
          errMsg(this.$t('deviceInactive.hint.error.systemErr'));
        });
    },
     rowClassName(record, index) {
      if(index === this.rowId){
        return "select_row_bg_color";
      }else{
        return "";
      }
    },
    selectRow(record, index){
       return {
            on: {
               click: () => {
                  this.rowId = index;
               }
            }
        }
    },  
  },
};
</script>
<style scoped>
@import '../../../assets/css/table.css';

/* 操作栏 */
.operate_nav {
  margin-top: 10px;
  text-align: right;
}

.operate_nav_title {
  /* 整行标题 */
  float: left;
  font-size: 24px;
  font-weight: bold;
  color: rgb(14, 14, 14);
}

.operate_nav_btn_frame {
  margin: 7px 0px 0px;
  display: inline-block;
  text-align: center;
  min-width: 70px;
  border: 1px solid rgb(160, 160, 160);
  height: 30px;
  line-height: 30px;
  margin-left: 15px;
  border-radius: 5px;
}

.operate_nav_btn_frame:hover {
  cursor: pointer;
  color: rgb(78, 78, 255);
  border: 1px solid rgb(78, 78, 255);
}

/*搜索框 */
.search_div {
  display: flex;
  margin-bottom: 10px;
  align-items: flex-end;
}
.search_line_one_group {
  justify-content: space-between;
  /* align-items: center; */
}
.search_item {
  display: inline-block;
  width: calc(100% - 80px) !important;
}

.search_item_btn {
  float: right;
  display: block;
  width: 50px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  border: 1px solid gray;
  text-align: center;
}

.search_item_btn:hover {
   cursor: pointer;
  color: rgb(78, 78, 255);
  border: 1px solid rgb(78, 78, 255);
}

.search_item_label {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  width: 80px;
  text-align: right;
}

.search_show {
  display: block;
  text-align: left;
  margin: 20px 0px 45px;
  transition: all 3s ease-in;
}

.search_hide {
  display: none;
  transition: all 3s ease-out;
}
/* 记录操作 */
.row_record_btn_operate {
  display: block;
  float: right;
  margin-right: 5px;
}
.row_record_btn_operate:hover {
  cursor: pointer;
}
::v-deep .ant-table-small {
  border: none;
}
</style>
 