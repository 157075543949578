<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="450px"
    :footer="null"
    :maskClosable="false"
    :dialogStyle="{ top: '20%' }"
    @cancel="handleCancel"
  >
    <div style="height:190px;">
      <div class="flex"><span class="label">{{$t('updatePrice.country')}}</span><a-input v-model="record.country" disabled/></div>
      <div class="flex"><span class="label">{{$t('updatePrice.price_0_0')}}:</span><a-input v-model="record.price_0_0" disabled/></div>
      <div class="flex"><span class="label">{{$t('updatePrice.packageType')}}</span><a-input v-model="packageType" disabled/></div>
      <div class="flex"><span class="label">{{$t('updatePrice.subPrice')}}</span><a-input v-model.trim="subPrice"/></div>
      <div style="text-align:center;margin-top:15px;">
          <span class="update_price_cancel" @click.prevent="cancel">{{$t('updatePrice.cancel')}}</span>
          <span class="update_price_confirm" @click.prevent="confirm">{{$t('updatePrice.confirm')}}</span>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { errMsg,successMsg, warnMsg } from '../../../utils/CommonUtils';
export default {
  props: ["visible", "record"],
  data() {
    return {
      title: this.$t('updatePrice.title'),
      showModal: false,
      packageType: "",
      subPrice: "",
      price: "",
      country: "",
    };
  },
  watch: {
    /** 通过监视visible值变化来控制弹框显示，注意：有部分弹框控制方式和这里不一样。如autoSubscribeModal */
    visible(val) {
      this.showModal = val;
      if(this.record.package_type == 0){
        this.packageType = this.$t('updatePrice.dayPacket')
      }else{
        this.packageType = this.$t('updatePrice.monthPacket')
      }
      this.subPrice = this.record.price_1_0
    },
  },
  methods: {
    cancel() {
      this.$emit("update-price-visible", false, 0);
    },
    confirm() {
      let reg = /[0-9]{1,20}[\\.]?[0-9]{0,3}/
      if(!reg.test(this.subPrice)){
         warnMsg(this.$t('updatePrice.hint.warn.subPriceWarn1'))
         return; 
      }
      if(this.record.price_1_0 === this.subPrice){
        warnMsg(this.$t('updatePrice.hint.warn.subPriceWarn2'));
        return;
      }
      if(this.subPrice < this.record.price_0_0){
        warnMsg(this.$t('updatePrice.hint.warn.subPriceWarn3'))
        return;
      }
      let param = {
        userId: this.record.userid,
        price: this.subPrice,
        attachid: this.record.attachid,
        countryCode: this.record.countryCode,
        countryName: this.record.country,
        package_type: this.record.price_type,
      }
      this.axios.post(this.baseUrl + "updateConfigFlowPrice", param,{headers: { token: sessionStorage.getItem("token") }}).then((res)=>{
        if(res.status == 200){
          if(res.data.code == 0){
            successMsg(this.$t('updatePrice.hint.success.operation'))
            this.$emit("update-price-visible", false, 1);
          }else{
            errMsg(this.$t('updatePrice.hint.error.operationErr'))
          }  
        }else{
          errMsg(this.$t('updatePrice.hint.error.operationErr'))
        }
      }).catch(()=>{
        errMsg(this.$t('updatePrice.hint.error.systemErr'))
      })
    },
    handleCancel() {
      this.$emit("update-price-visible", false, 0);
    },
  },
};
</script>
<style scoped>

.flex{
    display: flex;
    margin-bottom: 10px;
}
.label{
    width: 120px;
}
.update_price_cancel,
.update_price_confirm {
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  height: 33px;
  width: 60px;
  line-height: 33px;
}
.update_price_cancel {
  margin-right: 15px;
  border: 1px solid rgb(184, 184, 184);
}
.update_price_cancel:hover {
  cursor: pointer;
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}
.update_price_confirm {
  border: 1px solid rgb(78, 78, 255);
  background-color: rgb(78, 78, 255);
  color: white;
}
.update_price_confirm:hover {
  cursor: pointer;
  box-shadow: 0px 0px 2px 0px rgb(23, 23, 255);
}
</style>