import { message } from 'ant-design-vue'


/** 控制提示框距离顶部位置，显示时长，显示个数，其他参数请参考文档 */
message.config({
    top: `280px`,
    duration: 1,
    maxCount: 1,
});

//判断是否为空， 注意：param是数字类型0,也会返回true， '0'则不会。
let isNull = (param) => {
    if (param == "" || param == null || param.length == 0 ||
        typeof (param) == 'undefined') {
        return true;
    }
    return false;
}


let successMsg = (content) => {
    message.success(content)
}



let warnMsg = (content) => {
    message.warning(content)
}



let errMsg = (content) => {
    message.error(content)
}

/** 左侧菜单栏的标题 */
let getTitle = (num) => {
    if (num == 86) {
        return 'navcon.menu.首页';
    } else if (num == 87) {
        return 'navcon.menu.终端';
    } else if (num == 88) {
        return 'navcon.menu.计费';
    } else if (num == 89) {
        return 'navcon.menu.账号';
    } else if (num == 90) {
        return 'navcon.menu.报表';
    } else {
        return '';
    }
}


/** 格式化数字，text-数字， number-切分位数，symbol-切分的符号 */
let formatNumber = (text, number, symbol) =>{
    number =  number || 3;
    symbol = symbol || ',';
    let value = ""+text;
    if(value.length <= number) return value;
    value = value.split("").reverse().join(""); // 翻转整数
    let temp = ''
    for(let i=0; i<value.length; i++){
        temp += value[i]
        if((i+1) % number === 0 && i !== value.length - 1){
        temp += symbol
        }
    }
    temp = temp.split('').reverse().join('')
    return temp 
}

export { isNull, errMsg, successMsg, warnMsg, getTitle, formatNumber}