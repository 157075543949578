<template>
  <div>
    <div class="operate_nav">
      <span class="operate_nav_title">{{ title }}</span>
      <span class="operate_nav_btn_frame_0" @click.prevent="queryDeviceDetail" >{{this.$t('bill.button.queryDeviceDetail')}}</span>
      <span class="operate_nav_btn_frame_0" @click.prevent="queryDetail">
        {{this.$t('bill.button.queryDetail')}}
      </span>
      <span class="operate_nav_btn_frame" @click.prevent="exportData">
        <a-icon type="download" />
        {{this.$t('bill.button.report')}}
      </span>
    </div>
    <div>
      <div class="data_filter_nav">
        <a-config-provider >
        <a-month-picker
          :placeholder="this.$t('chart.changeDateMsg')"
          :allowClear="false"
          v-model="showDate"
          @change="changeDate"
        >
          <a-icon
            slot="suffixIcon"
            type="schedule"
            theme="filled"
            style="margin-right: 5px"
          />
        </a-month-picker>
        </a-config-provider>
        <div class="data_filter_sep"></div>
      </div>
    </div>
    <div>
      <a-config-provider >
        <a-table
          :loading="loading"
          :rowClassName="rowClassName"
          :columns="columns"
          :data-source="datas"
          :pagination="pagination"
          :rowKey="(record, i) => { return i;}"
          :customRow="selectRow"
          size="small"
        >
          <div slot="flowPoolName" slot-scope="text, record">
            <a class="row_record_flowPool"
              @click="flowPoolDetail(record.flowPoolName)"
              >{{ record.flowPoolName }}</a>
          </div>
          <div slot="tsNum" slot-scope="text, record">
            <a class="row_record_flowPool"
              @click="toOrder(record)"
              >{{ record.tsNum }}</a>
          </div>
          <div slot="excessPrice" slot-scope="text, record">
            <span v-if="record.excessPrice != -1">{{
              Math.floor(record.excessPrice * 100) / 100
            }}</span>
            <span v-else-if="record.excessPrice == -1">-</span>
          </div>
          <div slot="flowPoolUnitPrice" slot-scope="text, record">
            <span v-if="record.flowPoolUnitPrice != -1">{{
              record.flowPoolUnitPrice
            }}</span>
            <span v-else-if="record.flowPoolUnitPrice == -1">-</span>
          </div>

          <div slot="flowPoolPrice" slot-scope="text, record">
            <span v-if="record.flowPoolPrice != -1">{{record.flowPoolPrice}}</span>
            <span v-else-if="record.flowPoolPrice == -1">-</span>
          </div>
          <div slot="excessCost" slot-scope="text, record">
            <span v-if="record.excessCost != -1">{{ record.excessCost }}</span>
            <span v-else-if="record.excessCost == -1">-</span>
          </div>
          <div slot="totalCost" slot-scope="text, record">
            <span v-if="record.totalCost != -1">{{ record.totalCost }}</span>
            <span v-else-if="record.totalCost == -1">-</span>
          </div>
        </a-table>
      </a-config-provider>
    </div>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { errMsg, warnMsg, isNull } from "../../../utils/CommonUtils.js";
import moment from "moment";
export default {
  data() {
    return {
      title: this.$t('bill.title'),
      locale: zhCN,
      loading: true,
      zoneName: "",
      rowId: "",
      showDate: "",
      date: "",
      year: "",
      pagination: {
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeOptions: ["10", "20", "100", "300", "500"],
        showSizeChanger: true,
        onChange: (current, pageSize) =>
          this.changeCurrentPage(current, pageSize),
        onShowSizeChange: (current, pageSize) =>
          this.changePageSize(current, pageSize),
          showTotal: () => {
              const count = this.pagination.total;
              return this.$i18n.t('bill.totalRecords', { count });
          },
      },
      datas: [],
      columns: [
        {
          title: this.$t('bill.columns.flowPoolName'),
          dataIndex: "flowPoolName",
          scopedSlots: { customRender: "flowPoolName" },
        },
        {
          title: this.$t('bill.columns.month'),
          dataIndex: "month",
        },
        {
          title: this.$t('bill.columns.tsNum'),
          dataIndex: "tsNum",
          scopedSlots: { customRender: "tsNum" },
        },
        {
          title: this.$t('bill.columns.flowPoolTotalFlow'),
          dataIndex: "flowPoolTotalFlow",
        },
        {
          title: this.$t('bill.columns.flowPoolUseFlow'),
          dataIndex: "flowPoolUseFlow",
        },
        {
          title: this.$t('bill.columns.flowPoolUnitPrice'),
          dataIndex: "flowPoolUnitPrice",
          scopedSlots: { customRender: "flowPoolUnitPrice" },
        },
        {
          title: this.$t('bill.columns.flowPoolPrice'),
          dataIndex: "flowPoolPrice",
          scopedSlots: { customRender: "flowPoolPrice" },
        },
        {
          title: this.$t('bill.columns.excessFlow'),
          dataIndex: "excessFlow",
        },
        {
          title: this.$t('bill.columns.excessPrice'),
          dataIndex: "excessPrice",
          scopedSlots: { customRender: "excessPrice" },
        },
        {
          title: this.$t('bill.columns.excessCost'),
          dataIndex: "excessCost",
          scopedSlots: { customRender: "excessCost" },
        },
        {
          title: this.$t('bill.columns.totalCost'),
          dataIndex: "totalCost",
          scopedSlots: { customRender: "totalCost" },
        },
      ],
    };
  },
  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    var date = this.$route.params.date;
    this.zoneName = this.$route.params.name;
    if (isNull(date)) {
      //初始化日期
      var d = new Date();
      this.date = d.getFullYear() + "-" + (d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1));
      this.showDate = moment(d, "yyyy-mm");
    } else {
      this.date = date;
      this.showDate = moment(date);
    }
    this.init();
  },
  methods: {
    changeCurrentPage(currentPage, pageSize) {
      this.pagination.current = currentPage;
      this.pagination.pageSize = pageSize;
      this.rowId = "";
    },
    changePageSize(currentPage, pageSize) {
      this.pagination.pageSize = pageSize;
      this.pagination.current = 1;
      this.rowId = "";
    },
    /** 日期条件 */
    changeDate(dayjs, dateStr) {
      this.pagination.current = 1;
      this.pagination.current = 15;
      this.date = dateStr;
      this.init();
    },
    exportData() {
      if(isNull(this.datas)){
        warnMsg(this.$t('bill.hint.warn.reportWarn'));
        return;
      }
      this.axios.post(this.baseUrl + "exportFlowPool?date=" + this.date, "", {
          responseType: "blob",
          headers: { token: sessionStorage.getItem("token"),
          'Accept-Language': localStorage.getItem('locale') },
        }).then((res) => {
          if (res.status == 200) {
            let link = document.createElement("a");
            let url = window.URL.createObjectURL(
              new Blob([res.data], {
                type: "application/pdf;charset=UTF-8;",
              })
            );
            let fileName = window.decodeURI(res.headers["content-disposition"].split("=")[1],"UTF-8");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); //下载完成移除元素
            window.URL.revokeObjectURL(url); //释放掉blob对象
          }
        }).catch(() => {
          errMsg(this.$t('bill.hint.error.systemErr'));
        });
    },

    /** 超链接跳转 */
    flowPoolDetail(name) {
      let newUrl = this.$router.resolve({
        path: "/balance/flowPoolDetail",
        query: {
          year: this.date.split("-")[0],
          name: name,
        },
      });
      window.open(newUrl.href, "_blank");
    },

    init() {
      this.axios.post(this.baseUrl + "getFlowPool?date=" + this.date, "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.datas = res.data.data;
              this.pagination.total = res.data.count;
              if (this.datas.length > 0) {
                for (let i = 0; i < this.datas.length; i++) {
                  this.datas[i].month = this.date.split("-")[1];
                }
              }
            } else if (res.data.code == 1) {
              this.datas = [];
              this.pagination.total = 0;
            } else if (res.data.code == -1) {
              warnMsg(this.$t('bill.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -3) {
              errMsg(this.$t('bill.hint.error.systemErr'));
            }
          } else {
            errMsg(this.$t('bill.hint.error.getDateErr'));
          }
        }).catch(() => {
          this.loading = false;
          errMsg(this.$t('bill.hint.error.systemErr'));
        });
    },
    toOrder(record){
     this.$router.push({
        name: "device",
        params: {
          subMenu: 3,
          menu: 99,
          countryCode: record.flowPoolNameCode,
          date: this.date,
        },
      });
    },
    /** 查看详单 */
    queryDetail() {
      let newUrl = this.$router.resolve({
        path: "/balance/flowPoolUseDetail",
        query: {
          date: this.date,
        },
      });
      window.open(newUrl.href, "_blank");
    },
    /** 查看设备详单 */
    queryDeviceDetail(){
      let newUrl = this.$router.resolve({
        path: "/balance/deviceDetail",
        query: {
          date: this.date,
        },
      });
      window.open(newUrl.href, "_blank");
    },
    rowClassName(record, index) {
      if(index === this.rowId){
        return "select_row_bg_color";
      }else{
        if (!isNull(this.zoneName)) {
          if (record.flowPoolName.search(this.zoneName) != -1) {
            this.rowId = index;
            this.zoneName = "";
            return "select_row_bg_color"; /** 返回的是类名，在table.css文件中有 */
          }
        }
        return "";
      }
    },
    selectRow(record, index){
       return {
            on: {
               click: () => {
                  this.rowId = index;
               }
            }
        }
    },  
  },
};
</script>
<style scoped>
@import '../../../assets/css/table.css';
/* 操作栏 */
.operate_nav {
  margin-top: 10px;
  text-align: right;
}

.operate_nav_title {
  /* 整行标题 */
  float: left;
  font-size: 24px;
  font-weight: bold;
  color: rgb(14, 14, 14);
}

.operate_nav_btn_frame {
  margin: 7px 0px 0px;
  display: inline-block;
  text-align: center;
  min-width: 70px;
  border: 1px solid rgb(160, 160, 160);
  height: 30px;
  line-height: 30px;
  margin-left: 15px;
  border-radius: 5px;
}

.operate_nav_btn_frame_0 {
  margin: 7px 0px 0px;
  display: inline-block;
  text-align: center;
  min-width: 100px;
  border: 1px solid rgb(160, 160, 160);
  height: 30px;
  line-height: 30px;
  margin-left: 15px;
  border-radius: 5px;
}
.operate_nav_btn_frame_0:hover {
  cursor: pointer;
  color: rgb(78, 78, 255);
  border: 1px solid rgb(78, 78, 255);
}


.operate_nav_btn_frame:hover {
  cursor: pointer;
  color: rgb(78, 78, 255);
  border: 1px solid rgb(78, 78, 255);
}

.row_record_flowPool:hover {
  text-decoration: underline;
}

/* 日期过滤 */
.data_filter_nav {
  display: inline-flex;
  margin-top: 10px;
  text-align: left;
  width: 100%;
  text-align: left;
  padding: 0;
  height: 30px;
  line-height: 30px;
  align-items: center;
}

.data_filter_sep {
  height: 30px;
  width: 100%;
  border-bottom: 1px solid rgb(172, 172, 172);
}

/* 日期样式 */
::v-deep .ant-calendar-picker-icon {
  left: 0px;
  color: rgba(0, 0, 0);
}
::v-deep .ant-calendar-picker-input {
  border: none;
  width: 96px;
  padding-left: 20px;
  cursor: pointer !important;
}
::v-deep .ant-calendar-picker {
  cursor: pointer !important;
}
::v-deep
  .ant-calendar-picker:hover
  .ant-calendar-picker-input:not(.ant-input-disabled) {
  border: none;
  box-shadow: none;
}
::v-deep .ant-table-small {
  border: none;
}
</style>
 