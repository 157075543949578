<template>
  <div style="margin: 30px">
    <div class="operate_nav">
      <span class="operate_nav_title">{{ title }}</span>
    </div>
    <div style="margin-top: 10px">
      <a-config-provider >
         <a-table
          :loading="loading"
          :columns="columns"
          :data-source="datas"
          :pagination="pagination"
          :rowClassName="rowClassName"
          :customRow="selectRow"
          :rowKey="(record, i)=>{return i}"
          size="small"
        >
         <div slot="excessPrice" slot-scope="text, record">
          <span  v-if="record.excessPrice != -1">{{Math.floor(record.excessPrice * 100) / 100}}</span>
          <!-- 分销账号代付类型，隐藏价格信息 -->
           <span v-else-if="record.excessPrice == -1">-</span>
        </div>
         <div slot="flowPoolUnitPrice" slot-scope="text, record">
          <span v-if="record.flowPoolUnitPrice != -1">{{record.flowPoolUnitPrice}}</span>
          <span v-else-if="record.flowPoolUnitPrice == -1">-</span>
        </div>
        <div slot="flowPoolPrice" slot-scope="text, record">
          <span  v-if="record.flowPoolPrice != -1">{{ record.flowPoolPrice}}</span>
           <span v-else-if="record.flowPoolPrice == -1">-</span>
        </div>
         <div slot="excessCost" slot-scope="text, record">
          <span v-if="record.excessCost != -1">{{record.excessCost}}</span>
          <span v-else-if="record.excessCost == -1">-</span>
        </div>
        <div slot="totalCost" slot-scope="text, record">
          <span  v-if="record.totalCost != -1">{{ record.totalCost }}</span>
           <span v-else-if="record.totalCost == -1">-</span>
        </div>
        </a-table>
      </a-config-provider>
    </div>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { errMsg, isNull, warnMsg } from "../../../utils/CommonUtils.js";
export default {
  data() {
    return {
      title: this.$t('flowPoolDetail.title'),
      locale: zhCN,
      loading:true,
      year: "",
      name: "",
      rowId: "",
      pagination: false,
      // pagination: {
      //   total: 0,
      //   pageSize: 10,
      //   current: 1,
      //   pageSizeOptions: ["10", "20", "100", "300", "500"],
      //   showSizeChanger: true,
      //   onChange: (current, pageSize) =>
      //     this.changeCurrentPage(current, pageSize),
      //   onShowSizeChange: (current, pageSize) =>
      //     this.changePageSize(current, pageSize),
      //   showTotal: () => `共${this.pagination.total}条记录`,
      // },
      datas: [],
      columns: [
         {
          title: this.$t('flowPoolDetail.columns.flowPoolName'),
          dataIndex: "flowPoolName",
          scopedSlots: { customRender: "flowPoolName" },
        },
        {
          title: this.$t('flowPoolDetail.columns.date'),
          dataIndex: "dateStr",
        },
        {
          title: this.$t('flowPoolDetail.columns.tsNum'),
          dataIndex: "tsNum",
        },
        {
          title: this.$t('flowPoolDetail.columns.flowPoolTotalFlow'),
          dataIndex: "flowPoolTotalFlow",
        },
        {
          title: this.$t('flowPoolDetail.columns.flowPoolUseFlow'),
          dataIndex: "flowPoolUseFlow",
        },
        {
          title: this.$t('flowPoolDetail.columns.flowPoolUnitPrice'),
          dataIndex: "flowPoolUnitPrice",
           scopedSlots: { customRender: "flowPoolUnitPrice" },

        },
        {
          title: this.$t('flowPoolDetail.columns.flowPoolPrice'),
          dataIndex: "flowPoolPrice",
          scopedSlots: { customRender: "flowPoolPrice" },
        },
        {
          title: this.$t('flowPoolDetail.columns.excessFlow'),
          dataIndex: "excessFlow",
        },
        {
          title: this.$t('flowPoolDetail.columns.excessPrice'),
          dataIndex: "excessPrice",
          scopedSlots: { customRender: "excessPrice" },
        },
         {
          title: this.$t('flowPoolDetail.columns.excessCost'),
          dataIndex: "excessCost",
          scopedSlots: { customRender: "excessCost" },
        },
        {
          title: this.$t('flowPoolDetail.columns.totalCost'),
          dataIndex: "totalCost",
          scopedSlots: { customRender: "totalCost" },
        },
      ],
    };
  },
  created(){
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.name = this.$route.query.name
    this.year = this.$route.query.year
    this.init()
  },
  methods: {
    // changeCurrentPage(currentPage, pageSize) {
    //   this.pagination.current = currentPage;
    //   this.pagination.pageSize = pageSize;
    //   this.rowId = "";
    // },
    // changePageSize(currentPage, pageSize) {
    //   this.pagination.pageSize = pageSize;
    //   this.pagination.current = 1;
    //   this.rowId = "";
    // },

    init() {
      if(isNull(this.year) && isNull(this.name)){
        warnMsg(this.$t('flowPoolDetail.hint.warn.getDataWarn'));
        return;
      }
      this.axios.post(this.baseUrl + "getPoolByName?name="+this.name+"&year="+this.year, "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.datas = res.data.data;
              // this.pagination.total = res.data.count
            }else if (res.data.code == 1) {
              this.datas = [];
              // this.pagination.total = 0;
            }else if (res.data.code == -1) {
              warnMsg(this.$t('flowPoolDetail.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }else if(res.data.code == -3){
              errMsg(this.$t('flowPoolDetail.hint.error.systemErr'))
            } 
          } else {
            errMsg(this.$t('flowPoolDetail.hint.warn.getDataWarn'));
          }
        }).catch(() => {
          this.loading = false;
          errMsg(this.$t('flowPoolDetail.hint.error.systemErr'));
        });
    },
    rowClassName(record, index) {
      if(index === this.rowId){
        return "select_row_bg_color";
      }else{
        return "";
      }
    },
    selectRow(record, index){
       return {
            on: {
               click: () => {
                  this.rowId = index;
               }
            }
        }
    },  
  },
};
</script>
<style scoped>
@import '../../../assets/css/table.css';

/* 操作栏 */
.operate_nav {
  margin-top: 10px;
  text-align: right;
}

.operate_nav_title {
  /* 整行标题 */
  float: left;
  font-size: 24px;
  font-weight: bold;
  color: rgb(14, 14, 14);
}
::v-deep .ant-table-small {
  border: none;
}
</style>
 