<template>
<!-- '忘记密码' 弹窗 -->
  <a-modal
    v-model="showModal"
    :title="title"
    width="30%"
    :footer="null"
    :maskClosable="false"
    :dialogStyle="{ top: '10%' }"
    @cancel="handleCancel"
  >
    <a-form-model style="width: 90%; margin: auto">
      <div class="form_item">
        <a-input
          type="text"
          v-model.trim="phone"
          autocomplete="off"
          :placeholder="$t('updatePassword.phone')"
          :maxLength="25"
          allowClear
          ><a-icon
            slot="prefix"
            type="phone"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </div>
      <div class="form_item">
        <a-input
          type="password"
          v-model.trim="newPassword"
          autocomplete="off"
          :maxLength="25"
          :placeholder="$t('updatePassword.password')"
          allowClear
          ><a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </div>

      <div class="form_item">
        <a-input
          type="password"
          v-model.trim="confirmPassword"
          autocomplete="off"
          :maxLength="25"
          :placeholder="$t('updatePassword.passwordMsg')"
          allowClear
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </div>
      <div class="form_item" style="display: flex">
        <a-input
          v-model.trim="verify"
          autocomplete="off"
          allowClear
          style="width: 50%"
          :maxLength="4"
          :placeholder="$t('updatePassword.verify')"
        >
        </a-input>
        <span
          style="margin-left: 15px"
          :title="$t('updatePassword.refreshVerifyCode')"
          @click.prevent="refreshVefifyCode"
        >
          <img
            :src="verifyCodeImg"
            class="verify_code_img"
          />
        </span>
      </div>
         <div class="form_item" style="display: flex">
        <a-input
          v-model.trim="verifyCode"
          autocomplete="off"
          allowClear
          style="width: 50%"
          :placeholder="$t('updatePassword.verifyCode')"
          :maxLength="6"
          ><a-icon
            type="mail"
            slot="prefix"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
        <span
          :class="[
            'verify_code',
            code.hasSend || !code.canSend ? 'verify_code_count_down' : 'verify_code_btn',
          ]"
          @click="sendCode"
          >{{ code.codeMsg }}</span
        >
      </div>
      <div style="text-align: center">
        <a-button
          style="margin-right: 25px"
          type="primary"
          @click.prevent="confirmUpdatePassword"
        >{{$t('updatePassword.confirm')}}
        </a-button>
        <a-button @click.prevent="cancelUpdatePassword"> {{$t('updatePassword.cancel')}} </a-button>
      </div>
    </a-form-model>
  </a-modal>
</template>
<script>
import { isNull, errMsg, successMsg, warnMsg } from "../../utils/CommonUtils.js";
export default {
  props: ["visible"],
  data() {
    return {
      title: this.$t('updatePassword.title'),
      showModal: false,
      verifyCodeImg: "",
      sessionId: "",
      code: {
        timeInterval: "", //倒计时对象
        hasSend: false,
        canSend: false,
        codeMsg: this.$t('updatePassword.sendVerify'),
      },
      phone: "",
      newPassword: "",
      confirmPassword: "",
      verifyCode: "",
      verify: "",
    };
  },
  watch: {
    visible(val) {
      this.showModal = val;
      if (!this.showModal) {
        this.changeSendVerifyCodeContent();
        this.reset();
      }else{
        this.refreshVefifyCode();
      }
    },
    verify(newVal) {
      if(newVal.length != 4){
        this.code.canSend = false 
      }else{
        this.code.canSend = true 
      }
    },
  
  },
 
  methods: {
    reset(){
      this.phone = "";
      this.newPassword = "";
      this.confirmPassword = "";
      this.verifyCode = "";
      this.verify = "";
    },
    handleCancel() {
      this.$emit("update-password-visible", false);
    },
    cancelUpdatePassword() {
      this.$emit("update-password-visible", false);
    },
    confirmUpdatePassword() {
      var phoneReg = /^[0-9]{0,20}$/g
      var codeReg = /^[0-9a-zA-Z]{4}$/g
      if (isNull(this.phone)) {
        warnMsg(this.$t('updatePassword.hint.warn.phoneWarn1'));
        return;
      }
      if(!phoneReg.test(this.phone)){
        warnMsg(this.$t('updatePassword.hint.warn.phoneWarn2'));
        return;
      }

      if (isNull(this.newPassword)) {
        warnMsg(this.$t('updatePassword.hint.warn.passwordWarn1'));
        return;
      }

      if (isNull(this.confirmPassword)) {
        warnMsg(this.$t('updatePassword.hint.warn.confirmPasswordWarn1'));
        return;
      }
      if(this.newPassword != this.confirmPassword){
        warnMsg(this.$t('updatePassword.hint.warn.confirmPasswordWarn2'))
         return;
      }
      if(this.newPassword.length < 6){
        warnMsg(this.$t('updatePassword.hint.warn.passwordWarn2'))
        return;
      }
      if(isNull(this.verify)){
        warnMsg(this.$t('updatePassword.hint.warn.verifyWarn1'));
        return;
      }
      if(!codeReg.test(this.verify)){
        warnMsg(this.$t('updatePassword.hint.warn.verifyWarn2'))
      }
      if (isNull(this.verifyCode)) {
        warnMsg(this.$t('updatePassword.hint.warn.verifyCodeWarn'));
        return;
      }
      this.axios.post(this.baseUrl + "forgetPwd?phone="+this.phone+"&pwd="+this.newPassword+"&verifyCode="
      +this.verify+"&msgCode="+this.verifyCode, "",
        {headers: { token: sessionStorage.getItem("token"),sessionId: this.sessionId}}).then((res)=>{
        if(res.status == 200){
          if(res.data.code == 0){
            successMsg(this.$t('updatePassword.hint.success.update'))
            this.$emit("update-password-visible", false);
          }else if(res.data.code == -1 || res.data.code == 1){
            warnMsg(this.$t('updatePassword.hint.warn.updateWarn1'))
          }else if(res.data.code == 2){
            warnMsg(this.$t('updatePassword.hint.warn.updateWarn2'))
          }else if(res.data.code == 3){
            warnMsg(this.$t('updatePassword.hint.warn.updateWarn3'))
          }else if(res.data.code == -2){
            warnMsg(this.$t('updatePassword.hint.warn.updateWarn4'))
          }else if(res.data.code == -3){
            warnMsg(this.$t('updatePassword.hint.warn.updateWarn5'))
          }else if(res.data.code == -4){
            warnMsg(this.$t('updatePassword.hint.warn.updateWarn6'))
          } 
        }else{
          errMsg(this.$t('updatePassword.hint.error.sendErr'))
        }
      }).catch(()=>{
        errMsg(this.$t('updatePassword.hint.error.systemErr'))
      })  
    },
    sendCode() {
      var phoneReg = /^[0-9]{3,20}$/g
      if (isNull(this.phone)) {
         warnMsg(this.$t('updatePassword.hint.warn.phoneWarn1'));
        return;
      }
      if(!phoneReg.test(this.phone)){
        warnMsg(this.$t('updatePassword.hint.warn.phoneWarn2'));
        return;
      }
      this.axios.post(this.baseUrl + "forgetPwdGetCode?phonenumber="+this.phone, "",
        {headers: { token: sessionStorage.getItem("token") }}).then((res)=>{
        if(res.status == 200){
          if(res.data.code == 0){
            successMsg(this.$t('updatePassword.hint.success.send'))
            this.countDown();
          }else if(res.data.code == -1){
            warnMsg(this.$t('updatePassword.hint.warn.accountWarn1'))
          }else if(res.data.code == -2){
            errMsg(this.$t('updatePassword.hint.error.accountErr1'))
          }else if(res.data.code == -3){
            errMsg(this.$t('updatePassword.hint.error.sendErr'))
          }  
        }else{
          errMsg(this.$t('updatePassword.hint.error.sendErr'))
        }
      }).catch(()=>{
        errMsg(this.$t('updatePassword.hint.error.systemErr'))
      })  
    },
    countDown(){
      var i = 59;
      this.code.hasSend = true;
      this.code.codeMsg = this.$t('updatePassword.codeMsg2');
      this.code.timeInterval = setInterval(() => {
        if (i > 0) {
          this.code.codeMsg = i-- + this.$t('updatePassword.codeMsg3');
        } else {
          this.changeSendVerifyCodeContent();
        }
      }, 1000);
    },
    changeSendVerifyCodeContent() {
      this.code.codeMsg = this.$t('updatePassword.sendVerify');
      this.code.hasSend = false;
      clearInterval(this.code.timeInterval);
    },
    refreshVefifyCode() {
      //解析二进制图片流(验证码图片)
      this.axios.post(this.baseUrl + "getVerifyCode", "", { responseType: "blob" })
        .then((res) => {
          if (res.status == 200) {
            this.sessionId =  res.headers['sessionid'];
            this.verifyCodeImg = window.URL.createObjectURL(
              new Blob([res.data], { type: "image/jpeg" })
            );
          }
        });
    },
  },
};
</script>
<style scoped>
*{
  font-family: "黑体";
}
.form_item {
  margin-bottom: 20px;
}
.verify_code {
  display: inline-block;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  margin-left: 15px;
  text-align: center;
  padding: 0px 5px;
}
.verify_code:hover {
  cursor: pointer;
}
.verify_code_count_down {
  pointer-events: none;
  cursor: default;
  border: 1px solid #d9d9d9;
  color: #d9d9d9;
}

.verify_code_btn {
  border: 1px solid #d9d9d9;
}
.verify_code_btn:hover {
  color: rgb(137, 208, 255);
  border: 1px solid rgb(137, 208, 255);
}
.verify_code_btn:active {
  color: rgb(66, 178, 252);
}

.verify_code_img{
    vertical-align: top;
    padding-top: 1px;
    width: 120px;
    height: 38px;
}

.verify_code_img:hover{
  cursor: pointer;
}
</style>