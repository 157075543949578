<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="68%"
    :footer="null"
    :maskClosable="false"
    :dialogStyle="{ top: '10%' }"
    @cancel="handleCancel"
  >
     <a-table
      :loading="loading"
      :columns="showColumns"
      :data-source="datas"
      :pagination="pagination"
      :rowKey="(record, i) => {return i;}"
      style="height: 355px; overflow-y: auto"
      size="small"
    >
      <div slot="operation" slot-scope="text, record">
        <div v-if="record.flowStatus == 1">
          <span @click="addFlowPacet(record)" class="addBtn">{{$t('masterFlowPacket.addSubaccount')}}</span>
        </div>
      </div>
    <div slot="flowStatus" slot-scope="text, record">
        <span v-if="record.flowStatus == 1">{{$t('masterFlowPacket.notAdded')}}</span>
        <span v-else-if="record.flowStatus == 2">{{$t('masterFlowPacket.deactivate')}}</span>
        <span v-else>{{$t('masterFlowPacket.enable')}}</span>
    </div>
    </a-table>
  </a-modal>
</template>
<script>
import { errMsg, successMsg, warnMsg } from "../../../utils/CommonUtils";
export default {
  props: ["visible", "id"],
  data() {
    return {
      title: this.$t('masterFlowPacket.title'),
      showModal: false,
      loading:true,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (current, pageSize) =>
          this.changeCurrentPage(current, pageSize),
          showTotal: () => {
              const count = this.pagination.total;
              return this.$i18n.t('masterFlowPacket.totalRecords', { count });
          },
      },
      datas: [],
      showColumns: [
        {
          title: this.$t('masterFlowPacket.columns.country'),
          dataIndex: "country",
          width: 120,
        },
        {
          title: this.$t('masterFlowPacket.columns.showname'),
          dataIndex: "showName",
          width: 120,
        },
        {
          title: this.$t('masterFlowPacket.columns.price'),
          dataIndex: "price",
          width: 100,
        },
        {
          title: this.$t('masterFlowPacket.columns.status'),
          dataIndex: "flowStatus",
          width: 100,
          scopedSlots: { customRender: "flowStatus" },
        },
        {
          title: this.$t('masterFlowPacket.columns.explain'),
          dataIndex: "explain",
          ellipsis: true,
        },
        {
          title: this.$t('masterFlowPacket.columns.operation'),
          dataIndex: "operation",
           width: 120,
          scopedSlots: { customRender: "operation" },
        },
      ],
    };
  },
  watch: {
    visible(val) {
      this.showModal = val;
      if (this.showModal) {
        this.init();
      }
    },
  },
  methods: {
    addFlowPacet(record) {
       let param =  {
           user: this.id,
           priceId: record.packetid,
           attachid: record.accathid,
           type: 1,
       }
      this.axios.post(this.baseUrl + "subAccountAddFlowPacet", param, {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              successMsg(this.$t('masterFlowPacket.hint.success.add'));
              this.init();
            } else if (res.data.code == -1) {
              warnMsg(this.$t('masterFlowPacket.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -2) {
              warnMsg(this.$t('masterFlowPacket.hint.warn.getAccountWarn'));
            }else if (res.data.code == -3) {
              errMsg(this.$t('masterFlowPacket.hint.error.addErr'));
            }
          } else {
            errMsg(this.$t('masterFlowPacket.hint.error.addErr'));
          }
        }).catch(() => {
          errMsg(this.$t('masterFlowPacket.hint.error.systemErr'));
        });
    },
    //改变页码调用此函数
    changeCurrentPage(currentPage) {
      this.pagination.current = currentPage;
    },
    init() {
      this.axios.post(this.baseUrl + "addFlowPackage?sonId=" + this.id, "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.datas = res.data.data;
              this.pagination.total = res.data.count;
            }else if(res.data.code == -1){
              warnMsg(this.$t('masterFlowPacket.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }
          } else {
            errMsg(this.$t('masterFlowPacket.hint.error.getDataErr'));
          }
        }).catch(() => {
          this.loading = false;
          errMsg(this.$t('masterFlowPacket.hint.error.systemErr'));
        });
    },
    handleCancel() {
      this.$emit("master-flow-packet-visible", false);
    },
  },
};
</script>
<style scoped>

.addBtn{
  border: 1px solid rgb(218, 218, 218);
  padding: 0.5px 4px;
  border-radius: 3px;
}
.addBtn:hover{
  cursor: pointer;
  border: 1px solid rgb(108, 108, 255);
  color: rgb(108, 108, 255);
}

::v-deep .ant-modal-body{
  padding: 15px 24px 5px;
}
/* 表头样式 */
::v-deep .ant-table-thead > tr > th {
  background-color: white;
  padding: 12px 8px 3px !important;
}
/**表记录样式 */
::v-deep .ant-table-tbody > tr > td {
  padding: 2px 8px !important;
}
::v-deep .ant-table-small {
  border: none;
}
/* 分页位置 */
::v-deep .ant-table-pagination.ant-pagination {
  float: none;
  text-align: center;
}
</style>