<template>
  <div>
    <div class="operate_nav">
      <span class="operate_nav_title">{{ title }}</span>
      <span
        v-if="$store.state.role != 1"
        class="operate_nav_btn_frame"
        @click.prevent="showButton"
      >
        <span>
          <a-icon type="bars" /><span style="margin-left: 3px">{{$t('device.deviceManage.button.operation')}} </span>
        </span>
      </span>
      <span :class="['operate_nav_btn_frame', hasFilter ? 'search_Filter' : '']"
        @click.prevent="showSearch"
      >
        <span>
          <a-icon type="search" /><span style="margin-left: 3px">{{$t('device.deviceManage.button.search')}} </span>
        </span>
      </span>
      <span class="operate_nav_btn_frame" @click.prevent="exportData">
        <a-icon type="download" />
        {{$t('device.deviceManage.button.report')}}
      </span>
      <span class="operate_nav_btn_frame">
        <a-dropdown :trigger="['click']">
          <span style="display: inline-block; width: 100%; height: 100%">
            <a-icon type="filter" /><span style="margin-left: 3px"
              >{{$t('device.deviceManage.button.filter')}}
            </span>
          </span>
          <a-menu slot="overlay">
            <a-checkbox-group
              name="checkboxgroup"
              :options="options"
              :value="optionValues"
              @change="onChange"
            />
          </a-menu>
        </a-dropdown>
      </span>
      <export-tips
        v-if="tips"
        :total="pagination.total"
        @export-tips-visible="changeShowExportTips"
      ></export-tips>
    </div>
    <div :class="[search.visible ? 'search_show' : 'search_hide']">
      <div class="search_line_one_group search_div">
        <div style="width: 33.3%; display: flex">
          <span class="search_item_label">{{$t('device.deviceManage.searchList.group')}}</span>
          <a-select
            class="iot_search_item"
            :placeholder="$t('device.deviceManage.selectMsg')"
            allowClear
            v-model="search.group"
          >
            <a-select-option
              v-for="(groupName, i) in groups"
              :value="i"
              :key="i"
              >{{ groupName }}</a-select-option
            >
          </a-select>
        </div>
        <div style="width: 33.3%">
          <span class="search_item_label">{{$t('device.deviceManage.searchList.status')}}</span>
          <a-select
            class="iot_search_item"
            :placeholder="$t('device.deviceManage.selectMsg')"
            allowClear
            v-model="search.status"
          >
            <a-select-option value="0"> {{$t('device.deviceManage.normal')}} </a-select-option>
            <a-select-option value="2"> {{$t('device.deviceManage.deactivate')}} </a-select-option>
          </a-select>
        </div>
        <div style="width: 33.3%">
          <span class="search_item_label">{{$t('device.deviceManage.searchList.account')}}</span>
          <a-tree-select
            allowClear
            class="iot_search_item"
            v-model.trim="search.account"
            :tree-data="search.treeData"
            :maxTagCount="1"
            :treeCheckStrictly="true"
            tree-checkable
            :placeholder="$t('device.deviceManage.searchList.searchAccountMsg')"
          >
          </a-tree-select>
        </div>
      </div>
      <div class="search_line_one_group search_div">
        <div style="width: 33.3%; display: flex">
          <span class="search_item_label">{{$t('device.deviceManage.searchList.model')}}</span>
          <a-input
            class="iot_search_item"
            allowClear
            v-model.trim="search.model"
            :placeholder="$t('device.deviceManage.searchList.inputModelMsg')"
            autocomplete="off"
          />
        </div>
        <div style="width: 33.3%">
          <span class="search_item_label">{{$t('device.deviceManage.searchList.batch')}}</span>
          <a-input
            class="iot_search_item"
            allowClear
            v-model.trim="search.batch"
            :placeholder="$t('device.deviceManage.searchList.inputBatchMsg')"
            autocomplete="off"
          />
        </div>
        <div style="width: 33.3%">
          <span class="search_item_label">{{$t('device.deviceManage.searchList.version')}}</span>
          <a-input
            class="iot_search_item"
            allowClear
            v-model.trim="search.version"
            :placeholder="$t('device.deviceManage.searchList.inputVersionMsg')"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="search_div">
        <span class="search_item_label">{{$t('device.deviceManage.searchList.tsid')}}</span>
        <a-textarea
          style="margin-left: 6px"
          v-model.trim="search.tsid"
          :placeholder="$t('device.deviceManage.searchList.inputTsidMsg')"
          autocomplete="off"
        />
      </div>
      <div class="search_div">
        <span class="search_item_label">{{$t('device.deviceManage.searchList.imei')}}</span>
        <a-textarea
          style="margin-left: 6px"
          v-model.trim="search.imei"
          :placeholder="$t('device.deviceManage.searchList.inputImeiMsg')"
          autocomplete="off"
        />
      </div>

      <div class="search_div">
        <span class="search_item_label">{{$t('device.deviceManage.searchList.note')}}</span>
        <a-textarea
          style="margin-left: 6px"
          v-model.trim="search.note"
          :placeholder="$t('device.deviceManage.searchList.inputNoteMsg')"
          autocomplete="off"
        />
      </div>
      <div style="text-align: right">
        <span class="search_item_btn cursor_attr" @click.prevent="searchConfirm"
          >{{$t('device.deviceManage.searchList.search')}}</span
        >
        <span class="search_item_btn cursor_attr" @click.prevent="searchReset"
          >{{$t('device.deviceManage.searchList.reset')}}</span
        >
      </div>
    </div>
    <div style="clear: both"
      :class="[button.visible ? 'search_show' : 'search_hide']"
    >
      <span
        v-if="isParentAcount == 0"
        class="iot_operate_btn"
        @click.prevent="showModal('deviceAuth')"
        >{{$t('device.deviceManage.deviceAuth')}}</span
      >
      <span class="iot_operate_btn" @click.prevent="showModal('batchGroup')"
        >{{$t('device.deviceManage.batchGroup')}}</span
      >
      <span class="iot_operate_btn" @click.prevent="showModal('buyPacket')"
        >{{$t('device.deviceManage.buyPacket')}}</span
      >
      <span class="iot_operate_btn" @click.prevent="showModal('autoSubscribe')"
        >{{$t('device.deviceManage.autoSubscribe')}}</span
      >
      <span class="iot_operate_btn"
        @click.prevent="showModal('cancelSubscribe')"
        >{{$t('device.deviceManage.cancelSubscribe')}}</span
      >
      <span class="iot_operate_btn" @click.prevent="showModal('updateStatus')"
        >{{$t('device.deviceManage.updateStatus')}}</span
      >
      <span class="iot_operate_btn" @click.prevent="showModal('updateNote')"
        >{{$t('device.deviceManage.updateNote')}}</span
      >

      <auto-subscribe
        v-if="visible.autoSubscribe"
        :selectedRow="selectedRow"
        :visibleGroup="visible.autoSubscribeGroup"
        @auto-subscribe-visible="changeShowAutoSubscribe"
      >
      </auto-subscribe>
      <cancel-subscribe
        :visible="visible.cancelSubscribe"
        :visibleGroup="visible.cancelSubscribeGroup"
        :selectedRow="selectedRow"
        @cancel-subscribe-visible="changeShowCancelSubscribe"
      ></cancel-subscribe>
      <buy-packet
        v-if="visible.buyPacket"
        :visibleGroup="visible.buyPacketGroup"
        :selectedRow="selectedRow"
        @buy-packet-visible="changeShowBuyPacket"
      ></buy-packet>
      <batch-group
        :visible="visible.batchGroup"
        :selectedRow="selectedRow"
        @batch-group-visible="changeShowBatchGroup"
      ></batch-group>

      <update-note
        :visible="visible.updateNote"
        :selectedRow="selectedRow"
        @update-note-visible="changeShowUpdateNote"
      ></update-note>

      <update-status
        :visible="visible.updateStatus"
        :selectedRow="selectedRow"
        @update-status-visible="changeShowUpdateStatus"
      ></update-status>

      <device-auth
        v-if="visible.deviceAuth"
        :selectedRow="selectedRow"
        @device-auth-visible="changeShowDeviceAuth"
      ></device-auth>
    </div>
    <div
      style="
        text-align: right;
        display: flex;
        border-bottom: 1px solid rgb(235, 235, 235);
        margin-top: 8px;
        font-weight: 550;
        padding-bottom: 4px;
      "
    >
      <div style="margin-right: 30px">
        <span style="color: #6f6f6fa6; font-weight: normal">{{$t('device.deviceManage.totalDevice')}}&nbsp;&nbsp;&nbsp;</span>
        <span style="color: rgb(51, 51, 51)">{{ totalDevice }}{{$t('device.deviceManage.table')}}</span>
      </div>
      <div>
        <span style="color: #6f6f6fa6; font-weight: normal">{{$t('device.deviceManage.myDevice')}}&nbsp;&nbsp;&nbsp;</span>
        <span style="color: rgb(51, 51, 51)">{{ myDevice }}{{$t('device.deviceManage.table')}}</span>
      </div>
      <div style="margin-left: 25px">
        <a-checkbox
          style="color: #6f6f6fa6; font-weight: normal"
          v-model="deviceChecked"
          @change="selectMyDevice"
          >{{$t('device.deviceManage.ownDevice')}}</a-checkbox
        >
      </div>
    </div>
    <div style="clear: both">
      <a-config-provider >
        <a-table
          :loading="loading"
          :bordered="false"
          :row-selection="rowSelection"
          :pagination="pagination"
          :columns="showColumns"
          :data-source="datas"
          :rowClassName="rowClassName"
          :customRow="selectRow"
          :rowKey="(record, i) => {return i;}"
          size="small"
          :scroll="scrollX ? { x: true } : { x: 0 }"
        >
          <div slot="tsid" slot-scope="text, record">
            <a @click="showDayCost(record)">{{ record.tsid }} </a>
          </div>
          <div slot="status" slot-scope="text, record">
            <span v-if="record.status == 0">{{$t('device.deviceManage.normal')}}</span>
            <span v-else-if="record.status == 2">{{$t('device.deviceManage.deactivate')}}</span>
          </div>
          <div slot="autoRenewal" slot-scope="text, record">
            <span v-if="record.autoRenewal == 0">{{$t('device.deviceManage.yes')}}</span>
            <span v-else-if="record.autoRenewal == 1">{{$t('device.deviceManage.no')}}</span>
          </div>
          <div slot="remark" slot-scope="text, record">
            <span class="remark_hide" :title="record.remark">{{record.remark}}</span>
          </div>
          <div slot="operation" slot-scope="text, record">
            <div>
              <span class="row_record_btn_operate cursor_attr" :title="$t('device.deviceManage.operation')">
                <a-dropdown :trigger="['click']">
                  <a-icon type="ellipsis" />
                  <a-menu slot="overlay">
                    <a-menu-item @click="showDetail(record)">
                      {{$t('device.deviceManage.details')}}
                    </a-menu-item>
                    <a-menu-item @click="showHistory(record)">
                      {{$t('device.deviceManage.purchasedPackage')}}
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </span>
            </div>
          </div>
        </a-table>
      </a-config-provider>
      <device-detail
        v-if="visible.detail"
        :record="params.record"
        @detail-visible="changeShowDetail"
      >
      </device-detail>
      <history-packet
        v-if="visible.history"
        :tsid="params.tsid"
        @history-visible="changeShowHistory"
      >
      </history-packet>
    </div>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import exportTips from "../../dialog/exportTipsModal.vue";
import deviceAuth from "../../dialog/device/deviceAuthModal.vue";
import updateStatus from "../../dialog/device/updateStatusModal.vue";
import updateNote from "../../dialog/device/updateNoteModal.vue";
import batchGroup from "../../dialog/device/BatchGroupModal.vue";
import deviceDetail from "../../dialog/device/detailModal.vue";
import historyPacket from "../../dialog/device/purchasedPacketModal.vue";
import autoSubscribe from "../../dialog/device/autoSubscribeModal.vue";
import cancelSubscribe from "../../dialog/device/cancelSubscribeModal.vue";
import buyPacket from "../../dialog/device/buyPacketModal.vue";
import { isNull, warnMsg, errMsg } from "../../../utils/CommonUtils.js";
export default {
  components: {
    exportTips,
    deviceDetail,
    historyPacket,
    autoSubscribe,
    cancelSubscribe,
    buyPacket,
    batchGroup,
    updateNote,
    updateStatus,
    deviceAuth,
  },
  data() {
    return {
      title: this.$t('device.deviceManage.title'),
      locale: zhCN,
      tips: false,
      isParentAcount: "" /** 是否是主账号 */,
      rowId: "",
      deviceChecked: false,
      hasFilter: false /** 是否有搜索条件 */,
      loading: true,
      totalDevice: 0,
      myDevice: 0,
      selectedRow: [] /**选中记录*/,
      selectedRowKeys: [] /**选中记录的key*/,
      scrollX: false /**表格是否出现滚动条*/,
      params: {
        tsid: "",
        record: {},
      },
      pagination: {
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeOptions: ["10", "20", "100", "300", "500"],
        showSizeChanger: true,
        onChange: (current, pageSize) =>
          this.changeCurrentPage(current, pageSize),
        onShowSizeChange: (current, pageSize) =>
          this.changePageSize(current, pageSize),
          showTotal: () => {
              const count = this.pagination.total;
              return this.$i18n.t('device.deviceManage.totalRecords', { count });
          },
      },
      visible: {
        detail: false,
        history: false,
        autoSubscribe: false,
        cancelSubscribe: false,
        cancelSubscribeGroup: false,
        buyPacket: false,
        buyPacketGroup: false,
        autoSubscribeGroup: false,
        batchGroup: false,
        updateNote: false,
        updateStatus: false,
        deviceAuth: false,
      },
      search: {
        status: undefined /** 注意：用v-model双向绑定的情况下，不能是 "" ,双引号情况下，输入框会是空白，没有提示文字。 */,
        model: "",
        batch: "", //生产批次
        version: "",
        imei: "",
        tsid: "",
        account: [],
        treeData: [],
        group: [],
        note: "",
        visible: false,
      },
      button: {
        visible: false,
      },
      groups: [],
      datas: [],
      showColumns: [
        {
          title: this.$t('device.deviceManage.columns.tsid'),
          dataIndex: "tsid", /**对应后台响应的字段名称 */
          scopedSlots: { customRender: "tsid" }, /**给这列绑定自定义的显示方式 */
          width: 120,
        },
        {
          title: this.$t('device.deviceManage.columns.imei'),
          dataIndex: "imei",
          width: 140,
        },
        {
          title: this.$t('device.deviceManage.columns.belong'),
          dataIndex: "belong",
          ellipsis: true,
          width: 110,
        },
        {
          title: this.$t('device.deviceManage.columns.bissgroup'),
          dataIndex: "bissgroup",
          ellipsis: true,
          width: 120,
        },
        {
          title: this.$t('device.deviceManage.columns.status'),
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          width: 80,
        },
        {
          title: this.$t('device.deviceManage.columns.autoRenewal'),
          dataIndex: "autoRenewal",
          scopedSlots: { customRender: "autoRenewal" },
          width: 80,
        },
        {
          title: this.$t('device.deviceManage.columns.usePackage'),
          dataIndex: "usePackage",
          ellipsis: true, /**隐藏超过长度的内容 */
          width: 100,
        },
        {
          title: this.$t('device.deviceManage.columns.expireTime'),
          dataIndex: "expireTime",
          width: 150,
        },
        {
          title: this.$t('device.deviceManage.columns.remark'),
          dataIndex: "remark",
          width: 140,
          scopedSlots: { customRender: "remark" },
        },
        {
          title: this.$t('device.deviceManage.columns.operation'),
          dataIndex: "operation",
          fixed: "right", /**固定在表格的右侧,不随滚动条而滚动 */
          scopedSlots: { customRender: "operation" },
          width: 70,
        },
      ] /** 默认显示的列 */,
      columns: [
        {
          title: this.$t('device.deviceManage.columns.tsid'),
          dataIndex: "tsid",
          width: 120,
          scopedSlots: { customRender: "tsid" },
        },
        {
          title: this.$t('device.deviceManage.columns.imei'),
          dataIndex: "imei",
          width: 140,
        },
        {
          title: this.$t('device.deviceManage.columns.belong'),
          dataIndex: "belong",
          width: 110,
        },
        {
          title: this.$t('device.deviceManage.columns.bissgroup'),
          dataIndex: "bissgroup",
          width: 100,
        },
        {
          title: this.$t('device.deviceManage.columns.status'),
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          width: 80,
        },
        {
          title: this.$t('device.deviceManage.columns.autoRenewal'),
          dataIndex: "autoRenewal",
          scopedSlots: { customRender: "autoRenewal" },
          width: 80,
        },
        {
          title: this.$t('device.deviceManage.columns.usePackage'),
          dataIndex: "usePackage",
          ellipsis: true,
          width: 100,
        },
        {
          title: this.$t('device.deviceManage.columns.expireTime'),
          dataIndex: "expireTime",
          width: 150,
        },
        {
          title: this.$t('device.deviceManage.columns.model'),
          dataIndex: "model",
          width: 120,
        },
        {
          title: this.$t('device.deviceManage.columns.version'),
          dataIndex: "version",
          width: 120,
        },
        {
          title: this.$t('device.deviceManage.columns.remark'),
          dataIndex: "remark",
          width: 150,
          scopedSlots: { customRender: "remark" },
        },
        {
          title: this.$t('device.deviceManage.columns.operation'),
          dataIndex: "operation",
          fixed: "right",
          scopedSlots: { customRender: "operation" },
          width: 60,
        },
      ] /** 所有可以显示的列 */,
      options: [
        { label: this.$t('device.deviceManage.columns.tsid'), value: "tsid" },
        { label: this.$t('device.deviceManage.columns.imei'), value: "imei" },
        { label: this.$t('device.deviceManage.columns.belong'), value: "belong" },
        { label: this.$t('device.deviceManage.columns.bissgroup'), value: "bissgroup" },
        { label: this.$t('device.deviceManage.columns.status'), value: "status" },
        { label: this.$t('device.deviceManage.columns.autoRenewal'), value: "autoRenewal" },
        { label: this.$t('device.deviceManage.columns.usePackage'), value: "usePackaget" },
        { label: this.$t('device.deviceManage.columns.expireTime'), value: "expireTime" },
        { label: this.$t('device.deviceManage.columns.model'), value: "model" },
        { label: this.$t('device.deviceManage.columns.version'), value: "version" },
        { label: this.$t('device.deviceManage.columns.remark'), value: "remark" },
      ],/**默认展示列中所有值*/
      optionValues: [
        "tsid",
        "imei",
        "belong",
        "status",
        "autoRenewal",
        "usePackaget",
        "expireTime",
        "bissgroup",
        "remark",
      ] /**默认展示列选中的值*/,
    };
  },
  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    let temStatus = this.$route.params.status;
    if (!isNull(temStatus)) {
      this.search.status = temStatus;
    }
    this.getAccount();
    if (
      !isNull(this.search.status) ||
      !isNull(this.search.model) ||
      !isNull(this.search.batch) ||
      !isNull(this.search.imei) ||
      !isNull(this.search.tsid) ||
      !isNull(this.search.account) ||
      !isNull(this.search.group) ||
      !isNull(this.search.note) ||
      !isNull(this.search.version)
    ) {
      this.hasFilter = true;
    }
    this.isParentAcount = sessionStorage.getItem("main");
    this.init();
  },
  computed: {
    /** 控制表中多选框的选中，清除。 */
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        fixed: true,
        columnWidth: 60,
        selectedRowKeys, // 一定要加上这一行代码，清除才会有作用
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          this.selectedRow = selectedRows;
        },
      };
    },
  },
  methods: {
    /** 切换页码 */
    changeCurrentPage(currentPage, pageSize) {
      this.pagination.current = currentPage;
      this.pagination.pageSize = pageSize;
      this.rowId = "";
      if (this.deviceChecked) {
        this.changeDeviceChecked();
      } else {
        this.init();
      }
    },
    /** 改变每页显示记录数 */
    changePageSize(currentPage, pageSize) {
      this.pagination.pageSize = pageSize;
      this.pagination.current = 1;
      this.rowId = "";
      if (this.deviceChecked) {
        this.changeDeviceChecked();
      } else {
        this.init();
      }
    },
    /** 详情弹框回调 */
    changeShowDetail(val) {
      this.visible.detail = val;
    },
    /** 已购套餐弹框回调 */
    changeShowHistory(val) {
      this.visible.history = val;
    },
    /** 自动续订权弹框回调 */
    changeShowAutoSubscribe(val, flag) {
      this.visible.autoSubscribe = val;
      if (flag == 1) {
        this.rowId = "";
        this.init();
      }
    },
    /** 取消订购弹框回调 */
    changeShowCancelSubscribe(val, flag) {
      this.visible.cancelSubscribe = val;
      if (flag == 1) {
        this.rowId = "";
        this.init();
      }
    },
    /** 终端套餐订购弹框回调 */
    changeShowBuyPacket(val, flag) {
      this.visible.buyPacket = val;
      if (flag == 1) {
        this.rowId = "";
        this.init();
      }
    },
    /** 终端批量分组弹框回调 */
    changeShowBatchGroup(val, flag) {
      this.visible.batchGroup = val;
      if (flag == 1) {
        this.rowId = "";
        this.init();
        this.getGroup(); 
      }
    },
    /** 终端备注修改弹框回调 */
    changeShowUpdateNote(val, flag) {
      this.visible.updateNote = val;
      if (flag == 1) {
        this.rowId = "";
        this.init();
      }
    },
    /** 终端状态修改弹框回调 */
    changeShowUpdateStatus(val, flag) {
      this.visible.updateStatus = val;
      if (flag == 1) {
        this.rowId = "";
        this.init();
      }
    },
    /** 终端授权弹框回调 */
    changeShowDeviceAuth(val, flag) {
      this.visible.deviceAuth = val;
      if (flag == 1) {
        this.rowId = "";
        this.init();
      }
    },
    /** 导出提示弹框回调 */
    changeShowExportTips(show, flag, val) {
      this.tips = show;
      if (flag == 1) {
        this.exportReq(val);
      }
    },
    /** 导出记录，超过一万条时，提示导出记录区间 */
    exportData() {
      if (isNull(this.datas)) {
        warnMsg(this.$t('device.deviceManage.hint.warn.exportWarn'));
        return;
      }
      if (this.pagination.total > 10000) {
        this.tips = true;
      } else {
        this.exportReq(null);
      }
    },
    exportReq(val) {
      let param = this.initParam();
      if (!isNull(val)) {
        param.page = val;
      } else {
        param.page = 1;
      }
      this.axios.post(this.baseUrl + "exportDeviceInfo", param, {
          responseType: "blob",
          headers: { token: sessionStorage.getItem("token"),
          'Accept-Language': localStorage.getItem('locale')},
        }).then((res) => {
          if (res.status == 200) {
            let link = document.createElement("a");
            let url = window.URL.createObjectURL(
              new Blob([res.data], {
                type: "application/vnd.ms-excel;charset=UTF-8;",
              })
            );
            link.style.display = "none";
            link.href = url;
            let fileName = window.decodeURI(
              res.headers["content-disposition"].split("=")[1],
              "UTF-8"
            );//获取下载的文件名称
            link.setAttribute("download", fileName); //设置下载的文件名称
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); /**下载完成移除元素*/
            window.URL.revokeObjectURL(url); /**释放掉blob对象*/
          }
        }).catch(() => {
          errMsg(this.$t('device.deviceManage.hint.error.systemErr'));
        });
    },
    /** 获取归属账号信息 */
    getAccount() {
      this.axios.post(this.baseUrl + "getAllAccount", "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 1 || res.data.code == 0) {
              this.search.treeData = res.data.data;
            } else if (res.data.code == -1) {
              warnMsg(this.$t('device.deviceManage.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -2) {
              errMsg(this.$t('device.deviceManage.hint.error.systemErr'));
            }
          }
        }).catch(() => {
          errMsg(this.$t('device.deviceManage.hint.error.systemErr'));
        });
    },
    /** 获取分组信息 */
    getGroup() {
      this.axios.post(this.baseUrl + "getDeviceGroup", "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.groups = res.data.data;
            } else if (res.data.code == -1) {
              warnMsg(this.$t('device.deviceManage.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -2) {
              errMsg(this.$t('device.deviceManage.hint.error.systemErr'));
            }
          }
        }).catch(() => {
          errMsg(this.$t('device.deviceManage.hint.error.systemErr'));
        });
    },
    /** 页面初始化数据 */
    init() {
      this.selectedRowKeys = [];
      this.selectedRow = [];
      //this.initParam()的位置传的是json格式参数，单个参数不能放在这里
      this.axios.post(this.baseUrl + "getDeviceInfo", this.initParam(), {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.totalDevice = res.data.all;
              this.myDevice = res.data.self;
              this.pagination.total = res.data.count;
              this.datas = res.data.data;
            } else if (res.data.code == -1) {
              warnMsg(this.$t('device.deviceManage.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -2) {
              warnMsg(this.$t('device.deviceManage.hint.warn.retryWarn'));
            } else if (res.data.code == -3) {
              errMsg(this.$t('device.deviceManage.hint.error.systemErr'));
            }
          } else {
            errMsg(this.$t('device.deviceManage.hint.error.getDataErr'));
          }
        }).catch(() => {
          this.loading = false;
          errMsg(this.$t('device.deviceManage.hint.error.systemErr'));
        });
    },
    initParam() {
      let belong = "";
      if (!isNull(this.search.account)) {
        this.search.account.forEach((item) => {
          belong = belong + item.value + ",";
        });
        belong = belong.substring(0, belong.lastIndexOf(","));
      }
      let params = {
        tsid: this.search.tsid,
        imei: this.search.imei,
        belong: belong,
        bissgroup: this.groups[this.search.group],
        remark: this.search.note,
        version: this.search.version,
        model: this.search.model,
        batch: this.search.batch,
        dstatus: isNull(this.search.status) ? "" : this.search.status,
        page: this.pagination.current,
        limit: this.pagination.pageSize,

      };
      return params;
    },
    /** 跳转到终端日消费 */
    showDayCost(record) {
      let d = new Date();
      let time = d.getFullYear() + "-" + ((d.getMonth() + 1) > 9 ? (d.getMonth() + 1): ("0" + (d.getMonth() + 1)));
      let newUrl = this.$router.resolve({
        path: "/device/dayCost",
        query:{
          tsid: record.tsid,
          date: time,
        }
      });
      window.open(newUrl.href, "_blank");
    },
    /** 自有终端 */
    selectMyDevice(e) {
      this.loading = true;
      if (e.target.checked) {
        this.pagination.current = 1;
        this.hasFilter = true;
        this.rowId = "";
        this.changeDeviceChecked();
      } else {
        this.deviceChecked = false;
        this.searchReset();
        this.pagination.current = 1;
        this.pagination.pageSize = 10;
        this.rowId = "";
        this.init();
      }
    },
    changeDeviceChecked() {
      this.selectedRowKeys = [];
      this.selectedRow = [];
      this.deviceChecked = true;
      this.search.account = this.search.treeData.length >= 1 ? [{ title: this.search.treeData[0].title,
                key: this.search.treeData[0].value,
                value: this.search.treeData[0].value, },]: "";
      let params = {
        tsid: "",
        imei: "",
        belong: this.search.treeData.length >= 1 ? this.search.treeData[0].value : "",
        bissgroup: "",
        remark: "",
        dstatus: "",
        page: this.pagination.current,
        limit: this.pagination.pageSize,
      };
      this.axios.post(this.baseUrl + "getDeviceInfo", params, {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.totalDevice = res.data.all;
              this.myDevice = res.data.self;
              this.pagination.total = res.data.count;
              this.datas = res.data.data;
            } else if (res.data.code == -1) {
              warnMsg(this.$t('device.deviceManage.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -2) {
              warnMsg(this.$t('device.deviceManage.hint.warn.retryWarn'));
            }
          } else {
            errMsg(this.$t('device.deviceManage.hint.error.getDataErr'));
          }
        }).catch(() => {
          this.loading = false;
          errMsg(this.$t('device.deviceManage.hint.error.systemErr'));
        });
    },
    /** 展示列操作(修改要显示的列) */
    onChange(checkedValues) {
      if (checkedValues.length > 0) {
        this.optionValues = checkedValues;
        var showCol = new Array();
        checkedValues.forEach((val) => {
          var res = this.columns.find((item) => item.dataIndex == val);
          if (!isNull(res)) {
            showCol.push(res);
          }
        });
        showCol.push(this.columns[this.columns.length - 1]);
        if (showCol.length > 9) {
          this.scrollX = true;
        } else {
          this.scrollX = false;
        }
        this.showColumns = showCol;
      } else {
        warnMsg(this.$t('device.deviceManage.hint.warn.selectorColumnWarn'));
      }
    },
    /** 确认搜索 */
    searchConfirm() {
      /**替换字符串中的空格*/
      this.loading = true;
      this.deviceChecked = false;
      this.search.tsid = this.search.tsid.replace(/\s+|[\r\n]+/g, "");
      this.search.imei = this.search.imei.replace(/\s+|[\r\n]+/g, "");
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.rowId = "";
      if (
        !isNull(this.search.status) ||
        !isNull(this.search.model) ||
        !isNull(this.search.batch) ||
        !isNull(this.search.imei) ||
        !isNull(this.search.tsid) ||
        !isNull(this.search.account) ||
        !isNull(this.search.group) ||
        !isNull(this.search.note) ||
        !isNull(this.search.version)
      ) {
        this.hasFilter = true;
      } else {
        this.hasFilter = false;
      }
      this.init();
    },
    /** 搜索的字段重置 */
    searchReset() {
      this.search.status = undefined;
      this.search.model = "";
      this.search.version = "";
      this.search.batch = "";
      this.search.imei = "";
      this.search.tsid = "";
      this.search.account = [];
      this.search.group = [];
      this.search.note = "";
      this.hasFilter = false;
    },
    /** 是否显示操作按钮下的 终端授权，批量分组，套餐订购等按钮 */
    showButton() {
      if (this.button.visible) {
        this.button.visible = false;
      } else {
        this.button.visible = true;
      }
    },
    /** 显示详情弹框 */
    showDetail(record) {
      this.params.record = record;
      this.visible.detail = true;
    },
    /** 显示已购套餐弹框 */
    showHistory(record) {
      this.params.tsid = record.tsid;
      this.visible.history = true;
    },
    /** 显示终端授权，批量分组等操作之后的弹框 */
    showModal(flag) {
      if (flag == "buyPacket") {
        /**套餐订购*/
        if (this.selectedRow.length > 0) {
          this.visible.buyPacketGroup = false;
        } else {
          this.visible.buyPacketGroup = true;
        }
        this.visible.buyPacket = true;
      } else if (flag == "autoSubscribe") {
        /**自动续订*/
        if (this.selectedRow.length > 0) {
          this.visible.autoSubscribeGroup = false;
        } else {
          this.visible.autoSubscribeGroup = true;
        }
        this.visible.autoSubscribe = true;
      } else if (flag == "cancelSubscribe") {
        /**取消订购*/
        if (this.selectedRow.length > 0) {
          this.visible.cancelSubscribeGroup = false;
        } else {
          this.visible.cancelSubscribeGroup = true;
        }
        this.visible.cancelSubscribe = true;
      } else if (flag == "deviceAuth") {
        /**终端授权*/
        if (this.selectedRow.length == 0) {
          warnMsg(this.$t('device.deviceManage.hint.warn.deviceAuthWarn'));
          return;
        }
        this.visible.deviceAuth = true;
      } else if (flag == "batchGroup") {
        /**终端分组*/
        if (this.selectedRow.length == 0) {
          warnMsg(this.$t('device.deviceManage.hint.warn.batchGroupWarn'));
          return;
        }
        this.visible.batchGroup = true;
      } else if (flag == "updateNote") {
        /**修改备注*/
        if (this.selectedRow.length == 0) {
          warnMsg(this.$t('device.deviceManage.hint.warn.updateNoteWarn'));
          return;
        }
        this.visible.updateNote = true;
      } else if (flag == "updateStatus") {
        /**修改状态*/
        if (this.selectedRow.length == 0) {
          warnMsg(this.$t('device.deviceManage.hint.warn.updateStatusWarn'));
          return;
        }
        this.visible.updateStatus = true;
      }
    },
    /** 控制搜索栏的显示 */
    showSearch() {
      if (this.search.visible) {
        this.search.visible = false;
      } else {
        this.getGroup();
        this.search.visible = true;
      }
    },
    /** 给被点击的行记录添加class属性值 */
    rowClassName(record, index) {
      if (index === this.rowId) {
        return "select_row_bg_color"; /** 返回的是类名，在table.css文件中有 */
      } else {
        return "";
      }
    },
    /** 给行记录添加点击事件 */
    selectRow(record, index) {
      return {
        on: {
          click: () => {
            this.rowId = index;
          },
        },
      };
    },
  },
};
</script>
<style scoped>
@import "../../../assets/css/table.css";
/* 操作栏 */
.operate_nav {
  margin-top: 10px;
  text-align: right;
}

.operate_nav_title {
  /* 整行标题 */
  float: left;
  font-size: 24px;
  font-weight: bold;
  color: rgb(14, 14, 14);
}

.operate_nav_btn_frame {
  margin: 5px 0px;
  display: inline-block;
  text-align: center;
  border: 1px solid rgb(160, 160, 160);
  height: 30px;
  line-height: 30px;
  margin-left: 15px;
  border-radius: 5px;
  min-width: 70px;
}

.operate_nav_btn_frame:hover {
  cursor: pointer;
  color: rgb(78, 78, 255);
  border: 1px solid rgb(78, 78, 255);
}

/*搜索框 */
.search_line_one_group {
  justify-content: space-between;
  align-items: center;
}

.search_div {
  display: flex;
  margin-bottom: 10px;
  align-items: flex-end;
}
.iot_search_item {
  width: calc(100% - 80px);
}

.search_item_btn {
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 10px;
  width: 50px;
  height: 30px;
  line-height: 30px;
  border: 1px solid gray;
  text-align: center;
  border-radius: 5px;
}
.search_item_btn:hover {
  color: rgb(78, 78, 255);
  border: 1px solid rgb(78, 78, 255);
}

.search_item_label {
  display: inline-block;
  width: 80px;
  text-align: right;
  height: 32px;
  line-height: 32px;
}

.search_show {
  display: block;
  text-align: left;
  margin: 20px 0px 5px;
}
.search_hide {
  display: none;
}

.search_Filter {
  background-color: #1bb1f6;
  color: #fff;
}
.search_Filter:hover {
  border: 1px solid #fff;
  color: #fff !important;
}

/** 操作按钮 */
.iot_operate_btn {
  display: inline-block;
  min-width: 80px;
  height: 32px;
  line-height: 32px;
  margin-left: 23px;
  border: 1px solid rgb(185, 185, 185);
  text-align: center;
  border-radius: 3px;
  color: rgb(75, 75, 75);
}
.iot_operate_btn:hover {
  cursor: pointer;
  border: 1px solid #40a9ff;
  color: #40a9ff;
}
.iot_operate_btn:active {
  border: 1px solid #007be0;
  color: #007be0;
}

/* 记录操作 */
.row_record_btn_operate {
  display: block;
  float: right;
  margin-right: 5px;
}

.remark_hide {
  display: block;
  width: 150px;
  word-break: keep-all; /* 不换行 */
  white-space: nowrap; /* 不换行 */
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .ant-table-small {
  border: none;
}

.cursor_attr:hover {
  cursor: pointer;
}

.ant-checkbox-group-item {
  display: flex;
  margin-right: 8px;
  flex-direction: row;
  align-items: center;
}
</style>
 