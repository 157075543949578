<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="450px"
    :footer="null"
    :closable="false"
    :maskClosable="false"
    :dialogStyle="{ top: '23%' }"
  >
    <div class="popdiv_paycnt">
      <div class="dd">
        <img src="../../../assets/img/pay_icon70.png" />
      </div>
      <div class="dt">
        <p>
          支付完成前，请不要关闭此支付验证窗口<br />
          支付完成后，请根据您的情况点击下面按钮
        </p>
        <p>
          <span class="btn_grey">
            <a class="btn_grey115_26 cursor_attr" @click.prevent="verifyRecharge">支付遇到问题</a>
          </span>
          <a class="paydone cursor_attr" @click.prevent="verifyRecharge">支付完成</a>
        </p>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { warnMsg, errMsg } from "../../../utils/CommonUtils.js";
export default {
  props: ["visible", "recharge", "payMethod"],
  data() {
    return {
      title: "网络支付提示",
      showModal: false,
      hits: 0, //按钮被点击次数
    };
  },
  watch: {
    /** 通过监视visible值变化来控制弹框显示，注意：有部分弹框控制方式和这里不一样。 */
    visible(val) {
      this.showModal = val;
      if(!this.showModal){
        this.hits = 0
      }
    },
  },

methods: {
    /** 验证用户充值是否成功 */
    verifyRecharge(){
      if(this.hits == 0){
        this.hits = 1;
        let paymethod = this.payMethod == 1 ? "pay_alipay" : this.payMethod == 2 ? "pay_wxpay" : this.payMethod == 3 ? "pay_bank" : "";
        this.axios.post(this.baseUrl +"recharge_result?ordernumflag=" + this.recharge.ordernumflag + "&date=" + 
              this.recharge.date + "&amount=" + this.recharge.amount + "&givemoney=" + this.recharge.givemoney + "&bankdetailid=" + 
              "&paymethod=" + paymethod, "", { headers: { token: sessionStorage.getItem("token") } }
          ).then((res) => {
            if (res.status == 200) {
              if(res.data.code == 0){
                warnMsg("充值成功")
                this.$emit("pay-prompt-visible", false);  
              }else if(res.data.code == -1){
                  warnMsg("充值失败")
                  this.$emit("pay-prompt-visible", false);  
              }
            } else {
              warnMsg("充值失败");
              this.$emit("pay-prompt-visible", false);  
            }
          }).catch(() => {
            errMsg("系统异常,请联系客服");
            this.$emit("pay-prompt-visible", false);  
          });
      }else{
        warnMsg("验证中，请稍等...");
      } 
    }
  },
};
</script>
<style scoped>
.popdiv_paycnt {
  display: flex;
  justify-content: space-around;
}

.popdiv_paycnt .dt {
  float: left;
  font-size: 14px;
  padding-top: 10px;
}
.paydone {
  width: 115px;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  display: inline-block;
  font-family: microsoft yahei;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #d65c15;
  color: #fff !important;
  text-decoration: none;
  background: #ec6517;
  margin-left: 10px;
}

.btn_grey a.btn_grey115_26 {
  width: 115px;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
}

.btn_grey a {
  display: inline-block;
  font-family: microsoft yahei;
  text-align: center;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -ms-border-radius: 5px;
  margin-left: 5px;
  background: #f3f3f3;
  border: 1px solid #c6c6c6;
  color: #666666 !important;
}
.cursor_attr:hover{
  cursor: pointer;
}
</style>