<template>
  <div>
    <div class="operate_nav">
      <span class="operate_nav_title">{{ title }}</span>
      <span
        v-if="$store.state.role != 1"
        class="operate_nav_btn_frame"
        @click.prevent="batchOpen"
      >
        <a-icon type="check" />
        {{$t('deviceOrder.button.check')}}
      </span>
      <span
        v-if="$store.state.role != 1"
        class="operate_nav_btn_frame"
        @click.prevent="batchClose"
      >
        <a-icon type="close" />
        {{$t('deviceOrder.button.close')}}
      </span>
      <span class="operate_nav_btn_frame" @click.prevent="showSearch">
        <span>
          <a-icon type="search" /><span style="margin-left: 3px">{{$t('deviceOrder.button.search')}} </span>
        </span>
      </span>
      <span class="operate_nav_btn_frame" @click.prevent="exportData">
        <a-icon type="download" />
        {{$t('deviceOrder.button.report')}}
      </span>
      <span class="operate_nav_btn_frame">
        <a-dropdown :trigger="['click']">
          <span style="display: inline-block; width: 100%; height: 100%">
            <a-icon type="filter" /><span style="margin-left: 3px"
              >{{$t('deviceOrder.button.filter')}}
            </span>
          </span>
          <a-menu slot="overlay">
            <a-checkbox-group
              name="checkboxgroup"
              :options="options"
              :value="optionValues"
              @change="onChange"
            />
          </a-menu>
        </a-dropdown>
      </span>
      <export-tips
        v-if="tips"
        :total="pagination.total"
        @export-tips-visible="changeShowExportTips"
      ></export-tips>
    </div>
    <div :class="[search.visible ? 'search_show' : 'search_hide']">
      <div class="search_line_one_group search_div">
        <div style="width: 25%; display: flex;align-items: flex-end;">
          <span class="search_item_label">{{$t('deviceOrder.searchList.order')}}</span>
          <a-input
            allowClear
            class="iot_search_item"
            v-model.trim="search.order"
            :placeholder="$t('deviceOrder.searchList.orderMsg')"
            autocomplete="off"
          />
        </div>
        <div style="width: 25%">
          <span class="search_item_label">{{$t('deviceOrder.searchList.country')}}</span>
          <a-select
            allowClear
            class="iot_search_item"
            show-search
            v-model="search.country"
            option-filter-prop="children"
            :filter-option="filterOption"
            @change="changeCountry"
            :placeholder="$t('deviceOrder.searchList.countryMsg')"
          >
            <a-select-option
              v-for="item in countrys"
              :value="item.code"
              :key="item.code"
            >
              {{ item.country }}
            </a-select-option>
          </a-select>
        </div>
        <div style="width: 25%">
          <span class="search_item_label">{{$t('deviceOrder.searchList.orderTime')}}</span>
          <a-config-provider >
            <a-range-picker
              allowClear
              v-model="search.orderTime"
              class="iot_search_item"
              :placeholder="[$t('deviceOrder.searchList.placeholder')[0], $t('deviceOrder.searchList.placeholder')[1]]"
            />
          </a-config-provider>
        </div>
        <div style="width: 25%">
          <span class="search_item_label">{{$t('deviceOrder.searchList.startTime')}}</span>
          <a-config-provider >
            <a-range-picker
              allowClear
              v-model="search.startTime"
              class="iot_search_item"
              :placeholder="[$t('deviceOrder.searchList.placeholder')[0], $t('deviceOrder.searchList.placeholder')[1]]"
            />
          </a-config-provider>
        </div>
      </div>
      <div class="search_div">
        <span class="search_item_label">{{$t('deviceOrder.searchList.tsid')}}</span>
        <a-textarea
          style="margin-left: 6px"
          v-model.trim="search.tsid"
          :placeholder="$t('deviceOrder.searchList.tsidMsg')"
          autocomplete="off"
        />
      </div>
      <span class="search_item_btn" @click.prevent="searchConfirm">{{$t('deviceOrder.button.inquire')}}</span>
    </div>
    <div>
      <div class="filter_nav">
        <span
          v-for="menu in menus"
          :class="[
            'filter_item',
            selectMenu == menu.id ? 'filter_item_select' : '',
          ]"
          :key="menu.id"
          @click="changeSelected(menu.id)"
        >
          {{ menu.text }}
        </span>
      </div>
    </div>
    <div>
      <a-config-provider >
        <a-table
          :loading="loading"
          :columns="showColumns"
          :row-selection="rowSelection"
          :data-source="datas"
          :pagination="pagination"
          :rowClassName="rowClassName"
          :customRow="selectRow"
          :rowKey="
            (record, i) => {
              return i;
            }
          "
          :scroll="scrollX ? { x: true } : { x: 0 }"
          size="small"
        >
          <div slot="tsid" slot-scope="text, record">
            <a @click="showDayCost(record)">{{ record.tsid }} </a>
          </div>
          <div slot="payment" slot-scope="text, record">
            <span v-if="record.userid == 100">{{$t('deviceOrder.system')}}</span>
            <span v-else>{{ record.payment }}</span>
          </div>
          <div slot="homeAccount" slot-scope="text, record">
            <span v-if="record.userid == 100">{{$t('deviceOrder.system')}}</span>
            <span v-else>{{ record.homeAccount }}</span>
          </div>
          <div slot="price" slot-scope="text, record">
            <span v-if="record.price != -1">{{ record.price }}</span>
            <span v-else>-</span>
          </div>
          <div
            v-if="$store.state.role != 1"
            slot="operation"
            slot-scope="text, record"
          >
            <div v-if="record.status != 2">
              <span class="row_record_btn_operate" :title="$t('deviceOrder.columns.operation')">
                <a-dropdown :trigger="['click']">
                  <a-icon type="ellipsis" />
                  <a-menu
                    style="width: 70px; text-align: center"
                    slot="overlay"
                  >
                    <a-menu-item v-if="!isDisabled(record)" disabled>
                      <span v-if="record.status == 1"> {{$t('deviceOrder.button.check')}} </span>
                      <span v-if="record.status == 0"> {{$t('deviceOrder.button.close')}} </span>
                    </a-menu-item>
                    <a-menu-item v-else>
                      <span
                        @click.prevent="updateStatus(record.id, 0)"
                        v-if="record.status == 1"
                      >
                        {{$t('deviceOrder.button.check')}}
                      </span>
                      <span
                        @click.prevent="updateStatus(record.id, 1)"
                        v-if="record.status == 0"
                      >
                        {{$t('deviceOrder.button.close')}}
                      </span>
                    </a-menu-item>
                    <a-menu-item
                      v-if="showUnsubscribe(record)"
                      @click="showConfirm(record.order)"
                      >{{$t('deviceOrder.button.unsubscribe')}}</a-menu-item
                    >
                    <a-menu-item
                      v-if="showRefund(record)"
                      @click="refund(record)"
                      >{{$t('deviceOrder.button.refund')}}
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </span>
            </div>
          </div>
        </a-table>
      </a-config-provider>
    </div>
    <refund-modal
      v-if="visible.refund"
      :record="visible.record"
      @refund-visible="changeShowRefundModal"
    >
    </refund-modal>
  </div>
</template>
<script>
import moment from "moment";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import exportTips from "../../dialog/exportTipsModal.vue";
import {
  warnMsg,
  isNull,
  errMsg,
  successMsg,
} from "../../../utils/CommonUtils";
import refundModal from "../../dialog/device/refundModal.vue";
export default {
  components: { refundModal, exportTips },
  data() {
    return {
      title: this.$t('deviceOrder.title'),
      locale: zhCN,
      loading: true,
      rowId: "",
      date: "",
      scrollX: false,
      tips: false,
      countrys: [],
      visible: {
        refund: false,
        record: "",
      },
      search: {
        order: "",
        tsid: "",
        country: undefined,
        orderTime: undefined, //这里用undefined的原因：如果是空字符串 '' 的话,提示信息就不会显示
        startTime: undefined,
        status: "",
        visible: false,
      },
      selectedRow: [] /**选中记录*/,
      selectedRowKeys: [] /**选中记录的key*/,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "100", "300", "500"],
        showSizeChanger: true,
        onChange: (current, pageSize) =>
          this.changeCurrentPage(current, pageSize),
        onShowSizeChange: (current, pageSize) =>
          this.changePageSize(current, pageSize),
          showTotal: () => {
              const count = this.pagination.total;
              return this.$i18n.t('deviceOrder.totalRecords', { count });
          },
      },
      datas: [],
      showColumns: [
        {
          title: this.$t('deviceOrder.columns.tsid'),
          dataIndex: "tsid",
          width: 100,
          scopedSlots: { customRender: "tsid" },
        },
        {
          title: this.$t('deviceOrder.columns.order'),
          dataIndex: "order",
          width: 165,
        },
        {
          title: this.$t('deviceOrder.showcolumns.flow'),
          dataIndex: "flow",
          width: 100,
        },
        {
          title: this.$t('deviceOrder.showcolumns.used'),
          dataIndex: "used",
          width: 100,
        },
        {
          title: this.$t('deviceOrder.showcolumns.price'),
          dataIndex: "price",
          width: 100,
          scopedSlots: { customRender: "price" },
        },
        {
          title: this.$t('deviceOrder.columns.countryName'),
          dataIndex: "countryName",
          width: 100,
        },
        {
          title: this.$t('deviceOrder.columns.orderStatus'),
          dataIndex: "orderstatus",
          width: 80,
        },
        {
          title: this.$t('deviceOrder.columns.begin'),
          dataIndex: "begin",
          width: 160,
        },
        {
          title: this.$t('deviceOrder.columns.payment'),
          dataIndex: "payment",
          width: 120,
          scopedSlots: { customRender: "payment" },
        },
        {
          title: this.$t('deviceOrder.columns.operation'),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          width: 60,
        },
      ] /** 默认显示的列 */,
      columns: [
        {
          title: this.$t('deviceOrder.columns.tsid'),
          dataIndex: "tsid",
          width: 100,
          scopedSlots: { customRender: "tsid" },
        },
        {
          title: this.$t('deviceOrder.columns.order'),
          dataIndex: "order",
          width: 165,
        },
        {
          title: this.$t('deviceOrder.columns.flow'),
          dataIndex: "flow",
          width: 100,
        },
        {
          title: this.$t('deviceOrder.columns.used'),
          dataIndex: "used",
          width: 100,
        },
        {
          title: this.$t('deviceOrder.columns.price'),
          dataIndex: "price",
          width: 100,
          scopedSlots: { customRender: "price" },
        },
        {
          title: this.$t('deviceOrder.columns.countryName'),
          dataIndex: "countryName",
          width: 100,
        },
        {
          title: this.$t('deviceOrder.columns.orderStatus'),
          dataIndex: "orderstatus",
          width: 80,
        },
        {
          title: this.$t('deviceOrder.columns.subtime'),
          dataIndex: "subtime",
          width: 160,
        },
        {
          title: this.$t('deviceOrder.columns.begin'),
          dataIndex: "begin",
          width: 160,
        },
        {
          title: this.$t('deviceOrder.columns.end'),
          dataIndex: "end",
          width: 150,
        },
        {
          title: this.$t('deviceOrder.columns.payment'),
          dataIndex: "payment",
          width: 100,
          scopedSlots: { customRender: "payment" },
        },
        {
          title: this.$t('deviceOrder.columns.homeAccount'),
          dataIndex: "homeAccount",
          width: 100,
          scopedSlots: { customRender: "homeAccount" },
        },
        {
          title: this.$t('deviceOrder.columns.belongSystem'),
          dataIndex: "belongSystem",
          width: 100,
        },
        {
          title: this.$t('deviceOrder.columns.note'),
          dataIndex: "note",
          width: 160,
        },
        {
          title: this.$t('deviceOrder.columns.operation'),
          dataIndex: "operation",
          fixed: "right",
          scopedSlots: { customRender: "operation" },
          width: 60,
        },
      ] /** 所有可以显示的列 */,
      menus: [
        {
          id: 1,
          text: this.$t('deviceOrder.menus.allOrder'),
        },
        {
          id: 2,
          text: this.$t('deviceOrder.menus.effectiveOrder'),
        },
        {
          id: 3,
          text: this.$t('deviceOrder.menus.deactivateOrder'),
        },
        {
          id: 4,
          text: this.$t('deviceOrder.menus.unsubscribeOrder'),
        },
      ],
      selectMenu: 1,
      //筛选列时展示数据多选框列表
      options: [
        { label: this.$t('deviceOrder.columns.tsid'), value: "tsid" },
        { label: this.$t('deviceOrder.columns.order'), value: "order" },
        { label: this.$t('deviceOrder.columns.flow'), value: "flow" },
        { label: this.$t('deviceOrder.columns.used'), value: "used" },
        { label: this.$t('deviceOrder.columns.price'), value: "price" },
        { label: this.$t('deviceOrder.columns.countryName'), value: "countryName" },
        { label: this.$t('deviceOrder.columns.orderStatus'), value: "orderstatus" },
        { label: this.$t('deviceOrder.columns.subtime'), value: "subtime" },
        { label: this.$t('deviceOrder.columns.begin'), value: "begin" },
        { label: this.$t('deviceOrder.columns.end'), value: "end" },
        { label: this.$t('deviceOrder.columns.payment'), value: "payment" },
        { label: this.$t('deviceOrder.columns.homeAccount'), value: "homeAccount" },
        { label: this.$t('deviceOrder.columns.belongSystem'), value: "belongSystem" },
        { label: this.$t('deviceOrder.columns.note'), value: "note" },
      ],
      optionValues: [
        "tsid",   
        "order",
        "flow",
        "used",
        "price",
        "countryName",
        "orderstatus",
        "begin",
        "payment"
      ],
    };
  },
  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.date = new Date().getTime();
    let param = this.$route.params.args;
    if(!isNull(param)){
      let menu = param.menu;
      if(menu == 99){
        //countrys数组的code是string类型，所以这里也要是string类型搜索框才能选上
        this.search.country = param.countryCode  + "";
        this.setStartTime(param.date); 
      }else if(menu == 100){
        this.search.order = param.bankOrder
      }else if(menu == 106){
        this.search.country = param.zoneCode + "";
        this.setStartTime(param.date);
      }
    }
    
    this.init();
  },
  computed: {
    /** 控制表中多选框的选中，清除。 */
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        fixed: true,
        columnWidth: 70,
        selectedRowKeys, // 一定要加上这一行代码，清除才会有作用
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          this.selectedRow = selectedRows;
        },
      };
    },
  },
  methods: {
    /** 批量启用 */
    batchOpen() {
      if (this.selectedRow.length == 0) {
        warnMsg(this.$t('deviceOrder.hint.warn.selectedRowWarn1'));
        return;
      }
      if (this.selectedRow.length >= 100) {
        warnMsg(this.$t('deviceOrder.hint.warn.selectedRowWarn2'));
        return;
      }
      var count = 0;
      let now = new Date().getTime();
      for (let i = 0; i < this.selectedRow.length; i++) {
        if (
          !isNull(this.selectedRow[i].end) &&
          now > this.getTime(this.selectedRow[i].end)
        ) {
          count = count + 1;
        }
      }
      if (count > 0) {
        warnMsg(this.$t('deviceOrder.hint.warn.checkWarn'));
        return;
      }
      var ids = null;
      for (let i = 0; i < this.selectedRow.length; i++) {
        ids = ids + this.selectedRow[i].id + ",";
      }
      ids = ids.substring(0, ids.lastIndexOf(","));
      this.updateStatus(ids, 0);
    },
    /** 批量停用 */
    batchClose() {
      if (this.selectedRow.length == 0) {
        warnMsg(this.$t('deviceOrder.hint.warn.selectedRowWarn1'));
        return;
      }
      if (this.selectedRow.length >= 100) {
        warnMsg(this.$t('deviceOrder.hint.warn.selectedRowWarn2'));
        return;
      }
      var count = 0;
      let now = new Date().getTime();
      for (let i = 0; i < this.selectedRow.length; i++) {
        if (
          !isNull(this.selectedRow[i].end) &&
          now > this.getTime(this.selectedRow[i].end)
        ) {
          count = count + 1;
        }
      }
      if (count > 0) {
        warnMsg(this.$t('deviceOrder.hint.warn.closeWarn'));
        return;
      }
      var ids = null;
      for (let i = 0; i < this.selectedRow.length; i++) {
        ids = ids + this.selectedRow[i].id + ",";
      }
      ids = ids.substring(0, ids.lastIndexOf(","));
      this.updateStatus(ids, 1);
    },
    /** 全部订单，生效订单等调用 */
    changeSelected(val) {
      this.loading = true;
      this.selectMenu = val;
      this.initPagination();
      if (val == 2) {
        this.status = 0;
      } else if (val == 3) {
        this.status = 1;
      } else if (val == 4) {
        this.status = 2;
      } else {
        this.status = "";
      }
      this.rowId = "";
      this.init();
    },
    /** 切换页码 */
    changeCurrentPage(currentPage, pageSize) {
      this.loading = true;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pageSize;
      this.rowId = "";
      this.init();
    },
    /** 改变每页显示记录数 */
    changePageSize(currentPage, pageSize) {
      this.loading = true;
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.rowId = "";
      this.init();
    },
    /** 记录搜索框中最新被选中的国家 */
    changeCountry(value) {
      this.search.country = value;
    },

    changeShowRefundModal(val, flag) {
      this.visible.refund = val;
      if (flag == 1) {
        this.rowId = "";
        this.init();
      }
    },

    /** 导出提示弹框回调 */
    changeShowExportTips(show, flag, val) {
      this.tips = show;
      if (flag == 1) {
        this.exportReq(val);
      }
    },

    /** 导出，超过10000条记录会提示 */
    exportData() {
      if (isNull(this.datas)) {
        warnMsg(this.$t('deviceOrder.hint.warn.reportWarn'));
        return;
      }
      if (this.pagination.total > 10000) {
        this.tips = true;
      } else {
        this.exportReq(null);
      }
    },
    exportReq(val) {
      let param = this.initParam();
      if (!isNull(val)) {
        param.pageNo = val;
      } else {
        param.pageNo = 1;
      }
      this.axios.post(this.baseUrl + "exporFlowPackage", param, {
          responseType: "blob",
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            let link = document.createElement("a");
            let url = window.URL.createObjectURL(
              new Blob([res.data], {
                type: "application/vnd.ms-excel;charset=UTF-8;",
              })
            );
            link.style.display = "none";
            link.href = url;
            let fileName = window.decodeURI(
              res.headers["content-disposition"].split("=")[1],
              "UTF-8"
            );
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); //下载完成移除元素
            window.URL.revokeObjectURL(url); //释放掉blob对象
          }
        }).catch(() => {
          errMsg(this.$t('deviceOrder.hint.error.systemErr'));
        });
    },
    /** 搜索栏国家字段的过滤(手动输入值自动匹配符合条件的结果) */
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },

    initPagination() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
    },

    init() {
      this.selectedRowKeys = [];
      this.selectedRow = [];
      this.axios.post(this.baseUrl + "getFlowPackages", this.initParam(), {
          headers: { token: sessionStorage.getItem("token"),
          'Accept-Language': localStorage.getItem('locale') },
        }).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.pagination.total = res.data.count;
              this.datas = res.data.data;
            } else if (res.data.code == 1) {
              this.pagination.total = 0;
              this.datas = [];
            } else if (res.data.code == -1) {
              warnMsg(this.$t('deviceOrder.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -2) {
              warnMsg(this.$t('deviceOrder.hint.warn.retryWarn'));
            } else if (res.data.code == -3) {
              errMsg(this.$t('deviceOrder.hint.error.systemErr'));
            }
          } else {
            errMsg(this.$t('deviceOrder.hint.error.getDataErr'));
          }
        }).catch(() => {
          this.loading = false;
          errMsg(this.$t('deviceOrder.hint.error.systemErr'));
        });
    },

    initParam() {
      let begin = null,  subtime = null;
      if (!isNull(this.search.startTime)) {
        begin =  this.search.startTime[0].format("YYYY-MM-DD") + "/" +
          this.search.startTime[1].format("YYYY-MM-DD");
      }

      if (!isNull(this.search.orderTime)) {
        subtime = this.search.orderTime[0].format("YYYY-MM-DD") +  "/" +
          this.search.orderTime[1].format("YYYY-MM-DD");
      }
      // 所有请求参数都是空时，给个默认时间
      if (
        isNull(this.search.status) &&
        isNull(this.search.country) &&
        isNull(this.search.order) &&
        isNull(this.search.tsid) &&
        isNull(this.search.startTime) &&
        isNull(this.search.orderTime)
      ) {
        var arr = this.getlastMonth();
        begin = arr[0] + "/" + arr[1];
        this.search.startTime = [
          moment(new Date(arr[0]), "yyyy-mm-dd"),
          moment(new Date(arr[1]), "yyyy-mm-dd"),
        ];
      }

      let param = {
        tsid: this.search.tsid,
        order: this.search.order,
        country: this.search.country,
        subtime: subtime,
        begin: begin,
        status: this.status,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
      };
      return param;
    },

    /** 控制操作栏启用、停用显示 */
    isDisabled(record) {
      if (isNull(record.end)) {
        return true;
      } else {
        let et = this.getTime(record.end);
        if (this.date > et) {
          return false;
        } else {
          return true;
        }
      }
    },

    /** 获取一个月前和当前日期 */
    getlastMonth() {
      var date = new Date();
      date.setDate(date.getDate() - 30); //往前推30天
      var month = date.getMonth() + 1;
      var fullyear =
        date.getFullYear() + "-" + (month > 9 ? month : "0" + month) + "-" + date.getDate();

      var date2 = new Date();
      var month2 = date2.getMonth() + 1;
      var fullyear2 = date2.getFullYear() + "-" + (month2 > 9 ? month2 : "0" + month2) + "-" + date2.getDate();
      var arr = [];
      arr[0] = fullyear;
      arr[1] = fullyear2;
      return arr;
    },

    getTime(time) {
      time = time.substring(0, 19);
      time = time.replace(/-/g, "/");
      let et = new Date(time).getTime();
      return et;
    },
    /** 展示列 */
    onChange(checkedValues) {
      /**checkedValues: 选中的所有值*/
      if (checkedValues.length > 0) {
        this.optionValues = checkedValues;
        var showCol = new Array();
        checkedValues.forEach((val) => {
          var res = this.columns.find((item) => item.dataIndex == val);
          if (!isNull(res)) {
            showCol.push(res);
          }
        });
        showCol.push(this.columns[this.columns.length - 1]);
        if (showCol.length > 10) {
          /**显示x轴滚动条*/
          this.scrollX = true;
        } else {
          this.scrollX = false;
        }
        this.showColumns = showCol;
      } else {
        warnMsg(this.$t('deviceOrder.hint.warn.selectorColumnWarn'));
      }
    },

    /** 打开退款弹框界面 */
    refund(record) {
      this.visible.record = record;
      this.visible.refund = true;
    },
    /** 搜索栏的打开和关闭 */
    showSearch() {
      if (this.search.visible) {
        this.search.visible = false;
      } else {
        this.search.visible = true;
        if (this.countrys.length == 0) {
          this.axios.post(this.baseUrl + "getCountryList", "", {
              headers: { token: sessionStorage.getItem("token"),
              'Accept-Language': localStorage.getItem('locale') },
            }).then((res) => {
              if (res.status == 200) {
                let dataArr = res.data.data;
                for (let i = 0; i < dataArr.length; i++) {
                  var json = dataArr[i];
                  var key = "key" + i;
                  var country = {
                    code: json[key].split("-")[0],
                    country: json[key].split("-")[1],
                  };
                  this.countrys.push(country);
                }
              }
            });
        }
      }
    },
    /** 搜索确认 */
    searchConfirm() {
      /** 删除tsid之间的空格换行等 */
      this.search.tsid = this.search.tsid.replace(/\s+|[\r\n]+/g, "");
      this.loading = true;
      this.initPagination();
      this.rowId = "";
      this.init();
    },

    /** 判断是否显示退订按钮 */
    showUnsubscribe(record) {
      if (record.userid == 100) { //系统赠送流量包不可以退订
        return false;
      }
      let today = new Date();
      today.setHours(23);
      today.setMinutes(59);
      today.setSeconds(59);
      today = today.getTime(); /** 当天的23:59:59 */

      let date = record.begin;
      date = date.substring(0, 19);
      date = date.replace(/-/g, "/");
      let st = new Date(date).getTime(); /** 订单开始时间 */

      let date1 = record.end;
      date1 = date1.substring(0, 19);
      date1 = date1.replace(/-/g, "/");
      let et = new Date(date1).getTime(); /** 订单结束时间 */

      /** 状态  -1 未支付  0-正常  1-已停用 2-已退订, -2 订单已拆分, -3 待付款 */
      if ((record.status == 0 || record.status == 1) &&
        ((record.begin == "" && record.end == "") || (st > today && et > st))
      ) {
        return true;
      } else {
        return false;
      }
    },
    /** 点击退订按钮后，提示是否继续操作 */
    showConfirm(orderNum) {
      var currentObj = this; //解决this指向问题，因为在onOk()函数内部中，this对象获取不到。使用this来调用方法会报错. 
      this.$modal.confirm({
        content: this.$t('deviceOrder.unsubscribePacket'),
        okText: this.$t('deviceOrder.ok'),
        cancelText: this.$t('deviceOrder.cancle'),
        onOk() {
          currentObj.axios.post(currentObj.baseUrl + "detail_cancel_order?orderNum=" + orderNum, "",
              { headers: { token: sessionStorage.getItem("token") } }
            ).then((res) => {
              if (res.status == 200) {
                if (res.data.code == 0) {
                  successMsg(this.$t('deviceOrder.hint.success.unsubscribe'));
                  currentObj.rowId = "";
                  currentObj.init();
                } else if (res.data.code == -1) {
                  warnMsg(this.$t('deviceOrder.hint.warn.loginWarn'));
                  setTimeout(() => {
                    currentObj.$router.push({ name: "login" });
                  }, "2000");
                } else if (res.data.code == -3) {
                  warnMsg(this.$t('deviceOrder.hint.warn.packetWarn'));
                } else if (res.data.code == -4) {
                  warnMsg(this.$t('deviceOrder.hint.warn.unsubscribeWarn'));
                } else if (res.data.code == -7) {
                  errMsg(this.$t('deviceOrder.hint.error.systemErr'));
                }
              } else {
                warnMsg(this.$t('deviceOrder.hint.warn.getDataWarn'));
              }
            }).catch(() => {
              errMsg(this.$t('deviceOrder.hint.error.systemErr'));
            });
        },
        onCancel() {
          warnMsg(currentObj.$t('deviceOrder.hint.warn.cancelWarn'));
        },
      });
    },
    /** 判断是否显示退款按钮 */
    showRefund(row) {
      if (row.order.indexOf("PC") > -1) {
        return false;
      }

      /** 赠送流量包不能退款 */
      if (row.userid == 100) {  
        return false;
      }

      /** 套餐开始时间和结束时间有一个为空就不显示退款 */
      if (isNull(row.begin) || isNull(row.end)) {
        return false;
      }

      var status = row.status; /** 订单状态 */
      var refund = row.after_refund; /** 是否退过款   0或空-没有退过   1-退过款 */
      var end = new Date(row.end);

      /** 状态  -1 未支付  0-正常  1-已停用 2-已退订, -2 订单已拆分, -3 待付款 */
      var now_end = Math.floor((new Date() - end) / 24 / 60 / 60 / 1000); /** 当前时间与订单结束时间的差值 */
      if (refund == 0 || !refund) {
        if (now_end >= 1 && now_end <= 10) {   /** 订单结束十天内，且状态为未退订才显示退款按钮 */
          if (status == 0 || status == 1) {
            return true;
          } else if (status == 2) {
            return false;
          }
        } else {
          return false;
        }
      } else if (refund == 1) { /** 已申请过退款 */
        return false;
      } else {
        return false;
      }
    },
    /** 更新状态 */
    updateStatus(order, type) {
      if (isNull(order) || (type != 0 && type != 1)) {
        return;
      }
      this.axios.post(this.baseUrl + "changeOrderStatus?order=" + order + "&type=" + type, "",
          { headers: { token: sessionStorage.getItem("token") } }
        ).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              successMsg(this.$t('deviceOrder.hint.success.updateStatus'));
              this.rowId = "";
              this.init();
            } else if (res.data.code == -1) {
              warnMsg(this.$t('deviceOrder.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == 2) {
              warnMsg(this.$t('deviceOrder.hint.warn.updateStatusWarn1'));
            } else if (res.data.code == -7) {
              errMsg(this.$t('deviceOrder.hint.error.systemErr'));
            } else if (res.data.code == -3) {
              warnMsg(this.$t('deviceOrder.hint.warn.updateStatusWarn2'));
            }
          } else {
            errMsg(this.$t('deviceOrder.hint.error.updateStatusErr'));
          }
        }).catch(() => {
          errMsg(this.$t('deviceOrder.hint.error.systemErr'));
        });
    },
    /** 给被点击的行记录添加class属性值 */
    rowClassName(record, index) {
      if (index === this.rowId) {
        return "select_row_bg_color";
      } else {
        return "";
      }
    },
    /** 给行记录添加点击事件 */
    selectRow(record, index) {
      return {
        on: {
          click: () => {
            this.rowId = index;
          },
        },
      };
    },
    /** 跳转到终端日消费 */
    showDayCost(record) {
      let d = new Date();
      let time = d.getFullYear() + "-" + (d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1));
      let newUrl = this.$router.resolve({
        path: "/device/dayCost",
        query: {
          tsid: record.tsid,
          date: time,
        },
      });
      window.open(newUrl.href, "_blank");
    },
    setStartTime(date){
      this.search.startTime = [];
      this.search.startTime[0] = moment(new Date(date + "-01"), "yyyy-mm-dd");
      let d = new Date(date + "-01");
      let d2 = new Date(d.getFullYear(), d.getMonth()+1,0);
      this.search.startTime[1] = moment(d2, "yyyy-mm-dd");
    },
  },
};
</script>
<style scoped>
@import "../../../assets/css/table.css";
/* 过滤栏 */
.filter_nav {
  margin-top: 10px;
  width: 100%;
  text-align: left;
  padding: 0;
  height: 30px;
  border-bottom: 1px solid rgb(172, 172, 172);
}
.filter_item {
  margin-right: 20px;
  display: inline-block;
  height: 30px;
}
.filter_item {
  cursor: pointer;
}
.filter_item_select {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
/* 操作栏 */
.operate_nav {
  margin-top: 10px;
  text-align: right;
}
.operate_nav_title {
  /* 整行标题 */
  float: left;
  font-size: 24px;
  font-weight: bold;
  color: rgb(14, 14, 14);
}

.operate_nav_btn_frame {
  margin: 7px 0px 0px;
  display: inline-block;
  text-align: center;
  min-width: 70px;
  border: 1px solid rgb(160, 160, 160);
  height: 30px;
  line-height: 30px;
  margin-left: 15px;
  border-radius: 5px;
}

.operate_nav_btn_frame:hover {
  cursor: pointer;
  color: rgb(78, 78, 255);
  border: 1px solid rgb(78, 78, 255);
}

/*搜索框 */
.search_div {
  display: flex;
  margin-bottom: 10px;
}
.search_line_one_group {
  justify-content: space-between;
  /* align-items: center; */
}
.iot_search_item {
  width: calc(100% - 80px);
}

.search_item_btn {
  float: right;
  display: block;
  width: 50px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  border: 1px solid gray;
  text-align: center;
}

.search_item_btn:hover {
  cursor: pointer;
  color: rgb(78, 78, 255);
  border: 1px solid rgb(78, 78, 255);
}

.search_item_label {
  display: inline-block;
  /* height: 32px; */
  line-height: 32px;
  width: 80px;
  text-align: right;
}

.search_show {
  display: block;
  text-align: left;
  margin: 20px 0px 35px;
  transition: all 3s ease-in;
}

.search_hide {
  display: none;
  transition: all 3s ease-out;
}

/* 记录操作 */
.row_record_btn_operate {
  display: block;
  text-align: center;
}
.row_record_btn_operate:hover {
  cursor: pointer;
}
/* 筛选框样式修改 */
::v-deep .ant-checkbox-group {
  margin: 8px 15px !important;
  width: 120px;
}
::v-deep .ant-checkbox-group-item {
  display: block;
}
::v-deep .ant-table-small {
  border: none;
}
</style>