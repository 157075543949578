<template>
  <div class="device_div">
    <div class="leftmenu_div">
      <leftnav :activeMenu="activeMenu" :items="menus" @menu="menu"></leftnav>
    </div>
    <div class="content_div">
      <router-view name="content"></router-view>
    </div>
  </div>
</template>

<script>
import leftnav from "../../components/leftnav.vue";
export default {
  components: {
    leftnav,
  },
  data() {
    return {
      activeMenu: 87, //当前导航菜单在导航栏的位置
      menus: [],
    };
  },

  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    var arr = JSON.parse(sessionStorage.getItem("menus"));
    for (let i = 0; i < arr.length; i++) {
      if(arr[i].id == 87){
        this.menus = arr[i].list;
        break;
      }
    }
    this.init()
  },

  methods: {
    init(){
        this.$store.commit('saveActiveIndex',87);
        var flag = this.$route.params.subMenu;
        if (flag == 0) { //首页 跳转到 '终端管理'(带条件)
          this.$store.commit('saveLeftMenu', 94);
          var condition = this.$route.params.status;
          this.$router.push({
            name: "deviceManage",
            params: {
              status: condition,
            },
          });
        } else if (flag == 2) {//首页 跳转到 '当日活跃终端'
          this.$store.commit('saveLeftMenu', 96);
          this.$router.push({ name: "dayActive" });
        }else if (flag == 3) {//跳到 '已购套餐'
          this.$store.commit('saveLeftMenu', 95);
          this.$router.push({ name: "order", 
            params: {
              args: this.$route.params
            }, 
          });
        }else if (flag == 4) {//跳转到 '当月活跃终端'
          this.$store.commit('saveLeftMenu', 97);
          this.$router.push({ name: "monthActive", 
            params: {
              countryCode: this.$route.params.countryCode,
              date: this.$route.params.date,
            }, 
          });
        }else{ /** 默认点击终端菜单 */
          this.$store.commit('saveLeftMenu', this.menus[0].id);
          this.menu(this.menus[0].id)
        }
        
    },
    menu(selectMenu) {
      if (selectMenu == 94) {
        this.$router.push({ name: "deviceManage" });
      } else if (selectMenu == 95) {
        this.$router.push({ name: "order" });
      } else if (selectMenu == 96) {
        this.$router.push({ name: "dayActive" });
      } else if (selectMenu == 97) {
        this.$router.push({ name: "monthActive" });
      } else if (selectMenu == 98) {
        this.$router.push({ name: "inactive" });
      }
    },
  },
};
</script>

<style scoped>
.device_div {
  width: 100%;
  display: flex;
}
.leftmenu_div {
  width: 10%;
}
.content_div {
  width: 90%;
}
</style>