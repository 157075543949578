<template>
  
    <div>
      <div v-if="show.top">
        <div class="chart_search_item">
          <span class="title">{{$t('chart.dayActive')}}</span>
          <span style="margin-left: 10px; padding-bottom: 5px">
            <a-config-provider >
              <a-month-picker
                @change="changeLineChartDate"
                
              />
            </a-config-provider>
          </span>
        </div>
        <div class="month_active">
          <div
            id="month_active"
            @click.prevent="toDayActive"
            class="month_active_chart"
          ></div>
          <div class="month_active_data">
            <div class="month_active_data_detail">
              <div class="month_active_data_div2" @click.prevent="toBlance">
                <span class="month_active_data_label"> {{$t('chart.balance')}}</span>
                <div class="month_active_data_value">{{topRight.currencyStr}}{{ topRight.balance }}</div>
              </div>
              <div class="month_active_data_div2" @click.prevent="toBlance">
                <span class="month_active_data_label">{{$t('chart.cost')}}</span>
                <div class="month_active_data_value">{{topRight.currencyStr}}{{ topRight.cost }}</div>
              </div>
            </div>
            <div class="month_active_data_detail">
              <div class="month_active_data_div" @click.prevent="toDevice(0)">
                <span class="month_active_data_label">{{$t('chart.total')}}</span>
                <div class="month_active_data_value">{{ topRightTotal }}{{$t('chart.tower')}}</div>
              </div>
              <div class="month_active_data_div"  @click.prevent="toDevice(1)">
                <span class="month_active_data_label">{{$t('chart.using')}}</span>
                <div class="month_active_data_value">{{ topRightUsing }}{{$t('chart.tower')}}</div>
              </div>
              <div class="month_active_data_div"  @click.prevent="toDevice(2)">
                <span class="month_active_data_label">{{$t('chart.stop')}}</span>
                <div class="month_active_data_value">{{ topRightStop }}{{$t('chart.tower')}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="show.middle" style="margin-top: 35px">
        <div class="chart_search_item">
          <span class="title">{{$t('chart.trafficPoolOverview')}}</span>
          <span style="margin-left: 10px; padding-bottom: 5px">
            <a-config-provider >
              <a-month-picker
                :defaultValue="defaultDate"
                @change="changeFlowPoolDate"
                
              />
            </a-config-provider>
          </span>
        </div>
        <div class="flow_pool">
          <div
            v-for="(item, i) in flowPool"
            class="flow_pool_item"
            :class="[i % 3 != 2 ? 'flow_pool_item_border' : '']"
            :key="i"
            @click="toBill(item.name)"
          >
            <span class="flow_pool_title">{{ item.name }}{{$t('chart.trafficPool')}}</span>
            <div class="flow_pool_detail">
              <span><span style=" color: #6f6f6fa6;font-weight:normal;">{{$t('chart.device_all')}}</span>{{ item.device_all }}&nbsp;&nbsp;{{$t('chart.tower')}}</span>
              <span><span style=" color: #6f6f6fa6;font-weight:normal;">{{$t('chart.device_use')}}</span>{{ item.device_use }}&nbsp;&nbsp;{{$t('chart.tower')}}</span>
              <span><span style=" color: #6f6f6fa6;font-weight:normal;">{{$t('chart.device_stop')}}</span>{{ item.device_stop }}&nbsp;&nbsp;{{$t('chart.tower')}}</span>
            </div>
            <div>
              <div class="flow_pool_progress">
                <div>
                  <span>{{ item.usedFlow }}GB/{{ item.maxFlow }}GB</span>
                  <div class="progress_map">
                    <span class="bg_map" style="background-color: #00ff00"></span>
                    <span style=" color: #6f6f6fa6;font-weight:normal;">{{$t('chart.used')}}</span>
                    <span class="bg_map" style="background-color: #efefef"></span>
                    <span style=" color: #6f6f6fa6;font-weight:normal;">{{$t('chart.poolTraffic')}}</span>
                  </div>
                </div>
                <my-progress
                  :width="item.uesdPercent"
                  :color="usedFlowColor"
                ></my-progress>
              </div>
              <div class="flow_pool_progress">
                <div>
                  <span>{{ item.overFlow }}GB</span>
                  <div class="progress_map">
                    <span class="bg_map" style="background-color: #0181ff"></span>
                    <span style=" color: #6f6f6fa6;font-weight:normal;">{{$t('chart.flowExceeded')}}</span>
                  </div>
                </div>
                <my-progress
                  :width="item.overPercent"
                  :color="overFlowColor"
                ></my-progress>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="show.bottom" style="margin-top: 35px">
        <div class="chart_search_item">
          <span class="title">{{$t('chart.distributionTrend')}}</span>
        </div>
        <div
          id="device_distribute"
          class="device_distribute_chart"
        ></div>
      </div>
    </div>

  
</template>
<script>
import myProgress from "../../components/myProgress.vue";
import * as echarts from "echarts/core";
import moment from 'moment';
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BrushComponent,
  TitleComponent,
} from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { errMsg, isNull, warnMsg } from "../../utils/CommonUtils";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BrushComponent,
  TitleComponent,
  BarChart,
  LineChart,
  UniversalTransition,
  CanvasRenderer,
]);

var emphasisStyle = {
  itemStyle: {
    shadowBlur: 10,
    shadowColor: "rgba(0,0,0,0.3)",
  },
};
export default {
  components: {
    myProgress,
  },
  data() {
    return {
      locale: zhCN,
      usedFlowColor: "#00ff00", //已使用流量颜色
      overFlowColor: "#0181ff", //超额流量颜色
      flowPoolDate: "",
      searchDate: "",
      defaultDate: "",
      show:{
        top: false,
        middle: false,
        bottom: false,
      },
      topLeft: [],
      topRight: {},
      topRightTotal: 0,
      topRightUsing: 0,
      topRightStop: 0,
      sets: {},
      flowPool: [],
      bottom: {},
      lineOption: {
        grid: {
          //控制图标距离边界的位置
          left: 50,
        },
        tooltip: {     
          borderColor: "rgba(231, 231, 231, 1)",
          valueFormatter: (value) =>  value + " "+this.$t('chart.tower'),
        },
        xAxis: {
          type: "category",
          axisTick: {
            lineStyle: {
              color: "rgb(235, 235, 235)",
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "black",
            },
          },
          nameTextStyle: {
            color: "black",
          },
        },
        yAxis: {
          type: "value",
          show: true,
          min: 0,
          splitLine: {
            lineStyle: {
              color: "rgb(235, 235, 235)",
            },
          },
        },
        series: {
          type: "line",
        },
      },
      barOption: {
        legend: {
          left: "10%",
          selected: {
            '中国': false,
          }
        },
        grid: {
          left: 60,
          bottom: 50,
        },
        tooltip: {
          borderColor: "rgba(231, 231, 231, 1)",
          valueFormatter: (value) =>  value + " "+this.$t('chart.tower'),
        },
        xAxis: {
          axisLine: { onZero: true },
          splitLine: { show: false },
          splitArea: { show: false },
        },
        yAxis: {
          splitNumber: 10,
          type: "value",
        },
        series: [],
      },
    };
  },
  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    var arr = JSON.parse(sessionStorage.getItem("menus"));
    for (let i = 0; i < arr.length; i++) {
      if(arr[i].id == 86){
        var menus = arr[i].list;
        for (let j = 0; j < menus.length; j++) {
          if(menus[j].id == 91){
            this.show.top = true
          }else if(menus[j].id == 92){
            this.show.middle = true  
          }else if(menus[j].id == 93){
            this.show.bottom = true
          }
        }
      }
    }
    this.init();
    history.pushState(null,null,document.URL);
    this.$store.commit('saveActiveIndex',86);

    
  },
  methods: {
    /** 柱状图数据初始化 */
    barChartInit() {
      var barChart = document.getElementById("device_distribute");
      var myBarChart = echarts.init(barChart);
      this.barOption.xAxis.data = this.bottom.months;
      myBarChart.setOption(this.barOption);
      let obj = this;
      myBarChart.on("click",function(e){
        /** 跳转到终端地域分布 */
        var arr = JSON.parse(sessionStorage.getItem("menus"));
        var subMenus = [];
        for (let i = 0; i < arr.length; i++) {
          if(arr[i].id == 90){
              subMenus = arr[i].list;
              break;
          }
        }
        if(subMenus.length > 0){
          for (let i = 0; i < subMenus.length; i++) {
            if(subMenus[i].id == 106){
              obj.$router.push({name: "report",params:{
                    subMenu: 1,
                    date: e.name,
                  }});
              break;
            }
          }
      }
       
      })
      /** 图表同浏览器窗口伸缩,防止窗口缩小导致图表变形 */
      window.addEventListener("resize", function () {
        myBarChart.resize();
      });
    },  
    /** 折线图时间条件 */
    changeLineChartDate(moment, date) {
      if (isNull(date)) {
        return;
      }
      this.searchDate = date;
      this.axios.post(this.baseUrl + "getTopLeft?month=" + date, "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              if (isNull(res.data.data)) {
                warnMsg(this.$t('chart.hint.warn.notData'))
              } else {
                this.topLeft = res.data.data;
                this.lineOption.xAxis.data = this.getLastDayOfMonth();
                this.lineChartInit();
              }
            } else if (res.data.code == -1) {
              warnMsg(this.$t('chart.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -2) {
              warnMsg(this.$t('chart.hint.warn.getDataWarn'));
            }
          } else {
            warnMsg(this.$t('chart.hint.warn.retryWarn'));
          }
        }).catch(() => {
          errMsg(this.$t('chart.hint.error.systemErr'));
        });
    },
     /** 流量池时间条件 */
    changeFlowPoolDate(moment, date) {
      if (isNull(date)) {
        return;
      }
      this.flowPoolDate = date;
      this.axios.post(this.baseUrl + "getMiddle?month=" + date, "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              if (!isNull(res.data.data)) {
                this.formatFlowPool(res.data.data);
              } else {
                warnMsg(this.$t('chart.hint.warn.trafficPoolWarn'));
              }
            } else if (res.data.code == -1) {
              warnMsg(this.$t('chart.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -2) {
              warnMsg(this.$t('chart.hint.warn.getDataWarn'));
            }
          } else {
            warnMsg(this.$t('chart.hint.warn.retryWarn'));
          }
        }).catch(() => {
          errMsg(this.$t('chart.hint.error.systemErr'));
        });
    },
    /** 组装流量池数据 */
    formatFlowPool(datas) {
      for (let i = 0; i < datas.length; i++) {
        let item = datas[i];
        item.maxFlow = item.max_flow / 1024 / 1024; //总流量 单位G
        item.maxFlow = item.maxFlow.toFixed(2) > 0 ? item.maxFlow.toFixed(2) : 0;

        item.usedFlow = item.userd_flow / 1024 / 1024; //已用流量 单位G
        item.usedFlow = item.usedFlow.toFixed(2) > 0 ? item.usedFlow.toFixed(2) : 0;

        if (item.userd_flow - item.max_flow > 0) {
          item.overFlow = (item.userd_flow - item.max_flow) / 1024 / 1024; //超出流量 单位G
          item.overFlow = item.overFlow.toFixed(2) > 0 ? item.overFlow.toFixed(2) : 0;
          item.overPercent = item.overFlow / item.maxFlow > 1 ? 100 : (item.overFlow / item.maxFlow).toFixed(4) * 100; //超出流量比例 单位G
          item.uesdPercent = 100; //使用流量占总流量比例
        } else {
          item.overFlow = 0;
          item.overPercent = 0;
          item.uesdPercent = (item.userd_flow / item.max_flow).toFixed(4) * 100;
        }
      }
      this.flowPool = datas.sort((a, b)=>a.countryCode - b.countryCode)
    },

    /** 某个月所有时间 */
    getLastDayOfMonth() {
      let d = "";
      if (isNull(this.searchDate)) {
        d = new Date();
      } else {
        d = new Date(this.searchDate);
      }
      let date = new Date(d.getFullYear(), d.getMonth() + 1, 0); //日期设置为0号, 0表示1号的前一天
      let month = date.getMonth() + 1;
      month = month > 9?month:"0"+month;
      let year = date.getFullYear();
      let n = date.getDate();
      let days = [];
      for (let i = 0; i < n; i++) {
        if (i < 9) {
          days[i] = year + "-" + month + "-" + "0" + (i + 1);
        } else {
          days[i] = year + "-" + month + "-" + (i + 1);
        }
      }
      return days;
    },

    /** 最近一个月 */
    getLastMonth(){
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth();
      let m = month > 9 ? month: "0" + month;
       let m2 = (month+1) > 9 ? (month+1): "0" + (month+1);
      let today = d.getDate();
      let days = [];
      if(today < 30){
          let date = new Date(d.getFullYear(), d.getMonth(), 0);  //上个月的最后一天
          var count = 0;
          for (let i = (date.getDate()- (date.getDate() - today) + 1); i <= date.getDate(); i++) { /** 上月日期 */
            days[count] = year + "-" + m +"-" + (i>9?i:"0"+i);
            count++; 
          }
          for (let i = 0; i < today; i++) { /** 本月日期 */
            days[count] = year + "-" + m2 +"-" + ((i + 1)>9?(i + 1):"0"+(i + 1));
            count++; 
          }

      }else if(today == 30){
        for (let i = 0; i < today; i++) {
          days[i] = year + "-" + m2 +"-" + ((i + 1)>9?(i + 1):"0"+(i + 1));
        }
      }else if(today == 31){
        for (let i = 0; i < today; i++) {
          days[i] = year + "-" + m2 +"-" + ((i + 2)>9?(i + 2):"0"+(i + 2));
        }
      }
      return days;
    },
    init() {
      this.defaultDate = moment(new Date(), 'yyyy-mm-dd');
      this.flowPoolDate = this.defaultDate.format("yyyy-MM");
      this.axios.post(this.baseUrl + "getIndexInfo", "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              let datas = res.data;
              this.topLeft = datas.top_left;
              this.topRight = datas.top_right1;
              this.bottom = datas.bottom;
              let device = datas.top_right2;
              if(!isNull(device)){
                for (let i = 0; i < device.length; i++) {
                  this.topRightTotal =  this.topRightTotal + device[i].count
                  if(device[i].status == 0){
                    this.topRightUsing = device[i].count;
                  }else if(device[i].status == 2){
                    this.topRightStop = device[i].count;
                  }
                }
              }
              if(!isNull(datas.middle)){
                this.formatFlowPool(datas.middle);
              }
              if(!isNull(this.bottom)){
                 var record = this.bottom.result;
                 /** 动态生成要展示的柱状图数据结构 */
                  for (const key in record) {
                    var item = {
                        name: key,
                        type: 'bar',
                        stack: 'one',
                        emphasis: emphasisStyle,
                        data: record[key],
                        barWidth: 20,
                    } 
                    this.barOption.series.push(item);
                  }
              }
              if(!isNull(this.topLeft)){
                this.lineOption.xAxis.data = this.getLastMonth();
              }
              
            } else if (res.data.code == -1) {
              warnMsg(this.$t('chart.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }
          }
        }).catch(() => {
          errMsg(this.$t('chart.hint.error.systemErr'));
        });
    },
    /** 折线图所有数据初始化 */
    lineChartInit() {
      var chartDom = document.getElementById("month_active");
      var myChart = echarts.init(chartDom);
      if (!isNull(this.topLeft) && this.topLeft.length > 0) {
        this.lineOption.series.data = this.topLeft;
      }
      myChart.setOption(this.lineOption);
     /** 图表同浏览器窗口一起伸缩 */
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    /** 跳转到账单，前提：有这个菜单权限 */
    toBill(itemName){
      var arr = JSON.parse(sessionStorage.getItem("menus"));
      var subMenus = [];
      for (let i = 0; i < arr.length; i++) {
        if(arr[i].id == 88){
            subMenus = arr[i].list;
        }
      }
      if(subMenus.length > 0){
        for (let i = 0; i < subMenus.length; i++) {
          if(subMenus[i].id == 99){
              this.$router.push({
              name: "balance",
              params: {
                subMenu: 1,
                date: this.flowPoolDate,
                name: itemName,
              },
            });
          }
        }
      }
    },
    /** 跳转到当日活跃终端，前提：有这个菜单权限 */
    toDayActive() {
      var arr = JSON.parse(sessionStorage.getItem("menus"));
      var subMenus = [];
      for (let i = 0; i < arr.length; i++) {
        if(arr[i].id == 87){
            subMenus = arr[i].list;
            break;
        }
      }
      if(subMenus.length > 0){
        for (let i = 0; i < subMenus.length; i++) {
          if(subMenus[i].id == 96){
            this.$router.push({
              name: "device",
              params: {
                subMenu: 2,
              },
            });
            break;
          }
        }
      }
    },
     /** 跳转到余额，前提：有这个菜单权限 */
    toBlance() {
      var arr = JSON.parse(sessionStorage.getItem("menus"));
      var subMenus = [];
      //判断用户是否有某个菜单权限，有才会跳转
      for (let i = 0; i < arr.length; i++) {
        if(arr[i].id == 88){
            subMenus = arr[i].list;
            break;
        }
      }
      if(subMenus.length > 0){
        for (let i = 0; i < subMenus.length; i++) {
          if(subMenus[i].id == 101){
            this.$router.push({ name: "balance", params: {
                    subMenu: 3,
                  }, });
            break;      
          }
        }
      }
    },
     /** 跳转到终端管理，前提：有这个菜单权限 */
    toDevice(flag) {
      var arr = JSON.parse(sessionStorage.getItem("menus"));
      var subMenus = [];
      for (let i = 0; i < arr.length; i++) {
        if(arr[i].id == 87){
            subMenus = arr[i].list;
            break;
        }
      }
      if(subMenus.length > 0){
        for (let i = 0; i < subMenus.length; i++) {
          if(subMenus[i].id == 94){
             //带条件
              if (flag == 0) {
                this.$router.push({
                  name: "device",
                  params: {
                    subMenu: 0,
                  },
                });
                break;
              } else if (flag == 1) {
                this.$router.push({
                  name: "device",
                  params: {
                    subMenu: 0,
                    status: "0", //正常的终端
                },
                });
                break;
              } else if (flag == 2) {
                this.$router.push({
                  name: "device",
                  params: {
                      subMenu: 0,
                      status: "2",  //停用的终端
                  },
                });
                break;
              }
          }
        }
      }
    },
  },
  updated() {
    /** 折线图和柱状图渲染，不能放在vue生命周期其他更早的钩子函数里 */
    this.lineChartInit();
    if(!isNull(this.bottom)){
      this.barChartInit();
    }
  },
};
</script>
<style>
.ant-calendar-picker-container .ant-calendar-month-calendar{
  width: 220px;
}
.ant-calendar table, .ant-calendar th, .ant-calendar td a{
  padding: 8px;
  line-height: auto;
  height: auto;
}
.ant-calendar-month .ant-calendar-month-header-wrap{
  height: 207px !important;
}
</style>
<style scoped>
.title {
  display: block;
  font-size: 20px;
  font-weight: 800;
  text-align: left;
}

.chart_search_item {
  display: flex;
}

.border_none {
  border: none !important;
  padding-bottom: 5px;
}
.month_active {
  display: flex;
  height: 300px;
  border-top: 1px solid rgb(235, 235, 235);
}
.month_active_chart {
  height: 100%;
  width: 72%;
}
.month_active_data {
  margin-top: 10px;
  width: 28%;
  text-align: left;
  border-left: 1px solid rgb(235, 235, 235);
}
.month_active_data_detail {
  height: 50%;
  padding: 10% 0px 0px 25px;
  display: flex;
}
.month_active_data_div {
  width: 32%;
}
.month_active_data_div2 {
  width: 50%;
}
.month_active_data_div:hover, .month_active_data_div2:hover{
  cursor: pointer;
}

.month_active_data_label {
  font-size: 15px;
  /* font-weight: bold; */
  color: #6f6f6fa6;
}
.month_active_data_value {
  color: rgb(48, 48, 48);
  padding-top: 5px;
  font-size: 20px;
  font-weight: 600;
}

.flow_pool {
  width: 100%;
  text-align: left;
  border-top: 1px solid rgb(235, 235, 235);
}
.flow_pool:hover{
  cursor: pointer;
}
.flow_pool_item {
  display: inline-block;
  width: 33%;
  height: 100%;
  padding: 15px 45px;
  margin-top: 20px;
}

.flow_pool_item_border {
  border-right: 1px solid rgb(235, 235, 235);
}

.flow_pool_title {
  display: inline-block;
  margin-bottom: 15px;
  font-weight: bold;
}

.flow_pool_detail {
  font-weight: bold;
  color: black;
  font-size: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.progress_map {
  float: right;
}
.bg_map {
  display: inline-block;
  margin-left: 8px;
  margin-right: 3px;
  height: 10px;
  width: 10px;
  border: 1px solid rgb(214, 214, 214);
}

.flow_pool_progress {
  margin-top: 10px;
}

.device_distribute_chart {
  border-top: 1px solid rgb(235, 235, 235);
  padding: 20px 0px 0px;
  height: 400px;
}

::v-deep .month_active_chart canvas:hover{
  cursor: pointer !important;
}
</style>
