<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="350px"
    :footer="null"
    :maskClosable="false"
    @cancel="handleCancel"
    :dialogStyle="{ top: '25%' }"
  >
    <div>
      <form>
        <div class="update_password_div">
          <span class="update_password_label">{{$t('updatePasswordAccount.password')}}</span>
          <a-input-password
            class="new_name_input"
            autocomplete="off"
            allowClear
            :maxLength="25"
            :placeholder="$t('updatePasswordAccount.passwordMsg')"
            type="password"
            v-model.trim="password"
          />
        </div>
        <div class="update_password_div">
          <span class="update_password_label">{{$t('updatePasswordAccount.confirmPassword')}}</span>
          <a-input-password
            class="new_name_input"
            autocomplete="off"
            allowClear
            :placeholder="$t('updatePasswordAccount.confirmPasswordMsg')"
            type="password"
            :maxLength="25"
            v-model.trim="confirmPassword"
          />
        </div>
        <div class="update_password_div update_password_btn">
          <span class="update_password_cancel" @click.prevent="cancel"
            >{{$t('updatePasswordAccount.cancel')}}</span>
          <span class="update_password_confirm" @click.prevent="confirm"
            >{{$t('updatePasswordAccount.confirm')}}</span>
        </div>
      </form>
    </div>
  </a-modal>
</template>
<script>
import { isNull,successMsg, warnMsg, errMsg } from '../../../utils/CommonUtils';
export default {
  props: ["visible", "id"],
  data() {
    return {
      title: this.$t('updatePasswordAccount.title'),
      showModal: false,
      password: "",
      confirmPassword: "",
    };
  },
  watch: {
    /** 通过监视visible值变化来控制弹框显示，注意：有部分弹框控制方式和这里不一样。 */
    visible(val) {
      this.showModal = val;
      if(this.showModal){
        this.password = "";
        this.confirmPassword = "";
      }
    },
  },
  methods: {
    cancel() {
      this.$emit("update-password-visible", false, 0);
    },
    confirm() {
      if(isNull(this.password)){
        warnMsg(this.$t('updatePasswordAccount.hint.warn.passwordWarn1'))
        return;
      }
      if(isNull(this.confirmPassword)){
        warnMsg(this.$t('updatePasswordAccount.hint.warn.confirmPasswordWarn1'))
        return;
      }
      if(this.password != this.confirmPassword){
        warnMsg(this.$t('updatePasswordAccount.hint.warn.confirmPasswordWarn2'))
        return;
      }
      if(this.password.length < 6){
        warnMsg(this.$t('updatePasswordAccount.hint.warn.passwordWarn2'))
        return;
      }
      this.axios.post(this.baseUrl + "updatePwd?id="+this.id + "&afterPwd="+this.password, "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              successMsg(this.$t('updatePasswordAccount.hint.success.update'))
              this.$emit("update-password-visible", false, 1);
            }else if(res.data.code == -1){
              warnMsg(this.$t('updatePasswordAccount.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }else if(res.data.code == -2){
              warnMsg(this.$t('updatePasswordAccount.hint.warn.operationWarn'))
            }else if(res.data.code == -3){
              warnMsg(this.$t('updatePasswordAccount.hint.warn.permissionWarn'))
            }
          } else {
            errMsg(this.$t('updatePasswordAccount.hint.error.updateErr'));
          }
        }).catch(() => {
          errMsg(this.$t('updatePasswordAccount.hint.error.systemErr'));
        });
    },
    handleCancel() {
      this.$emit("update-password-visible", false, 0);
    },
  },
};
</script>
<style scoped>
.update_password_div {
  margin-bottom: 10px;
  font-size: 15px;
}
.new_name_input {
  width: 200px;
}
.update_password_label {
  width: 80px;
  display: inline-block;
  text-align: right;
}
.update_password_btn {
  text-align: center;
  margin: 20px 0 0 0;
}
.update_password_cancel,
.update_password_confirm {
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  height: 33px;
  width: 60px;
  line-height: 33px;
}
.update_password_cancel {
  margin-right: 15px;
  border: 1px solid rgb(184, 184, 184);
}
.update_password_cancel:hover {
  cursor: pointer;
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}
.update_password_confirm {
  border: 1px solid rgb(78, 78, 255);
  background-color: rgb(78, 78, 255);
  color: white;
}
.update_password_confirm:hover {
  cursor: pointer;
  box-shadow: 0px 0px 2px 0px rgb(23, 23, 255);
}
::v-deep .ant-modal-body {
  padding: 16px 24px 12px;
}
</style>