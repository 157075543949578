import Vue from 'vue'
import App from './App.vue'


import VueRouter from 'vue-router'
import router from "./router/index.js"
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store/store.js';
import i18n from './i18n/index.js'; //国际化


import {Drawer, DatePicker,Icon,Button,Dropdown,Menu,FormModel,Input,Radio,Select,TreeSelect,
  Collapse,Popover,Table,Modal,ConfigProvider,Checkbox } from 'ant-design-vue';

import 'ant-design-vue/dist/antd.css';
import './assets/css/pagination.css';  

Vue.use(DatePicker)
Vue.use(Icon)
Vue.use(Drawer)
Vue.use(Button)
Vue.use(Dropdown)
Vue.use(Menu)
Vue.use(FormModel)
Vue.use(Input)
Vue.use(Radio)
Vue.use(Select)
Vue.use(TreeSelect)
Vue.use(Collapse)
Vue.use(Popover)
Vue.use(Table)
Vue.use(Modal)
Vue.use(ConfigProvider)
Vue.use(Checkbox)


Vue.use(VueAxios, axios)
Vue.use(VueRouter)

Vue.config.productionTip = false
let wxPayCallBackUrl = "https://cmp.roam.link/iot"; //生产
// let wxPayCallBackUrl = "https://bpm.roamwifi.net/"; //测试
// let wxPayCallBackUrl = "http://10.10.14.116:9090/"; //志敏开发
// let wxPayCallBackUrl = "http://10.10.14.54:9090/"; //王振开发
// let wxPayCallBackUrl = "http://10.10.14.66:8077/"; //国坤开发
let url = "https://cmp.roam.link/iot/supply_api/"; //生产
// let url = "https://bpm.roamwifi.net/supply_api/";  // 测试
// let url = "http://10.10.14.54:9090/supply_api/";  //王振开发
// let url = "http://10.10.14.66:8077/supply_api/";  //国坤开发
// let url = "http://10.10.14.116:9090/supply_api/";  //开发
let sign_key = "1234567890qwertyuiopasdfghjklzxc";


Vue.prototype.wxPayCallBackUrl = wxPayCallBackUrl;
Vue.prototype.baseUrl = url;
Vue.prototype.signKey = sign_key;
Vue.prototype.$modal = Modal;
/** 解决路由重复访问报错 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};




router.beforeEach((to, from, next) => {
  /* 根据路由的参数修改页面的title */
  if (to.meta.title) {
      document.title = to.meta.title
  }

/** 没有登录但是直接访问菜单路径时，重定向到登录界面 */
  if(to.name != "login" && sessionStorage.getItem("token") == null){
    router.push({name: 'login'})
  }

/** 路由到登录时，清除登录后记录的数据(不管有没有数据) */
  if(to.name == "login"){
    sessionStorage.removeItem("username")
    sessionStorage.removeItem("token")
    sessionStorage.removeItem("role")
    sessionStorage.removeItem("activeIndex")
    sessionStorage.removeItem("main")
    sessionStorage.removeItem("bpmUserType")
    sessionStorage.removeItem("menus")
  }
    next()
})



new Vue({
  render: h => h(App),
  i18n,
  router,
  store,
}).$mount('#app')  