<template>
  <!-- 整个前端主项目界面布局,index.vue包含两部分(head,content),content包含两部分(leftmenu,和right内容) -->
  <div>
    <div class="header">
      <navcon></navcon>
    </div>
    <div class="content">
      <router-view name="main"></router-view>
    </div>
  </div>
</template>
<script>
import navcon from "../components/navcon.vue";
export default {
  components: {
    navcon,
  },
  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    window.addEventListener("popstate", this.reload);
  },
  destroyed() {
    window.removeEventListener("popstate", this.reload);
  },
  methods: {
    reload() {
      location.reload(); //解决用户点击浏览器回退问题
    },
  },
};
</script>
<style>
* {
  font-family: "微软雅黑";
}
.header {
  width: 100%;
  height: 50px;
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: #fafafa;
  border-bottom-color: #e3e3e3;
  border-bottom-style: inset;
  border-bottom-width: 1px;
}
.content {
  width: 100%;
  text-align: center;
  padding: 65px 30px 0px 30px;
}
</style>
