<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="66%"
    :footer="null"
    :maskClosable="false"
    :dialogStyle="{ top: '12%' }"
    @cancel="handleCancel"
  >
    <div style="padding:20px 24px 10px;">
      <div style="height:250px;overFlow-y:scroll;">
          <div v-if="visibleGroup" class="auto_sub_div">
            <span>{{$t('cancelSubscribe.selectGroup')}}</span
            ><a-select :placeholder="$t('autoSubscribe.selectGroupMsg')" allowClear v-model="groupIndex" class="select_list">
              <a-select-option v-for="(group, i) in groups" :value="i" :key="i">{{group}}</a-select-option>
            </a-select>
          </div>
          <div v-if="!visibleGroup">
            <span>{{$t('cancelSubscribe.selectDevice')}}</span>
              <div style="margin-top:15px;">
                {{imei}}
              </div>
          </div>
      </div>  
      <div class="auto_sub_btn">
        <span class="auto_sub_cancel cursor_attr" @click.prevent="cancel">{{$t('cancelSubscribe.cancel')}}</span>
        <span class="auto_sub_confirm cursor_attr" @click.prevent="confirm">{{$t('cancelSubscribe.define')}}</span>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { errMsg, isNull, successMsg, warnMsg } from '../../../utils/CommonUtils';
export default {
  props: ["visible","visibleGroup","selectedRow"],  //visible弹框是否可见， visibleGroup分组框是否可见
  data() {
    return {
      title: this.$t('cancelSubscribe.title'),
      showModal: false,
      imei: "",
      groupIndex: undefined,
      groups: [],
    };
  },
  watch: {
    visible(val) {
      this.showModal = val;
      if(this.showModal){
        if(this.visibleGroup){ /**是否选中分组 */
          this.init();
        }else{
          this.getImei()
        }
      }
    },
  },
  methods: {
    getImei(){
        var imeis = "";
        if (this.selectedRow.length > 1) {
          this.selectedRow.forEach((item) => {
            imeis = imeis + item.imei + ",";
          });
          imeis = imeis.substring(0, imeis.lastIndexOf(','));
        } else if (this.selectedRow.length == 1){
          imeis = this.selectedRow[0].imei;
        }
        this.imei = imeis;
    },
    init(){
        //初始化分组信息
        this.axios.post(this.baseUrl + "getDeviceGroup","",{headers: {token: sessionStorage.getItem("token")}}).then((res)=>{
          if(res.status == 200){
             if(res.data.code == 0){
               this.groups = res.data.data
             }else if(res.data.code == -1){
                warnMsg(this.$t('cancelSubscribe.hint.warn.loginWarn'))
                setTimeout (()=>{
                  this.$router.push({ name: "login"});
                },"2000");
             }else if(res.data.code == -2){
               warnMsg(this.$t('cancelSubscribe.hint.warn.retryWarn'))
             }
          }
        }).catch(()=>{
            errMsg(this.$t('cancelSubscribe.hint.error.systemErr'))
        })
    },
    handleCancel() {
      this.$emit("cancel-subscribe-visible", false);
    },
    cancel() {
      this.$emit("cancel-subscribe-visible", false);
    },
    confirm() {
        if(isNull(this.groupIndex) && this.groupIndex !== 0 && isNull(this.imei)){
          warnMsg(this.$t('cancelSubscribe.hint.warn.selectImeiWarn'))
          return;
        }
        var condition = (isNull(this.groupIndex) && this.groupIndex != 0) ?this.imei:this.groups[this.groupIndex];
        var type = (isNull(this.groupIndex) && this.groupIndex != 0)?1:0;
        this.axios.post(this.baseUrl + "cancelRenewal?condition=" + condition +"&type="+type,"",{headers: {token: sessionStorage.getItem("token")}}).then((res)=>{
          if(res.status == 200){
            if(res.data.code == 0){
                successMsg(this.$t('cancelSubscribe.hint.success.cancelRenew'))
                this.$emit("cancel-subscribe-visible", false, 1);
            }else if(res.data.code == -1 && res.data.message == "未登录"){
               warnMsg(this.$t('cancelSubscribe.hint.warn.loginWarn'))
                setTimeout (()=>{
                  this.$router.push({ name: "login"});
                },"2000");
            }else if(res.data.code == -1){
              warnMsg(this.$t('cancelSubscribe.hint.warn.cancelRenewWarn1'))
            }else if(res.data.code == -2){
              warnMsg(this.$t('cancelSubscribe.hint.warn.cancelRenewWarn2'))
            }else if(res.data.code == -3){
              warnMsg(this.$t('cancelSubscribe.hint.warn.cancelRenewWarn3'))
            }else if(res.data.code == -4){
              warnMsg(this.$t('cancelSubscribe.hint.warn.cancelRenewWarn4'))
            }else if(res.data.code == -5){
              warnMsg(this.$t('cancelSubscribe.hint.warn.cancelRenewWarn5'))
            }else if(res.data.code == -6){
              warnMsg(this.$t('cancelSubscribe.hint.warn.cancelRenewWarn6'))
            }else if(res.data.code == -7){
              errMsg(this.$t('cancelSubscribe.hint.error.systemErr'))
            }
      }
    }).catch(()=>{
      errMsg(this.$t('cancelSubscribe.hint.error.systemErr'))
    })
    },

  },
};
</script>
<style scoped>
* {
  font-size: 15px;
}

.auto_sub_div {
  display: inline-block;
  width: 50%;
  margin-bottom: 10px;
}
.select_list {
  width: 70%;
  margin-right: 50px;
}

.auto_sub_div {
  height: 36px;
  line-height: 36px;
  margin-bottom: 10px;
  font-size: 15px;
}
.auto_sub_btn {
  text-align: right;
  margin: 20px 0 0 0;
}

.auto_sub_cancel,
.auto_sub_confirm {
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  height: 33px;
  width: 60px;
  line-height: 33px;
}
.auto_sub_cancel {
  margin-right: 15px;
  border: 1px solid rgb(184, 184, 184);
}
.auto_sub_cancel:hover {
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}
.auto_sub_confirm {
  border: 1px solid rgb(78, 78, 255);
  background-color: rgb(78, 78, 255);
  color: white;
}
.auto_sub_confirm:hover {
  box-shadow: 0px 0px 2px 0px rgb(23, 23, 255);
}

.item_packet {
  display: inline-block;
  width: 80px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #acacac;
  margin-right: 10px;
  border-radius: 3px;
}

.select {
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}

.unselect:hover {
  border: 1px solid rgb(144, 144, 255);
  color: rgb(144, 144, 255);
}
.cursor_attr:hover{
  cursor: pointer;
} 
::v-deep .ant-modal-body {
  padding: 0px;
}

::v-deep .ant-collapse {
  border: none;
}
</style>