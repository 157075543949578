<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="350px"
    :footer="null"
    :maskClosable="false"
    @cancel="handleCancel"
    :dialogStyle="{ top: '23%' }"
  >
    <div>
      <div class="update_name_div">
        <span>{{$t('updatePhone.phone')}}</span>
        <a-input
          class="new_name_input"
          :placeholder="$t('updatePhone.phoneMsg')"
          :maxLength="20"
          v-model.trim="phone"
        />
      </div>
      <div class="update_name_div" style="display: flex; align-items: center">
        <span>{{$t('updatePhone.verifyCode')}}</span>
        <a-input style="width: 35%" :placeholder="$t('updatePhone.verifyCodeMsg')" :maxLength="6" v-model.trim="verifyCode" />
        <span
          :class="[
            'verify_code',
            code.hasSend ? 'verify_code_count_down' : 'verify_code_btn',
          ]"
          @click="sendCode"
          >{{ code.codeMsg }}</span
        >
      </div>
      <div class="update_name_div update_name_btn">
        <span class="update_name_cancel" @click.prevent="cancel">{{$t('updatePhone.cancel')}}</span>
        <span class="update_name_confirm" @click.prevent="confirm">{{$t('updatePhone.confirm')}}</span>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { warnMsg,isNull,errMsg,successMsg } from '../../../utils/CommonUtils';
export default {
  props: ["visible", "id"],
  data() {
    return {
      title: this.$t('updatePhone.title'),
      showModal: false,
      phone: "",
      verifyCode: "",
      code: {
        timeInterval: "", //倒计时对象
        hasSend: false,
        codeMsg: this.$t('updatePhone.codeMsg1'),
      },
    };
  },
  watch: {
    /** 通过监视visible值变化来控制弹框显示，注意：有部分弹框控制方式和这里不一样。如autoSubscribeModal */
    visible(val) {
      this.showModal = val;
      if (!this.showModal) {
        this.changeSendVerifyCodeContent();
        this.phone = "";
        this.verifyCode = ""
      }
    },
  },
  methods: {
    cancel() {
      this.$emit("update-phone-visible", false, 0);
    },
    confirm() {
      var phoneReg = /^[0-9]{3,20}$/g
      var codeReg = /^[0-9]{6}$/g
      if (isNull(this.phone)) {
        warnMsg(this.$t('updatePhone.hint.warn.phoneWarn1'));
        return;
      }
      if(!phoneReg.test(this.phone)){
        warnMsg(this.$t('updatePhone.hint.warn.phoneWarn2'));
        return;
      }
      if (isNull(this.verifyCode)) {
        warnMsg(this.$t('updatePhone.hint.warn.verifyCodeWarn1'));
        return;
      }
      if(!codeReg.test(this.verifyCode)){
        warnMsg(this.$t('updatePhone.hint.warn.verifyCodeWarn2'));
        return;
      }
      this.axios.post(this.baseUrl + "updateSonAccountPhone?phone="+this.phone+"&code="+this.verifyCode+"&id="+this.id,"",
       {headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if(res.status == 200){
            if(res.data.code == 0){
              successMsg(this.$t('updatePhone.hint.success.update'))
              this.$emit("update-phone-visible", false, 1);
            }else if(res.data.code == -1){
              warnMsg(this.$t('updatePhone.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }else if(res.data.code == 1){
              errMsg(this.$t('updatePhone.hint.warn.verifyCodeWarn2'))
            }else if(res.data.code == 2){
              errMsg(this.$t('updatePhone.hint.error.updateErr'))
            }else if(res.data.code == -2){
              warnMsg(this.$t('updatePhone.hint.warn.verifyCodeWarn3'))
            }else if(res.data.code == -3){
              warnMsg(this.$t('updatePhone.hint.warn.permissionWarn'))
            }
          }
        }).catch(()=>{
          errMsg(this.$t('updatePhone.hint.error.systemErr'))
        })
    },
    changeSendVerifyCodeContent() {
      this.code.codeMsg = this.$t('updatePhone.codeMsg1');
      this.code.hasSend = false;
      clearInterval(this.code.timeInterval);
    },
    handleCancel() {
      this.$emit("update-phone-visible", false, 0);
    },
    sendCode() {
      var phoneReg = /^[0-9]{3,20}$/g
      if (isNull(this.phone)) {
         warnMsg(this.$t('updatePhone.hint.warn.phoneWarn1'));
        return;
      }
      if(!phoneReg.test(this.phone)){
        warnMsg(this.$t('updatePhone.hint.warn.phoneWarn2'));
        return;
      }
      this.axios.post(this.baseUrl + "getCode?phonenumber="+this.phone+"&codeFlag=1", "",{headers: { token: sessionStorage.getItem("token") }}).then((res)=>{
        if(res.status == 200){
          if(res.data.code == 0){
            successMsg(this.$t('updatePhone.hint.success.sendMsg'))
            this.countDown();
          }else if(res.data.code == -1){
            warnMsg(this.$t('updatePhone.hint.warn.loginWarn'));
            setTimeout(() => {
              this.$router.push({ name: "login" });
            }, "2000");
          }  
        }else{
          errMsg(this.$t('updatePhone.hint.error.sendMsgErr'))
        }
      }).catch(()=>{
        errMsg(this.$t('updatePhone.hint.error.systemErr'))
      })  
    },
     countDown(){
      var i = 59;
      this.code.hasSend = true;
      this.code.codeMsg = this.$t('updatePhone.codeMsg2');
      this.code.timeInterval = setInterval(() => {
        if (i > 0) {
          this.code.codeMsg = i-- + this.$t('updatePhone.codeMsg3');
        } else {
          this.changeSendVerifyCodeContent();
        }
      }, 1000);
    }
  },
};
</script>
<style scoped>
.verify_code {
  display: inline-block;
  border-radius: 5px;
  height: 33px;
  line-height: 33px;
  margin-left: 12px;
  text-align: center;
  padding: 0px 6px;
}
.verify_code_count_down {
  pointer-events: none;
  cursor: default;
  border: 1px solid #d9d9d9;
  color: #d9d9d9;
}
.verify_code_btn {
  width: 90px;
  border: 1px solid #d9d9d9;
}
.verify_code_btn:hover {
  color: rgb(137, 208, 255);
  border: 1px solid rgb(137, 208, 255);
}
.verify_code_btn:active {
  color: rgb(66, 178, 252);
}
.update_name_div {
  margin-bottom: 10px;
  font-size: 15px;
}
.new_name_input {
  width: 216px;
}
.update_name_btn {
  text-align: center;
  margin: 20px 0 0 0;
}
.update_name_cancel,
.update_name_confirm {
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  height: 33px;
  width: 60px;
  line-height: 33px;
}
.update_name_cancel {
  margin-right: 15px;
  border: 1px solid rgb(184, 184, 184);
}
.update_name_cancel:hover {
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}
.update_name_confirm {
  border: 1px solid rgb(78, 78, 255);
  background-color: rgb(78, 78, 255);
  color: white;
}
.update_name_confirm:hover {
  box-shadow: 0px 0px 2px 0px rgb(23, 23, 255);
}
::v-deep .ant-modal-body {
  padding: 16px 24px 12px;
}
</style>