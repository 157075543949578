<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="78%"
    :footer="null"
    :maskClosable="false"
    :dialogStyle="{ top: '6%' }"
    @cancel="handleCancel"
  >
    <div
      style="
        padding: 20px 26px 35px;
        height: 430px;
        overflow-y: auto;
      "
    >
      <div style="margin-top: 15px" v-if="orders.length > 0">
        <div class="title">{{$t('inactiveDetail.packetOrder')}}</div>
        <table style="width: 100%" v-for="(order,i) in orders" :key="i">
          <tr style="line-height: 25px">
            <td class="tds table_td_label">{{$t('inactiveDetail.orderNum')}}</td>
            <td class="tdvs1">{{order.order_num}}</td>
            <td class="tds table_td_label">{{$t('inactiveDetail.countryCode')}}</td>
            <td class="tdvs1">{{order.countrycode}}</td>
            <td class="tds table_td_label">{{$t('inactiveDetail.packetFlow')}}</td>
            <td class="tdvs1">{{order.Packet_flow}} KB</td>
            <td class="tds table_td_label">{{$t('inactiveDetail.orderTime')}}</td>
            <td class="tdvs1">{{order.ordertime}}</td>
           
          </tr>
          <tr style="line-height: 25px">
            <td class="tds table_td_label">{{$t('inactiveDetail.packetTime')}}</td>
            <td class="tdvs1">{{order.allTime}}</td>
            <td class="tds table_td_label">{{$t('inactiveDetail.amount')}}</td>
            <td class="tdvs1" v-if="order.price != -1">{{order.price}}</td>
            <td class="tdvs1" v-else>-</td>
            <td class="tds table_td_label">{{$t('inactiveDetail.status')}}</td>
            <td class="tdvs1">{{order.status}}</td>
            <td class="tds table_td_label">{{$t('inactiveDetail.effectiveTime')}}</td>
            <td class="tdvs1">{{order.valid_startdate}} / {{order.valid_enddate}}</td>
          </tr>
          <tr> 
            <td colspan="4" style="text-align:left;padding-left:3px;"><span class="table_td_label">{{$t('inactiveDetail.usedFlowMaxFlow')}}</span>{{order.usedflow}}/{{order.maxFlow}}</td>
          </tr>
        </table>
      </div>
      <div style="margin-top: 15px" v-if="autoSub.length > 0">
        <div class="title">{{$t('inactiveDetail.autoRenewal')}}</div>
        <table style="width: 100%;margin-bottom:15px;" v-for="(sub, i) in autoSub" :key="i">
          <tr style="line-height: 25px">
            <td class="tds table_td_label table_td_label1">{{$t('inactiveDetail.packageName')}}</td>
            <td class="tdvs tdvs_value">{{sub.attachName}}</td>
            <td class="tds table_td_label table_td_label1">{{$t('inactiveDetail.packagePrice')}}</td>
            <td class="tdvs tdvs_value" v-if="sub.packetprice != -1">{{sub.packetprice}}</td>
            <td class="tdvs tdvs_value" v-else>-</td>
            <td class="tds table_td_label table_td_label2">{{$t('inactiveDetail.packageCountry')}}</td>
            <td class="tdvs tdvs_value">{{sub.countryName}}</td>
          </tr>
          <tr style="line-height: 25px">
            <td class="tds table_td_label table_td_label1">{{$t('inactiveDetail.operatorAccount')}}</td>
            <td class="tdvs tdvs_value" :title="sub.operatorid">{{sub.operatorid}}</td>
            <td class="tds table_td_label table_td_label1">{{$t('inactiveDetail.orderStatus')}}</td>
            <td class="tdvs tdvs_value">{{sub.status==0?this.$t('inactiveDetail.normal'):sub.status==2?this.$t('inactiveDetail.deactivate'):''}}</td>
            <td class="tds table_td_label table_td_label2">{{$t('inactiveDetail.operationTime')}}</td>
            <td class="tdvs tdvs_value">{{sub.updatetime}}</td>
          </tr>
          <tr style="line-height: 25px">
            <td class="tds table_td_label table_td_label1">{{$t('inactiveDetail.startTime')}}</td>
            <td class="tdvs tdvs_value">{{sub.begintime}}</td>
            <td class="tds table_td_label table_td_label1">{{$t('inactiveDetail.endTime')}}</td>
            <td class="tdvs tdvs_value">{{sub.endtime}}</td>
            <td class="tds table_td_label table_td_label2">{{$t('inactiveDetail.renewAfterUsedUp')}}</td>
            <td class="tdvs tdvs_value">{{sub.usedUp==1?this.$t('inactiveDetail.no'):sub.usedUp==0?this.$t('inactiveDetail.yes'):''}}</td>
          </tr>
        </table>
      </div>
      <div v-if="orders.length == 0 && autoSub.length == 0">{{$t('inactiveDetail.noData')}}</div>
    </div>
  </a-modal>
</template>
<script>
import { errMsg, isNull, warnMsg } from "../../../utils/CommonUtils.js";
export default {
  props: ["tsid"],
  data() {
    return {
      title: this.$t('inactiveDetail.title'),
      showModal: false,
      orders: [],
      autoSub: [],
    };
  },
  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.showModal = true;
    this.init();
  },
  methods: {
    init() {
      if (isNull(this.tsid)) {
        return;
      }
      this.axios.post(this.baseUrl + "getDetailOrder?tsid=" + this.tsid, "", {
          headers: { token: sessionStorage.getItem("token"),
          'Accept-Language': localStorage.getItem('locale') },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              let data = res.data.data;
              for (let i = 0; i < data.length; i++) {
                let order = data[i];
                order.status = order.status == 0 ? this.$t('inactiveDetail.normal') : order.status == 2 ? this.$t('inactiveDetail.deactivate') : "";
                order.allTime = order.time_unit == 1 ? order.days + this.$t('inactiveDetail.month') : order.time_unit == 0
                    ? order.days == 365 ? "1"+this.$t('inactiveDetail.year') : order.days == 730 ? "2"+this.$t('inactiveDetail.year') : order.days + this.$t('inactiveDetail.day') : "";
              }
              this.orders = data;
            }else if (res.data.code == -1) {
              warnMsg(this.$t('inactiveDetail.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }
          }
        }).catch(() => {
          errMsg(this.$t('inactiveDetail.hint.error.systemErr'));
        });

      this.axios.post(this.baseUrl + "getDetailAuto?tsid=" + this.tsid, "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.autoSub = res.data.data;
            }
          }
        })
    },
    handleCancel() {
      this.$emit("inactive-detail-visible", false);
    },
  },
};
</script>
<style scoped>

.table_1 {
  width: 100%;
  font-size: 12px;
}
.table_1_tr {
  height: 45px;
  line-height: 25px;
}
.table_1_td_label {
  min-width: 45px;
  text-align: right;
}

.table_td_label {
  width: 80px;
  font-weight: bold;
}
.table_td_label1{
  width: 100px;
}
.table_td_label2{
  width: 150px;
}
.table_1_td_label2 {
  min-width: 65px;
  text-align: right;
}

.table_1_input_text {
  border: 0px;
  outline-width: 0px;
  background-color: #fafafa;
}
.title {
  padding: 5px;
  font-weight: 800;
  font-size: 18px;
}
.tds {
  text-align: right;
}
.tdvs1{
 text-align: left;
 width: 190px;
}
.tdvs {
  text-align: left;
}
.tdvs_value{
  display: inline-block;
  width: 150px;
  word-break:keep-all;/* 不换行 */
  white-space:nowrap;/* 不换行 */
  overflow:hidden;
  text-overflow:ellipsis;
}
.ivs {
  cursor: no-drop;
  border: 1px solid #c2c2c2;
  border-color: #cccccc;
  -webkit-box-shadow: 0 0 3px #c2c2c2;
  -moz-box-shadow: 0 0 3px #c2c2c2;
  box-shadow: 0 0 3px #c2c2c2;
}

/* 限制备注内容过多 */
.note_wrap {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-indent: 28px;
}

::v-deep .ant-modal-body {
  padding: 0px;
}
</style>