<template>
  <div>
    <div class="operate_nav">
      <span class="operate_nav_title">{{ title }}</span>
      <span class="operate_nav_btn_frame" @click.prevent="showSearch">
        <span>
          <a-icon type="search" /><span style="margin-left: 3px">{{this.$t('billDetail.search')}} </span>
        </span>
      </span>
      <span class="operate_nav_btn_frame" @click.prevent="exportData">
        <a-icon type="download" />
        {{this.$t('billDetail.report')}}
      </span>
      <export-tips
        v-if="tips"
        :total="pagination.total"
        @export-tips-visible="changeShowExportTips"  
      ></export-tips>
    </div>

    <div :class="[search.visible ? 'search_show' : 'search_hide']">
      <div class="search_item_div">
        <span class="search_item_label">{{this.$t('billDetail.searchList.bankOrder')}}</span>
        <a-input
          class="search_item"
          allowClear
          v-model="search.bankOrder"
          :placeholder="this.$t('billDetail.searchList.bankOrderMsg')"
          autocomplete="off"
        />
      </div>
      <div style="margin-left: 8px" class="search_item_div">
        <span class="search_item_label">{{$t('billDetail.searchList.type')}}</span>
        <a-select
          allowClear
          class="search_item"
          :placeholder="$t('billDetail.searchList.selectMsg')"
          v-model="search.type"
        >
          <a-select-option v-for="type in types" :key="type.key">{{
            type.value
          }}</a-select-option>
        </a-select>
      </div>
      <div style="margin-left: 8px" class="search_item_div">
        <span class="search_item_label">{{this.$t('billDetail.searchList.orderTime')}}</span>
        <a-config-provider >
        <a-range-picker
          allowClear
          v-model="search.orderTime"
          class="search_item"
          :placeholder="[$t('billDetail.searchList.placeholder')[0], $t('billDetail.searchList.placeholder')[1]]"
        />
        </a-config-provider>
      </div>
      <span class="search_item_btn" @click.prevent="searchConfirm">{{this.$t('billDetail.searchList.inquire')}}</span>
    </div>
    <div style="margin-top: 10px">
      <a-config-provider >
         <a-table
          :loading="loading"
          :columns="columns"
          :data-source="datas"
          :pagination="pagination"
          :rowClassName="rowClassName"
          :customRow="selectRow"
          :rowKey="(record, i) => {return i;}"
          size="small"
        >
        <div slot="bankOrder" slot-scope="text, record">
          <a v-if="isClick(record.bankOrder)" @click="toOrder(record)">{{ record.bankOrder }} </a>
          <span v-else>{{ record.bankOrder }} </span>
        </div>
        <div slot="income" slot-scope="text, record"  style="text-align:right;">
          <span style="color: #00ff00;" v-if="record.income >= 0">{{record.income}}</span>
          <span style="text-align:right;" v-if="record.income < 0">{{record.income}}</span>
        </div>
        <div slot="balance" slot-scope="text, record"  style="text-align:right;">
           <span>{{record.balance}}</span>
        </div>
        </a-table>
      </a-config-provider>
    </div>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import exportTips from "../../dialog/exportTipsModal.vue";
import { isNull, errMsg, warnMsg} from "../../../utils/CommonUtils";
export default {
  components:{
    exportTips
  },
  data() {
    return {
      title: this.$t('billDetail.title'),
      locale: zhCN,
      loading: true,
      tips: false,
      rowId: "",
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "100", "300", "500"],
        showSizeChanger: true,
        onChange: (current, pageSize) =>
          this.changeCurrentPage(current, pageSize),
        onShowSizeChange: (current, pageSize) =>
          this.changePageSize(current, pageSize),
          showTotal: () => {
              const count = this.pagination.total;
              return this.$i18n.t('billDetail.totalRecords', { count });
          },
      },
      search: {
        bankOrder: "",
        type: undefined,
        orderTime: undefined,
        visible: false,
      },
      types: [],
      datas: [],
      columns: [
        {
          title: this.$t('billDetail.columns.bankOrder'),
          dataIndex: "bankOrder",
          width:180,
          scopedSlots: { customRender: "bankOrder" },
        },
        {
          title: this.$t('billDetail.columns.payMethod'),
          dataIndex: "payMethod",
        },
        {
          title: this.$t('billDetail.columns.income'),
          dataIndex: "income",
          width:100,
          scopedSlots: { customRender: "income" },
        },
        {
          title: this.$t('billDetail.columns.balance'),
          dataIndex: "balance",
          width:100,
          scopedSlots: { customRender: "balance" },
        },
        {
          title: this.$t('billDetail.columns.operateTime'),
          dataIndex: "operateTime",
        },
        {
          title: this.$t('billDetail.columns.orderPersion'),
          dataIndex: "orderPersion",
          width:120,
        },
        // {
        //   title: "操作人",
        //   dataIndex: "phoneNumber",
        //   width:120,
        //   ellipsis: true,
        // },
        {
          title: this.$t('billDetail.columns.remark'),
          dataIndex: "remark",
          ellipsis: true,
        },
      ],
    };
  },

  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.init();
  },
  methods: {
    changeCurrentPage(currentPage, pageSize) {
      this.pagination.current = currentPage;
      this.pagination.pageSize = pageSize;
      this.rowId = "";
      this.init();
    },
    changePageSize(currentPage, pageSize) {
      this.pagination.current = 1;
      this.pagination.pageSize = pageSize;
      this.rowId = "";
      this.init();
    },
    changeShowExportTips(show, flag, val){
      this.tips = show;
      if(flag == 1){
        this.exportReq(val)
      }
    },
    exportData() {
      if(isNull(this.datas)){
        warnMsg(this.$t('billDetail.hint.warn.reportWarn'));
        return;
      }
      if(this.pagination.total > 10000){
         this.tips = true; 
      }else{
        this.exportReq(null);
      }
    },
    exportReq(val){
        let param = this.initParam()
        if(!isNull(val)){
           param.pageNo = val;
        }else{
          param.pageNo = 1;
        }
        this.axios.post(this.baseUrl + "exportBalanceDetail", param, {
          responseType: "blob",
          headers: { token: sessionStorage.getItem("token")},
        }).then((res) => {
          if (res.status == 200) {
            let link = document.createElement("a");
            let url = window.URL.createObjectURL(
              new Blob([res.data], {
                type: "application/vnd.ms-excel;charset=UTF-8;",
              })
            );
            link.style.display = "none";
            link.href = url;
            let fileName = window.decodeURI(res.headers['content-disposition'].split('=')[1], "UTF-8");
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); //下载完成移除元素
            window.URL.revokeObjectURL(url); //释放掉blob对象
          }
        }).catch(() => {
          errMsg(this.$t('billDetail.hint.error.systemErr'));
        });
    },
     /** 获取一个月前和当前日期 */
    getlastMonth() {
      var date = new Date();
      date.setDate(date.getDate() - 30); //往前推30天
      var month = date.getMonth() + 1;
      var fullyear =
        date.getFullYear() +
        "/" +
        (month > 9 ? month : "0" + month) +
        "/" +
        date.getDate();

      var date2 = new Date();
      var month2 = date2.getMonth() + 1;
      var fullyear2 =
        date2.getFullYear() +
        "/" +
        (month2 > 9 ? month2 : "0" + month2) +
        "/" +
        date2.getDate();
      var arr = [];
      arr[0] = fullyear;
      arr[1] = fullyear2;
      return arr;
    },
    isClick(bankOrder){
      if(bankOrder.substring(0, 1) == "Z"){
        return false;
      }else{
        return true;
      }
    },
    init() {
      this.axios.post(this.baseUrl + "getBankDetail", this.initParam(), {
          headers: { token: sessionStorage.getItem("token"),
          'Accept-Language': localStorage.getItem('locale') },
        }).then((res) => {
          this.loading = false;
          if (res.status == 200) {
            if (res.data.code == 0) {
              if (res.data.count > 0) {
                this.pagination.total = res.data.count;
                let data = res.data.data;
                let type = null;
                for (let i = 0; i < data.length; i++) {
                  type = data[i].type;
                  if (type == "1" || type == "4" || type == "9" || type == "12" || type == "14" || type == "16" || type == "20" || type == "22" ||
                    type == "24" || type == "26" || type == "27" || type == "28" || type == "35" || type == "40" || type == "42" || type == "45" 
                    || type == "46"
                  ) {
                    data[i].income = data[i].money; //收入
                    // data[i].expenses = 0
                  }else if(type == "2" || type == "3" || type == "5" || type == "8" || type == "13" || type=="15" || type == "17" ||type == "21" 
                  ||type == "23" || type=="25" || type=="29" || type=="30" || type=="31" || type=="34" || type=="36"  || type=="41" || type=="44" 
                  || type=="50" || type=="52" || type=="54" || type=="55" || type=="56"
                    
                  ){
                    data[i].income = "-" + data[i].money;//支出
                    // data[i].income = 0 
                  }
                }
                this.datas = data;
              }else if (res.data.count == 0) {
                 this.pagination.total = 0;
                 this.datas = [] 
              }
            }else if(res.data.code == -1){
              warnMsg(this.$t('billDetail.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }
          } else {
            errMsg(this.$t('billDetail.hint.error.getDateErr'));
          }
        }).catch(() => {
          this.loading = false;
          errMsg(this.$t('billDetail.hint.error.systemErr'));
        });
    },
    initParam() {
      var date = null;
      if (!isNull(this.search.orderTime)) {
        date = this.search.orderTime[0].format("YYYY/MM/DD") + "-" + this.search.orderTime[1].format("YYYY/MM/DD");
      }else{ //没有时间条件默认给最近一个月
        var arr = this.getlastMonth();
        date = arr[0] + "-" + arr[1];
        this.search.orderTime = [
          moment(new Date(arr[0]), "yyyy-mm-dd"),
          moment(new Date(arr[1]), "yyyy-mm-dd"),
        ];
      }
      var type = null;
      if (this.search.type == 0 || !isNull(this.search.type)) {
        type = this.search.type;
      }
      let param = {
        bankOrder: this.search.bankOrder,
        date: date,
        type: type,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
      };
      return param;
    },
    showSearch() {
      if (this.search.visible) {
        this.search.visible = false;
      } else {
        if (this.types.length == 0) {
          this.axios.post(this.baseUrl + "getDictItems?code=bpm_roamlink_balance_details_type", "", {
              headers: { token: sessionStorage.getItem("token") },
            }).then((res) => {
              if (res.status == 200) {
                this.types = res.data.data;
              } else {
                errMsg(this.$t('billDetail.hint.error.getTypeErr'));
              }
            }).catch(() => {
              errMsg(this.$t('billDetail.hint.error.systemErr'));
            });
        }
        this.search.visible = true;
      }
    },
    searchConfirm() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.rowId = "";
      this.init();
    },
    rowClassName(record, index) {
      if(index === this.rowId){
        return "select_row_bg_color";
      }else{
        return "";
      }
    },
    selectRow(record, index){
       return {
            on: {
               click: () => {
                  this.rowId = index;
               }
            }
        }
    },  
    toOrder(record){
       this.$router.push({
        name: "device",
        params: {
          subMenu: 3,
          menu: 100,
          bankOrder: record.bankOrder,
        },
      });
    }
  },
};
</script>
<style scoped>
@import '../../../assets/css/table.css';
/* 操作栏 */
.operate_nav {
  margin-top: 10px;
  text-align: right;
}

.operate_nav_title {
  /* 整行标题 */
  float: left;
  font-size: 24px;
  font-weight: bold;
  color: rgb(14, 14, 14);
}

.operate_nav_btn_frame {
  margin: 7px 0px 0px;
  display: inline-block;
  text-align: center;
  min-width: 70px;
  border: 1px solid rgb(160, 160, 160);
  height: 30px;
  line-height: 30px;
  margin-left: 15px;
  border-radius: 5px;
}

.operate_nav_btn_frame:hover {
  cursor: pointer;
  color: rgb(78, 78, 255);
  border: 1px solid rgb(78, 78, 255);
}

/*搜索框 */
.search_item_div {
  display: inline-block;
  width: 25%;
  margin-bottom: 10px;
}

.search_item {
  display: inline-block;
  width: calc(100% - 70px) !important;
}

.search_item_btn {
  float: right;
  display: block;
  width: 50px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  border: 1px solid gray;
  text-align: center;
}

.search_item_btn:hover {
  cursor: pointer;
  color: rgb(78, 78, 255);
  border: 1px solid rgb(78, 78, 255);
}

.search_item_label {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  width: 70px;
  text-align: right;
}

.search_show {
  display: block;
  text-align: left;
  margin: 20px 0px;
}

.search_hide {
  display: none;
}
::v-deep .ant-table-small {
  border: none;
}
</style>