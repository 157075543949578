<template>
  <div>
    <div style="text-align: left; margin-top: 10px">
      <span class="balance_title">{{ title }}</span>
    </div>
    <div class="balance_main">
      <div class="balance_info">
        <div style="margin-top: 5px; text-align: left; font-size: 15px">
          <span  style="color: #6f6f6fa6;"> {{this.$t('balanceDetail.consume')}} </span>
          <span style="float: right">
            {{ data.currencyStr }}&nbsp;&nbsp;{{ data.cost }}
          </span>
        </div>
        <div style="text-align: left; font-size: 15px">
          <span style="color: #6f6f6fa6;" > {{this.$t('balanceDetail.balance')}} </span>
          <span style="float: right">
            {{ data.currencyStr }}&nbsp;&nbsp;{{ data.balance }}
          </span>
        </div>
      </div>
      <div class="balance_recharge">
        <span class="recharge" style="margin-top: 5px; font-weight: 800"
          >{{this.$t('balanceDetail.recharge')}}</span
        >
        <span class="recharge recharge_money" @click.prevent="rechargeOnline"
          >{{this.$t('balanceDetail.rechargeOnline')}}</span>
      </div>
    </div>
    <div v-if="show" style="width: 100%; margin-top: 50px; text-align: left">
      <div>
        <span style="font-size: 16px; margin-right: 5px"> {{this.$t('balanceDetail.rechargeAmount')}}</span>
        <a-input
          style="width: 280px"
          size="large"
          v-model="money"
          :maxLength="13"
          allowClear
          :placeholder="this.$t('balanceDetail.rechargeAmountMsg')"
        >
          <span slot="suffix">¥</span>
        </a-input>
        <span style="color:red;margin-left:10px;">{{this.$t('balanceDetail.note')}}</span>
      </div>
      <div>
        <a-radio-group v-model="payMethod" style="margin-top: 25px">
          <div style="margin-bottom: 25px" :title="this.$t('balanceDetail.alipay')">
            <a-radio :value="1">
              <img src="../../../assets/img/zhifubao.png" />
            </a-radio>
          </div>
          <div style="margin-bottom: 25px" :title="this.$t('balanceDetail.wecaht')">
            <a-radio :value="2">
              <img
                src="../../../assets/img/wechat.png"
                style="margin-bottom: 2px"
              />
            </a-radio>
          </div>
          <div :title="this.$t('balanceDetail.cmb')">
            <a-radio :value="3">
              <img src="../../../assets/img/cmb.jpg" />
            </a-radio>
          </div>
        </a-radio-group>
      </div>
      <div class="recharge_btn">
        <span class="account_recharge_cancel" @click.prevent="cancel"
          >{{this.$t('balanceDetail.cancel')}}</span
        >
        <span class="account_recharge_confirm" @click.prevent="confirm"
          >{{this.$t('balanceDetail.confirm')}}</span>
      </div>
    </div>
    <pay-prompt
      :visible="visible"
      :recharge="rechargeInfo"
      :payMethod="payMethod"
      @pay-prompt-visible="showPayPrompt" 
    >
    </pay-prompt>
  </div>
</template>
<script>
import payPrompt from "../../dialog/balance/payPromptModal.vue"
import { errMsg,  isNull,  warnMsg } from "../../../utils/CommonUtils.js";
export default {
  components:{
    payPrompt
  },
  data() {
    return {
      title: this.$t('balanceDetail.title'),
      data: {},
      payMethod: 3,
      show: false,
      money: "",
      visible: false,
      rechargeInfo: "",
    };
  },
  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.init();
  },
  methods: {
    cancel() {
      this.show = false;
    },
    confirm() {
      let reg = /^[0-9]{1,}[\\.]?[0-9]{0,2}$/
      if(isNull(this.money)){
        warnMsg(this.$t('balanceDetail.hint.warn.moneyWarn1'))
        return;
      }
      if (!reg.test(this.money)) {
        warnMsg(this.$t('balanceDetail.hint.warn.moneyWarn2'));
        return; 
      }
      if(this.money <= 0){
        warnMsg(this.$t('balanceDetail.hint.warn.moneyWarn3'))
        return;
      }
      if(this.money.indexOf(".") == this.money.length - 1){
         this.money = this.money.replace(".", "");
      }
      if (this.payMethod == 1) { /** 支付宝充值 */
        this.axios.post(this.baseUrl + "online_recharge?online_recharge_givemoney=0" +
              "&online_recharge_money=" + this.money + "&pay_method=pay_alipay", "",
            { headers: { token: sessionStorage.getItem("token") } }
          ).then((res) => {
            if (res.status == 200) {
              if(res.data.mesFlag == "success"){
                this.rechargeInfo = res.data.params;
                let form = res.data.form;
                let newUrl = this.$router.resolve({ //新的窗口打开支付宝付款界面
                  path: "/balance/alipay",
                  query: {
                    form: form,
                  },
                });
                window.open(newUrl.href, "_blank");
                this.visible = true
              }else if(res.data.code == -1){
                warnMsg(this.$t('balanceDetail.hint.warn.loginWarn'));
                setTimeout(() => {
                  this.$router.push({ name: "login" });
                }, "2000");
              }else if(res.data.mesFlag == "error"){
                warnMsg(this.$t('balanceDetail.hint.warn.payWarn'));
              }
            } else {
              warnMsg(this.$t('balanceDetail.hint.warn.payWarn'));
            }
          }).catch(() => {
            errMsg(this.$t('balanceDetail.hint.error.systemErr'));
          });
      } else if (this.payMethod == 2) { /** 微信充值 */
        this.axios.post(this.baseUrl +"online_recharge?online_recharge_givemoney=0" +
              "&online_recharge_money=" +this.money +"&pay_method=pay_wxpay","",
              { headers: { token: sessionStorage.getItem("token") } }
          ).then((res) => {
            if (res.status == 200) {
              if(res.data.mesFlag == "success"){
                this.rechargeInfo = res.data.params;
                localStorage.setItem("wxpay",JSON.stringify(res.data.params))
                localStorage.setItem("form", res.data.form)
                let newUrl = this.$router.resolve({   //新的窗口打开微信付款界面
                  path: "/balance/wxPay",
                });
                window.open(newUrl.href, "_blank");
                this.visible = true
              }else if(res.data.code == -1){
                warnMsg(this.$t('balanceDetail.hint.warn.loginWarn'));
                setTimeout(() => {
                  this.$router.push({ name: "login" });
                }, "2000");
              }else if(res.data.mesFlag == "error"){
                warnMsg(this.$t('balanceDetail.hint.warn.payWarn'));
              }
            } else {
              warnMsg(this.$t('balanceDetail.hint.warn.payWarn'));
            }
          }).catch(() => {
            errMsg(this.$t('balanceDetail.hint.error.systemErr'));
          });
      } else if (this.payMethod == 3) {  /** 银行卡充值 */
        this.axios.post(this.baseUrl + "online_recharge?online_recharge_givemoney=0" +
              "&online_recharge_money=" + this.money + "&pay_method=pay_bank", "",
            { headers: { token: sessionStorage.getItem("token") } }
          ).then((res) => {
            if (res.status == 200) {
              if(res.data.mesFlag == "success"){
                this.rechargeInfo = res.data.params;
                let form = res.data.form;
                let newUrl = this.$router.resolve({ //新的窗口打开银行卡付款界面
                  path: "/balance/bankCardPay",
                  query: {
                    form: form,
                  },
                });
                window.open(newUrl.href, "_blank");
                this.visible = true
              }else if(res.data.code == -1){
                warnMsg(this.$t('balanceDetail.hint.warn.loginWarn'));
                setTimeout(() => {
                  this.$router.push({ name: "login" });
                }, "2000");
              }else if(res.data.mesFlag == "error"){
                warnMsg(this.$t('balanceDetail.hint.warn.payWarn'));
              }
            } else {
              warnMsg(this.$t('balanceDetail.hint.warn.payWarn'));
            }
          }).catch(() => {
            errMsg(this.$t('balanceDetail.hint.error.systemErr'));
          });
      }
    },
    init() {
      this.axios.post(this.baseUrl + "getCurMonthCost", "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.data = res.data.data
            } else if (res.data.code == -1) {
              warnMsg(this.$t('balanceDetail.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            }
          }
        }).catch(() => {
          errMsg(this.$t('balanceDetail.hint.error.systemErr'));
        });
    },
    /** 在线充值操作权限判断， 客服账号和分销账号(代付类型)没有操作权限*/
    rechargeOnline() {
      if(this.$store.state.role == 1 || sessionStorage.getItem("bpmUserType") == 1){
        warnMsg(this.$t('balanceDetail.hint.warn.operationWarn'))
        return;
      }
      if (this.show) {
        this.show = false;
      } else {
        this.show = true;
      }
    },
    showPayPrompt(val){
      this.visible = val;
    }
  },
};
</script>
<style scoped>
.balance_title {
  font-size: 24px;
  font-weight: bold;
  color: rgb(14, 14, 14);
}

.balance_main {
  display: flex;
  width: 100%;
  height: 30px;
  margin-top: 10px;
}

.balance_info {
  margin-right: 50px;
  width: 75%;
  border-top: 1px solid rgb(230, 230, 230);
}

.balance_sub_title {
  text-align: left;
  display: block;
  font-weight: bold;
  color: rgb(14, 14, 14);
  border-bottom: 2px solid rgb(187, 187, 187);
  padding-bottom: 8px;
  width: 100%;
  font-size: 24px;
}
.balance_recharge {
  display: inline-block;
  height: 60px;
  width: 200px;
  text-align: left;
  background-color: rgb(238 238 255);
}

.recharge {
  display: block;
  height: 25px;
  /* width: 70px; */
  line-height: 25px;
  padding-left: 8px;
}

.recharge_money {
  color: rgb(73, 182, 255);
}
.recharge_money:hover {
  text-decoration-line: underline;
  cursor: pointer;
}
.recharge_money:active {
  color: rgb(9, 154, 250);
}

.payment_method {
  height: 42px;
  line-height: 38px;
}

.account_recharge_cancel,
.account_recharge_confirm {
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  height: 33px;
  min-width: 100px;
  line-height: 33px;
}

.account_recharge_cancel {
  margin-right: 25px;
  border: 1px solid rgb(184, 184, 184);
}
.account_recharge_cancel:hover {
  cursor: pointer;
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}
.account_recharge_confirm {
  border: 1px solid rgb(78, 78, 255);
  background-color: rgb(78, 78, 255);
  color: white;
}
.account_recharge_confirm:hover {
  cursor: pointer;
  box-shadow: 0px 0px 2px 0px rgb(23, 23, 255);
}

.recharge_btn {
  margin-top: 20px;
  text-align: right;
  margin-right: calc(25% - 250px);
}
</style>