<template>
<!-- 报表界面：左边菜单栏和右边内容组成 -->
  <div class="report_div">
    <div class="leftmenu_div">
      <leftnav :activeMenu="activeMenu" :items="menus" @menu="menu"></leftnav>
    </div>
    <div class="content_div">
      <router-view name="content"></router-view>
    </div>
  </div>
</template>

<script>
import leftnav from "../../components/leftnav.vue";
export default {
  components: {
    leftnav,
  },
  data() {
    return {
      activeMenu: 90, //标识当前菜单
      menus: [],
    };
  },
  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.$store.commit("saveActiveIndex", 90);
    var arr = JSON.parse(sessionStorage.getItem("menus"));
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id == 90) {
        this.menus = arr[i].list;
        break;
      }
    }
    var flag = this.$route.params.subMenu;
    if(flag == 1){
      this.$store.commit('saveLeftMenu', 106);
      this.$router.push({name: "deviceDistribution",params:{
        date: this.$route.params.date,
      }});
    }else{
      this.menu(this.menus[0].id);
      this.$store.commit("saveLeftMenu", this.menus[0].id);
    }
   
  },

  methods: {
    menu(selectMenu) {
      if (selectMenu == 105) {
        this.$router.push({ name: "packetList" });
      } else if (selectMenu == 106) {
        this.$router.push({ name: "deviceDistribution" });
      }
    },
  },
};
</script>

<style scoped>
.report_div {
  width: 100%;
  display: flex;
}
.leftmenu_div {
  width: 10%;
}
.content_div {
  width: 90%;
}
</style>