<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="78%"
    :footer="null"
    :maskClosable="false"
    :dialogStyle="{ top: '6%' }"
    @cancel="handleCancel"
  >
    <div style="padding: 20px 26px 35px;height:430px;overflow-y:auto;">
      <div>
        <div class="title">{{$t('deviceDetail.basicInfo')}}</div>
        <table style="width: 100%">
          <tr style="line-height: 25px">
            <td class="tds table_td_label">{{$t('deviceDetail.tsid')}}</td>
            <td class="tdvs1">{{record.tsid}}</td>
            <td class="tds table_td_label">{{$t('deviceDetail.model')}}</td>
            <td class="tdvs1">{{record.model}}</td>
            <td class="tds table_td_label">{{$t('deviceDetail.batch')}}</td>
            <td class="tdvs1">{{record.batch}}</td>
            <td class="tds table_td_label">{{$t('deviceDetail.status')}}</td>
            <td class="tdvs1">{{record.status==0?$t('deviceDetail.normal'):record.status==2?$t('deviceDetail.stopped'):''}}</td>
          </tr>
          <tr style="line-height: 25px">
            <td class="tds table_td_label">{{$t('deviceDetail.imei')}}</td>
            <td class="tdvs1">{{record.imei}}</td>
            <td class="tds table_td_label">{{$t('deviceDetail.version')}}</td>
            <td class="tdvs1">{{record.version}}</td>
            <td class="tds table_td_label">{{$t('deviceDetail.bissgroup')}}</td>
            <td class="tdvs1">{{record.bissgroup}}</td>
            <td class="tds table_td_label">{{$t('deviceDetail.mac')}}</td>
            <td class="tdvs1">{{record.mac}}</td>
          </tr>
          <tr style="line-height: 25px">
            <td class="tds table_td_label">{{$t('deviceDetail.remark')}}</td>
            <td class="tdvs" colspan="7">
              <span
                class="note_wrap"
                :title="record.remark"
              >
                {{record.remark}}
              </span>
            </td>
          </tr>
        </table>
      </div>
      <div style="margin-top: 15px" v-if="orders.length > 0">
        <div class="title">{{$t('deviceDetail.packageOrders')}}</div>
        <table style="width: 100%" v-for="(order,i) in orders" :key="i">
          <tr style="line-height: 25px">
            <td class="tds table_td_label">{{$t('deviceDetail.orderNum')}}</td>
            <td class="tdvs1">{{order.order_num}}</td>
            <td class="tds table_td_label">{{$t('deviceDetail.countryCode')}}</td>
            <td class="tdvs1">{{order.countrycode}}</td>
            <td class="tds table_td_label">{{$t('deviceDetail.packetFlow')}}</td>
            <td class="tdvs1">{{order.Packet_flow}} KB</td>
            <td class="tds table_td_label">{{$t('deviceDetail.orderTime')}}</td>
            <td class="tdvs1">{{order.ordertime}}</td>
           
          </tr>
          <tr style="line-height: 25px">
             <td class="tds table_td_label">{{$t('deviceDetail.packetTime')}}</td>
            <td class="tdvs1">{{order.allTime}}</td>
            <td class="tds table_td_label">{{$t('deviceDetail.amount')}}</td>
            <td class="tdvs1" v-if="order.price != -1">{{order.price}}</td>
            <td class="tdvs1" v-else>-</td>
            <td class="tds table_td_label">{{$t('deviceDetail.status')}}</td>
            <td class="tdvs1">{{order.status}}</td>
            <td class="tds table_td_label">{{$t('deviceDetail.effectiveTime')}}</td>
            <td class="tdvs1">{{order.valid_startdate}} / {{order.valid_enddate}}</td>
          </tr>
          <tr> 
            <td colspan="4" style="text-align:left;padding-left:3px;"><span class="table_td_label">{{$t('deviceDetail.usedFlowMaxFlow')}}</span>{{order.usedflow}}/{{order.maxFlow}}</td>
          </tr>
        </table>
      </div>
      <div style="margin-top: 15px" v-if="autoSub.length > 0">
        <div class="title">{{$t('deviceDetail.autoRenewal')}}</div>
        <table style="width: 100%;margin-bottom:15px;" v-for="(sub, i) in autoSub" :key="i">
          <tr style="line-height: 25px">
            <td class="tds table_td_label table_td_label1">{{$t('deviceDetail.packageName')}}</td>
            <td class="tdvs tdvs_value">{{sub.attachName}}</td>
            <td class="tds table_td_label table_td_label1">{{$t('deviceDetail.packagePrice')}}</td>
            <td class="tdvs tdvs_value" v-if="sub.packetprice != -1">{{sub.packetprice}}</td>
            <td class="tdvs tdvs_value" v-else>-</td>
            <td class="tds table_td_label table_td_label2">{{$t('deviceDetail.packageCountry')}}</td>
            <td class="tdvs tdvs_value">{{sub.countryName}}</td>
          </tr>
          <tr style="line-height: 25px">
            <td class="tds table_td_label table_td_label1">{{$t('deviceDetail.operatorAccount')}}</td>
            <td class="tdvs tdvs_value" :title="sub.operatorid">{{sub.operatorid}}</td>
            <td class="tds table_td_label table_td_label1">{{$t('deviceDetail.orderStatus')}}</td>
            <td class="tdvs tdvs_value">{{sub.status==0?$t('deviceDetail.normal'):sub.status==2?$t('deviceDetail.stopped'):''}}</td>
            <td class="tds table_td_label table_td_label2">{{$t('deviceDetail.operationTime')}}</td>
            <td class="tdvs tdvs_value">{{sub.updatetime}}</td>
          </tr>
          <tr style="line-height: 25px">
            <td class="tds table_td_label table_td_label1">{{$t('deviceDetail.startTime')}}</td>
            <td class="tdvs tdvs_value">{{sub.begintime}}</td>
            <td class="tds table_td_label table_td_label1">{{$t('deviceDetail.endTime')}}</td>
            <td class="tdvs tdvs_value">{{sub.endtime}}</td>
            <td class="tds table_td_label table_td_label2">{{$t('deviceDetail.renewAfterUsedUp')}}</td>
            <td class="tdvs tdvs_value">{{sub.usedUp==1?$t('deviceDetail.yes'):sub.usedUp==0?$t('deviceDetail.no'):''}}</td>
          </tr>
        </table>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { errMsg, isNull,} from "../../../utils/CommonUtils.js";
export default {
  props: ["record"],
  data() {
    return {
      title: this.$t('deviceDetail.title'),
      showModal: false,
      orders:[],
      autoSub:[],
    };
  },
  created(){
  const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.showModal = true;
    this.init()
  },
  methods: {
    init() {
      if(isNull(this.record.tsid)){
        return;
      }
      this.axios.post(this.baseUrl + "getDetailOrder?tsid="+this.record.tsid,"",
          { headers: { token: sessionStorage.getItem("token"),
          'Accept-Language': localStorage.getItem('locale') } }).then((res) => {
            if (res.status == 200) {
              if (res.data.code == 0) {
                let data = res.data.data;
                for (let i = 0; i < data.length; i++) {
                  let order = data[i];
                  order.status = order.status==0?this.$t('deviceDetail.normal'):order.status==2?this.$t('deviceDetail.stopped'):''
                  order.allTime = order.time_unit==1?order.days + this.$t('deviceDetail.month'):order.time_unit==0?order.days==365?"1"+this.$t('deviceDetail.year'):order.days==730?"2"+this.$t('deviceDetail.year'):order.days + this.$t('deviceDetail.day'):'';
                }
                this.orders = data;
              }
            }
        }).catch(() => {
            errMsg(this.$t('deviceDetail.hint.error.systemErr'));
        });

      this.axios.post(this.baseUrl + "getDetailAuto?tsid="+this.record.tsid,"",
          { headers: { token: sessionStorage.getItem("token") } }).then((res) => {
            if (res.status == 200) {
               if (res.data.code == 0) {
                this.autoSub = res.data.data;
              }
            } 
        })
    },
    handleCancel() {
      this.$emit("detail-visible", false);
    },
  },
};
</script>
<style scoped>

.table_td_label {
  width: 80px;
  font-weight: bold;
}
.table_td_label1{
  width: 100px;
}
.table_td_label2{
  width: 150px;
}

.title {
  padding: 5px;
  font-weight: 800;
  font-size: 18px;
}
.tds {
  /* text-align: right; */
}

.tdvs {
  text-align: left;
}
.tdvs1{
 text-align: left;
 width: 190px;
}
.tdvs_value{
  display: inline-block;
  width: 150px;
  word-break:keep-all;/* 不换行 */
  white-space:nowrap;/* 不换行 */
  overflow:hidden;
  text-overflow:ellipsis;
}

.ivs {
  cursor: no-drop;
  border: 1px solid #c2c2c2;
  border-color: #cccccc;
  -webkit-box-shadow: 0 0 3px #c2c2c2;
  -moz-box-shadow: 0 0 3px #c2c2c2;
  box-shadow: 0 0 3px #c2c2c2;
}

/* 限制备注内容过多 */
.note_wrap {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-indent: 28px;
}

::v-deep .ant-modal-body {
  padding: 0px;
}
</style>