<template>
  <div class="progress_outer">
    <div class="progress_bg_2" :style="progressWith"></div>
  </div>
</template>
<script>
/** 首页流量池用到的进度条 */
export default {
  props: ["width", "color"],
  computed: {
    progressWith: function () {
      return "width:" + this.width + "%;background-color:" + this.color + ";";
    },
  },
};
</script>
<style scoped>
.progress_outer {
  width: 100%;
  background-color: #efefef;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 100px;
}
.progress_bg_1 {
  background-color: #1890ff;
  height: 8px;
  border-radius: 100px;
}

.progress_bg_2 {
  background-color: #ff0000;
  height: 8px;
  border-radius: 100px;
}
</style>
