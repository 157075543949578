<template>
  <div class="head">
    <div class="logo">
      <img src="../assets/img/logo.png" style="margin-top: 3px; height: 85%" />
    </div>
    <div class="nav">
      <ul class="menu">
        <li class="item" v-for="(menu, i) in menus" :key="i">
          <span :class="['submenu',$store.state.activeIndex == menu.id ? 'select' : '',]"
            @click.prevent="selectItem(menu.id)"
            >{{ $t(`navcon.menu.${menu.name}`) }}</span>
        </li>
      </ul>
    </div>
    <div class="loginInfo">
      <!-- 加一个中英文切换的按钮 -->
      <span class="operate_nav_btn_frame" @click="toggleLanguage">{{$t('navcon.button.language')}}</span>
      <a-icon
        type="bell"
        class="message"
        :title="$t('navcon.alarm')"
        @click.prevent="notify"
      />
      <span class="setting" :title="$t('navcon.api')" @click.prevent="openApi">API </span>
      <span>
        <a-dropdown>
          <span> {{ username }}<a-icon type="down" /> </span>
          <a-menu slot="overlay">
            <a-menu-item @click="updatePwd()">{{$t('navcon.button.updatePassword')}}</a-menu-item>
            <a-menu-item @click="logout()"> {{$t('navcon.button.logOut')}} </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </div>
    <!-- 修改密码模态框 -->
    <a-modal
      v-model="visible"
      :title="title"
      width="26%"
      :footer="null"
      :maskClosable="false"
    >
      <a-form-model ref="form" :model="form" :rules="formRules">
        <a-form-model-item ref="oldPassword" :label="$t('navcon.label.oldPassword')" prop="oldPassword">
          <a-input-password
            v-model.trim="form.oldPassword"
            autocomplete="off"
            :maxLength="25"
            allowClear
          />
        </a-form-model-item>
        <a-form-model-item ref="newPassword" :label="$t('navcon.label.newPassword')" prop="newPassword">
          <a-input-password
            v-model.trim="form.newPassword"
            :maxLength="25"
            autocomplete="off"
            allowClear/>
        </a-form-model-item>
        <a-form-model-item
          ref="confirmPassword"
          :label="$t('navcon.label.confirmPassword')"
          prop="confirmPassword"
        >
          <a-input-password
            v-model.trim="form.confirmPassword"
            :maxLength="25"
            autocomplete="off"
            allowClear/>
        </a-form-model-item>
        <div class="update_password">
          <a-button @click.prevent="cancelUpdatePassword"> {{$t('navcon.cancel')}} </a-button>
          <a-button
            style="margin-left: 10px"
            type="primary"
            @click.prevent="confirmUpdatePassword"
          >
            {{$t('navcon.confirm')}}
          </a-button>
        </div>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
/** 头部导航栏 */
import { errMsg, isNull, successMsg, warnMsg } from "../utils/CommonUtils.js";
export default {
  name: "navcon",
  data() {
    return {
      username: this.$t('navcon.hint.login'),
      visible: false,
      title: this.$t('navcon.title'),
      menus: [],
      form: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      formRules: {
        oldPassword: [
          { required: true, message: this.$t('navcon.hint.oldPassword'), trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: this.$t('navcon.hint.newPassword'), trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, message: this.$t('navcon.hint.confirmPassword'), trigger: "blur" },
        ],
      },
    };
  },
  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    /** 解决页面刷新时，部分数据丢失问题 */
    window.addEventListener("beforeunload", this.storeSelectMenu, false);
    this.username = sessionStorage.getItem("username");
    /** 解决刷新时，header菜单位置不正确问题 */
    if (isNull(sessionStorage.getItem("activeIndex"))) {
      this.$router.push({ name: "chart" });
    } else {
      this.$store.commit("saveActiveIndex",sessionStorage.getItem("activeIndex"));
      sessionStorage.removeItem("activeIndex");
    }
    this.save();
    this.menus = JSON.parse(sessionStorage.getItem("menus"));
  },
  methods: {
    toggleLanguage() {
    // 根据当前语言选择切换到另一种语言
    // if (this.$i18n.locale === 'zh') {
    //   this.$i18n.locale = 'en';
    // } else {
    //   this.$i18n.locale = 'zh';
    // }
    localStorage.setItem('locale', this.$i18n.locale =='en' ? 'zh' : 'en');
    location.reload();
    // window.location.reload();
  },
    cancelUpdatePassword() {
      this.visible = false;
      this.$refs.form.resetFields(); //重置提示和密码框信息
    },
    confirmUpdatePassword() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.oldPassword == this.form.newPassword) {
            errMsg(this.$t('navcon.hint.error.passwordErr1'));
            return;
          }
          if (this.form.newPassword != this.form.confirmPassword) {
            errMsg(this.$t('navcon.hint.error.passwordErr2'));
            return;
          }
          if (this.form.newPassword.length < 6) {
            warnMsg(this.$t('navcon.hint.warn.passwordWarn1'));
            return;
          }
          this.axios.post(this.baseUrl +"changePassWord?old=" + this.form.oldPassword + "&after=" +  this.form.newPassword,
              "", { headers: { token: sessionStorage.getItem("token") }, }
            ).then((res) => {
              if (res.status == 200) {
                if (res.data.code == 0) {
                  successMsg("修改成功");
                  this.visible = false;
                } else if (res.data.code == 1) {
                  warnMsg(this.$t('navcon.hint.warn.passwordWarn2'));
                } else if (res.data.code == -2) {
                  warnMsg(this.$t('navcon.hint.warn.updateWarn'));
                } else if (res.data.code == 2) {
                  warnMsg(this.$t('navcon.hint.warn.passwordWarn3'));
                } else if (res.data.code == -1) {
                  warnMsg(this.$t('navcon.hint.warn.loginWarn'));
                  setTimeout(() => {
                    this.$router.push({ name: "login" });
                  }, "2000");
                }
              } else {
                errMsg(this.$t('navcon.hint.error.updateWarn'));
              }
            }).catch(() => {
              errMsg(this.$t('navcon.hint.error.systemErr'));
            });
        } else {
          return false;
        }
      });
    },
    notify() {
      /** 空白界面打开vue界面 */
      let newUrl = this.$router.resolve({path: "/alarmInfo",});
      window.open(newUrl.href, "_blank");
    },
    openApi() {
      /** 空白界面打开.html文件，注意：.html文件存放位置必须是public文件夹下 */ 
      window.open("/roamlink-api.html", "_blank");
    },
    save() {
      //解决刷新时，用户角色数据丢失问题
      if (sessionStorage.getItem("role") == 0 ||!isNull(sessionStorage.getItem("role"))) {
        this.$store.commit("saveUserRole", sessionStorage.getItem("role"));
        sessionStorage.removeItem("role");
      }
    },
    updatePwd() {
      this.visible = true;
    },
    logout() {
      var lang = localStorage.getItem('locale');
      this.$router.push(`/login/${lang}`);
    },
    selectItem(index) {
      if (this.$store.state.activeIndex != index) {
        if (index == 86) {
          this.$router.push({ name: "chart" });
        } else if (index == 87) {
          this.$router.push({ name: "device" });
        } else if (index == 88) {
          this.$router.push({ name: "balance" });
        } else if (index == 89) {
          this.$router.push({ name: "account" });
        } else if (index == 90) {
          this.$router.push({ name: "report" });
        }
      }
    },
    storeSelectMenu() {
      sessionStorage.setItem("activeIndex", this.$store.state.activeIndex);
      sessionStorage.setItem("role", this.$store.state.role);
    },
  },
  destroy() {
    window.removeEventListener("beforeunload", this.storeSelectMenu, false);
  },
};
</script>
<style scoped>
.nav {
  display: inline-block;
}

.logo {
  display: inline-block;
  width: 180px;
  height: 50px;
  margin-left: 20px;
  vertical-align: top;
  box-sizing: border-box;
}
.menu {
  display: inline-block;
  margin: 0 0 0 80px;
}
.item {
  display: inline-block;
  list-style: none;
  height: 50px;
  line-height: 50px;
  width: 100px;
  text-align: center;
  font-size: 16px;
}

.submenu:hover {
  cursor: pointer;
}
.select {
  /* border-radius: 15px; */
  color: #1bb1f6;
  font-weight: bold;
  padding-bottom: 12px;
  border-bottom-color: #1bb1f6;
  border-bottom-style: inset;
  border-bottom-width: 2px;
}

/* 头部右侧样式 */
.loginInfo {
  float: right;
  margin-right: 30px;
  height: 50px;
  line-height: 50px;
}

.setting {
  margin-right: 20px;
  cursor: pointer;
}
.message {
  margin-right: 20px;
}
.el-dropdown-link {
  font-size: 16px;
}
/* 模态框 */
.update_password {
  text-align: right;
  margin-bottom: 10px;
}
::v-deep .ant-form-item-label {
  line-height: 15px;
}
::v-deep .ant-modal-body {
  padding: 3px 20px;
}

.operate_nav_btn_frame {
  margin: 7px 0px 0px;
  display: inline-block;
  text-align: center;
  min-width: 70px;
  border: 1px solid rgb(160, 160, 160);
  height: 30px;
  line-height: 30px;
  margin-left: 15px;
  border-radius: 5px;
  margin-right: 13px;
}
</style>