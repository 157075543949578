<template>
  <div style="margin: 30px">
    <div class="operate_nav">
      <span class="operate_nav_title">{{ title }}</span>
      <!-- <a-input
        type="text"
        class="iot_search_item"
        v-model.trim="search.email"
        placeholder="请输入email"
        autocomplete="off"
        allowClear
      >
      </a-input>
      <span class="search_item_btn" @click.prevent="searchConfirm">查询</span> -->
    </div>
    <div>
      <a-config-provider >
        <a-table
          :loading="loading"
          :columns="columns"
          :data-source="datas"
          :pagination="pagination"
          :rowClassName="rowClassName"
          :customRow="selectRow"
          :rowKey="
            (record, i) => {
              return i;
            }
          "
          size="middle"
        >
          <!-- <div slot="status" slot-scope="text, record">
            <span v-if="record.status == 1">成功</span>
            <span v-if="record.status == 0">失败</span>
          </div> -->
        </a-table>
      </a-config-provider>
    </div>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { errMsg, warnMsg } from "../../utils/CommonUtils.js";
export default {
  data() {
    return {
      title: this.$t('alarmInfo.title'),
      locale: zhCN,
      loading: true,
      rowId: "",
      search: {
        visible: false,
        email: "",
      },
      pagination: {
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        onChange: (current, pageSize) =>
          this.changeCurrentPage(current, pageSize),
        onShowSizeChange: (current, pageSize) =>
          this.changePageSize(current, pageSize),
          showTotal: () => {
              const count = this.pagination.total;
              return this.$i18n.t('alarmInfo.totalRecords', { count });
          },
      },

      datas: [],
      columns: [
        // {
        //   title: "Email",
        //   dataIndex: "email",
        //   width: 300,
        // },
        {
          title: this.$t('alarmInfo.time'),
          dataIndex: "sendDate",
          width: 230,
        },
        // {
        //   title: "状态",
        //   dataIndex: "status",
        //   scopedSlots: { customRender: "status" },
        //   width: 100,
        // },
        {
          title: this.$t('alarmInfo.content'),
          dataIndex: "content",
        },
      ],
    };
  },
  created() {
    const savedLocale = localStorage.getItem('locale');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.init();
  },
  methods: {
    //改变页码调用此函数
    changeCurrentPage(currentPage, pageSize) {
      this.pagination.current = currentPage;
      this.pagination.pageSize = pageSize;
      this.init();
    },
    //改变页记录数会调用此函数
    changePageSize(currentPage, pageSize) {
      this.pagination.pageSize = pageSize;
      this.pagination.current = 1;
      this.init();
    },

    init() {
      this.axios.post(this.baseUrl + "getAlarmMessages", this.initParam(), {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            this.loading = false;
            if (res.data.code == 0) {
              var datas = res.data.data.list;
              this.pagination.total = res.data.data.count;
              for (let i = 0; i < datas.length; i++) {
                datas[i].content = datas[i].content.replaceAll("<br/>", "，");
              }
              this.datas = datas;
            } else if (res.data.code == -1) {
              warnMsg(this.$t('alarmInfo.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -2) {
              errMsg(this.$t('alarmInfo.hint.error.systemErr'));
            }
          } else {
            this.loading = false;
            warnMsg(this.$t('alarmInfo.hint.warn.getDataErr'));
          }
        }).catch(() => {
          this.loading = false;
          errMsg(this.$t('alarmInfo.hint.error.systemErr'));
        });
    },
    initParam() {
      let param = {
        // email: this.search.email,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
      };
      return param;
    },
   rowClassName(record, index) {
      if(index === this.rowId){
        return "select_row_bg_color";
      }else{
        return "";
      }
    },
    selectRow(record, index){
       return {
            on: {
               click: () => {
                  this.rowId = index;
               }
            }
        }
    },  
  },
};
</script>
<style scoped>
@import '../../assets/css/table.css';
/* 操作栏 */
.operate_nav {
  margin-top: 36px;
  text-align: right;
}

.operate_nav_title {
  /* 整行标题 */
  float: left;
  font-size: 24px;
  line-height: 1;
  font-weight: bold;
  color: rgb(14, 14, 14);
}
::v-deep .ant-table-small {
  border: none;
}
</style>
 