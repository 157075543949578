<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="68%"
    :footer="null"
    :maskClosable="false"
    :dialogStyle="{ top: '10%' }"
    @cancel="handleCancel"
  >
    <div style="text-align:right;"><span @click="showMasterManage" class="master_flow">{{$t('flowPacket.masterPacket')}}</span>
    <master-flow-packet
      :visible="show.masterManage"
      :id="sonId"
      @master-flow-packet-visible="changeMasterManage"
    ></master-flow-packet>
      <update-price
        :visible="show.updatePrice"
        :record="record"
        @update-price-visible="changeUpdatePrice"
      ></update-price>
    </div> 
     <a-table
      :loading="loading"
      :columns="showColumns"
      :data-source="datas"
      :pagination="pagination"
      :rowKey="(record,i)=>{return i}"
      size="small"
    >
      <div slot="operation" slot-scope="text, record">
        <div v-if="record.bpmUserType != 1" class="row_record_operate row_record_edit">
          <a-icon
            class="edit"
            type="edit"
            @click.prevent="updatePrice(record)"
            :title="$t('flowPacket.updatePrice')"
          />
        </div>
        <div v-if="record.status == 1" class="row_record_operate row_record_del">
          <a-icon
            class="delete"
            type="delete"
            @click.prevent="del(record)"
            :title=" $t('flowPacket.delete')"
          />
        </div>
      </div>
      <div slot="count" slot-scope="text, record">
        <span>{{record.conut}}{{record.packet_unit=='G'?"GB":record.packet_unit}}</span>
      </div>
      <div slot="maxFlow" slot-scope="text, record">
        <span >{{record.maxFlow/1024}}</span>
      </div>
      <div slot="price_type" slot-scope="text, record">
        <span v-if="record.price_type == 0">{{record.days}}{{$t('flowPacket.day')}}</span>
        <span v-else>{{record.days}}{{$t('flowPacket.month')}}</span>
      </div>
    </a-table>
  </a-modal>
</template>
<script>
import { errMsg,warnMsg,successMsg } from '../../../utils/CommonUtils';
import masterFlowPacket from "./masterFlowPacketModal.vue"
import updatePrice from "./updatePriceModal.vue"
export default {
  props: ["visible","id"],
  components:{
    masterFlowPacket,
    updatePrice,
  },
  data() {
    return {
      title: this.$t('flowPacket.title'),
      sonId: "",
      loading:true,
      showModal: false,
      record: "",
      show:{
          masterManage: false,
          updatePrice: false,
      },
      pagination: {
        total: 0,
        pageSize: 10,
        current: 1,
        onChange: (current, pageSize) =>
          this.changeCurrentPage(current, pageSize),
          showTotal: () => {
              const count = this.pagination.total;
              return this.$i18n.t('flowPacket.totalRecords', { count });
          },
      },
      datas: [],
      showColumns: [
        {
          title: this.$t('flowPacket.columns.country'),
          dataIndex: "country",
        },
        {
          title: this.$t('flowPacket.columns.name'),
          dataIndex: "show_name",
        },
         {
          title: this.$t('flowPacket.columns.count'),
          dataIndex: "conut",
          scopedSlots: { customRender: "count" },
        },
        {
          title: this.$t('flowPacket.columns.maxflow'),
          dataIndex: "maxFlow",
          scopedSlots: { customRender: "maxFlow" },
        },
        {
          title: this.$t('flowPacket.columns.priceType'),
          dataIndex: "price_type",
          scopedSlots: { customRender: "price_type" },
        },
        {
          title: this.$t('flowPacket.columns.price_0_0'),
          dataIndex: "price_0_0",
        },
        {
          title: this.$t('flowPacket.columns.price_1_0'),
          dataIndex: "price_1_0",
        },
        {
          title: this.$t('flowPacket.columns.operation'),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ], 
    };
  },
  watch: {
    visible(val) {
      this.showModal = val;
      if(this.showModal){
        this.init()
        this.sonId = this.id
      }
    },
  },
  methods: {
    //改变页码调用此函数
    changeCurrentPage(currentPage) {
      this.pagination.current = currentPage;
    },
    changeMasterManage(val){
      this.show.masterManage = val
      this.init()
    },
    changeUpdatePrice(val,flag){
      this.show.updatePrice = val
      if(flag == 1){
        this.init()
      }
    },
    del(record) {
       let param =  {
           user: this.id,
           priceId: record.packetid,
           attachid: record.attachid,
           type: 2,
       }
      this.axios.post(this.baseUrl + "subAccountAddFlowPacet", param, {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              successMsg(this.$t('flowPacket.hint.success.delete'));
              this.init()
            } else if (res.data.code == -1) {
              warnMsg(this.$t('flowPacket.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -2) {
              warnMsg(this.$t('flowPacket.hint.warn.getAccountWarn'));
            }else if (res.data.code == -3) {
              warnMsg(this.$t('flowPacket.hint.warn.deleteWarn'));
            }
          } else {
            errMsg(this.$t('flowPacket.hint.error.deleteWarn'));
          }
        }).catch(() => {
          errMsg(this.$t('flowPacket.hint.error.systemErr'));
        });
    },
    handleCancel() {
      this.$emit("flow-visible", false);
    },
    init(){
      this.axios.post(this.baseUrl + "QueryCountryAndMoneyFoFlowPackage?sonId="+this.id,"",
        {headers: { token: sessionStorage.getItem("token") }}).then((res)=>{
        this.loading = false;
        if(res.status == 200){
           if(res.data.code == 0){
             this.datas = res.data.data
             this.pagination.total = res.data.count
           }else if(res.data.code == 1){
             this.datas = []
             this.pagination.total = 0
           }else if(res.data.code == -1){
              warnMsg(this.$t('flowPacket.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
           } 
        }else{  
          errMsg(this.$t('flowPacket.hint.error.getDataErr'))
        }
      }).catch(()=>{
        this.loading = false;
        errMsg(this.$t('flowPacket.hint.error.systemErr'))
      })
    },
    showMasterManage(){
      this.show.masterManage = true
    },
    updatePrice(record) {
      this.record = record
      this.show.updatePrice = true
    },
  },
};
</script>
<style scoped>

.master_flow{
  display: inline-block;
  min-width: 100px;
  height: 30px;
  border: 1px solid rgb(211, 211, 211);
  text-align: center;
  line-height: 30px;
  border-radius: 5px;
}
.master_flow:hover{
  cursor: pointer;
  color:  rgb(78, 78, 255);
  border: 1px solid  rgb(78, 78, 255);
}

.row_record_operate {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin-left: 10px;
}

.edit:hover,
.delete:hover {
  color: rgb(137, 208, 255);
  border: 1px solid rgb(137, 208, 255);
}
.edit:active,
.delete:active {
  color: rgb(66, 178, 252);
}


::v-deep .ant-modal-body{
  padding: 15px 24px 24px;
}
/* 表头样式 */
::v-deep .ant-table-thead > tr > th {
  background-color: white;
  padding: 12px 8px 3px !important;
}
/**表记录样式 */
::v-deep .ant-table-tbody > tr > td {
  padding: 2px 8px !important;
}
::v-deep .ant-table-small {
  border: none;
}
/* 分页位置 */
::v-deep .ant-table-pagination.ant-pagination {
  float: none;
  text-align: center;
}
</style>