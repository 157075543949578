const zh = {
    /** 请求 */
    request: {
        hint: {

        }
    },

    /** 响应 */
    response: {
        hint: {
            exception: "",
            warning: "",
            success: "",
            login: "",
        }
    },

    /** 公共 */
    common: {
        button: {
            cancel: "Cancel",
            confirm: "Confirm",
        }
    },

    /** 页面头部导航栏 */
    navcon: {
        menu: {
            首页: "Home",
            终端: "Terminal",
            计费: "Billing",
            账号: "Account",
            报表: "Report",
        },
        /** 修改密码模态框 */
        title: "Change password",
        alarm: "Alert notice",
        api: 'api',
        cancel: "Cancel",
        confirm: "Confirm",
        button: {
            updatePassword: "Change password",
            logOut: "Logout",
            language: '中文'
        },
        label: {
            oldPassword: 'Old password',
            newPassword: 'New password',
            confirmPassword: 'Confirm password'
        },
        hint: {
            oldPassword: 'Please enter old password',
            newPassword: 'Please enter new password',
            confirmPassword: 'Please enter a confirmation password',
            login: 'Please sign in',
            warn: {
                loginWarn: 'Please log in first',
                passwordWarn1: 'The length of the new password cannot be less than 6 digits',
                passwordWarn2: 'Old password is wrong',
                passwordWarn3: 'The new password is the same as the old password and the modification failed',
                updateWarn: 'Failed to modify',
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                updateErr: 'Failed to modify',
                passwordErr1: 'The password has not been changed, please refill the new password',
                passwordErr2: 'The new password does not match the confirmed password',
            }
        },
        error: {

        }
    },

    leftnav: {
        终端管理: 'Management',
        已购套餐: 'Orders',
        当日活跃终端: 'Active for the day',
        当月活跃终端: 'Active for the month',
        不活跃终端: 'Inactive',
        账单: 'Bill',
        账户流水: 'Account Details',
        余额: 'Balance',
        账号一览: 'List',
        分销账号管理: 'Distribution',
        客服账号管理: 'Customer service',
        套餐列表: 'Package list',
        终端地域分布: 'Terminal regional distribution',
    },

    /** 登录界面 */
    login: {
        title: "RoamLink CMP",
        formTitle: "Login",
        forget: "Forget password",
        signIn: "Login",
        refreshVerifyCode: "Can't see clearly, please change it",
        hint: {
            phone: "Phone number",
            password: "Password",
            verifyCode: "Verification code",
        },
        loginHint: {
            phone: 'Mobile phone number cannot be empty',
            phoneError: 'Incorrect phone number format',
            password: 'Password cannot be empty',
            verifyCode: 'Verification code cannot be empty',
            verifyCodeError: 'Verification code Error',
            usernameWarn: 'Please enter username or password',
            loginWarn1: 'Login failed, no interface access rights',
            loginWarn2: 'Login failed, no menu access',
            codeWarn: 'Please get the verification code again',
            warn: 'Unknown exception, please contact customer service',
            systemErr: 'System abnormality, please contact customer service',
            err: 'Request failed, please contact customer service'
        },
    },

    /** 首页界面 */
    chart: {
        date: 'Date',
        name: 'Name',
        address: 'Address',
        dayActive: 'Daily active terminal',
        changeDateMsg: 'Please select a date',
        balance: 'Balance',
        cost: 'Monthly consumption',
        total: 'Terminal Total',
        using: "In use",
        stop: 'Deactivate',
        tower: ' pcs',
        trafficPoolOverview: 'Traffic Pool Overview',
        trafficPool: 'Traffic',
        device_all: 'Number of terminals:',
        device_use: 'Active terminals:',
        device_stop: 'Unused terminal:',
        used: 'Used',
        poolTraffic: 'Pool traffic',
        flowExceeded: 'Exceeding traffic',
        distributionTrend: 'Terminal distribution trend',
        hint: {
            warn: {
                notData: 'No data',
                loginWarn: 'Please log in first',
                getDataWarn: 'Failed to obtain data',
                retryWarn: 'Please try again later. If multiple attempts are unsuccessful, please contact customer service.',
                trafficPoolWarn: 'No traffic pool information',
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',

            }
        }
    },

    /**============================================== */
    /** 终端界面 */
    device: {
        menu: {
            deviceManage: 'Terminal management',
            dayActive: 'Daily active terminal',
            order: 'Orders',
            monthActive: 'Monthly active terminal',
            flowPool: 'Ordered traffice pool',
            inactiveDevice: 'Inactive terminal',
        },
        deviceManage: {
            title: 'Terminal management',
            selectMsg: 'Please select',
            button: {
                operation: 'Operation',
                search: 'Search',
                report: 'Export',
                filter: 'Column',
            },
            searchList:{
                search: 'Query',
                reset: 'Reset',
                group: 'Group:',
                status: 'Status:',
                account: 'Belong:',
                model: 'Model:',
                batch: 'Batch:',
                version: 'Version:',
                tsid: 'TSID:',
                imei: 'IMEI:',
                note: 'Remarks:',
                searchAccountMsg: 'Please select a belonging account',
                inputModelMsg: 'Please enter the product model',
                inputBatchMsg: 'Please enter the production model',
                inputVersionMsg: 'Please enter the software version',
                inputTsidMsg: 'Please enter the number separated by commas in English',
                inputImeiMsg: 'Please enter IMEI separated by commas in English',
                inputNoteMsg: 'Please enter a remark',
            },

            deviceAuth: 'Terminal authorization',
            batchGroup: 'Terminal Grouping',
            buyPacket: 'Order Package',
            autoSubscribe: 'Auto renewal',
            cancelSubscribe: 'Cancel renewal',
            updateStatus: 'Change status',
            updateNote: 'Change remarks',
            

            totalDevice: 'Total terminals:',
            myDevice: 'My Terminals:',
            ownDevice: 'My Terminal',
            table: ' pcs',
            normal: 'Normal',
            deactivate: 'Deactivate',
            yes: 'Yes',
            no: 'No',
            operation: 'Operation',
            details: 'Details',
            purchasedPackage: 'Orders',

            totalRecords: '{count} records in total',

            hint:{
                success: {

                },
                warn: {
                    exportWarn: 'No data to export',
                    loginWarn: 'Please log in first',
                    retryWarn: 'Please try again later',
                    selectorColumnWarn: 'Please select at least one column',
                    deviceAuthWarn: 'Please select the terminal to be authorized',
                    batchGroupWarn: 'Please select the terminals to group',
                    updateNoteWarn: 'Please select the terminal to modify the remark',
                    updateStatusWarn: 'Please select the terminal to modify the status',
                },
                error: {
                    systemErr: 'System abnormality, please contact customer service',
                    getDataErr: 'Failed to query data'
                }  
            },

            columns:{
                tsid: 'TSID',
                imei: 'IMEI',
                belong: 'Belonging account',
                bissgroup: 'Business grouping',
                status: 'Status',
                autoRenewal: 'Auto renewal',
                usePackage: 'Use package',
                expireTime: 'Package expiration time',
                model: 'Model',
                version: 'Version',
                remark: 'Remark',
                operation: 'Operation',
            }
        },
        dayActive: {
            title: "",
        },
        order: {
            title: "",
        },
        monthActive: {
            title: "",
        },
        flowPool: {
            title: "",
        },
        inactiveDevice: {
            title: "",
        },
    },


    //终端授权弹窗
    deviceAuthModal:{
        title: 'Terminal authorization',
        mainAccount: 'Master account',
        selectAccountMsg: 'Please select a account',
        distributeDevice: 'Distribute terminal',
        tipTitle: 'Do devices that do not belong to the current account need to be authorized together?',
        hint: {
            success: {
                distribute: 'Distribute terminal successfully',
            },
            warn: {
                loginWarn: 'Please log in first',
                imeisWarn: 'No authorized terminals',
                distributeWarn1: 'Distribution failed',
                distributeWarn2: 'The terminal is an associated distributor',
                distributeWarn3: 'Subordinate account error',
                searchAccountWarn: 'Please select a account',
                accountWarn0: 'The terminal does not belong to the current account or a sub account of the current account, operation failed!',
                accountWarn2: 'No devices found',
                operationWarn: 'Operation failed. Please try again later!',
                retryWarn: 'Please try again later',
            },
            error: {
                longinErr: 'Not Logged In',
                distributeErr: 'Please log out and try again. If multiple attempts are invalid, please contact customer service',
                systemErr: 'System abnormality, please contact customer service',
                accountErr: 'Failed to obtain belonging account',
            }
        }
    },

    //终端分组(批量分组)弹窗
    batchGroup: {
        title: 'Batch grouping',
        clearGroup: 'Clear group',
        groupName: 'Group name:',
        existingGroup: 'Existing groups:',
        inputGroupNameMsg: 'Please enter a group name',
        cancel: "Cancel",
        define: 'Confirm',
        tipTitle: 'Do you want to modify devices that do not belong to the current account ?',
        hint:{
            success:{
                updateGroup: 'Successfully modified the group',
                clearGroup: 'Clear group successfully',
            },
            warn:{
                addGroupWarn: 'There are no terminals to add groups to',
                updateGroupWarn: 'Failed to modify group',
                updateGroupWarn2: 'Please log out and try again. If multiple attempts fail, please contact customer service',
                clearGroupWarn: 'There are no terminals to clear the group from',
                clearGroupWarn1: 'Failed to clear group',
                loginWarn: 'Please log in first',
                selectImeiWarn: 'Please select a terminal',
                inputGroupWarn: 'Please enter a new group name',
                accountWarn0: 'The terminal does not belong to the current account or a sub account of the current account, operation failed!',
                accountWarn2: 'No devices found',
                operationWarn: 'Operation failed. Please try again later!',
                retryWarn: 'Please try again later',
            },
            error:{
                longinErr: 'Not Logged In',
                systemErr: 'System abnormality, please contact customer service',
            }
        }
    },

    //套餐订购弹窗
    buyPacket:{
        title: 'Order Package',
        search: 'Search:',
        selectGroup: 'Please select a group',
        selectGroupMsg: 'Please select a group',
        selectCountryMsg: 'Please select a country',
        beginTime: 'Start time:',
        selectDateMsg: 'Please select a date',
        supportCountry: 'Supported Country',
        selectPacketMsg: 'Package selected:',
        cancel: "Cancel",
        define: 'Confirm',
        packageTime: 'Package duration',
        day: 'Day',
        month: 'Month',
        year: 'Year',
        effectiveMonth: 'Effective this month',
        nextEffectiveMonth: 'Effective in the following month',
        hint:{
            success:{
                subscribe: 'Successfully ordered',
            },
            warn:{
                packetWarn: 'Traffic packet information error, please contact customer service.',
                selectImeiWarn: 'Please select a terminal or group',
                selectPacketWarn: 'Please select a package',
                selectDateWarn: 'Please select the start time of the traffic packet',
                loginWarn: 'Please log in first',
                subscribeWarn2: 'Order failed. Please try again later',
                subscribeWarn3: 'No operation permission',
                subscribeWarn4: 'Terminal status error, unable to order',
                subscribeWarn5: 'Insufficient Balance',
                retryWarn: 'Please try again later',
            },
            error:{
                longinErr: 'Not Logged In',
                systemErr: 'System abnormality, please contact customer service',
                subscribeErr1: 'Batch order failed, please contact customer service',
            }
        }
    },

    //自动续订弹窗
    autoSubscribe:{
        title: 'Auto renewal',
        search: 'Search',
        selectGroup: 'Please select a group:',
        selectGroupMsg: 'Please select a group',
        selectCountryMsg: 'Please select a country',
        isOrderEndRenew: 'Do you want to renew after the order ends:',
        isTrafficEndRenew: 'Do you want to renew after using up the traffic:',
        renewDate: 'Renewal validity period:',
        foreverValidity: 'Permanently valid',
        searchTime: 'Please select a time',
        activePeriod: 'Effective period:',
        placeholder: ['Start date','End date'],
        supportCountry: 'Supported Country',
        selectPacketMsg: 'Package selected:',
        cancel: "Cancel",
        define: 'Confirm',
        day: 'Day',
        month: 'Month',
        year: 'Year',
        yes: 'Yes',
        no: 'No',
        hint:{
            success:{
                renew: 'Auto renewal successful',
            },
            warn:{
                selectImeiWarn: 'Please select a terminal or group',
                selectPacketWarn: 'Please select a package',
                loginWarn: 'Please log in first',
                renewWarn3: 'Warehousing failed',
                renewWarn4: 'There are orders that are not in use or in use',
                renewWarn5: 'Incorrect terminal ownership, please contact customer service',
                renewWarn6: 'No operation permission',
                renewWarn7: 'Please do not renew again',
                renewWarn8: 'Insufficient Balance',
                retryWarn: 'Please try again later',
                getDataWarn: 'Failed to query data. Please try again later',
            },
            error:{
                longinErr: 'Not Logged In',
                systemErr: 'System abnormality, please contact customer service',
                subscribeErr1: 'Batch order failed, please contact customer service',
            }
        }
    },

    //取消续订弹窗
    cancelSubscribe:{
        title: 'Cancel renewal',
        selectGroup: 'Please select a group',
        selectDevice: 'Terminal selected',
        cancel: "Cancel",
        define: 'Confirm',
        hint:{
            success:{
                cancelRenew: 'Successfully cancelled renewal',
            },
            warn:{
                selectImeiWarn: 'Please select a terminal or group',
                loginWarn: 'Please log in first',
                cancelRenewWarn1: 'Terminal query failed',
                cancelRenewWarn2: 'Terminal not binded',
                cancelRenewWarn3: 'Operation failed',
                cancelRenewWarn4: 'No auto renewal record',
                cancelRenewWarn5: 'No permission',
                cancelRenewWarn6: 'Please select the group or device',
                retryWarn: 'Please try again later',
            },
            error:{
                longinErr: 'Not Logged In',
                systemErr: 'System abnormality, please contact customer service',
            }
        }
    },

    //状态变更(修改状态)弹窗
    updateStatus:{
        title: 'Modify status',
        status: 'Status:',
        selectMsg: 'Please select',
        normal: 'Normal',
        deactivate: 'Deactivate',
        cancel: "Cancel",
        define: 'Confirm',
        tipTitle: 'Do you want to modify devices that do not belong to the current account ?',
        hint:{
            success:{
                update: 'Successfully modified',
                update1: 'Some terminals have been successfully modified'
            },
            warn:{
                updateWarn: 'There are no terminals to modify the status',
                loginWarn: 'Please log in first',
                updateWarn2: 'Terminal not binded',
                retryWarn: 'Please try again later',
                selectDevice: 'Please select a terminal',
                selectStatus: 'Please select a status',
                accountWarn0: 'The terminal does not belong to the current account or a sub account of the current account, operation failed!',
                getDeviceWarn: 'No devices found',
                operationWarn: 'Operation failed. Please try again later!',
            },
            error:{
                longinErr: 'Not Logged In',
                updateErr1: 'Failed to modify',
                updateErr6: 'Please select the terminal you want to modify',
                systemErr: 'System abnormality',
                systemErr2: 'System abnormality, please contact customer service',
            }
        }
    },

    //修改备注弹窗
    updateNote:{
        title: 'Change remarks',
        note: 'Remark',
        emptyNote: 'Clear remarks',
        inputNoteMsg: 'Please enter a remark',
        cancel: "Cancel",
        define: 'Confirm',
        tipTitle: 'Do you want to modify devices that do not belong to the current account ?',
        hint:{
            success:{
                update: 'Successfully modified',
                update1: 'Some terminals have been successfully modified',
                emptyNote: 'Clear remarks successfully'
            },
            warn:{
                addNoteDeviceWarn: 'There are no terminals to add remarks to',
                loginWarn: 'Please log in first',
                updateWarn2: 'Terminal not binded',
                updateWarn3: 'Please select the terminal or group you want to modify',
                retryWarn: 'Please try again later',
                emptyNoteWarn: 'There are no terminals to clear remarks from',
                selectDeviceWarn: 'Please select a terminal',
                inputNoteWarn: 'Please enter remark information',
                accountWarn0: 'The terminal does not belong to the current account or a sub account of the current account, operation failed!',
                getDeviceWarn: 'No devices found',
                operationWarn: 'Operation failed. Please try again later!',
            },
            error:{
                longinErr: 'Not Logged In',
                updateErr1: 'Failed to modify',
                updateErr6: 'Please select the terminal you want to modify',
                systemErr: 'System abnormality',
                systemErr2: 'System abnormality, please contact customer service',
                emptyNoteErr: 'Failed to clear remarks',
                emptyNoteErr2: 'Failed to clear remarks,please try again later',
                
            }
        }
    },

    //终端管理 详情弹窗
    deviceDetail: {
        title: 'Details',
        basicInfo: 'Basic information',
        tsid: 'TSID:',
        model: 'Model:',
        batch: 'Batch:',
        status: 'Status:',
        normal: 'Normal',
        stopped: 'Deactivate',
        imei: 'IMEI:',
        version: 'Version:',
        bissgroup: 'Business Grouping:',
        mac: 'MAC:',
        remark: 'Remarks:',
        packageOrders: 'Package order',
        orderNum: 'Order ID:',
        countryCode: 'Book country:',
        packetFlow: 'Package traffice:',
        orderTime: 'Order time:',
        packetTime: 'Package duration:',
        amount: 'Amount:',
        effectiveTime: 'Effective time:',
        usedFlowMaxFlow: 'Used traffic/maximum traffic:',
        autoRenewal: 'Auto renewal',
        packageName: 'Package name:',
        packagePrice: 'Package price:',
        packageCountry: 'Package country:',
        operatorAccount: 'Operation account:',
        orderStatus: 'Order status:',
        operationTime: 'Operation time:',
        startTime: 'Start time:',
        endTime: 'End time:',
        renewAfterUsedUp: 'Do you want to renew after using up the traffic:',
        yes: 'Yes',
        no: 'No',
        year: 'Year',
        month: 'Month',
        day: 'Day',
        hint: {
            error: {
                systemErr: 'System abnormality, please contact customer service'
            }
        }
    },


    //终端管理 已购套餐弹窗
    purchasedPacket: {
        title: 'Orders',
        unsubscribePacket: 'Unsubscribe package?',
        unsubscribed: 'Unsubscribe',
        ok: 'Confirm',
        cancle: "Cancel",
        status: {
            normal: 'Normal',
            stopped: 'Deactivated',
            unsubscribed: 'Unsubscribed',
        },
        columns: {
            orderNum: 'Order ID',
            startTime: 'Start time',
            endTime: 'End time',
            orderTime: 'Order time',
            createUser: 'Account number',
            flowCount: 'Package traffic(KB)',
            usedFlow: 'Used Traffic(KB)',
            countryCn: 'Package country',
            status: 'Status',
            operation: 'Operation',
        },
        hint: {
            success: {
                unsubscribe: 'Unsubscribed successfully',
            },
            warn: {
                getDataWarn: 'Failed to query data',
                getDataWarn1: 'Failed to query data, incorrect tsid format',
                getDataWarn2: 'Query failed. Please try again later',
                loginWarn: 'Please log in first',
                retryWarn: 'Please try again later',
                packetWarn: 'Package information abnormality',
                unsubscribeWarn: 'Unsubscribe failed, please contact customer service',
                cancelWarn: 'Cancelled',
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
            }
        }
    },


    



    //已购套餐页
    deviceOrder: {
        title: 'Orders',
        system: 'System',
        totalRecords: '{count} records in total',
        unsubscribePacket: 'Unsubscribe package?',
        ok: 'Confirm',
        cancle: "Cancel",
        searchList: {
            order: 'Order ID:',
            country: 'Country:',
            orderTime: 'Order time:',
            startTime: 'Start time:',
            tsid: 'TSID:',
            orderMsg: 'Please enter the order number',
            countryMsg: 'Please select a country',
            placeholder: ['Start date','End date'],
            tsidMsg: 'Please enter the number separated by commas in English',
        },
        button: {
            check: 'Enable',
            close: 'Deactivate',
            search: 'Search',
            report: 'Export',
            filter: 'Column',
            inquire: 'Query',
            unsubscribe: 'Unsubscribe',
            refund: 'Refund',
        },
        showcolumns: {
            tsid: 'TSID',
            order: 'Order ID',
            flow: 'Package traffic(KB)',
            used: 'Used(KB)',
            price: 'Price(yuan)',
            countryName: 'Country',
            orderStatus: 'Status',
            begin: 'Start time',
            payment: 'Payment account',
            operation: 'Operation',
        },
        columns: {
            tsid: 'TSID',
            order: 'Order ID',
            flow: 'Total traffic',
            used: 'Used',
            price: 'Price',
            countryName: 'Country',
            orderStatus: 'Status',
            subtime: 'Order time',
            begin: 'Start time',
            end: 'End time',
            payment: 'Payment account',
            homeAccount: 'Account number',
            belongSystem: 'Ordering system',
            note: 'Remark',
            operation: 'Operation',
        },
        menus: {
            allOrder: 'All orders',
            effectiveOrder: 'Effective order',
            deactivateOrder: 'Deactivate order',
            unsubscribeOrder: 'Unsubscribe Order',
        },
        hint: {
            success: {
                unsubscribe: 'Unsubscribed successfully',
                updateStatus: 'Successfully modified status',
            },
            warn: {
                selectedRowWarn1: 'Please select a record',
                selectedRowWarn2: 'Each operation cannot exceed 100 units',
                checkWarn: 'Closed orders cannot be activated',
                closeWarn: 'Closed orders cannot be deactivated',
                reportWarn: 'No data to export',
                loginWarn: 'Please log in first',
                retryWarn: 'Please log out and try again. If multiple attempts are invalid, please contact customer service',
                selectorColumnWarn: 'Please select at least one column',
                packetWarn: 'Package information abnormality',
                unsubscribeWarn: 'Unsubscribe failed, please contact customer service',
                cancelWarn: 'Cancelled',
                updateStatusWarn1: 'This order cannot modify status',
                updateStatusWarn2: 'Modification not supported in the current status',
                getDataWarn: 'Failed to query. Please try again later'
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                getDataErr: 'Failed to query data',
                updateStatusErr: 'Failed to modify status',
            }
        }

    },


    //当日活跃终端
    deviceDayActive: {
        title: 'Active terminals for the day',
        totalRecords: '{count} records in total',
        online: 'Online',
        offline: 'Offline',
        button: {
            search: 'Search',
            report: 'Export',
            reload: 'Refresh',
            inquire: 'Query',
        },
        searchList: {
            useCountry: 'Country:',
            status: 'Status:',
            date: 'Date:',
            tsid: 'TSID:',
            imei: 'IMEI:',
            useCountryMsg: 'Please select a country',
            selectMsg: 'Please select',
            dateMsg: 'Please select a date',
            tsidMsg: 'Please enter the number separated by commas in English',
            imeiMsg: 'Please enter IMEI separated by commas in English',
        },
        columns: {
            tsid: 'TSID',
            imei: 'IMEI',
            account: 'Account',
            date: 'Date',
            remainTime: 'Usage Duration(Min)',
            flow: 'Using Traffic(KB)',
            country: 'Using Country',
            status: 'Online status',
        },
        hint: {
            success: {
                reload: 'Refresh successful',
            },
            warn: {
                reportWarn: 'No data to export',
                loginWarn: 'Please log in first',
                getDateWarn: 'Failed to query data. Please try again later',

            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
            }
        }
    },


    //当月活跃终端
    deviceMonthActive: {
        title: 'Active terminals for the month',
        totalRecords: '{count} records in total',
        online: 'Online',
        offline: 'Offline',
        button: {
            search: 'Search',
            report: 'Export',
            inquire: 'Query',
        },
        searchList: {
            useCountry: 'Country:',
            date: 'Date:',
            tsid: 'TSID:',
            imei: 'IMEI:',
            useCountryMsg: 'Please select a country',
            dateMsg: 'Please select a date',
            tsidMsg: 'Please enter the number separated by commas in English',
            imeiMsg: 'Please enter IMEI separated by commas in English',
        },
        columns: {
            tsid: 'TSID',
            imei: 'IMEI',
            account: 'Account',
            date: 'Date',
            remainTime: 'Usage Duration(Min)',
            flow: 'Using Traffic(KB)',
            country: 'Using Country',
        },
        hint: {
            success: {
                reload: 'Refresh successful',
            },
            warn: {
                reportWarn: 'No data to export',
                loginWarn: 'Please log in first',
                getDateWarn: 'Failed to query data. Please try again later',

            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                retryErr: 'Please log out and try again. If multiple attempts are invalid, please contact customer service',
            }
        }
    },


    //不活跃终端
    deviceInactive: {
        title: 'Inactive terminal',
        totalRecords: '{count} records in total',
        normal: 'Normal',
        deactivate: 'Deactivate',
        uninitialized: 'Uninitialized',
        recycle: 'Recycle',
        oneMonth: 'One month',
        threeMonth: 'Three month',
        sixMonth: 'Six month',
        oneYear: 'One year',
        yes: 'Yes',
        no: 'No',
        operation: 'Operation',
        button: {
            check: 'Enable',
            close: 'Deactivate',
            search: 'Search',
            inquire: 'Query',
            detail: 'Details',
        },
        searchList:{
            group: 'Group:',
            status: 'Status:',
            days: 'Shutdown duration:',
            tsid: 'TSID:',
            imei: 'IMEI:',
            selectMsg: 'Please select',
            tsidMsg: 'Please enter the number separated by commas in English',
            imeiMsg: 'Please enter IMEI separated by commas in English',
        },
        columns: {
            tsid: 'TSID',
            imei: 'IMEI',
            date: 'Last used date',
            days: 'Shutdown duration(days)',
            status: 'Status',
            auto: 'Auto renewal',
            bissgroup: 'Business grouping',
            remark: 'Remark',
            operation: 'Operation'
        },
        hint: {
            success: {
                updateStatus: 'Successfully modified status',

            },
            warn: {
                selectedRowWarn1: 'Please select a record',
                selectedRowWarn2: 'Each operation cannot exceed 100 units',
                loginWarn: 'Please log in first',
                retryWarn: 'Please log out and try again. If multiple attempts are invalid, please contact customer service',
                updateWarn: 'Failed to modify',
                updateStatusWarn: 'Failed to modify status'
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                getDataErr: 'Failed to query data',
            }
        }

    },


    //终端日消费
    deviceDayCosts: {
        title: 'Terminal daily consumption',
        date: 'Date:',
        selectDateMsg: 'Please select a date',
        columns: {
            tsid: 'TSID',
            date: 'Date',
            used: 'Usage Duration(Min)',
            flow: 'Using Traffic(KB)',
            country: 'Using Country',
        },
        hint: {
            success: {
            },
            warn: {
                getDataWarn: 'Failed to query data',
                loginWarn: 'Please log in first',
                retryWarn: 'Please log out and try again. If multiple attempts are invalid, please contact customer service',
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
            }
        }
    },


    //终端使用详情
    deviceUseDetail: {
        title: 'Terminal usage details',
        changeOperator: 'Switch operator:',
        selectMsg: 'Please select',
        date: 'Date:',
        selectDateMsg: 'Please select a date',
        reload: 'Refresh',
        initialHearbeat: 'Initial heart beat',
        normalHearbeat: 'Normal Heart Beat',
        reissueHearbeat: 'Reissue heart beat',
        totalRecords: '{count} records in total',
        content: "The system will start switching to the operator after the next heartbeat. The new access operator is {val}。",
        okText: "Confirm",
        cancelText: "Cancel",
        columns: {
            tsid: 'TSID',
            country: 'Country',
            type: 'Type',
            date: 'Time',
            flow: 'Traffic increment',
            newCount: 'Heartbeats',
            operator: 'Access operator',
            signalType: 'Access network type',
            signal: 'Signal strength',
            status: 'Status',
        },
        hint: {
            success: {
                changeOperator: 'Successfully switched operator',
                reload: 'Refresh successful'
            },
            warn: {
                getDataWarn: 'Failed to query data',
                getDataWarn2: 'No data found',
                loginWarn: 'Please log in first',
                retryWarn: 'Please log out and try again. If multiple attempts are invalid, please contact customer service',
                changeOperatorWarn: 'Switch the operator to be consistent with the operator to be modified',
                changeOperatorWarn2: 'Switching operator acquisition failed',
                cancelWarn: 'Cancelled',
                dateWarn: 'Time error',
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                changeOperatorErr: 'Failed to switch operator',
                getDateErr: 'Failed to query data',
                reloadErr: 'Failed to refresh'
            }
        }
    },

    /**============================================== */


















    /**============================================== */
    //计费

    //账单
    bill: {
        title: 'Bill',
        totalRecords: '{count} records in total',
        button: {
            queryDeviceDetail: 'Device details',
            queryDetail: 'Usage details',
            report: 'Export',
        },
        columns: {
            flowPoolName: 'Traffic pool name',
            month: 'Month',
            tsNum: 'Number of activate terminals',
            flowPoolTotalFlow: 'Total traffic(MB)',
            flowPoolUseFlow: 'Using traffic(MB)',
            flowPoolUnitPrice: 'Price(￥)',
            flowPoolPrice: 'Total amount(￥)',
            excessFlow: 'Excess traffic(MB)',
            excessPrice: 'Excess unit price(￥/MB)',
            excessCost: 'Total excess expenses(￥)',
            totalCost: 'Total cost(￥)',
        },
        hint: {
            success: {
            },
            warn: {
                reportWarn: 'No data to export',
                getDataWarn: 'Failed to query data',
                loginWarn: 'Please log in first',
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                getDateErr: 'Failed to query data',
            }
        }

    },

    //查看设备详单（跳转 设备详情）
    toDeviceDetail: {
        title: 'Device details',
        report: 'Export',
        totalRecords: '{count} records in total',
        columns: {
            tsid: 'TSID',
            imei: 'IMEI',
            month: 'Month',
            poolname: 'Package area',
            showname: 'Package type',
            country: 'Using Country',
            duration: 'Usage duration(Min)',
            flow: 'Using traffic(MB)',
            unitPrice: 'Package fee',
            price: 'Excess traffic fee',
        },
        hint: {
            success: {
            },
            warn: {
                getDataWarn: 'Failed to query data',
                loginWarn: 'Please log in first',
                reportWarn: 'No data to export',
                retryWarn: 'Please log out and try again. If multiple attempts are invalid, please contact customer service',
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                getDateErr: 'Failed to query data',
            }
        }

    },


    //查看使用明细
    flowPoolUseDetail: {
        title: 'The traffic pool usage details',
        report: 'Export',
        totalRecords: '{count} records in total',
        columns: {
            tsid: 'TSID',
            imei: 'IMEI',
            data: 'Time',
            used: 'Usage Duration(Min)',
            flow: 'Using Traffic(KB)',
            country: 'Country',
        },
        hint: {
            success: {
            },
            warn: {
                getDataWarn: 'Failed to query data',
                loginWarn: 'Please log in first',
                reportWarn: 'No data to export',
                retryWarn: 'Please log out and try again. If multiple attempts are invalid, please contact customer service',
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                getDateErr: 'Failed to query data',
            }
        }

    },

    //查看使用明细
    flowPoolDetail: {
        title: 'The traffic pool usage details',
        report: 'Export',
        columns: {
            flowPoolName: 'Traffic pool name',
            tsid: 'TSID',
            date: 'Date',
            tsNum: 'Number of terminals',
            flowPoolTotalFlow: 'Total traffic(MB)',
            flowPoolUseFlow: 'Using traffic(MB)',
            flowPoolUnitPrice: 'Price(￥)',
            flowPoolPrice: 'Total amount(￥)',
            excessFlow: 'Excess traffic(MB)',
            excessPrice: 'Excess unit price(￥/MB)',
            excessCost: 'Total excess expenses(￥)',
            totalCost: 'Total cost(￥)',
        },
        hint: {
            success: {
            },
            warn: {
                getDataWarn: 'Failed to query data',
                loginWarn: 'Please log in first',
                reportWarn: 'No data to export',
                retryWarn: 'Please log out and try again. If multiple attempts are invalid, please contact customer service',
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                getDateErr: 'Failed to query data',
            }
        }

    },


    //账户流水
    billDetail: {
        title: 'Account Details',
        search: 'Search',
        report: 'Export',
        totalRecords: '{count} records in total',
        searchList: {
            bankOrder: 'Order ID:',
            type: 'Type:',
            orderTime: 'Order time:',
            placeholder: ['Start time', 'End time'],
            bankOrderMsg: 'Please enter order number',
            selectMsg: 'Please select',
            inquire: 'Query',

        },
        columns: {
            bankOrder: 'Order ID',
            payMethod: 'Transaction type',
            income: 'Income and expense(￥)',
            balance: 'Blance(￥)',
            operateTime: 'Operation time',
            orderPersion: 'Transaction account',
            remark: 'Remark',
        },
        hint: {
            success: {
                
            },
            warn: {
                getDataWarn: 'Failed to query data',
                loginWarn: 'Please log in first',
                reportWarn: 'No data to export',
                retryWarn: 'Please log out and try again. If multiple attempts are invalid, please contact customer service',
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                getDateErr: 'Failed to query data',
                getTypeErr: 'Failed to obtain transaction type',
            }
        }
    },


    //余额
    balanceDetail: {
        title: 'Balance',
        consume: 'Current month consumption',
        balance: 'Balance',
        recharge: 'Recharge',
        rechargeOnline: 'Online recharge',
        rechargeAmount: 'Recharge amount',
        rechargeAmountMsg: 'Please enter amount',
        note: 'Note: If there are decimal places, only two decimal places are supported.',
        alipay: 'Alipay payment',
        wecaht: 'WeChat payment',
        cmb: 'Bank card payment',
        cancel: "Cancel",
        confirm: 'Immediate payment',
        hint: {
            success: {
                
            },
            warn: {
                getDataWarn: 'Failed to query data',
                loginWarn: 'Please log in first',
                reportWarn: 'No data to export',
                retryWarn: 'Please log out and try again. If multiple attempts are invalid, please contact customer service',
                moneyWarn1: 'Please enter the recharge amount, it must be greater than 0',
                moneyWarn2: 'Incorrect format of recharge amount',
                moneyWarn3: 'The recharge amount must be greater than 0',
                payWarn: 'Payment failed',
                operationWarn: 'No operation permissions',
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                getDateErr: 'Failed to query data',
                getTypeErr: 'Failed to obtain transaction type',
            }
        }
    },

    /**============================================== */

















    /**============================================== */


    //账号
    //账号一览
    accountDescribe: {
        title: 'Account list',
        mainAccount: 'Master account',
        customerAccount: 'Customer service account',
        distributeAccount: 'Distribution account',
        box0: {
            title1: '1.The master account can create and deactivate customer service and distribution accounts;',
            title2: '2.The main account can authorize its own terminal to be used by the distribution account;',
            title3: '3.The master account can configure different prices for the distribution account, and the difference will be returned to the master account in real time;',
            title4: '4.The master account can hide the package price of the distribution account;'
        },
        box1: {
            title1: '1.The customer service account is mainly used to assist the Master account;',
            title2: '2.Compared to the master account, the customer service account has the same permission range , it can viewe and export;',
            title3: '3.The customer service account does not have permission to operate and is not allowed to modify terminal data;'
        },
        box2: {
            title1: '1.Distribution accounts are mainly used to develop subordinate customers and distributors;',
            title2: '2.The functional permissions of the distribution account are consistent with the master account,But the range of authority is limited to the terminals authorized by the main account;',
            title3: '3.There are two types of distribution accounts: self payment and payment on behalf. Self payment accounts need to be recharged on their own, and fees incurred for terminal use are deducted from the distribution account; The payment on behalf account cannot be recharged, and the fees incurred from terminal use will be deducted from the master account;'
        },
        hint: {
            success: {
                
            },
            warn: {
                getDataWarn: 'Failed to obtain data',
                loginWarn: 'Please log in first',
                retryWarn: 'Please try again later. If multiple attempts are unsuccessful, please contact customer service.'
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
            }
        }
    },


    //分销账号管理
    subAccount: {
        title: 'Distribution account management',
        operateAccount: 'Create subaccount',
        behalfpay: 'Payment on behalf',
        selfPay: 'Self payment',
        deactivate: 'Deactivate',
        enable: 'Enable',
        hide: 'Hide',
        show: 'Show',
        configurePackage: 'Configure Package Price',
        updateName: 'Modify Name',
        updatePassword: 'Change password',
        recharge: 'Recharge',
        totalRecords: '{count} records in total',
        columns: {
            phone: 'Account',
            mainA: 'Master account',
            relaname: 'Name',
            balance: 'Blance(￥)',
            type: 'Type',
            show: 'Do you want to display the package price',
            status: 'Status',
            date: 'Creation time',

        },
        hint: {
            success: {
                update: 'Successfully modified'
            },
            warn: {
                getDataWarn: 'Failed to obtain data',
                loginWarn: 'Please log in first',
                retryWarn: 'Please try again later. If multiple attempts are unsuccessful, please contact customer service.',
                getAccountWarn: 'No account found',
                permissionWarn: 'No permission',
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                getDataErr: 'Failed to obtain data',
            }
        }
    },



    //创建分销账号弹窗
    createAccount: {
        title: 'Create Distribution Account',
        phone: 'Cellphone number:',
        verifyCode: 'Verification code:',
        username: 'Account name:',
        password: 'Password:',
        confirmPassword: 'Confirm password:',
        bpmUserType: 'Settlement methods:',
        price: 'Traffic packet price:',
        behalfpay: 'Payment on behalf',
        selfPay: 'Self payment',
        deactivate: 'Deactivate',
        enable: 'Enable',
        hide: 'Hide',
        show: 'Show',
        cancel: "Cancel",
        add: 'Add',
        phoneMsg: 'Please enter a mobile phone number',
        verifyCodeMsg: 'Please enter the verification code',
        usernameMsg: 'Please enter a account name',
        passwordMsg: 'Please a password',
        confirmPasswordMsg: 'Please enter a confirmation password',
        codeMsg1: 'Send verification code',
        codeMsg2: 'Retrieve after 60 seconds',
        codeMsg3: "Retrieve after 'seconds",
        hint: {
            success: {
                create: 'Created successfully',
                update: 'Successfully modified',
                sendMsg: 'SMS sent successfully, please pay attention',
            },
            warn: {
                getDataWarn: 'Failed to obtain data',
                loginWarn: 'Please log in first',
                retryWarn: 'Please try again later. If multiple attempts are unsuccessful, please contact customer service.',
                getAccountWarn: 'No account found',
                permissionWarn: 'No permission',
                phoneWarn1: 'Mobile phone number cannot be empty',
                phoneWarn2: 'Incorrect phone number format',
                codeWarn1: 'Verification code cannot be empty',
                codeWarn2: 'Verification code Error',
                codeWarn3: 'Verification code has expired',
                usernameWarn: 'Account name cannot be empty',
                passwordWarn1: 'Password cannot be empty',
                passwordWarn2: 'The password length cannot be less than 6 digits',
                confirmPasswordWarn1: 'Confirm password cannot be empty',
                confirmPasswordWarn2: 'The password and confirmation password do not match',
                accountWarn1: 'You are not the master account and do not have operation permissions',
                accountWarn2: 'Account already exists',
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                getDataErr: 'Failed to obtain data',
                sendMsgErr: 'Failed to send SMS'
            }
        }
    },


    //配置套餐价格（流量包管理）弹窗
    flowPacket: {
        title: 'Traffic packet management',
        masterPacket: 'Master account traffic packet',
        updatePrice: 'Modify price',
        delete: 'Delet',
        day: 'Day',
        month: 'Month',
        totalRecords: '{count} records in total',
        columns: {
            country: 'Country',
            name: 'Name',
            count: 'Pacakge traffic',
            maxflow: 'Maximum traffic (MB)',
            priceType: 'Package duration',
            price_0_0: 'Current price(￥)',
            price_1_0: 'Sub account price(￥)',
            operation: 'Operation',   
        },
        hint: {
            success: {
                delete: 'Successfully deleted'
            },
            warn: {
                loginWarn: 'Please log in first',
                getAccountWarn: 'No master account traffic package found',
                deleteWarn: 'Failed to delet',
            },
            error: {
                systemErr: 'System error, please contact customer service',
                getDataErr: 'Failed to query data',
                deleteErr: 'Failed to delet',
            }
        }
    },


    //主账号流量包管理 弹窗
    masterFlowPacket: {
        title: 'Master account traffic packet management',
        addSubaccount: 'Add to sub account',
        deactivate: 'Deactivate',
        enable: 'Enable',
        notAdded: 'Not added',
        totalRecords: '{count} records in total',
        columns: {
            country: 'Country',
            showname: 'Package name',
            price: 'Price(yuan)',
            status: 'Status',
            explain: 'Explain',
            operation: 'Operation'
        },
        hint: {
            success: {
                add: 'Added successfully'
            },
            warn: {
                getDataWarn: 'Failed to obtain data',
                loginWarn: 'Please log in first',
                retryWarn: 'Please try again later. If multiple attempts are unsuccessful, please contact customer service.',
                getAccountWarn: 'No master account traffic package found',
                permissionWarn: 'No permission',
            },
            error: {
                systemErr: 'System error, please contact customer service.',
                getDataErr: 'Failed to query data',
                addErr: 'Failed to add'
            }
        }
    },


    //修改价格 弹窗
    updatePrice: {
        title: 'Modify price',
        country: 'Country name:',
        price_0_0: 'Current price:',
        packageType: 'Traffic packet type:',
        subPrice: 'Sub account price:',
        cancel: "Cancel",
        confirm: 'Confirm',
        dayPacket: 'Day/Packet',
        monthPacket: 'Month/Packet',
        hint: {
            success: {
                operation: 'Operation successfully'
            },
            warn: {
                subPriceWarn1: 'Incorrect sub account price',
                subPriceWarn2: 'No modification of sub account price',
                subPriceWarn3: 'The sub account price must be greater than the current level price',
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                getDataErr: 'Failed to query data',
                operationErr: 'Operation failed'
            }
        }
    },

    //修改姓名 弹窗
    updatename: {
        title: 'Modify Name',
        name: 'Name:',
        nameMsg: 'Please enter name',
        cancel: "Cancel",
        confirm: 'Confirm',
        hint: {
            success: {
                update: 'Successfully modified'
            },
            warn: {
                nameWarn: 'Please enter a new name',
                loginWarn: 'Please log in first',
                operationWarn: 'Operation failed',
                permissionWarn: 'No operation permissions',

            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                updateErr: 'Modification failed, please try again later'
            }
        }
    },


    //修改密码(分销账号管理中的) 弹窗
    updatePasswordAccount: {
        title: 'Change password',
        password: 'New passwod:',
        confirmPassword: 'Confirm password:',
        cancel: "Cancel",
        confirm: 'Confirm',
        passwordMsg: 'Please enter a new password',
        confirmPasswordMsg: 'Please enter a confirmation password',
        hint: {
            success: {
                update: 'Successfully modified'
            },
            warn: {
                loginWarn: 'Please log in first',
                operationWarn: 'Operation failed',
                permissionWarn: 'No operation permissions',
                passwordWarn1: 'The new password cannot be empty',
                passwordWarn2: 'The length of the new password cannot be less than 6 digits',
                confirmPasswordWarn1: 'Confirm password cannot be empty',
                confirmPasswordWarn2: 'The new password does not match the confirmed password',

            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                updateErr: 'Modification failed, please try again later'
            }
        }
    },

    //修改手机号 弹窗
    updatePhone: {
        title: 'Modify mobile phone number',
        phone: 'Mobile phone number:',
        phoneMsg: 'Please enter a mobile phone number',
        verifyCode: 'Verification code:',
        verifyCodeMsg: 'Verification code',
        cancel: "Cancel",
        confirm: 'Confirm',
        codeMsg1: 'Send verification code',
        codeMsg2: 'Retrieve in 60s',
        codeMsg3: "Retrieve in 's",
        hint: {
            success: {
                update: 'Successfully modified',
                sendMsg: 'SMS sent successfully, please pay attention',
            },
            warn: {
                loginWarn: 'Please log in first',
                operationWarn: 'Operation failed',
                permissionWarn: 'No permission',
                phoneWarn1: 'Mobile phone number cannot be empty',
                phoneWarn2: 'Incorrect phone number format',
                verifyCodeWarn1: 'Verification code cannot be empty',
                verifyCodeWarn2: 'Verification code Error',
                verifyCodeWarn3: 'The verification code has expired, please resend it',

            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                updateErr: 'Failed to modify',
                verifyCodeErr: 'Verification code Error',
                sendMsgErr: 'Failed to send SMS'
            }
        }
    },


    //充值 弹窗
    accountRecharge: {
        title: 'Recharge',
        balance: 'Balance:',
        subBalance: 'Sub account balance:',
        recharge: 'Recharge amount:',
        rechargeMsg: 'Please enter the recharge amount',
        cancel: "Cancel",
        confirm: 'Confirm',
        hint: {
            success: {
                recharge: 'Successfully recharged'
            },
            warn: {
                loginWarn: 'Please log in first',
                rechargeWarn1: 'Failed to recharge',
                rechargeWarn2: 'Please enter the recharge amount, it must be greater than 0',
                rechargeWarn3: 'Incorrect format or more than two decimal places',
                rechargeWarn4: 'Please enter the correct recharge amount',
                rechargeWarn5: 'The recharge amount must be greater than 0',
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                updateErr: 'Modification failed, please try again later',
                getDateErr: 'Failed to query data',
            }
        }
    },


    //客服账号管理
    serviceAccount: {
        title: 'Customer service account management',
        createAccount: 'Create Customer service account',
        operation: 'Operation',
        updatePhone: 'Modify mobile phone number',
        updateName: 'Modify Name',
        updatePassword: 'Change password',
        deactivate: 'Deactivate',
        enable: 'Enable',
        totalRecords: '{count} records in total',
        phone: 'Account',
        mainA: 'Master account',
        relaname: 'Name',
        type: 'Type',
        date: 'Creation time',
        hint: {
            success: {
                update: 'Successfully modified'
            },
            warn: {
                loginWarn: 'Please log in first',
                getDateWarn: 'No account found',
                permissionWarn: 'No permission',
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                getDateErr: 'Failed to query data',
            }
        }
    },


    //创建客服账号 弹窗
    createServiceAccount: {
        title: 'Create customer service account',
        phone: 'Cellphone number:',
        verifyCode: 'Verification code:',
        username: 'Account name:',
        password: 'Password:',
        confirmPassword: 'Confirm password:',
        bpmUserType: 'Settlement methods:',
        price: 'Traffic packet price:',
        behalfpay: 'Payment on behalf',
        selfPay: 'Self payment',
        deactivate: 'Deactivate',
        enable: 'Enable',
        hide: 'Hide',
        show: 'Show',
        cancel: "Cancel",
        add: 'Add',
        phoneMsg: 'Please enter a mobile phone number',
        verifyCodeMsg: 'Please enter the verification code',
        usernameMsg: 'Please enter a account name',
        passwordMsg: 'Please a password',
        confirmPasswordMsg: 'Please enter a confirmation password',
        codeMsg1: 'Send verification code',
        codeMsg2: 'Retrieve after 60 seconds',
        codeMsg3: "Retrieve after 'seconds",
        hint: {
            success: {
                create: 'Created successfully',
                update: 'Successfully modified',
                sendMsg: 'SMS sent successfully, please pay attention',
            },
            warn: {
                getDataWarn: 'Failed to obtain data',
                loginWarn: 'Please log in first',
                retryWarn: 'Please try again later. If multiple attempts are unsuccessful, please contact customer service.',
                getAccountWarn: 'No account found',
                permissionWarn: 'No permission',
                phoneWarn1: 'Mobile phone number cannot be empty',
                phoneWarn2: 'Incorrect phone number format',
                codeWarn1: 'Verification code cannot be empty',
                codeWarn2: 'Verification code Error',
                codeWarn3: 'Verification code has expired',
                usernameWarn: 'Account name cannot be empty',
                passwordWarn1: 'Password cannot be empty',
                passwordWarn2: 'The password length cannot be less than 6 digits',
                confirmPasswordWarn1: 'Confirm password cannot be empty',
                confirmPasswordWarn2: 'The password and confirmation password do not match',
                accountWarn1: 'You are not the master account and do not have operation permissions',
                accountWarn2: 'Account already exists',
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                getDataErr: 'Failed to obtain data',
                sendMsgErr: 'Failed to send SMS'
            }
        }

    },

    /**============================================== */























//报表
//套餐列表
packetList: {
    title: 'Package list',
    columns: {
        areaName: 'Area',
        supportNames: 'Supported Country',
        overPrice: 'Excess unit price(￥/MB)',
        price: 'Price(￥/Month)',
    },
        hint: {
            warn: {
                loginWarn: 'Please log in first',

            },
            error: {
                getDataErr: 'Failed to query data',
                systemErr: 'System abnormality, please contact customer service',
            }
        }
},

//终端地域分布
deviceDistribution: {
    title: 'Terminal regional distribution',
    report: 'Export',
    totalRecords: '{count} records in total',
    columns: {
        zone: 'Zone',
        names: 'Country',
        dayes: 'Number of active terminals of the day(Pcs)',
        month: 'Number of active terminals of the month(Pcs)'
    },
    hint: {
        warn: {
            reportWarn: 'No data to export',
            loginWarn: 'Please log in first',

        },
        error: {
            systemErr: 'System abnormality, please contact customer service',
            getDataErr: 'Failed to query data',
        }
    }
},


/**============================================== */





//通知信息
alarmInfo: {
    title: 'Notification information',
    totalRecords: '{count} records in total',
    time: 'Time',
    content: 'Notification content',
    hint: {
        warn: {
            loginWarn: 'Please log in first',
            getDataErr: 'Query failed',
        },
        error: {
            systemErr: 'System abnormality, please contact customer service',
            getDataErr: 'Failed to query data',
        }
    }
},



//device中的详情
inactiveDetail:{
        title: 'Details',
        packetOrder: 'Package order',
        status: 'Status',
        orderNum: 'Order ID:',
        countryCode: 'Book country:',
        packetFlow: 'Package traffice:',
        orderTime: 'Order time:',
        packetTime: 'Package duration:',
        amount: 'Amount:',
        effectiveTime: 'Effective time:',
        usedFlowMaxFlow: 'Used traffic/maximum traffic:',
        autoRenewal: 'Auto renewal',
        packageName: 'Package name:',
        packagePrice: 'Package price:',
        packageCountry: 'Package country:',
        operatorAccount: 'Operation account:',
        orderStatus: 'Order status:',
        operationTime: 'Operation time:',
        startTime: 'Start time:',
        endTime: 'End time:',
        renewAfterUsedUp: 'Do you want to renew after using up the traffic:',
        yes: 'Yes',
        no: 'No',
        year: 'Year',
        month: 'Month',
        day: 'Day',
        normal: 'Normal',
        deactivate: 'Deactivate',
        noData: 'No data',
        hint: {
            warn: {
                loginWarn: 'Please log in first',
                getDataErr: 'Query failed',
            },
            error: {
                systemErr: 'System abnormality, please contact customer service',
                getDataErr: 'Failed to query data',
            }
        }
},



//device中的退款
refundModal: {
    title: 'Refund',
    orderNum: 'Order ID:',
    tsid: 'TSID:',
    beginTime: 'Start usage time:',
    endTime: 'End use time:',
    reason: 'Reason for applying for refund:',
    reasonMsg: 'Please enter the reason for refund',
    cancel: "Cancel",
    confirm: 'Confirm',
    hint: {
        success: {
            refund: 'Refund application in progress',
        },
        warn: {
            loginWarn: 'Please log in first',
            refundWarn1: 'Please enter the reason for refund',
            refundWarn2: 'Unsubscription failed',
            refundWarn3: 'No operation permissions',
            refundWarn4: 'This order cannot be refunded',
        },
        error: {
            systemErr: 'System abnormality, please contact customer service',
            refundErr: 'The operation failed, please try again later',
        }
    }
},



/** 终端归属提示框 */
tipModal: {
    title: 'Tips',
    account: 'Belonging account',
    imei: 'IMEI',
    no: 'No',
    yes: 'Yes'
},


//每日终端活跃数
dayActiveDevice: {
    title: 'Daily number of terminal activities',
    columns: {
        zone: 'Zone',
        namecn: 'Country',
        date: 'Date',
        count: 'Number of active terminals (Pcs)'
    },
    hint: {
        warn: {
            loginWarn: 'Please log in first',
            getDataErr: 'Query failed',
        },
        error: {
            systemErr: 'System abnormality, please contact customer service',
            getDataErr: 'Failed to query data',
        }
    }
},


//每月终端活跃数
monthActiveDevice: {
    title: 'Monthly number of terminal activities',
    columns: {
        zone: 'Zone',
        namecn: 'Country',
        date: 'Month',
        count: 'Number of active terminals (Pcs)'
    },
    hint: {
        warn: {
            loginWarn: 'Please log in first',
            getDataErr: 'Query failed',
        },
        error: {
            systemErr: 'System abnormality, please contact customer service',
            getDataErr: 'Failed to query data',
        }
    }
},



//导出提示
exportTips: {
    title: 'Export Tips',
    cancel: "Cancel",
    confirm: "Confirm",
    tip: 'Please select the export record number range:',
},



//navcon中的忘记密码弹窗
updatePassword: {
    phone: 'Mobile phone number',
    title: 'Change password',
    password: 'New password',
    confirmPassword: 'Confirm new password',
    cancel: "Cancel",
    confirm: 'Confirm',
    passwordMsg: 'Confirm new password',
    verify: 'Verification code',
    refreshVerifyCode: "Can't see clearly, change one",
    verifyCode: 'SMS verification code',
    sendVerify: 'Send verification code',
    codeMsg2: 'Retrieve after 60 seconds',
    codeMsg3: "Retrieve after 'seconds",
    hint: {
        success: {
            update: 'Successfully modified',
            send: 'SMS sent successfully, please pay attention'
        },
        warn: {
            loginWarn: 'Please log in first',
            operationWarn: 'Operation failed',
            permissionWarn: 'No operation permissions',
            phoneWarn1: 'Mobile phone number cannot be empty',
            phoneWarn2: 'Incorrect phone number format',
            passwordWarn1: 'New password cannot be empty',
            passwordWarn2: 'The length of the new password cannot be less than 6 digits',
            confirmPasswordWarn1: 'Confirm password cannot be empty',
            confirmPasswordWarn2: 'The new password does not match the confirmed password',
            verifyWarn1: 'Verification code cannot be empty',
            verifyWarn2: 'Verification code Error',
            verifyCodeWarn: 'SMS verification code cannot be empty',
            updateWarn1: 'Failed to modify',
            updateWarn2: 'Attribution system error',
            updateWarn3: 'Account error',
            updateWarn4: 'Verification code invalid',
            updateWarn5: 'SMS verification code expired',
            updateWarn6: 'The SMS verification code is wrong or the verification code has expired or the verification code does not match the phone number',
            accountWarn1: 'Invalid account',


        },
        error: {
            systemErr: 'System abnormality, please contact customer service',
            updateErr: 'Modification failed, please try again later',
            sendErr: 'Failed to send SMS',
            accountErr1: 'Exception, please contact customer service',

        }
    }
},





}

export default zh;