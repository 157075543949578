<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="68%"
    :footer="null"
    :maskClosable="false"
    :dialogStyle="{ top: '16%' }"
    @cancel="handleCancel"
  >
    <div style="padding: 12px 24px; background-color: #f7f7f7">
      <div style="text-align: right; margin-bottom: 10px">
        <span class="update_note_clear" @click.prevent="verifyDeviceBelong(0)"
          >{{$t('updateNote.emptyNote')}}</span
        >
      </div>
      <div class="update_note_content"  >
        <div>
          <span class="update_note_label">{{$t('updateNote.note')}}</span>
          <a-input v-model="note" :placeholder="$t('updateNote.inputNoteMsg')" :maxLength="150" style="width: 70%" />
        </div>
      </div>
      <div style="text-align: right; padding-top: 24px">
        <span class="update_note_cancel" @click.prevent="cancel">{{$t('updateNote.cancel')}}</span>
        <span class="update_note_confirm" @click.prevent="verifyDeviceBelong(1)">{{$t('updateNote.define')}}</span>
      </div>
    </div>
      <tip-modal
      v-if="tipVisible"
      :content="content"
      :tipTitle="tipTitle"
      @tip-visible="tipCallback"
    >
    </tip-modal>
  </a-modal>
</template>
<script>
import tipModal from "./tipModal.vue"
import { errMsg, warnMsg,isNull,successMsg } from '../../../utils/CommonUtils';
export default {
  components:{
    tipModal
  },
  props: ["visible","selectedRow"],
  data() {
    return {
      title: this.$t('updateNote.title'),
      showModal: false,
      note: "",
      tipVisible: false,
      content: "",
      allImei: "",
      passImei: "",
      tipTitle: "",
      flag: "",
    };
  },
  watch: {
    visible(val) {
      this.showModal = val;
    },
  },
   
  methods: {
    handleCancel() {
      this.note = "";
      this.$emit("update-note-visible", false, 0);
    },
    cancel() {
      this.note = "";
      this.$emit("update-note-visible", false, 0);
    },
    /** 获取拼接的imei字符串 */
     getImei(){
      let imeis = "";
      if (this.selectedRow.length > 1) {
        this.selectedRow.forEach((item) => {
          imeis = imeis + item.imei + ",";
        });
      } else if (this.selectedRow.length == 1){
        imeis = this.selectedRow[0].imei;
      }
      return imeis;
    },
    /** 获取拼接的imei字符串 */
    getImeiStr(imei){
      let imeis = "";
      if (imei.length > 1) {
          for (let i = 0; i < imei.length; i++) {
            imeis = imeis + imei[i] + ",";          
          }
      } else if (imei.length == 1){
        return imei[0];
      }
      return imeis;
    },
    confirm(imei) {
      if(isNull(imei)){
        warnMsg(this.$t('updateNote.hint.warn.addNoteDeviceWarn'));
        return;
      }
      this.axios.post(this.baseUrl + "batchRemark?imei="+imei+"&remark="+this.note,"",{ headers: { token: sessionStorage.getItem("token")}}).then((res)=>{
            if(res.status == 200){
              if(res.data.code == 0){
                successMsg(this.$t('updateNote.hint.success.update'))
                this.note = "";
                this.$emit("update-note-visible", false, 1);
              }else if(res.data.code == 1){
                successMsg(this.$t('updateNote.hint.success.update1'))
                this.note = "";
                this.$emit("update-note-visible", false, 1);
              }else if(res.data.code == -1 && res.data.message == "未登录"){
                warnMsg(this.$t('updateNote.hint.warn.loginWarn'))
                setTimeout (()=>{
                  this.$router.push({ name: "login"});
                },"2000");
              }else if(res.data.code == -3){
                warnMsg(this.$t('updateNote.hint.warn.updateWarn3'))  
              }else if(res.data.code == -2){
                warnMsg(this.$t('updateNote.hint.warn.updateWarn2'))
              }else if(res.data.code == -7){
                errMsg(this.$t('updateNote.hint.error.systemErr'))
              }
            }
          }).catch(()=>{
            errMsg(this.$t('updateNote.hint.error.systemErr2'))
          })
    },
    clearNote(imei) {
      if(isNull(imei)){
        warnMsg(this.$t('updateNote.hint.warn.emptyNoteWarn'));
        return;
      }
       this.axios.post(this.baseUrl + "clear_remark?imei="+imei,"",{ headers: { token: sessionStorage.getItem("token")}}).then((res)=>{
            if(res.status == 200){
              if(res.data.code == 0){
                successMsg(this.$t('updateNote.hint.success.emptyNote'))
                this.note = "";
                this.$emit("update-note-visible", false, 1);
              }else if(res.data.code == 1){
                 errMsg(this.$t('updateNote.hint.error.emptyNoteErr'));
              }else if(res.data.code == -1){
                warnMsg(this.$t('updateNote.hint.warn.loginWarn'))
                setTimeout (()=>{
                  this.$router.push({ name: "login"});
                },"2000");
              }else if(res.data.code == -2){
                errMsg(this.$t('updateNote.hint.error.emptyNoteErr2'))
              } 
            }
          }).catch(()=>{
            errMsg(this.$t('updateNote.hint.error.systemErr2'))
          })
    },
    tipCallback(val, flag0){ // 提示回调
      this.tipVisible = val;
      if (flag0 == 1) { //取消
        if(this.flag == 0){
          this.clearNote(this.passImei);
        }else if(this.flag == 1){
          this.confirm(this.passImei)
        }
      }else if(flag0 == 2){ //确认
        if(this.flag == 0){ //清除备注
          this.clearNote(this.allImei);
        }else if(this.flag == 1){ //添加备注
          this.confirm(this.allImei)
        }
      }
    },
    /** 验证终端归属，如果终端归属账号不是当前登录账号，则提示用户是否继续操作 */
    verifyDeviceBelong(flag){
      let imei = this.getImei()
      if (isNull(imei)) {
            warnMsg(this.$t('updateNote.hint.warn.selectDeviceWarn'));
            return;
      }
      if(flag == 1){
        if(isNull(this.note)){
          warnMsg(this.$t('updateNote.hint.warn.inputNoteWarn'));
          return;
        }
      }
      this.axios.post(this.baseUrl + "verifyImeisBelong",{imeis:imei},{ headers: { token: sessionStorage.getItem("token")}}).then((res)=>{
          if(res.status == 200){
              if(res.data.code == 0){
                let datas = res.data.data;
                if(datas.isMainAccount){
                    if((datas.passCount == 0) && (datas.childCount == 0) && (datas.noPassCount == 0)){  //没有可修改的设备
                      warnMsg(this.$t('updateNote.hint.warn.accountWarn0')) 
                    }else if((datas.childCount == 0) && (datas.noPassCount == 0) && (datas.passCount > 0)){ //全部是主账号的设备
                      let devices = this.getImeiStr(datas.passImei);
                      if(flag == 0){
                        this.clearNote(devices);
                      }else if(flag == 1){
                        this.confirm(devices)
                      }
                  }else {  
                      let devices = datas.passCount > 0 ? this.getImeiStr(datas.passImei):"";
                      let imeis = "";
                      let imeiList = []
                      let childrenImeis = [];
                      let childImei = "";
                      if(datas.noPassCount > 0){
                          imeiList = datas.noPassImei;
                          imeiList.forEach(imei=>{
                            childImei = childImei + imei + ", ";
                            imeis = imeis + imei + ",";
                          })
                          childrenImeis.push({
                            "account": "其他",
                            "imei": childImei,
                          })
                          childImei = "";
                      }
                      
                      if(datas.childCount > 0){
                        for (const key in datas.childImeiMap) {
                          imeiList = datas.childImeiMap[key]
                          imeiList.forEach(imei=>{
                            childImei = childImei + imei + ", ";
                            imeis = imeis + imei + ",";
                          })
                          childImei = childImei.substring(0, childImei.lastIndexOf(","));
                          childrenImeis.push({
                            "account": key,
                            "imei": childImei,
                          })
                          childImei = "";
                        }
                      }
                      this.passImei = devices; //主账号设备
                      this.allImei = imeis + devices;
                      this.tipTitle = this.$t('updateNote.tipTitle');
                      this.content = childrenImeis;
                      this.tipVisible = true;
                      this.flag = flag;
                    }
                }else{
                    if(this.flag == 0){ //清除备注
                      this.clearNote(imei);
                    }else if(this.flag == 1){ //添加备注
                      this.confirm(imei)
                    }
                }
              }else if(res.data.code == -1  && res.data.message == "未登录"){
                warnMsg(this.$t('updateNote.hint.warn.loginWarn'))
                setTimeout (()=>{
                  this.$router.push({ name: "login"});
                },"2000");
              }else if(res.data.code == -2){
                warnMsg(this.$t('updateNote.hint.warn.getDeviceWarn'))
              }else if(res.data.code == -3){
                errMsg(this.$t('updateNote.hint.error.systemErr2'))
              }
          }else{
            warnMsg(this.$t('updateNote.hint.warn.operationWarn'))
          }
        }).catch(()=>{
          errMsg(this.$t('updateNote.hint.error.systemErr2'))
        })
    }

  },
};
</script>
<style scoped>
.update_note_content {
  height: 100px;
  margin: 20px 0px 0px 0px;
  box-shadow: 0px 0px 3px 0px rgb(187, 187, 187);
  padding: 20px 0px 0px 0px;
  background-color: rgb(255, 255, 255);
}
.update_note_label {
  display: inline-block;
  height: 36px;
  line-height: 36px;
  width: 80px;
  text-align: right;
  padding-right: 10px;
}
.update_note_clear {
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  height: 36px;
  min-width: 80px;
  line-height: 36px;
  border: 1px solid rgb(184, 184, 184);
  background-color: rgb(255, 255, 255);
}
.update_note_clear:hover {
  cursor: pointer;
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}

.update_note_cancel,
.update_note_confirm {
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  height: 33px;
  width: 60px;
  line-height: 33px;
}
.update_note_cancel {
  margin-right: 15px;
  border: 1px solid rgb(184, 184, 184);
  background-color: white;
}
.update_note_cancel:hover {
  cursor: pointer;
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}
.update_note_confirm {
  border: 1px solid rgb(78, 78, 255);
  background-color: rgb(78, 78, 255);
  color: white;
}
.update_note_confirm:hover {
  cursor: pointer;
  box-shadow: 0px 0px 2px 0px rgb(23, 23, 255);
}

::v-deep .ant-modal-body {
  padding: 0px;
}
</style>