<template>
  <a-modal
    v-model="showModal"
    :title="title"
    width="68%"
    :footer="null"
    :maskClosable="false"
    :dialogStyle="{ top: '25%' }"
    @cancel="handleCancel"
  >
    <div style="padding: 12px 24px; background-color: #f7f7f7">
      <div class="update_status_content">
        <div style="padding-left: 34px">
          {{$t('updateStatus.status')}}
          <a-select
            :placeholder="$t('updateStatus.selectMsg')"
            class="update_status"
            v-model="status"
          >
            <a-select-option value="0"> {{$t('updateStatus.normal')}} </a-select-option>
            <a-select-option value="2"> {{$t('updateStatus.deactivate')}} </a-select-option>
          </a-select>
        </div>
      </div>
      <div style="text-align: right; padding-top: 24px">
        <span class="update_status_cancel" @click.prevent="cancel">{{$t('updateStatus.cancel')}}</span>
        <span class="update_status_confirm" @click.prevent="verifyDeviceBelong">{{$t('updateStatus.define')}}</span>
      </div>
    </div>
    <tip-modal
      v-if="tipVisible"
      :content="content"
      :tipTitle="tipTitle"
      @tip-visible="tipCallback"
    >
    </tip-modal>
  </a-modal>
</template>
<script>
import tipModal from "./tipModal.vue"
import { errMsg, isNull, successMsg, warnMsg } from "../../../utils/CommonUtils";
export default {
  components:{
    tipModal
  },
  props: ["visible", "selectedRow"],
  data() {
    return {
      title: this.$t('updateStatus.title'),
      showModal: false,
      status: undefined,
      tipVisible: false,
      content: "",
      allImei: "",
      passImei: "",
      tipTitle: "",
    };
  },
  watch: {
    visible(val) {
      this.showModal = val;
    },
  },
  methods: {
    handleCancel() {
      this.status = undefined;
      this.$emit("update-status-visible", false, 0);
    },
    cancel() {
      this.status = undefined;
      this.$emit("update-status-visible", false, 0);
    },
    confirm(imeis) {
      if(isNull(imeis)){
        warnMsg(this.$t('updateStatus.hint.warn.updateWarn'));
        return;
      }
      this.axios.post(this.baseUrl +"statusChange?imei=" +imeis + "&status=" +this.status,"",{ headers: { token: sessionStorage.getItem("token") } }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              successMsg(this.$t('updateStatus.hint.success.update'))
              this.status = undefined;
              this.$emit("update-status-visible", false, 1);
            } else if (res.data.code == 1) {
              successMsg(this.$t('updateStatus.hint.success.update1'))
              this.status = undefined;
              this.$emit("update-status-visible", false, 1);
            } else if (res.data.code == -1) {
              errMsg(this.$t('updateStatus.hint.error.updateErr1'));
            } else if (res.data.code == -2) {
              warnMsg(this.$t('updateStatus.hint.warn.updateWarn2'));
            } else if (res.data.code == -5) {
              warnMsg(this.$t('updateStatus.hint.warn.loginWarn'));
              setTimeout (()=>{
                this.$router.push({ name: "login"});
              },"2000");
            } else if (res.data.code == -7) {
              errMsg(this.$t('updateStatus.hint.error.systemErr'));
            } else if (res.data.code == -6) {
              errMsg(this.$t('updateStatus.hint.error.updateErr6'));
            }
          }
        }).catch(() => {
          errMsg(this.$t('updateStatus.hint.error.systemErr2'));
        });
    },
    getImei(){
      let imeis = "";
      if (this.selectedRow.length > 1) {
        this.selectedRow.forEach((item) => {
          imeis = imeis + item.imei + ",";
        });
      } else if (this.selectedRow.length == 1){
        imeis = this.selectedRow[0].imei;
      }
      return imeis;
    },
     getImeiStr(imei){
      let imeis = "";
      if (imei.length > 1) {
          for (let i = 0; i < imei.length; i++) {
            imeis = imeis + imei[i] + ",";          
          }
      } else if (imei.length == 1){
        return imei[0];
      }
      return imeis;
    },
    tipCallback(val, flag){
      this.tipVisible = val;
      if (flag == 1) { //取消
        this.confirm(this.passImei);
      }else if(flag == 2){ //确认
        this.confirm(this.allImei);
      }
    },
    /** 验证终端归属，如果终端归属账号不是当前登录账号，则提示用户是否继续操作 */
    verifyDeviceBelong(){
      let imei = this.getImei()
      if (isNull(imei)) {
            warnMsg(this.$t('updateStatus.hint.warn.selectDevice'));
            return;
      }
      if (isNull(this.status)) {
        warnMsg(this.$t('updateStatus.hint.warn.selectStatus'));
        return;
      }
      this.axios.post(this.baseUrl + "verifyImeisBelong",{imeis:imei},{ headers: { token: sessionStorage.getItem("token")}}).then((res)=>{
          if(res.status == 200){
              if(res.data.code == 0){
                let datas = res.data.data;
                if(datas.isMainAccount){
                    if((datas.passCount == 0) && (datas.childCount == 0) && (datas.noPassCount == 0)){  //没有可修改的设备
                      warnMsg(this.$t('updateStatus.hint.warn.accountWarn0')) 
                    }else if((datas.childCount == 0) && (datas.noPassCount == 0) && (datas.passCount > 0)){ //全部是主账号的设备
                      let devices = this.getImeiStr(datas.passImei);
                      this.confirm(devices);
                    }else {
                      let devices = datas.passCount > 0 ? this.getImeiStr(datas.passImei):"";
                      let imeis = "";
                      let imeiList = []
                      let childrenImeis = [];
                      let childImei = "";
                      if(datas.noPassCount > 0){
                          imeiList = datas.noPassImei;
                          imeiList.forEach(imei=>{
                            childImei = childImei + imei + ", ";
                            imeis = imeis + imei + ",";
                          })
                          childrenImeis.push({
                            "account": "其他",
                            "imei": childImei,
                          })
                          childImei = "";
                      }
                      
                      if(datas.childCount > 0){
                        for (const key in datas.childImeiMap) {
                          imeiList = datas.childImeiMap[key]
                          imeiList.forEach(imei=>{
                            childImei = childImei + imei + ", ";
                            imeis = imeis + imei + ",";
                          })
                          childImei = childImei.substring(0, childImei.lastIndexOf(","));
                          childrenImeis.push({
                            "account": key,
                            "imei": childImei,
                          })
                          childImei = "";
                        }
                      }
                      this.passImei = devices; //主账号设备
                      this.allImei = imeis + devices;
                      this.tipTitle = this.$t('updateStatus.tipTitle');
                      this.content = childrenImeis;
                      this.tipVisible = true;
                    }
                }else{
                  this.confirm(imei);
                }
              }else if(res.data.code == -1  && res.data.message == "未登录"){
                warnMsg(this.$t('updateStatus.hint.warn.loginWarn'))
                setTimeout (()=>{
                  this.$router.push({ name: "login"});
                },"2000");
              }else if(res.data.code == -2){
                warnMsg(this.$t('updateStatus.hint.warn.getDeviceWarn'))
              }else if(res.data.code == -3){
                errMsg(this.$t('updateStatus.hint.error.systemErr2'))
              }
          }else{
            warnMsg(this.$t('updateStatus.hint.warn.operationWarn'))
          }
        }).catch(()=>{
          errMsg(this.$t('updateStatus.hint.error.systemErr2'))
        })
    }
  },
};
</script>
<style scoped>
.update_status_content {
  height: 100px;
  margin: 20px 0px 0px 0px;
  box-shadow: 0px 0px 3px 0px rgb(187, 187, 187);
  padding: 20px 0px 0px 0px;
  background-color: rgb(255, 255, 255);
}
.update_status {
  display: inline-block;
  width: 200px;
}
.update_status_cancel,
.update_status_confirm {
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  height: 33px;
  width: 60px;
  line-height: 33px;
}
.update_status_cancel {
  margin-right: 15px;
  border: 1px solid rgb(184, 184, 184);
  background-color: white;
}
.update_status_cancel:hover {
  cursor: pointer;
  border: 1px solid rgb(78, 78, 255);
  color: rgb(78, 78, 255);
}
.update_status_confirm {
  border: 1px solid rgb(78, 78, 255);
  background-color: rgb(78, 78, 255);
  color: white;
}
.update_status_confirm:hover {
  cursor: pointer;
  box-shadow: 0px 0px 2px 0px rgb(23, 23, 255);
}

::v-deep .ant-modal-body {
  padding: 0px;
}
</style>