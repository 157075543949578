import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
        activeIndex: 86, /** 当前活跃主菜单 */
        leftMenu: "",   /** 当前活跃侧边栏菜单 */
        role: "",       /** 当前登录的用户角色  是否是客服账号(login.vue界面中有用到) */
    },
    mutations: {
        saveActiveIndex(state, activeIndex) {
            state.activeIndex = activeIndex;
        },
        saveLeftMenu(state, leftMenu) {
            state.leftMenu = leftMenu;
        },
        saveUserRole(state, role) {
            state.role = role;
        },
    }
})

export default store;
