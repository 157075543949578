<template>
  <div>
    <div class="operate_nav">
      <div class="account_info">
        <img class="account_img" src="../../../assets/img/account.png" />
        <div class="account_type account_master">
          <span class="account_realName">{{realName}}</span>
          <div :class="{'form_box_0': currentLanguage === 'zh', 'form_box_0-en': currentLanguage === 'en'}">
            <div >
              <span class="form_box_0_title">{{this.$t('accountDescribe.mainAccount')}}</span>
              <div class="describe_one">
                <div>{{this.$t('accountDescribe.box0.title1')}}</div>
                <div>{{this.$t('accountDescribe.box0.title2')}}</div>
                <div>{{this.$t('accountDescribe.box0.title3')}}</div>
                <div>{{this.$t('accountDescribe.box0.title4')}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="account_type account_service" @click.self="toServiceAccount">
          <span class="account_count account_count_one" v-if="serviceAccountCount > 9">{{serviceAccountCount}}</span>
          <span class="account_count_2 account_count_one" v-if="serviceAccountCount < 10">{{serviceAccountCount}}</span>
          <div :class="{'form_box_1': currentLanguage === 'zh', 'form_box_1-en': currentLanguage === 'en'}">
            <div style="text-align:left;">
              <span class="form_box_1_title">{{this.$t('accountDescribe.customerAccount')}}</span>
              <div class="describe_one">
                 <div>{{this.$t('accountDescribe.box1.title1')}}</div>
                <div>{{this.$t('accountDescribe.box1.title2')}}</div>
                <div>{{this.$t('accountDescribe.box1.title3')}}</div>
              </div>
            </div>
          </div>
        </div>
 
        <div class="account_type account_sub" @click.self="toSubAccount">
          <span class="account_count account_count_two" v-if="subAccountCount > 9">{{subAccountCount}}</span>
          <span class="account_count_2 account_count_two" v-if="subAccountCount < 10">{{subAccountCount}}</span>
          <div :class="{'form_box_2': currentLanguage === 'zh', 'form_box_2-en': currentLanguage === 'en'}">
            <div style="text-align:left;">
              <span class="form_box_2_title">{{this.$t('accountDescribe.distributeAccount')}}</span>
              <div class="describe_one">
                <div>{{this.$t('accountDescribe.box2.title1')}}</div>
                <div>{{this.$t('accountDescribe.box2.title2')}}</div>
                <div>{{this.$t('accountDescribe.box2.title3')}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { errMsg, warnMsg } from "../../../utils/CommonUtils";
export default {
  components: {},
  data() {
    return {
      title: this.$t('accountDescribe.title'),
      subAccountCount: 0,  //子账号数量
      serviceAccountCount: 0,  //客服账号数量
      realName: "",
    };
  },

  created() {
    // 从本地存储中获取当前语言
    const storedLanguage = localStorage.getItem('locale');
    if (storedLanguage) {
      this.currentLanguage = storedLanguage;
    }
    this.init();
  },
  methods: {
    init() {
      this.axios.post(this.baseUrl + "getAccountProfile", "", {
          headers: { token: sessionStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.subAccountCount = res.data.data.subAccountCount;
              this.serviceAccountCount = res.data.data.serviceAccountCount;
              this.realName = res.data.data.realname;
            } else if (res.data.code == -1) {
              warnMsg(this.$t('accountDescribe.hint.warn.loginWarn'));
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, "2000");
            } else if (res.data.code == -2) {
              warnMsg(this.$t('accountDescribe.hint.warn.getDataWarn'));
            }
          } else {
            warnMsg(this.$t('accountDescribe.hint.warn.retryWarn'));
          }
        }).catch(() => {
          errMsg(this.$t('accountDescribe.hint.error.systemErr'));
        });
    },
    /** 跳转到分销账号 */
    toSubAccount(){
      this.$store.commit('saveLeftMenu', 103);
      this.$router.push({ name: "subAccount" });
    },
    /** 跳转到客服账号 */
    toServiceAccount(){
      this.$store.commit('saveLeftMenu', 104);
      this.$router.push({ name: "serviceAccount" });
    }
  },
};
</script>
<style scoped>
/* 操作栏 */
.operate_nav {
  margin-top: 10px;
  text-align: right;
}

.account_realName{
  position: relative;
  left: 12px;
  top: 80px;
}

.operate_nav_title {
  /* 整行标题 */
  float: left;
  font-size: 24px;
  font-weight: bold;
  color: rgb(14, 14, 14);
}
.account_info {
  width: 1049px;
  height: 499px;
  text-align: left;
  position: absolute;
}

.account_type {
  cursor: pointer;
  position: relative;
  width: 75px;
  height: 75px;
}

.account_img{
  position: relative;
  top: 70px;
  left: 192px;
}

.account_master {
  bottom: 150px;
  left: 396px;
  cursor: default;
}

.account_service {
  bottom: 83px;
  left: 192px;
  text-align: right;
}

.account_count{
  background-color: #54ea0a;
  width: 30px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  position: relative;
  color: white;
  text-align: center;
  border-radius: 10px;
}

.account_count_2{
  background-color: #54ea0a;
  width: 22px;
  height: 22px;
  line-height: 22px;
  display: inline-block;
  position: relative;
  color: white;
  text-align: center;
  border-radius: 100%;
}

.account_count_one{
  left: 12px;
  top: -13px;
  cursor: default;
}
.account_count_two{
  left: 12px;
  top: -8px;
  cursor: default;
}
.account_sub {
  left: 597px;
  bottom: 160px;
  text-align: right;
}
.form_box_0 {
  position: relative;
  top: -60px;
  left: 100px;
  width: 550px;
  /* height: 150px; */
  padding: 15px;
  box-shadow: 0px 0px 8px 0px #dadada;
   cursor: default;
}

.form_box_0-en {
  position: relative;
  top: -100px;
  left: 100px;
  width: 650px;
  /* height: 170px; */
  padding: 15px;
  padding-left: 20px;
  box-shadow: 0px 0px 8px 0px #dadada;
   cursor: default;
}

.form_box_1 {
  position: relative;
  top: 75px;
  left: -166px;
  width: 410px;
  height: 130px;
   padding-top: 15px;
  padding-left: 20px;
  box-shadow: 0px 0px 8px 0px #dadada;
  cursor: default;
}

.form_box_1-en {
  /* 英文语言下的样式 */
  position: relative;
  top: 75px;
  left: -166px;
  width: 410px;
  height: 210px;
  padding-top: 15px;
  padding-left: 20px;
  box-shadow: 0px 0px 8px 0px #dadada;
  cursor: default;
}

.form_box_2 {
  position: relative;
  top: 75px;
  left: -100px;
  width: 550px;
  height: 160px;
  padding: 15px 5px 0px 20px;
  box-shadow: 0px 0px 8px 0px #dadada;
  cursor: default;
}

.form_box_2-en {
  position: relative;
  top: 75px;
  left: -100px;
  width: 650px;
  height: 270px;
  padding: 15px 5px 0px 20px;
  box-shadow: 0px 0px 8px 0px #dadada;
  cursor: default;
}

.describe_one{
  margin-top: 10px;
  color: #6d6d6d;
}

.form_box_0_title{
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 2px;
  border-bottom: 2px solid #4f94ff;
}
.form_box_1_title{
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 2px;
  border-bottom: 2px solid #ff8d02;
}
.form_box_2_title{
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 2px;
  border-bottom: 2px solid #47cc7f;
}
</style>
 